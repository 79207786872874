/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import classnames from "classnames";
import axios from 'axios'
import {
    Button,
    CardHeader,
    CardTitle,
    Card,
    CardBody,
    Breadcrumb,
    BreadcrumbItem,
    TabContent,
    TabPane,
    NavLink,
    Modal,
    ModalBody,
    Col,
    Row,
    FormGroup,
    Label,
    Spinner
} from "reactstrap";

import Select from "react-select";
import '../AvailableHours/AvailableHours.css'
import MyCalendar from "../../Calendar/MyCalendar"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faMinus, faPlus, faTimesCircle, faExclamationCircle, faTrash } from "@fortawesome/free-solid-svg-icons"

import Header from "../../../components/Header";
import HeaderTitle from "../../../components/HeaderTitle";
import Navigation from "../SubNav";
import { GlobalState } from "../../../contextAPI/Context";
import { useLocation } from "react-router-dom";
import moment from 'moment';
import momentTimeZone from 'moment-timezone';
import DeleteConfirmationModal from './DeleteConfirmationModal'


let token = localStorage.getItem('token');
let timeZone = ""; // eslint-disable-line
let utc_offset = "";
const dayToNumber = { "sunday": 1, "monday": 2, "tuesday": 3, "wednesday": 4, "thursday": 5, "friday": 6, "saturday": 7 };
const numberToDay = { "1": "sunday", "2": "monday", "3": "tuesday", "4": "wednesday", "5": "thursday", "6": "friday", "7": "saturday" };

// OBTAINING BASE URL BASED ON ENVIRONMENT
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const TimeLabel = ({ day, index, startTime, specialityId, endTime, deleteLabel, details, specialities }) => {
    const [deleteModalState, setDeleteModalState] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const getSpecialityText = (specialityId) => {
        const selectedSpeciality = specialities.filter(speciality => speciality.value === Number(specialityId));
        return selectedSpeciality && selectedSpeciality.length > 0 ? selectedSpeciality[0].label : '-';
    };

    const capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    const deleteSlot = () => {
        setModalMessage(`Are you sure you want to delete the slot from ${startTime} to ${endTime} for ${capitalize(day)}`)
        setDeleteModalState(true);
    };

    const setDeleteApproved = (deleted) => {
        setDeleteModalState(false);
        deleteLabel(day, index);
    };

    return (
        <>
            <div className="doc-slot-list">
                <div className="time-slot-label">
                    {startTime} - {endTime}
                </div>
                <a className="delete_schedule" >
                    <FontAwesomeIcon
                        icon={faTrash}
                        className="align-middle hand-cursor"
                        color="white"
                        onClick={() => deleteSlot()}
                    />
                </a>
                <p className="time-slot-speciality-text">{getSpecialityText(specialityId)}</p>
            </div>
            <DeleteConfirmationModal
                modalHeading="Delete Slot"
                deleteModalState={deleteModalState}
                modalMessage={modalMessage}
                setDeleteModalState={setDeleteModalState}
                setDeleteApproved={setDeleteApproved}
            />
        </>
    )
}
const getFullWeekDetails = () => {
    let week = []
    var d = new Date();
    d.setDate(d.getDate() - d.getDay() - 1);
    for (let i = 0; i <= 6; i++) {
        d.setDate(d.getDate() + 1);
        let month = d.getMonth() + 1;
        if (month < 10) {
            month = "0" + month;
        }
        let date = d.getDate();
        if (date < 10) {
            date = "0" + date;
        }
        week.push(d.getFullYear() + "-" + month + "-" + date);
    }
    return week;
}

const TimeSlot = ({ newSlot, index, slotAdd, slotRemove, changeSlot, lastItem, consulatationDuration }) => {
    const allAvailableStartTimeSlots = ["Select", "00:00 AM", "00:15 AM", "00:30 AM", "00:45 AM", "01:00 AM", "01:15 AM", "01:30 AM", "01:45 AM", "02:00 AM", "02:15 AM", "02:30 AM", "02:45 AM", "03:00 AM", "03:15 AM", "03:30 AM",
        "03:45 AM", "04:00 AM", "04:15 AM", "04:30 AM", "04:45 AM", "05:00 AM", "05:15 AM", "05:30 AM", "05:45 AM", "06:00 AM", "06:15 AM", "06:30 AM", "06:45 AM", "07:00 AM", "07:15 AM", "07:30 AM", "07:45 AM", "08:00 AM", "08:15 AM",
        "08:30 AM", "08:45 AM", "09:00 AM", "09:15 AM", "09:30 AM", "09:45 AM", "10:00 AM", "10:15 AM", "10:30 AM", "10:45 AM", "11:00 AM", "11:15 AM", "11:30 AM", "11:45 AM",
        "12:00 PM", "12:15 PM", "12:30 PM", "12:45 PM", "01:00 PM", "01:15 PM", "01:30 PM", "01:45 PM", "02:00 PM", "02:15 PM", "02:30 PM", "02:45 PM", "03:00 PM", "03:15 PM", "03:30 PM", "03:45 PM", "04:00 PM", "04:15 PM", "04:30 PM",
        "04:45 PM", "05:00 PM", "05:15 PM", "05:30 PM", "05:45 PM", "06:00 PM", "06:15 PM", "06:30 PM", "06:45 PM", "07:00 PM", "07:15 PM", "07:30 PM", "07:45 PM", "08:00 PM", "08:15 PM", "08:30 PM", "08:45 PM", "09:00 PM", "09:15 PM", "09:30 PM", "09:45 PM", "10:00 PM", "10:15 PM", "10:30 PM", "10:45 PM"];
    const allAvailableEndTimeSlots = ["Select", "00:15 AM", "00:30 AM", "00:45 AM", "01:00 AM", "01:15 AM", "01:30 AM", "01:45 AM", "02:00 AM", "02:15 AM", "02:30 AM", "02:45 AM", "03:00 AM", "03:15 AM", "03:30 AM",
        "03:45 AM", "04:00 AM", "04:15 AM", "04:30 AM", "04:45 AM", "05:00 AM", "05:15 AM", "05:30 AM", "05:45 AM", "06:00 AM", "06:15 AM", "06:30 AM", "06:45 AM", "07:00 AM", "07:15 AM", "07:30 AM", "07:45 AM", "08:00 AM", "08:15 AM", "08:30 AM", "08:45 AM", "09:00 AM", "09:15 AM", "09:30 AM", "09:45 AM", "10:00 AM", "10:15 AM", "10:30 AM", "10:45 AM", "11:00 AM", "11:15 AM", "11:30 AM", "11:45 AM",
        "12:00 PM", "12:15 PM", "12:30 PM", "12:45 PM", "01:00 PM", "01:15 PM", "01:30 PM", "01:45 PM", "02:00 PM", "02:15 PM", "02:30 PM", "02:45 PM", "03:00 PM", "03:15 PM", "03:30 PM", "03:45 PM", "04:00 PM", "04:15 PM", "04:30 PM",
        "04:45 PM", "05:00 PM", "05:15 PM", "05:30 PM", "05:45 PM", "06:00 PM", "06:15 PM", "06:30 PM", "06:45 PM", "07:00 PM", "07:15 PM", "07:30 PM", "07:45 PM", "08:00 PM", "08:15 PM", "08:30 PM", "08:45 PM", "09:00 PM", "09:15 PM", "09:30 PM", "09:45 PM", "10:00 PM", "10:15 PM", "10:30 PM", "10:45 PM", "11:00 PM"];
    const [availableStartTimeSlots] = useState(allAvailableStartTimeSlots);
    const [availableEndTime, setAvailableEndTime] = useState(allAvailableEndTimeSlots);
    const [dummySlot, setDummySlot] = useState(newSlot);
    let availableStartTimeSlotsOptions = availableStartTimeSlots.map(function (time) {
        return { value: time, label: time };
    })

    useEffect(() => {
        if (availableEndTime.indexOf(dummySlot.endTime) < 0) {
            setDummySlot({ startTime: dummySlot.startTime, endTime: availableEndTime[0] })
            changeSlot(index, { startTime: dummySlot.startTime, endTime: availableEndTime[0] });
        }
    }, [availableEndTime, dummySlot.endTime]); // eslint-disable-line

    useEffect(() => {
        let sliceCount = (consulatationDuration / 60) / 15;
        if (dummySlot.startTime !== "Select") {
            let indexToSplice = allAvailableStartTimeSlots.indexOf(dummySlot.startTime) + (sliceCount > 1 ? sliceCount - 1 : 0);
            setAvailableEndTime(allAvailableEndTimeSlots.slice(indexToSplice));
        }
    }, [dummySlot.startTime]); // eslint-disable-line

    useEffect(() => {
        setDummySlot(newSlot);
    }, [newSlot])

    const handleChangeStartTime = (event) => {
        setDummySlot({ startTime: event.value, endTime: dummySlot.endTime })
        changeSlot(index, { startTime: event.value, endTime: dummySlot.endTime });
    }
    const handleChangeEndTime = (event) => {
        setDummySlot({ startTime: dummySlot.startTime, endTime: event.value })
        changeSlot(index, { startTime: dummySlot.startTime, endTime: event.value });
    }
    let availableEndTimeSlotsOptions = availableEndTime.map(function (time) {
        return { value: time, label: time };
    });
    return (
        <Row className="time-duration-row">
            <Col md="5">
                <FormGroup>
                    <Label>Start Time</Label>
                    <Select
                        menuPlacement="auto"
                        className="react-select-container startTime"
                        classNamePrefix="react-select"
                        options={availableStartTimeSlotsOptions}
                        placeholder="Select Start Time"
                        value={availableStartTimeSlotsOptions.filter(option => option.value === dummySlot.startTime)}
                        onChange={e => handleChangeStartTime(e)}
                        isSearchable
                    />
                </FormGroup>
            </Col>
            <Col md="4">
                <FormGroup>
                    <Label>End Time</Label>
                    <Select
                        menuPlacement="auto"
                        className="react-select-container endTime"
                        classNamePrefix="react-select"
                        options={availableEndTimeSlotsOptions}
                        value={availableEndTimeSlotsOptions.filter(option => option.value === dummySlot.endTime)}
                        placeholder="Select End Time"
                        onChange={e => handleChangeEndTime(e)}
                        isSearchable
                    />
                </FormGroup>
            </Col>
            <Col md="3">
                <div className="add-row-btn">
                    <label class="d-md-block d-sm-none d-none">&nbsp;</label>
                    {lastItem ? (
                        <>
                            <Button className="mr-1 mb-1 delete-row-btn btn-primary" onClick={() => slotRemove(index, dummySlot)}>
                                <FontAwesomeIcon icon={faMinus} />
                            </Button>
                            {dummySlot.startTime !== 'Select' && dummySlot.endTime !== 'Select' ?
                                (
                                    <>
                                        <Button className="mr-1 mb-1 add-row-btn btn-primary" onClick={() => slotAdd(dummySlot)}>
                                            <FontAwesomeIcon icon={faPlus} />
                                        </Button>
                                    </>
                                ) : null
                            }
                        </>
                    ) : dummySlot.startTime !== 'Select' && dummySlot.endTime !== 'Select' ?
                        (
                            <>
                                <Button className="mr-1 mb-1 delete-row-btn btn-primary" onClick={() => slotRemove(index, dummySlot)}>
                                    <FontAwesomeIcon icon={faMinus} />
                                </Button></>
                        ) : null
                    }
                </div>
            </Col>
        </Row>
    )
}

const ModalAddTimeSlot = ({ details, isOpen, toggle, currentTab, updateModalSlots, handleSubmit, updateTimeSlotLabels, updateSelectedSpeciality, updateMergeError, updateShowMergeError }) => {
    // LOCAL VARIABLES
    const [fields, setFields] = useState(updateModalSlots);
    const [mergeError, setMergeError] = useState(updateMergeError);
    const [showMergeError, setShowMergeError] = useState(updateShowMergeError);
    const [specialities, setSpecialities] = useState([]);
    const [selectedSpeciality, setSelectedSpeciality] = useState(null);
    const [consulatationDuration, setConsultationDuration] = useState(30);
    const [newerSlots, setNewerSlots] = useState([{ startTime: 'Select', endTime: 'Select' }]);
    const [confirmationModalHeading, setConfirmationModalHeading] = useState('');
    const [confirmationModalMessage, setConfirmationModalMessage] = useState('');
    const [deleteModalState, setDeleteModalState] = useState(false);
    const [callbackData, setCallbackData] = useState(false);

    useEffect(() => {
        setShowMergeError(updateShowMergeError);
        setMergeError(updateMergeError);
    }, [updateShowMergeError, updateMergeError])

    useEffect(() => {
        if (details && details.physicianData && details.physicianData.appointmentFee) {
            let specialityData = details.physicianData.appointmentFee.map((item) => { return { "value": item.specialityID, "label": item.speciality, "duration": item.duration ? parseInt(item.duration) : 30 } });
            setSpecialities(specialityData);
            if (details.physicianData.appointmentFee.length > 0) {
                setConsultationDuration(details.physicianData.appointmentFee[0].duration ? parseInt(details.physicianData.appointmentFee[0].duration) : 30);
                setSelectedSpeciality(details.physicianData.appointmentFee[0].specialityID);
                updateSelectedSpeciality(details.physicianData.appointmentFee[0].specialityID);
            }
        }
    }, [details, updateSelectedSpeciality]);

    const handleAddSlot = (slot) => {
        setNewerSlots([...newerSlots, slot]);
    }

    const changeSlot = (index, slot) => {
        const values = [...newerSlots];
        values[index] = slot;
        setNewerSlots(values);
    }

    const handleRemoveSlot = (index, slot) => {
        const values = [...newerSlots];
        values.splice(index, 1);
        setNewerSlots(values);

    }

    const getSpecialityText = (specialityId) => {
        const conflictedSpeciatlity = specialities.filter(speciality => speciality.value === Number(specialityId));
        return conflictedSpeciatlity && conflictedSpeciatlity.length > 0 ? conflictedSpeciatlity[0].label : '-';
    }

    const getMergeMessage = () => {
        return `The selected time frame for <b>${getSpecialityText(mergeError.selected.specialityId)}</b> is overlapping with slots configured against <b>${getSpecialityText(mergeError.conflict.specialityId)}</b>. Please try another time slot`
    }

    const getTimeString = (timeInt) => {
        let hr = Math.floor(timeInt);
        let min = (timeInt % 1) * 60;
        min = min === 0 ? "00" : min;
        let timeMedian = "AM";
        if (hr > 12) {
            hr = hr - 12;
            timeMedian = "PM";
        } else if (hr === 12) {
            timeMedian = "PM";
        }
        hr = hr < 10 ? "0" + hr : hr;
        return hr + ":" + min + " " + timeMedian;
    }

    const removeDuplicate = (values) => {
        let jsonObject = values.map(JSON.stringify);
        let uniqueSet = new Set(jsonObject);
        let uniqueArray = Array.from(uniqueSet).map(JSON.parse);
        return uniqueArray;
    }

    const processFields = (newerSlots, force) => {
        let values;
        if (force) {
            values = [...newerSlots];
        } else {
            values = [...fields];
            if (newerSlots) {
                newerSlots = newerSlots.filter(slot => slot.startTime != 'Select' && slot.endTime != 'Select'); // eslint-disable-line
                values = values.concat(newerSlots);
            }
        }
        values = removeDuplicate(values);
        values = values.map(field => {
            var fieldObj = field;
            for (let props in fieldObj) {
                if (fieldObj.hasOwnProperty(props) && props !== "specialityId") {

                    let hr = parseInt(fieldObj[props].split(":")[0]);
                    let minFraction = parseInt(fieldObj[props].split(":")[1]) / 60;
                    if (fieldObj[props].indexOf(" PM") > -1) {
                        fieldObj[props] = fieldObj[props].replace(" PM", "");
                        if (parseInt(fieldObj[props].split(":")[0]) < 12) {
                            hr = 12 + hr;
                        }
                    }
                    else if (fieldObj[props].indexOf(" AM") > -1) {
                        fieldObj[props] = fieldObj[props].replace(" AM", "");
                        if (parseInt(fieldObj[props].split(":")[0]) === 12) {
                            hr = 0;
                        }
                    }
                    fieldObj[props] = hr + minFraction;

                }
            }
            return fieldObj;
        });
        values.sort(function (a, b) { return a.startTime - b.startTime });
        let processTimeSlots = [];
        let mergeError = {
            conflict: null,
            selected: null
        };
        values.forEach((value, index, valueArray) => {
            if (value.endTime === 0) {
                value.endTime = 24;
            }
            if (valueArray[index + 1] && valueArray[index + 1].endTime === 0) {
                valueArray[index + 1].endTime = 24;
            }
            if (index !== valueArray.length - 1) {
                if (value.startTime <= valueArray[index + 1].startTime && valueArray[index + 1].startTime <= value.endTime) {
                    if (value.specialityId === valueArray[index + 1].specialityId) {
                        valueArray[index + 1].startTime = value.startTime;
                        if (valueArray[index + 1].endTime > value.endTime) {
                            value.endTime = valueArray[index + 1].endTime;
                        } else {
                            valueArray[index + 1].endTime = value.endTime;
                        }
                    } else {
                        if (value.endTime !== valueArray[index + 1].startTime) {
                            if (valueArray[index + 1].specialityId === selectedSpeciality) {
                                mergeError['conflict'] = {
                                    startTime: getTimeString(value.startTime),
                                    endTime: getTimeString(value.endTime),
                                    specialityId: value.specialityId
                                };
                                mergeError['selected'] = {
                                    startTime: getTimeString(valueArray[index + 1].startTime),
                                    endTime: getTimeString(valueArray[index + 1].endTime),
                                    specialityId: valueArray[index + 1].specialityId
                                }
                            } else {
                                mergeError['conflict'] = {
                                    startTime: getTimeString(valueArray[index + 1].startTime),
                                    endTime: getTimeString(valueArray[index + 1].endTime),
                                    specialityId: valueArray[index + 1].specialityId
                                }
                                mergeError['selected'] = {
                                    startTime: getTimeString(value.startTime),
                                    endTime: getTimeString(value.endTime),
                                    specialityId: value.specialityId
                                }
                            }
                        }
                    }
                }

            }
            if (value.endTime === 24) {
                value.endTime = 0;
            }
            if (valueArray[index + 1] && valueArray[index + 1].endTime === 24) {
                valueArray[index + 1].endTime = 0;
            }
            if (processTimeSlots.length > 0 && processTimeSlots[processTimeSlots.length - 1].startTime === value.startTime && processTimeSlots[processTimeSlots.length - 1].specialityId === value.specialityId) {
                processTimeSlots[processTimeSlots.length - 1].endTime = value.endTime;
            } else {
                processTimeSlots.push(value);
            }
        });
        processTimeSlots = processTimeSlots.map(field => {
            var fieldObj = field;
            for (let props in fieldObj) {

                if (fieldObj.hasOwnProperty(props) && props !== "specialityId") {
                    let hr = Math.floor(fieldObj[props]);
                    let min = (fieldObj[props] % 1) * 60;
                    min = min === 0 ? "00" : min;
                    let timeMedian = "AM";
                    if (hr > 12) {
                        hr = hr - 12;
                        timeMedian = "PM";
                    } else if (hr === 12) {
                        timeMedian = "PM";
                    }
                    hr = hr < 10 ? "0" + hr : hr;
                    fieldObj[props] = hr + ":" + min + " " + timeMedian;
                }

            }
            return fieldObj;
        });
        return { processedSlots: processTimeSlots, error: mergeError };
    }

    const deleteAllSlots = (currentTab) => {
        setConfirmationModalHeading('Delete All Slots');
        setConfirmationModalMessage(`Are you sure you want to delete all slots for ${capitalize(currentTab)} ?`);
        setCallbackData({
            type: 'delete',
            currentTab: currentTab
        });
        setDeleteModalState(true);
    }

    const updateDeletedSlot = (currentTab, slots) => {
        var { processedSlots } = processFields(slots, true);
        handleSubmit(currentTab, processedSlots, "singleDate");
    }

    const saveToSlots = (currentTab, type) => {
        setMergeError({});
        setShowMergeError(false);
        let otherSpecialityTiming = fields.filter(field => field.specialityId !== selectedSpeciality);
        let newSlots = newerSlots.map((slot) => {
            slot.specialityId = selectedSpeciality
            return slot;
        });
        newSlots = [...otherSpecialityTiming, ...newSlots];
        var { error, processedSlots } = processFields(newSlots);
        if (error && error.conflict) {
            error.day = currentTab;
            setMergeError(error);
            setShowMergeError(true);
        } else {
            if (type === "all") {
                setConfirmationModalHeading('Copy To All Days');
                setConfirmationModalMessage('Are you sure you want to copy the slots to all days ?');
                setCallbackData({
                    type: 'copy',
                    currentTab: currentTab,
                    processedSlots: processedSlots,
                });
                setDeleteModalState(true);
            } else {
                handleSubmit(currentTab, processedSlots, "singleDate");
            }
        }
    }

    const capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    const deleteLabel = (day, index) => {
        const values = [...fields];
        values.splice(index, 1);
        setFields(values);
        updateDeletedSlot(currentTab, values);
    }

    const handleSpecialityChange = (event) => {
        setConsultationDuration(event.duration)
        setSelectedSpeciality(event.value)
        updateSelectedSpeciality(event.value);
    }

    const deleteConfirmation = (callbackData) => {
        if (callbackData.type === 'copy') {
            handleSubmit(callbackData.currentTab, callbackData.processedSlots, "all");
        } else if (callbackData.type === 'delete') {
            handleSubmit(callbackData.currentTab, [], "singleDate");
        }
        setDeleteModalState(false);
    }

    return (
        <>
            <Modal
                isOpen={isOpen} toggle={toggle} Currenttab={currentTab}
                centered
            >
                <h3 className="ModalHeader mt-2">
                    Configure Availability For {capitalize(currentTab)}
                </h3>
                <div className="modal-close">
                    <FontAwesomeIcon
                        icon={faTimesCircle}
                        onClick={() => toggle()}
                    />
                </div>
                <div className="modal-border mt-3"></div>


                <ModalBody>
                    <Card className="card-element">
                        <h4 className="modal-title" style={{ marginBottom: "7px" }}>Existing Slots : </h4>
                        <div className="doc-times">
                            {fields.length > 0 ?
                                fields.map((field, idx) => {
                                    return (
                                        <>
                                            <TimeLabel startTime={field.startTime} specialityId={field.specialityId} endTime={field.endTime} deleteLabel={deleteLabel} day={currentTab} index={idx} details={details} specialities={specialities} />
                                        </>
                                    );
                                }) : <div>No Slots Configured</div>}
                        </div>
                        {fields.length > 0 ?
                            <div className="btn-wrapper">
                                <Button /* color="secondary" */ className="delete-btn-config-modal" onClick={() => deleteAllSlots(currentTab)}>Delete All Slots for {capitalize(currentTab)}</Button>{" "}
                                <Button /* color="primary" */ className="copy-btn-config-modal" onClick={() => saveToSlots(currentTab, 'all')}>Copy to All Days</Button>{" "}
                            </div>
                            : null
                        }
                    </Card>
                    <Card className="card-element">
                        {showMergeError ?
                            <div className="slot-conflict">
                                <FontAwesomeIcon
                                    icon={faExclamationCircle}
                                    color="red"
                                />
                                <div class="slot-conflict-text" dangerouslySetInnerHTML={{ __html: getMergeMessage() }} />
                            </div>
                            : null}
                        <h4 className="modal-title" style={{ marginBottom: "7px" }}>Select Speciality : </h4>
                        <Select
                            className="react-select-container speciality"
                            classNamePrefix="react-select"
                            options={specialities}
                            placeholder="Select Speciality"
                            value={specialities.filter(option => option.value === selectedSpeciality)}
                            onChange={e => handleSpecialityChange(e)}
                        />
                        <h4 className="modal-title" style={{ marginBottom: "7px", marginTop: "20px" }}>Add New Slot :</h4>
                        {newerSlots.length > 0 ?
                            newerSlots.map((newSlot, idx) => {
                                return (
                                    <>
                                        <TimeSlot newSlot={newSlot} index={idx} changeSlot={changeSlot} slotAdd={handleAddSlot} slotRemove={handleRemoveSlot} lastItem={newerSlots.length - 1 === idx} consulatationDuration={consulatationDuration} />
                                    </>
                                );
                            }) : <Button className="mr-1 mb-1 add-row-btn btn-primary" onClick={() => setNewerSlots([{ startTime: 'Select', endTime: 'Select' }])}>
                                <FontAwesomeIcon icon={faPlus} />
                            </Button>
                        }
                        <div className="btn-wrapper">
                            <Button /* color="primary" */ className="copy-btn-config-modal" onClick={() => saveToSlots(currentTab)}>Save for {capitalize(currentTab)}</Button>
                        </div>
                    </Card>
                </ModalBody>
            </Modal>
            <DeleteConfirmationModal modalHeading={confirmationModalHeading}
                modalMessage={confirmationModalMessage}
                deleteModalState={deleteModalState}
                setDeleteModalState={setDeleteModalState}
                setDeleteApproved={deleteConfirmation}
                callbackData={callbackData} />
        </>
    );
}

const AvailableHoursInfo = () => {
    // GLOBAL CONTEXT
    const { spinner, setSpinner, setPhysicianId, setPhysicianName, physicianName } = useContext(GlobalState);
    const [details, setDetails] = useState("");
    const [specialities, setSpecialities] = useState([]);
    const [mergeError, setMergeError] = useState({});
    const [showMergeError, setShowMergeError] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);
    const [PhysicianSpecialityDropDownList, setPhysicianSpecialityDropDownList] = useState("");
    const [PhysicianSpeciality, setPhysicianSpeciality] = useState({ value: "", label: "All Speciality" });
    const [actionHandleSubmit, setActionHandleSubmit] = useState(false);
    const [userId, setUserId] = useState("");
    const [timeZoneCard, setTimeZoneCard] = useState("");
    let userID = localStorage.getItem("userId");

    // GET URL PARAMETERS 
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();


    // EFFECT TO GET THE URL PARAMETERS 
    useEffect(() => {
        if (query) {
            setUserId(Number(atob(query.get("pid"))));
            setPhysicianId(Number(atob(query.get("pid"))));
            setPhysicianName(atob(query.get("pname")));
        }
    }, [query])// eslint-disable-line

    useEffect(() => {
        if (userId && pageLoading) {
            getAllAvailableSlot();
            setPageLoading(false);
        }
    }, [userId, pageLoading, PhysicianSpeciality]) // eslint-disable-line

    useEffect(() => { createDetailsVariableDataStructure() }, [userId]) // eslint-disable-line

    useEffect(() => {
        let specialityData = [];
        if (details && details.physicianData && details.physicianData.appointmentFee) {
            specialityData = details.physicianData.appointmentFee.map((item) => { return { "value": item.specialityID, "label": item.speciality } });
            setSpecialities(specialityData);
        }
        let specialityDropDownData = [{
            value: "", label: "All Speciality"
        }]
        setPhysicianSpeciality({
            value: "", label: "All Speciality"
        });
        specialityDropDownData = [...specialityDropDownData, ...specialityData];
        if (specialityDropDownData.length > 1) { setPhysicianSpecialityDropDownList(specialityDropDownData) }
    }, [details]);

    const [activeTab, setActiveTab] = useState("sunday");


    const [timeslot, settimeslot] = useState({});
    const [timeSlotLabels, setTimeSlotLabels] = useState([]);
    const [filteredtimeSlotLabels, setFilteredTimeSlotLabels] = useState([]);
    const [calendarSlot, setcalendarSlot] = useState({});
    const [calendarSlotCopy, setcalendarSlotCopy] = useState({});
    const [selectedSpeciality, setSelectedSpeciality] = useState(null);
    const addCalendarSlot = (slotObj) => {
        let currentSlots = JSON.parse(JSON.stringify(calendarSlot));
        currentSlots.push(slotObj);
        setcalendarSlot(currentSlots);
        setcalendarSlotCopy(currentSlots);
    }

    useEffect(() => {

        if (PhysicianSpeciality.label && calendarSlotCopy) {
            if (PhysicianSpeciality.label === "All Speciality") {
                setcalendarSlot(calendarSlotCopy)
                setTimeSlotLabels(filteredtimeSlotLabels)
            } else {
                let filteredCalenderSlots = calendarSlotCopy.filter(slots => slots.extendedProps.specialityText === PhysicianSpeciality.label)
                setcalendarSlot(filteredCalenderSlots);
                let filteredTimeLabelSlots = filteredtimeSlotLabels.filter(slots => slots.specialityId === PhysicianSpeciality.value.toString())
                setTimeSlotLabels(filteredTimeLabelSlots);
            }
        }
    }, [PhysicianSpeciality, actionHandleSubmit, activeTab, calendarSlotCopy, filteredtimeSlotLabels]);

    const removeCalendarSlot = (slotObj) => {
        let currentSlots = JSON.parse(JSON.stringify(calendarSlot));
        currentSlots = currentSlots.filter(slot => slot.start !== slotObj.start && slot.end !== slotObj.end)
        setcalendarSlot(currentSlots);
        setcalendarSlotCopy(currentSlots);
    }
    const [updateModalSlots, setUpdateModalSlots] = useState([]);
    const updateSlotDetails = (day, fields) => {
        let currentSlot = timeslot;
        currentSlot[day] = fields;
        if (activeTab === day)
            setTimeSlotLabels(currentSlot[day]);
        setFilteredTimeSlotLabels(currentSlot[day]);
        settimeslot(currentSlot);
        setModal(false);
    }
    const updateCalendarSlotDetails = (datesValue) => {
        setcalendarSlot(datesValue);
        setcalendarSlotCopy(datesValue);

    }
    const toggle = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
        setActionHandleSubmit(!actionHandleSubmit)
        setTimeSlotLabels(timeslot[tab] ? timeslot[tab] : []);
        setFilteredTimeSlotLabels(timeslot[tab] ? timeslot[tab] : []);
    };
    const [modal, setModal] = useState(false);
    const triggerModal = () => {
        setMergeError({});
        setShowMergeError(false);
        if (!modal) {
            let timeslotForModal = JSON.parse(JSON.stringify(timeslot));
            setUpdateModalSlots(timeslotForModal[activeTab] && timeslotForModal[activeTab].length ? timeslotForModal[activeTab] : []);
        }
        setModal(!modal);
    }
    const deleteLabel = (day, index) => {
        let currentSlot = timeslot;
        if (currentSlot[day]) {
            if (PhysicianSpeciality.label && PhysicianSpeciality.label === "All Speciality") {
                currentSlot[day].splice(index, 1);
            } else {
                let otherSpecialitySlots = currentSlot[day].filter(field => field.specialityId !== PhysicianSpeciality.value);
                let selectedSpecialitySlots = currentSlot[day].filter(field => field.specialityId === PhysicianSpeciality.value.toString());
                selectedSpecialitySlots.splice(index, 1);
                currentSlot[day] = [...otherSpecialitySlots, ...selectedSpecialitySlots];
            }
        }
        setTimeSlotLabels(currentSlot[day]);
        settimeslot(currentSlot);
        setFilteredTimeSlotLabels(currentSlot[day]);
        handleSubmit(day, currentSlot[day], "deleteSlot")
    };

    const getDuration = () => details.physicianData.appointmentFee && details.physicianData.appointmentFee[0] && details.physicianData.appointmentFee[0].duration ? details.physicianData.appointmentFee[0].duration : '30';
    const handleSubmit = (currentTab, fields, type) => {
        let newarray = [];
        let arrayOfTimeSlots = []
        let currentTimeSlots = timeslot;
        let mappedLocalUTCTime = {};

        if (type === "all") {
            updateAvailableSlotsToUTCTime(currentTab, fields, mappedLocalUTCTime, "all")
            let hourData = [];
            if (mappedLocalUTCTime[currentTab]) {
                hourData = JSON.parse(JSON.stringify(mappedLocalUTCTime[currentTab]));
            }
            mappedLocalUTCTime = {};
            for (let day = 1; day <= 7; day++) {
                mappedLocalUTCTime[numberToDay[day]] = hourData;

            }
        } else {
            if (Object.keys(currentTimeSlots).length === 0) {
                updateAvailableSlotsToUTCTime(currentTab, fields, mappedLocalUTCTime)
            } else {
                if (!currentTimeSlots[currentTab]) {
                    updateAvailableSlotsToUTCTime(currentTab, fields, mappedLocalUTCTime);
                }
                for (const [key, value] of Object.entries(currentTimeSlots)) {
                    if (currentTab === key) {
                        updateAvailableSlotsToUTCTime(key, fields, mappedLocalUTCTime)
                    } else {
                        updateAvailableSlotsToUTCTime(key, value, mappedLocalUTCTime)
                    }
                }
            }
        }

        let duration = getDuration();
        if (mappedLocalUTCTime) {
            for (const [key, value] of Object.entries(mappedLocalUTCTime)) {
                newarray = value.map(a => {
                    let endTime24hr = convertTo24Hour(a.endTime)
                    let startTime24hr = convertTo24Hour(a.startTime)
                    if (!(startTime24hr === "00:00" && endTime24hr === "00:00")) {
                        let endTime = endTime24hr === "00:00" ? "24:00" : endTime24hr
                        return { specialityId: a.specialityId.toString(), working_hours: convertTo24Hour(a.startTime) + '-' + endTime };
                    }
                    return null
                }).filter(value => value !== null)
                arrayOfTimeSlots.push({
                    day: dayToNumber[key],
                    slotDuration: (parseInt(duration) / 60),
                    isWorking: 1,
                    workingHours: newarray
                })
            }
        }
        setSpinner(!spinner)
        let noWorkingData = [
            { "day": 1, "slotDuration": (parseInt(duration) / 60), "isWorking": 0, "workingHours": [] },
            { "day": 2, "slotDuration": (parseInt(duration) / 60), "isWorking": 0, "workingHours": [] },
            { "day": 3, "slotDuration": (parseInt(duration) / 60), "isWorking": 0, "workingHours": [] },
            { "day": 4, "slotDuration": (parseInt(duration) / 60), "isWorking": 0, "workingHours": [] },
            { "day": 5, "slotDuration": (parseInt(duration) / 60), "isWorking": 0, "workingHours": [] },
            { "day": 6, "slotDuration": (parseInt(duration) / 60), "isWorking": 0, "workingHours": [] },
            { "day": 7, "slotDuration": (parseInt(duration) / 60), "isWorking": 0, "workingHours": [] }]
            
        arrayOfTimeSlots = noWorkingData.map(workingData => {
            let availableDayData = arrayOfTimeSlots.filter(timeSlots => timeSlots.day === workingData.day);
            return availableDayData.length ? availableDayData[0] : workingData
        })


        if (!PhysicianSpeciality.label || PhysicianSpeciality.label === "All Speciality") {
            setTimeSlotLabels(fields);
        } else {
            setTimeSlotLabels([]);
        }
        setFilteredTimeSlotLabels(fields);
        setModal(false);

        const params = {
            "userID": userID ? Number(userID) : 0,
            "user_id": userId ? Number(userId) : 0,
            // data: JSON.stringify(arrayOfTimeSlots),
            data: arrayOfTimeSlots,
        };

        axios.post(`${API_ENDPOINT}/user/set-schedule-working-times/`, params, {
            headers: {
                'Authorization': token
            }
        }).then(function () {
            getAllAvailableSlot();
            setSpinner(!spinner)
        })
            .catch(function (error) {
                // console.log(error);
                setSpinner(!spinner)
            });
    }

    const convertTo12Hour = (time) => {
        var hours = parseInt(time.substr(0, 2));
        var minutes = parseInt(time.substr(3, 2));
        var timeMedian = hours < 12 ? "AM" : "PM";
        hours = hours > 12 ? hours - 12 : hours;
        hours = hours < 10 ? "0" + hours : hours;
        minutes = minutes === 0 ? "00" : minutes;
        return hours + ":" + minutes + " " + timeMedian;
    }

    const nextDateofDay = (dayNum) => {
        let d = new Date();
        d.setDate(d.getDate() + (dayNum + 7 - d.getDay()) % 7);
        let month = d.getMonth() + 1;
        if (month < 10) {
            month = "0" + month;
        }
        let date = d.getDate();
        if (date < 10) {
            date = "0" + date;
        }
        return d.getFullYear() + "-" + month + "-" + date;

    }
    const updateAvailableSlotsToLocalTimeForCalendar = (day, workingTime) => {
        let startTimeSplit = workingTime.startAt.split(":");
        let endTimeSplit = workingTime.endAt.split(":");
        let startTime = new Date(new Date(nextDateofDay((parseInt(day) - 1))).setUTCHours(startTimeSplit[0], startTimeSplit[1], startTimeSplit[2]));
        let endTime = new Date(new Date(nextDateofDay((parseInt(day) - 1))).setUTCHours(endTimeSplit[0], endTimeSplit[1], endTimeSplit[2]));
        let startTimeHr = startTime.getHours();
        let startTimeMin = startTime.getMinutes();
        startTimeHr = startTimeHr < 10 ? "0" + startTimeHr : startTimeHr;
        startTimeMin = startTimeMin < 10 ? "0" + startTimeMin : startTimeMin;
        let endTimeHr = endTime.getHours();
        let endTimeMin = endTime.getMinutes();
        endTimeHr = endTimeHr < 10 ? "0" + endTimeHr : endTimeHr;
        endTimeMin = endTimeMin < 10 ? "0" + endTimeMin : endTimeMin;

        return { day: parseInt(startTime.getDay() + 1), updatedSlots: { startTime: startTimeHr + ":" + startTimeMin + ':00', endTime: endTimeHr + ":" + endTimeMin + ':00', specialityText: workingTime.specialityText } }
    }

    const updateAvailableSlotsToLocalTime = (day, workingHours, availableLocalTimeSlots) => {
        workingHours = workingHours.map(workingHour => { // eslint-disable-line
            let timeArray = workingHour.working_hours.split('-');
            if (timeArray.length === 2) {
                let startTimeValue = convertTo24Hour(timeArray[0])
                let endTimeValue = convertTo24Hour(timeArray[1])
                
                let offset = 0;
                if (getMinutesInt(startTimeValue) > getMinutesInt(endTimeValue)) {
                    offset++;
                }

                let startTime = calculateLocalOffset(timeArray[0], nextDateofDay((parseInt(day) - 1)))
                let endTime = calculateLocalOffset(timeArray[1], nextDateofDay((parseInt(day) - 1 + offset)))

                var startLocalHr = startTime.getHours();
                startLocalHr = startLocalHr < 10 ? "0" + startLocalHr : "" + startLocalHr;
                var startLocalMin = startTime.getMinutes();
                startLocalMin = startLocalMin < 10 ? "0" + startLocalMin : "" + startLocalMin;

                var endLocalHr = endTime.getHours();
                endLocalHr = endLocalHr < 10 ? "0" + endLocalHr : "" + endLocalHr;
                var endLocalMin = endTime.getMinutes();
                endLocalMin = endLocalMin < 10 ? "0" + endLocalMin : "" + endLocalMin;

                let localWorkingHour = startLocalHr + ":" + startLocalMin + "-" + endLocalHr + ":" + endLocalMin;
                let localDay = numberToDay[(startTime.getDay() + 1) + ""];
                if (availableLocalTimeSlots[localDay]) {
                    availableLocalTimeSlots[localDay].push(
                        {
                            specialityId: workingHour.specialityId,
                            startTime: convertTo12Hour(localWorkingHour.split("-")[0]),
                            endTime: convertTo12Hour(localWorkingHour.split("-")[1])
                        }
                    );

                } else {
                    availableLocalTimeSlots[localDay] = [
                        {
                            specialityId: workingHour.specialityId,
                            startTime: convertTo12Hour(localWorkingHour.split("-")[0]),
                            endTime: convertTo12Hour(localWorkingHour.split("-")[1])
                        }
                    ]
                }
            }
        });
    }

    const updateAvailableSlotsToUTCTime = (day, workingHours, availableLocalTimeSlots, type) => {
        workingHours = workingHours.map(workingHour => { // eslint-disable-line
            let dayNumber = dayToNumber[day].toString()
            let startTimeValue = convertTo24Hour(workingHour.startTime)
            let endTimeValue = convertTo24Hour(workingHour.endTime)
            if (startTimeValue && endTimeValue) {
                let offset = 0;
                if (getMinutesInt(startTimeValue) > getMinutesInt(endTimeValue)) {
                    offset++;
                }
                
                let startTime = calculateUtcOffset(workingHour.startTime, nextDateofDay((parseInt(dayNumber) - 1)))
                let endTime = calculateUtcOffset(workingHour.endTime, nextDateofDay((parseInt(dayNumber) - 1 + offset)))

                var startUTCHr = startTime.getHours();
                startUTCHr = startUTCHr < 10 ? "0" + startUTCHr : "" + startUTCHr;
                var startUTCMin = startTime.getMinutes();
                startUTCMin = startUTCMin < 10 ? "0" + startUTCMin : "" + startUTCMin;

                var endUTCHr = endTime.getHours();
                endUTCHr = endUTCHr < 10 ? "0" + endUTCHr : "" + endUTCHr;
                var endUTCMin = endTime.getMinutes();
                endUTCMin = endUTCMin < 10 ? "0" + endUTCMin : "" + endUTCMin;
                let startDay = (startTime.getDay() + 1) + "";
                let endDay = (endTime.getDay() + 1) + "";
                if (((startDay === dayNumber && endDay === dayNumber)) || (startDay !== dayNumber && endDay !== dayNumber)) {
                    if (type === "all") {
                        slotsCreation(availableLocalTimeSlots, dayNumber, startUTCHr + ":" + startUTCMin, endUTCHr + ":" + endUTCMin, workingHour.specialityId)
                    } else {
                        slotsCreation(availableLocalTimeSlots, startDay, startUTCHr + ":" + startUTCMin, endUTCHr + ":" + endUTCMin, workingHour.specialityId)
                    }

                } else if ((startDay !== dayNumber && endDay === dayNumber) || (startDay === dayNumber && endDay !== dayNumber)) {
                    if (type === "all") {
                        slotsCreation(availableLocalTimeSlots, dayNumber, startUTCHr + ":" + startUTCMin, "00:00", workingHour.specialityId)
                        slotsCreation(availableLocalTimeSlots, dayNumber, "00:00", endUTCHr + ":" + endUTCMin, workingHour.specialityId)
                    } else {
                        slotsCreation(availableLocalTimeSlots, startDay, startUTCHr + ":" + startUTCMin, "00:00", workingHour.specialityId)
                        slotsCreation(availableLocalTimeSlots, endDay, "00:00", endUTCHr + ":" + endUTCMin, workingHour.specialityId)
                    }
                }
            }
        })


    }

    const slotsCreation = (availableLocalTimeSlots, startDay, startTimes, endTimes, selectedSpeciality) => {
        if (availableLocalTimeSlots[numberToDay[startDay]]) {
            availableLocalTimeSlots[numberToDay[startDay]].push(
                {
                    specialityId: selectedSpeciality,
                    startTime: convertTo12Hour(startTimes),
                    endTime: convertTo12Hour(endTimes)
                }
            );

        } else {
            availableLocalTimeSlots[numberToDay[startDay]] = [
                {
                    specialityId: selectedSpeciality,
                    startTime: convertTo12Hour(startTimes),
                    endTime: convertTo12Hour(endTimes)
                }
            ]
        }
    }

    const getTimeString = (timeInt) => {
        if (timeInt === 24) {
            timeInt = 0;
        }
        let hr = Math.floor(timeInt);
        let min = (timeInt % 1) * 60;
        min = min === 0 ? "00" : min;
        let timeMedian = "AM";
        if (hr > 12) {
            hr = hr - 12;
            timeMedian = "PM";
        } else if (hr === 12) {
            timeMedian = "PM";
        }
        hr = hr < 10 ? "0" + hr : hr;
        return hr + ":" + min + " " + timeMedian;
    }

    const processFields = (values) => {
        values = values.map(field => {
            var fieldObj = field;
            for (let props in fieldObj) {
                if (fieldObj.hasOwnProperty(props) && props !== "specialityId") {

                    let hr = parseInt(fieldObj[props].split(":")[0]);
                    let minFraction = parseInt(fieldObj[props].split(":")[1]) / 60;
                    if (fieldObj[props].indexOf(" PM") > -1) {
                        fieldObj[props] = fieldObj[props].replace(" PM", "");
                        if (parseInt(fieldObj[props].split(":")[0]) < 12) {
                            hr = 12 + hr;
                        }
                    }
                    else if (fieldObj[props].indexOf(" AM") > -1) {
                        fieldObj[props] = fieldObj[props].replace(" AM", "");
                        if (parseInt(fieldObj[props].split(":")[0]) === 12) {
                            hr = 0;
                        }
                    }
                    fieldObj[props] = hr + minFraction;

                }
            }
            return fieldObj;
        });
        values.sort(function (a, b) { return a.startTime - b.startTime });
        let processTimeSlots = [];
        let mergeError = {
            conflict: null,
            selected: null
        };
        values.forEach((value, index, valueArray) => {
            if (value.endTime === 0) {
                value.endTime = 24;
            }
            if (valueArray[index + 1] && valueArray[index + 1].endTime === 0) {
                valueArray[index + 1].endTime = 24;
            }
            if (index !== valueArray.length - 1) {
                if (value.startTime <= valueArray[index + 1].startTime && valueArray[index + 1].startTime <= value.endTime) {
                    if (value.specialityId === valueArray[index + 1].specialityId) {
                        valueArray[index + 1].startTime = value.startTime;
                        if (valueArray[index + 1].endTime > value.endTime) {
                            value.endTime = valueArray[index + 1].endTime;
                        } else {
                            valueArray[index + 1].endTime = value.endTime;
                        }
                    } else {
                        if (value.endTime !== valueArray[index + 1].startTime) {
                            if (valueArray[index + 1].specialityId === selectedSpeciality) {
                                mergeError['conflict'] = {
                                    startTime: getTimeString(value.startTime),
                                    endTime: getTimeString(value.endTime),
                                    specialityId: value.specialityId
                                };
                                mergeError['selected'] = {
                                    startTime: getTimeString(valueArray[index + 1].startTime),
                                    endTime: getTimeString(valueArray[index + 1].endTime),
                                    specialityId: valueArray[index + 1].specialityId
                                }
                            } else {
                                mergeError['conflict'] = {
                                    startTime: getTimeString(valueArray[index + 1].startTime),
                                    endTime: getTimeString(valueArray[index + 1].endTime),
                                    specialityId: valueArray[index + 1].specialityId
                                }
                                mergeError['selected'] = {
                                    startTime: getTimeString(value.startTime),
                                    endTime: getTimeString(value.endTime),
                                    specialityId: value.specialityId
                                }
                            }
                        }
                    }
                }

            }
            if (value.endTime === 24) {
                value.endTime = 0;
            }
            if (valueArray[index + 1] && valueArray[index + 1].endTime === 24) {
                valueArray[index + 1].endTime = 0;
            }
            if (processTimeSlots.length > 0 && processTimeSlots[processTimeSlots.length - 1].startTime === value.startTime && processTimeSlots[processTimeSlots.length - 1].specialityId === value.specialityId) {
                processTimeSlots[processTimeSlots.length - 1].endTime = value.endTime;
            } else {
                processTimeSlots.push(value);
            }
        });
        processTimeSlots = processTimeSlots.map(field => {
            var fieldObj = field;
            for (let props in fieldObj) {

                if (fieldObj.hasOwnProperty(props) && props !== "specialityId") {
                    let hr = Math.floor(fieldObj[props]);
                    let min = (fieldObj[props] % 1) * 60;
                    min = min === 0 ? "00" : min;
                    let timeMedian = "AM";
                    if (hr > 12) {
                        hr = hr - 12;
                        timeMedian = "PM";
                    } else if (hr === 12) {
                        timeMedian = "PM";
                    }
                    hr = hr < 10 ? "0" + hr : hr;
                    fieldObj[props] = hr + ":" + min + " " + timeMedian;
                }

            }
            return fieldObj;
        });
        return { processedSlots: processTimeSlots, error: mergeError };
    }

    const createDetailsVariableDataStructure = () => {
        if(userId === "") return;
        setSpinner(true)

        const param = PhysicianSpeciality.value
            ? {
                "user_id": userId ? userId : 0,
                "specialityId": PhysicianSpeciality.value
            }
            : { "user_id": userId ? userId : 0 }

        axios
            .post(`${API_ENDPOINT}/user/get-physician-profile-data/`, param, {
                headers: {
                    Authorization: token,
                },
            })
            .then(function (response) {
                // console.log('response', response.data.data[0])
                const source = [response.data]
                const newArrayOfObj = source.map(({ data: physicianData, ...rest }) => ({ physicianData, ...rest }));
                const appointmentFee = newArrayOfObj.map(L1 => L1.speciality_details.map(L2 => L2));
                const newArrayOfObj2 = newArrayOfObj[0].physicianData.map(item => ({ appointmentFee: appointmentFee[0], 0: item }))
                newArrayOfObj[0].physicianData = newArrayOfObj2[0];
                setDetails(newArrayOfObj[0])
                setSpinner(false);
            })
            .catch(function (error) {
                // console.log(error);
                setSpinner(false);
            });
    }

    const getAllAvailableSlot = () => {
        setSpinner(true)

        const param = PhysicianSpeciality.value
            ? {
                "user_id": userId ? userId : 0,
                "specialityId": PhysicianSpeciality.value
            }
            : { "user_id": userId ? userId : 0 }


        axios.post(`${API_ENDPOINT}/user/get-schedule-working-times/`, param, {
            headers: {
                'Authorization': token
            }
        }).then(function (response) {
            setSpinner(false)
            let mappedLocalAvailableTime = {};
            if (response.data && response.data.data && response.data.data.workingTime) {
                timeZone = response.data.tz;
                utc_offset = response.data.utc_offset;
                setTimeZoneCard(response.data.tz);
                response.data.data.workingTime.forEach(workTime => {
                    try {
                        let workingHours = JSON.parse(workTime.working_hours);
                        updateAvailableSlotsToLocalTime(workTime.appointmentDay, workingHours, mappedLocalAvailableTime);
                    } catch (e) { }
                })
            }
            for (const [key, value] of Object.entries(mappedLocalAvailableTime)) {
                var { processedSlots } = processFields(value);
                mappedLocalAvailableTime[key] = processedSlots;
            }
            if (mappedLocalAvailableTime[activeTab]) {
                setTimeSlotLabels(mappedLocalAvailableTime[activeTab]);
                setFilteredTimeSlotLabels(mappedLocalAvailableTime[activeTab]);
            }
            settimeslot(mappedLocalAvailableTime);

            if (response.data && response.data.data && response.data.data.slots) {
                let fullWeekDates = getFullWeekDetails();
                updateCalendarSlotDetails(response.data.data.slots.map(slots => {
                    let { day, updatedSlots } = updateAvailableSlotsToLocalTimeForCalendar(slots.appointmentDay, slots);
                    let startTime = fullWeekDates[day - 1] + ' ' + updatedSlots.startTime;
                    let endTime = fullWeekDates[day - 1] + ' ' + updatedSlots.endTime;

                    return { start: startTime, end: endTime, extendedProps: { specialityText: updatedSlots.specialityText } };
                }));
            }

            setActionHandleSubmit(!actionHandleSubmit)

        })
            .catch(function (error) {
                setSpinner(false)
                // console.log(error);
            });

    }

    return (
        <Card className="availability-card disable-scrollbars hours">
            <div className="physician-spinner">
                <Spinner style={spinner ? { "display": "block" } : { "display": "none" }} />
            </div>
            <CardHeader className="availability-card-adjustment">
                <CardTitle tag="h5" className="mb-0">
                    {physicianName ? `${physicianName}'s Availability` : ""}
                </CardTitle>

            </CardHeader>
            <CardHeader>
                <div className="speciality-dropdown-spec">

                    <Select
                        value={PhysicianSpeciality}
                        options={PhysicianSpecialityDropDownList}
                        onChange={(event) => {
                            setPhysicianSpeciality(event)
                            setPageLoading(true)
                        }}
                        placeholder="Select"
                    />
                </div>
            </CardHeader>
            <Row>
                <CardBody>
                    {timeZoneCard
                        ?
                        <div className="time-zone-card">
                            <span>
                                <span className="time-zone-exclamation">
                                    <FontAwesomeIcon
                                        icon={faExclamationCircle}
                                        className="align-middle"
                                    />
                                </span>
                                {`Availability is displayed based on the doctor's timezone "${timeZoneCard}" (${momentTimeZone.tz(timeZoneCard).format('z')})`}
                            </span>
                        </div>
                        : ""}
                    <div className="card schedule-widget mb-0">
                        <div className="schedule-header">
                            <div className="schedule-nav">
                                <ul className="nav nav-tabs nav-justified">
                                    <li className="nav-item">
                                        <NavLink
                                            className={classnames({ active: activeTab === "sunday" })}
                                            onClick={() => {
                                                toggle("sunday");
                                            }}
                                        >
                                            Sunday
                                    </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink
                                            className={classnames({ active: activeTab === "monday" })}
                                            onClick={() => {
                                                toggle("monday");
                                            }}
                                        >
                                            Monday
                                    </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink
                                            className={classnames({ active: activeTab === "tuesday" })}
                                            onClick={() => {
                                                toggle("tuesday");
                                            }}
                                        >
                                            Tuesday
                                    </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink
                                            className={classnames({ active: activeTab === "wednesday" })}
                                            onClick={() => {
                                                toggle("wednesday");
                                            }}
                                        >
                                            Wednesday
                                    </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink
                                            className={classnames({ active: activeTab === "thursday" })}
                                            onClick={() => {
                                                toggle("thursday");
                                            }}
                                        >
                                            Thursday
                                    </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink
                                            className={classnames({ active: activeTab === "friday" })}
                                            onClick={() => {
                                                toggle("friday");
                                            }}
                                        >
                                            Friday
                                    </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink
                                            className={classnames({ active: activeTab === "saturday" })}
                                            onClick={() => {
                                                toggle("saturday");
                                            }}
                                        >
                                            Saturday
                                    </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <TabContent activeTab={activeTab} className="schedule-cont">
                            <TabPane tabId={activeTab}>
                                <h4 className="card-title d-flex justify-content-between">
                                    <span>Time Slots</span>
                                    <a className="edit-link" data-toggle="modal" href="#edit_time_slot" onClick={triggerModal}>
                                        <FontAwesomeIcon
                                            icon={faEdit}
                                            className="align-middle"
                                        /> Configure </a>
                                </h4>
                                <div className="doc-times">
                                    {timeSlotLabels.map((singleTimeSlots, index) => {
                                        return (
                                            <TimeLabel startTime={singleTimeSlots.startTime} specialityId={singleTimeSlots.specialityId} endTime={singleTimeSlots.endTime} deleteLabel={deleteLabel} day={activeTab} index={index} details={details} specialities={specialities} />
                                        )
                                    })
                                    }
                                </div>
                            </TabPane>
                        </TabContent>
                    </div>
                </CardBody>
            </Row>
            {modal ?
                <ModalAddTimeSlot isOpen={modal} toggle={triggerModal} currentTimeSlots={timeslot} currentTab={activeTab} handleSubmit={handleSubmit} updateSlotDetails={updateSlotDetails} updateModalSlots={updateModalSlots} details={details} updateSelectedSpeciality={setSelectedSpeciality} updateTimeSlotLabels={setFilteredTimeSlotLabels} updateMergeError={mergeError} updateShowMergeError={showMergeError} /> : null
            }
            {calendarSlot.length > 0 ?
                <div className="availability-calender disable-scrollbars">
                    <MyCalendar timeSlotDetailsCalendar={calendarSlot} addCalendarSlot={addCalendarSlot} removeCalendarSlot={removeCalendarSlot} />
                </div>
                : null
            }
        </Card>
    )
}

const AvailableHours = () => {
    // CONTEXT VARIABLES
    const { physicianName } = useContext(GlobalState);
    const [pathName, setPathName] = useState("");
    let location = useLocation();

    const getUserTypeFromURL = () => {
        let pathName = location.pathname
        let userTypeInURL = pathName.split("/")
        let filtered = userTypeInURL.filter(function (item) { return item !== "" })
        userTypeInURL = filtered[0]
        userTypeInURL = userTypeInURL.toLocaleLowerCase()
        userTypeInURL = userTypeInURL.charAt(0).toUpperCase() + userTypeInURL.slice(1)
        setPathName(userTypeInURL)
    }

    // EFFECT TO CHANGE THE BREADCRUMB BASE ON URL
    useEffect(() => { getUserTypeFromURL() }, [])// eslint-disable-line
    return (
        <>
            <Header>
                <HeaderTitle>My Account</HeaderTitle>
                <Breadcrumb>
                    <BreadcrumbItem>
                        <a href={`/${pathName.toLowerCase()}`}>{pathName}</a>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <Link to="/settings">My Account</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>{physicianName}</BreadcrumbItem>
                </Breadcrumb>
            </Header>
            <Row>
                <Col md="3" xl="2">
                    <Navigation />
                </Col>
                <Col md="9" xl="10">
                    <AvailableHoursInfo />
                </Col>
            </Row>
        </>
    )
}

function getMinutesInt(hrString) {
    let timeStringArray = hrString && hrString.split(":");
    return timeStringArray.length > 0 ? parseInt(timeStringArray[1]) + (parseInt(timeStringArray[0] * 60)) : null;
};

// CALENDER SETTER FUNCTION 
function calculateUtcOffset(time, day) {
    let nextDay = moment(day, 'YYYY-MM-DD').format('DD-MM-YYYY')
    let dateTime = `${nextDay} ${time}`
    // UTC OFFSET ADJUSTMENT 
    let utcEquivalentOfPhysicianTime = moment(dateTime, "DD-MM-YYYY HH:mm A").add(-(utc_offset), 'm').format()
    utcEquivalentOfPhysicianTime = new Date(utcEquivalentOfPhysicianTime)
    return utcEquivalentOfPhysicianTime
}

function calculateLocalOffset(time, day) {
    let nextDay = moment(day, 'YYYY-MM-DD').format('DD-MM-YYYY')
    let dateTime = `${nextDay} ${time}`
    // UTC OFFSET ADJUSTMENT 
    let utcEquivalentOfPhysicianTime = moment(dateTime, "DD-MM-YYYY HH:mm A").add(utc_offset, 'm').format()
    utcEquivalentOfPhysicianTime = new Date(utcEquivalentOfPhysicianTime)
    return utcEquivalentOfPhysicianTime
}

function convertTo24Hour(time) {
    var hours = parseInt(time.substr(0, 2));
    if (time.indexOf('AM') !== -1 && hours === 12) {
        time = time.replace('12', '00');
    }
    if (time.indexOf('PM') !== -1 && hours < 12) {
        if (hours < 10) {
            time = time.replace("0" + hours, (hours + 12));
        } else {
            time = time.replace(hours, (hours + 12));
        }
    }
    return time.replace(/( AM| PM)/, "");
}

export default connect(store => ({
    sidebar: store.sidebar
}))(AvailableHours);
