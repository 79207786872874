import React, { useState, useEffect, useContext } from 'react';
import { GlobalState } from '../../contextAPI/Context';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Input, Label, FormGroup, } from 'reactstrap';
import Select from 'react-select';
import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import ToggleButton from 'react-toggle-button';



const EditRuleConfigurationModal = (props) => {
    const {
        editUserType,
        setEditUserType, // eslint-disable-line
        editApptType,
        setEditApptType, // eslint-disable-line
        editCommisionFeePercent,
        setEditCommisionFeePercent,
        editProcessingFeePercent,
        setEditProcessingFeePercent,
        editIsVat,
        setEditIsVat,
        editStatus,
        editVatPercentage,
        setEditVatPercentage,
        editRuleConfigrationModal,
        setEditRuleConfigrationModal,
        editRuleId,
        setEditRuleId,
        token,
    } = props;

    // CONTEXT VARIABLES 
    const {
        ruleConfigListReload, 
        setRuleConfigListReload, } = useContext(GlobalState);

    // LOCAL VARIABLES
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    let userID = localStorage.getItem("userId");

    const [usersRolesList, setUsersRolesList] = useState([]);
    const [usersRoles, setUsersRoles] = useState([]);
    const [apptTypeList, setApptTypeList] = useState([]);
    const [apptType, setApptType] = useState([]);

    const [usersRolesObject, setUsersRolesObject] = useState("");
    const [apptTypeObject, setApptTypeObject] = useState("");

    const [vatStatus, setVatStatus] = useState(false);
    const [vatPercentageStatus, setVatPercentageStatus] = useState(true);

    useEffect(() => { if (editIsVat) setVatStatus(editIsVat) }, [editIsVat, editRuleId])

    const toggle = () => { 
        setEditRuleConfigrationModal(!editRuleConfigrationModal);
        setVatStatus(false);
        setEditRuleId("");
        setEditIsVat("");
     }

    const FetchAllUsersRoles = () => {

        axios.get(API_ENDPOINT + '/external/list-user-roles/appusers', {
            headers: {
                Authorization: props.token,
            },
        })
            .then(res => {
                if (res.status === 200) {
                    setUsersRolesList(res.data);
                }
            });
    }

    const FetchApptTypes = () => {

        axios.get(API_ENDPOINT + '/external/list-appointment-types/', {
            headers: {
                Authorization: props.token,
            },
        })
            .then(res => {
                if (res.status === 200) {
                    setApptTypeList(res.data);
                }
            });
    }

    const createOptions = () => {

        var array = usersRolesList
        const UsersRole = array.map((keys) => { 
            return { value: keys.id, label: keys.designation }

        });
        setUsersRoles(UsersRole)

        var array1 = apptTypeList
        const ApptType = array1.map((key) => {
            return { value: key.id, label: key.name }
        });
        setApptType(ApptType)

    }


    const userTypePopulate = () => {

        usersRoles.filter((roles) => { // eslint-disable-line
            if (roles.value === editUserType) {
                setUsersRolesObject(roles)
            }

        })

        apptType.filter((appt) => { // eslint-disable-line
            if (appt.value === editApptType) {
                setApptTypeObject(appt)
            }

        })

    }

    const updateRuleConfigurationApiCall = () => {
        axios.post(`${API_ENDPOINT}/external/update-payment-rule/`, {
            "rule_id": editRuleId,
            "commision_fee_percent": editCommisionFeePercent ? Number(editCommisionFeePercent) : 0,
            "processing_fee_percent": editProcessingFeePercent ? Number(editProcessingFeePercent)  : 0,
            "is_vat": vatStatus ? 1 : 0,
            "vat_percentage": editVatPercentage ? Number(editVatPercentage) : 0,
            "status": editStatus,
            "updated_by": Number(userID)
        }, {
            headers: {
                Authorization: token,
            }
        })
            .then(function (response) {
                // console.log(response);
                setEditRuleConfigrationModal(false)
                setRuleConfigListReload(ruleConfigListReload + 1)
                toastr.success("Successfully Updated", "Payment configuration updated successfully");
            })
            .catch(function (error) {
                toastr.error("Update Failed", "Please try again")
            });
    }

    const confirmation = () => {
    
    
        if (!editCommisionFeePercent) {
            toastr.error("Commission Fee Is Required", "Please enter the commission fee!")
        }
        if (!editProcessingFeePercent) {
            toastr.error("Processing Fee Is Required", "Please enter the processing fee!")
        }
        

        const callAPIWithVat = () => {
            if (editCommisionFeePercent && editProcessingFeePercent && editVatPercentage) {
                updateRuleConfigurationApiCall()
            }
        }

        const callAPIWithOutVat = () => {
            if (editCommisionFeePercent && editProcessingFeePercent ) {
                updateRuleConfigurationApiCall()
            }
        }

        const validateVat = () => {

            if (vatStatus && editVatPercentage < 0 ) {
                toastr.error("VAT Is Required", "Please enter the VAT!")
            } else {
                callAPIWithVat()
            }
        }

        vatStatus ? validateVat() : callAPIWithOutVat()

    };

    const CommissionFeeHandler = (num) => {
        let decimal = false
        let value = ""
        num % 1 !== 0 ? decimal = true : decimal = false
        if (decimal) {
          value = Math.round(num * 100) / 100;
        } else {
          value = num;
        }
    
        if (value <= 100) setEditCommisionFeePercent(value);
        if (value > 100) setEditCommisionFeePercent("100");
        if (value <= 0) setEditCommisionFeePercent("1");
        if (value === "") setEditCommisionFeePercent("");
    };

    const ProcessingFeeHandler = (num) => {
        let decimal = false
        let value = ""
        num % 1 !== 0 ? decimal = true : decimal = false
        if (decimal) {
          value = Math.round(num * 100) / 100;
        } else {
          value = num;
        }
    
        if (value <= 100) setEditProcessingFeePercent(value);
        if (value > 100) setEditProcessingFeePercent("100");
        if (value <= 0) setEditProcessingFeePercent("1");
        if (value === "") setEditProcessingFeePercent("");
    };

    const VatFeeHandler = (num) => {
        let decimal = false
        let value = ""
        num % 1 !== 0 ? decimal = true : decimal = false
        if (decimal) {
          value = Math.round(num * 100) / 100;
        } else {
          value = num;
        }
    
        if (value <= 100) setEditVatPercentage(value);
        if (value > 100) setEditVatPercentage("100");
        if (value <= 0) setEditVatPercentage("1");
        if (value === "") setEditVatPercentage("");
    };

    useEffect(() => {
        if (editRuleConfigrationModal) {
            FetchApptTypes();
            FetchAllUsersRoles();
        }
    }, [editRuleConfigrationModal])  // eslint-disable-line

    useEffect(() => { if (apptTypeList && usersRolesList) createOptions() }, [apptTypeList, usersRolesList])  // eslint-disable-line
    useEffect(() => { if (apptType && usersRoles) userTypePopulate() }, [apptType, usersRoles])  // eslint-disable-line

    return (
        <div>
            <Modal isOpen={editRuleConfigrationModal} toggle={toggle} size="md" >
                <ModalHeader tag="h4" cssModule={{'modal-title': 'w-100 text-center'}}  toggle={toggle}>Edit Payment Configuration</ModalHeader>
                <ModalBody className="overflow-modal disable-scrollbars" >
                    <FormGroup>
                        <Row>
                            <Col xs="6" >
                                <Label>
                                    User Type 
                                </Label>
                                <Select
                                    placeholder="Global"
                                    options={usersRoles}
                                    value={usersRolesObject}
                                    isSearchable
                                    isClearable
                                    isDisabled={true}
                                />
                                <br></br>
                                <Label>
                                    Commission Fee (%) <span className="required">*</span>
                                </Label>
                                <Input
                                    required
                                    onChange={e => { if (e.target.value.length <= 5) CommissionFeeHandler(e.target.value)}}
                                    type="number"
                                    name="commission_fee"
                                    placeholder="Commission fee"
                                    value={editCommisionFeePercent}
                                    
                                />
                                <br></br>
                                <Label>Is VAT Applicable</Label> <br></br>
                                <div className="toggle-button">
                                    <ToggleButton
                                        inactiveLabel=""
                                        activeLabel=""
                                        onToggle={() => { setVatStatus(!vatStatus); setEditIsVat(vatStatus); setVatPercentageStatus(!vatPercentageStatus);  }}
                                        value={vatStatus}
                                    />
                                </div>
                            </Col>
                            <Col xs="6">
                                <Label>
                                    Appointment Type <span className="required">*</span>
                                </Label>
                                <Select
                                    placeholder="Select"
                                    options={apptType}
                                    value={apptTypeObject}
                                    isDisabled={true}
                                    isSearchable
                                    isClearable
            
                                />
                                <br></br>
                                <Label>
                                    Processing Fee (%) <span className="required">*</span>
                                </Label>
                                <Input
                                    required
                                    onChange={e => { if (e.target.value.length <= 5) ProcessingFeeHandler(e.target.value)}}
                                    type="number"
                                    name="Processing_fee"
                                    placeholder="Processing fee"
                                    value={editProcessingFeePercent}
                                />
                                <br></br>


                                { vatStatus ?
                                    <>
                                        <Label>VAT (%) <span className="required">*</span></Label>
                                        <Input
                                            required
                                            onChange={e => { if (e.target.value.length <= 5) VatFeeHandler(e.target.value)}}
                                            type="number"
                                            name="vat_fee"
                                            placeholder="VAT fee"
                                            value={editVatPercentage >= 1 ? editVatPercentage : "" }
                                            /* disabled={!editIsVat} */
                                        />
                                    </> : ""
                                }
                            </Col>
                        </Row>
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button
                        className="yes-button button-width"
                     onClick={confirmation}
                     /* disabled={addButtonStatus} */
                    >Update</Button>{' '}
                    <Button outline className="no-button button-width" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>

        </div>
    );
}

export default EditRuleConfigurationModal;