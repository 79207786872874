import React, { useContext, useEffect, useState } from 'react'
import { GlobalState } from '../../contextAPI/Context'
import { Row, Col, Button, Spinner } from 'reactstrap'
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import PdfViewer from "../Settings/Kyc/DocumentViewer/PdfViewer";
import { toastr } from 'react-redux-toastr';


function PrescriptionTab(props) {
    // CONTEXT VARIABLES 
    const {
        spinner, setSpinner,
        prescriptionReload, setPrescriptionReload,
        setConfirmationModal, statusChangeConfirmation,setStatusChangeConfirmation,
        prescriptionRowData, setEditPrescriptionModal,
    } = useContext(GlobalState);

    const [pdfContainerWidth, setPdfContainerWidth] = useState();

    let pdfWrapper = null;
    // LOCAL VARIABLES 
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    let token = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');
    const [preview, setPreview] = useState("");

    // API CALL TO GET PRESCRIPTION PDF
    const getPrescriptionPdf = () => {
        setSpinner(true);
        const params = {
            appointment_id: prescriptionRowData.appointment_id,
            note_type: prescriptionRowData.note_type,
            is_view: 1
        };
        axios.post(`${API_ENDPOINT}/user/view-appointment-notes/`, params, {
            headers: {
                Authorization: token,
            },
            responseType: 'arraybuffer',
        })
            .then(function (response) {
                let image = btoa( // eslint-disable-line
                    new Uint8Array(response.data)
                        .reduce((data, byte) => data + String.fromCharCode(byte), '')
                );
                setPreview(Buffer.from(response.data, "binary").toString("base64"));
                setSpinner(false);
            })
            .catch(function (thrown) {
                // handle error
                setSpinner(false);
            });
    }

    const updatePharmacyApiCall = () => {
        setSpinner(true);
        axios.post(`${API_ENDPOINT}/external/update-prescription-workflow-status/`, {
            "appointment_id": prescriptionRowData.appointment_id,
            "uploader_id": Number(userId),
            "status_id": 29,
        }, {
            headers: {
                Authorization: token,
            }
        })
            .then(function (response) {
                // console.log(response);
                setSpinner(false);
                setConfirmationModal(false)
                setPrescriptionReload(prescriptionReload + 1)
                toastr.success("Prescription Status Updated Successfully")
                setStatusChangeConfirmation(false)
            })
            .catch(function (error) {
                // console.log(error);
                toastr.error("Update Failed", "Please try again")
            });
    }

    useEffect(() => { if (prescriptionRowData) getPrescriptionPdf(); }, [prescriptionRowData]) // eslint-disable-line
    useEffect(() => { if (statusChangeConfirmation) {
                                // console.log("statusChangeConfirmation",statusChangeConfirmation)
                                // debugger
                                updatePharmacyApiCall()
                              } }, [statusChangeConfirmation]) // eslint-disable-line
    useEffect(() => { setPdfSize(); },[]); // eslint-disable-line
    const setPdfSize = () => {
        if(pdfWrapper) {
            setPdfContainerWidth(pdfWrapper.getBoundingClientRect().width * 0.9);
        }
    }

    return (
        <div className="prescription-view-container">
            <h5>Pharmacy Details</h5>
            <Row>
                <Col>
                    <table>
                        <tbody>
                            <tr>
                                <td> Name </td>
                                <td> : </td>
                                <td> {prescriptionRowData.pharmacy_name ? prescriptionRowData.pharmacy_name : "N/A"} </td>
                            </tr>
                            <tr>
                                <td> Address </td>
                                <td> : </td>
                                <td> 
                                    {prescriptionRowData.pharmacy_address ? `${prescriptionRowData.pharmacy_address}` : "N/A"}{prescriptionRowData.pharmacy_city ? `, ${prescriptionRowData.pharmacy_city}` : ""}{prescriptionRowData.pharmacy_state ? `, ${prescriptionRowData.pharmacy_state}` : ""}{prescriptionRowData.pharmacy_country ? `, ${prescriptionRowData.pharmacy_country}` : ""}{prescriptionRowData.pharmacy_zip ? `, ${prescriptionRowData.pharmacy_zip}` : ""}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Col>
                <Col>
                    <table>
                        <tbody>
                            <tr>
                                <td> Email </td>
                                <td> : </td>
                                <td> {prescriptionRowData.pharmacy_email ? prescriptionRowData.pharmacy_email : "N/A"} </td>
                            </tr>
                            <tr>
                                <td> Phone </td>
                                <td> : </td>
                                <td> {prescriptionRowData.pharmacy_contactNumber ? prescriptionRowData.pharmacy_contactNumber : "N/A"} </td>
                            </tr>
                            <tr>
                                <td> E-fax </td>
                                <td> : </td>
                                <td> {prescriptionRowData.pharmacy_efax ? prescriptionRowData.pharmacy_efax : "N/A"} </td>
                            </tr>
                        </tbody>
                    </table>
                </Col>
                {prescriptionRowData.prescription_status_id !== 29
                    ? <Col sm="1">
                        <Button
                            className="money-button"
                            outline
                            onClick={() => { setEditPrescriptionModal(true) }}
                            color="primary"
                            title="Change Pharmacy"
                        >
                            <FontAwesomeIcon
                                icon={faEdit}
                                fixedWidth
                                className="align-middle"
                                title="Change Pharmacy"
                            />
                        </Button>
                    </Col>
                    : ""}
            </Row>

            <Row>
                <Col>
                    <div className="prescription-pdf-viewer-container" ref={(ref) => pdfWrapper = ref}>
                        <div className="prescription-pdf-viewer disable-scrollbars">
                            {!spinner && preview ? <PdfViewer width={pdfContainerWidth} url={preview}/> : <Spinner color="success" />}
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default PrescriptionTab
