import * as actionTypes from "./actionTypes";
import Axios from "axios";
import { toastr } from 'react-redux-toastr'

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const authSuccess = (token, userid) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    token: token,
    userid: userid,
  };
};

export const authCheckTimeOut = (exptime) => {
  return (dispatch) => {
    setTimeout(() => {
      dispatch(logout());
    }, exptime * 1000);
  };
};

export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error,
  };
};

export const logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("expirationDate");
  localStorage.removeItem("userId");
  toastr.success('Logout Successful', 'Admin logout successfully');
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const auth = (username, password) => {
  return (dispatch) => {
    dispatch(authStart());
    const AuthData = {
      username: username,
      password: password,
    };

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const API_TOKEN = process.env.REACT_APP_API_TOKEN;

    const signinurl = API_ENDPOINT + "/user/admin-user-login/";

    Axios.post(signinurl, AuthData)
      .then((res) => {
        const expirationDate = new Date(new Date().getTime() + 86400000);
        localStorage.setItem('token', API_TOKEN);
        localStorage.setItem('expirationDate', expirationDate);
        localStorage.setItem('userId', res.data.userID);
        dispatch(authSuccess(API_TOKEN, res.data.userID));
        dispatch(authCheckTimeOut(86400));
        toastr.success('Login Successful', 'Logged in as an Admin user');
      })
      .catch((error) => {
        dispatch(authFail(error));
      });
  };
};

// export const setAuthRedirect = (path) => {
//   return {
//     type: actionTypes.SET_AUTH_REDIRECT_PATH,
//     path: path,
//   };
// };

export const authStateCheck = () => {
  return (dispatch) => {
    const token = localStorage.getItem("token");

    // FORCE LOGOUT USER IF THE TOKEN IS MISSING
    let auth = true
    setInterval(() => {
      if (!localStorage.getItem('token')) {
        if (auth && window.location.pathname !== '/') {
          dispatch(logout());
          auth = false
        }
      }
    }, 2000);

    if (!token) {
      dispatch(logout());
    } else {
      const expirationDate = new Date(localStorage.getItem("expirationDate"));
      if (expirationDate <= new Date()) {
        dispatch(logout());
      } else {
        const userId = localStorage.getItem("userId");
        dispatch(authSuccess(token, userId));
        dispatch(
          authCheckTimeOut(
            (expirationDate.getTime() - new Date().getTime()) / 1000
          )
        );
      }
    }
  };
};
