import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, FormGroup } from "reactstrap";
import { AvForm, AvGroup, AvField } from 'availity-reactstrap-validation'

const RejectDocModal = (props) => {
  // DEPENDENCY VARIABLES  
  const {
    rejectDocModal,
    setRejectDocModal,
    setUserConfirmation,
    setStatusUpdateNote,
    setDocUpdateStatusCode
  } = props;

  // LOCAL VARIABLE
  const [reason, setReason] = useState("")

  const toggle = () => {
    setRejectDocModal(!rejectDocModal);
    setReason("");
  };

  const confirmation = () => {
    if (reason) {
      setUserConfirmation(true);
      setDocUpdateStatusCode(25)
      toggle();
    }
  };

  return (
    <div>
      <AvForm>
        <Modal isOpen={rejectDocModal} toggle={toggle}>
          <ModalHeader tag="h4" cssModule={{'modal-title': 'w-100 text-center'}}  toggle={toggle}>Are you sure you want to reject this document?</ModalHeader>
          <ModalBody>
            <AvGroup>
              <Label>Reason for rejection<span className="required"> * </span></Label>
              <AvField
                className="reject-reason"
                type="textarea"
                name="reject-reason"
                value={reason}
                onChange={(e) => { setStatusUpdateNote(e.target.value); setReason(e.target.value); }}
                validate={{
                  required: { value: true, errorMessage: 'Please enter a reason for rejecting this before proceeding' },
                  minLength: { value: 1, errorMessage: 'Maximum characters allowed 500' },
                  maxLength: { value: 500, errorMessage: 'Maximum characters allowed 500' }
                }}
              />
            </AvGroup>
          </ModalBody>
          <ModalFooter>
            <FormGroup>
              <Button
                className="yes-button button-width"
                type="submit"
                onClick={confirmation}
              >
                Yes
              </Button>{" "}
              <Button
                outline className="no-button button-width"
                onClick={toggle}
              >
                No
          </Button>
            </FormGroup>
          </ModalFooter>
        </Modal>
      </AvForm>
    </div>
  );
};

export default RejectDocModal;
