import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import "../Account/Account.css";
import { toastr } from "react-redux-toastr";
import getSymbolFromCurrency from 'currency-symbol-map';

import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardHeader,
  CardTitle,
  Col,
  Label,
  Input,
  CustomInput,
  FormGroup,
  Row,
  Spinner, 
  Collapse,
} from "reactstrap";

import {
  AvForm,
  AvField,
  AvGroup,
} from "availity-reactstrap-validation";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faAngleRight,
  faAngleDown,
  faExclamationTriangle,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";

import Header from "../../../components/Header";
import HeaderTitle from "../../../components/HeaderTitle";
import Navigation from "../SubNav";
import { GlobalState } from "../../../contextAPI/Context";
import SuggestedRatesModal from './SuggestedRatesModal';

const AccountInfo = () => {
  // CONTEXT VARIABLES
  const { reloadPhysicianIdentityData, physicianId, setPhysicianId, setPhysicianName } = useContext(GlobalState);

  // LOCAL VARIABLES
  const [userId, setUserId] = useState("")
  const [details, setDetails] = useState("")
  const [multiSpecialityArray, setMultiSpecialityArray] = useState("")
  const [multiSpecialityLength, setMultiSpecialityLength] = useState("")
  const [specialityAmount, setSpecialityAmount] = useState('')
  const [totalPatientFee, setTotalPatientFee] = useState("");
  const [specialityPaymentDetail, setSpecialityPaymentDetail] = useState("");
  const [specialityId, setSpecialityId] = useState("");
  const [allCountryOpt, setAllCountryOpt] = useState("");

  const [spinner, setSpinner] = useState(false) 
  const [currency, setCurrency] = useState("");
  const [countryID, setCountryID] = useState(0);
  const [countryListFromAPI, setCountryListFromAPI] = useState("");
  const [currencyOptions, setCurrencyOptions] = useState("");
  const [userDetails, setUserDetails] = useState("");
  const [duration, setDuration] = useState("");
  const [collapseConsultation, setCollapseConsultation] = useState(true);
  const [allowOutsideCountryPatients, setAllowOutsideCountryPatients] = useState(0)

  const [allRegPhyDetails, setAllRegPhyDetails] = useState("");
  const [NotificationEmail, setNotificationEmail] = useState(false);
  const [NotificationSMS, setNotificationSMS] = useState(false);
  const [NotificationInApp, setNotificationInApp] = useState(true);
  const [suggestedRatesModal, setSuggestedRatesModal] = useState(false);
  const [amountFromApi, setAmountFromApi] = useState("");
  const [currencyCode, setCurrencyCode] = useState("");
  let registeredCountry = [];

  // GET URL PARAMETERS 
  function useQuery() { return new URLSearchParams(useLocation().search); }
  let query = useQuery();

  // GET TOKEN
  let token = localStorage.getItem("token");

  // OBTAINING BASE URL BASED ON ENVIRONMENT
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

  const allowPatientsOutsideCheckBox = () => {
    let status = "";
    if (userDetails) {
      status = Number(userDetails.isAllowedPatientsOutsideRegisteredCountry)
      setAllowOutsideCountryPatients(status);
    }
  }

  const handleOutsidePatients = () => {
    setAllowOutsideCountryPatients(allowOutsideCountryPatients ? 0 : 1);
  }

  const getUserDetails = () => {
    setSpinner(true);

    const params = {
      user_id: physicianId
    }

    axios
      .post(`${API_ENDPOINT}/user/get-physician-profile-data/`, params,
        {
          headers: {
            Authorization: token,
          },
        })
      .then(function (response) {
        setSpinner(false);
        setSpecialityPaymentDetail(response.data.payment_config_details[0])
        setDetails(response.data.data[0]);
        setMultiSpecialityArray(response.data.speciality_details);
        setUserDetails(response.data.account_details[0]);

        setNotificationEmail(response.data.account_details[0].send_email);
        setNotificationSMS(response.data.account_details[0].send_sms);
        setNotificationInApp(response.data.account_details[0].send_inapp);
      })
      .catch(function (error) {
        // console.log(error);
        setSpinner(false);
      });
  };

  // CREATES THE OPTIONS FOR CURRENCY DROPDOWN
  const createCurrencyOptions = () => {
    let allCountryOptions = [];
    let options = [];

    var array = countryListFromAPI;

    options.push(
      array.map((item) => (
        <option key={item.countryID} value={item.countryID}>
          {item.currency}
        </option>
      ))
    );

    array.map((item) => {  //eslint-disable-line
      allCountryOptions.push(item)
    })
    setAllCountryOpt(allCountryOptions)
    setCurrencyOptions(options);

  };

  // CREATES THE OPTIONS FOR DURATION DROPDOWN
  const durationOptions = [
    <option key={0} value="900">
      15
    </option>,
    <option key={1} value="1800">
      30
    </option>,
    <option key={2} value="2700">
      45
    </option>,
    <option key={3} value="3600">
      60
    </option>,
  ];

    // CREATE SPECIALITY ARRAY
  let specialityDataArray = []
  const createSpecialityDataArray = () => {

    specialityDataArray = specialityId.map((item, index) => ({ "id": item, "amount": specialityAmount[index] }))

  }

  const optimizeSpecialityDataArray = () => {
    let optimizedSpecialityDataArray = specialityDataArray.filter((item, index) => { //eslint-disable-line
      if (item.amount !== amountFromApi[index]) return item
    })
    specialityDataArray = optimizedSpecialityDataArray
  }

  // NEW FUNCTION TO SET CONSULTATION DETAILS (THIS FUNCTION SHOULD BE REPLACED ONCE THE FULL EDIT FEATURES COMES TO THIS PAGE)
  const updateAllConsultationSettings = () => {
    setSpinner(true);
    createSpecialityDataArray();
    optimizeSpecialityDataArray();

    const params = {
      "userID": userId ? Number(userId) : 0,
      "user_id": physicianId ? Number(physicianId) : 0,
      "currency": currency ? currency : "",
      "countryID": countryID ? Number(countryID) : 0,
      "duration": duration ? Number(duration) : 0,
      "email_message": NotificationEmail ? Number(NotificationEmail) : 0,
      "inapp_message": NotificationInApp ? Number(NotificationInApp) : 0,
      "sms_message": NotificationSMS ? Number(NotificationSMS) : 0,
      "isAllowedPatientsOutsideRegisteredCountry": userDetails ? Number(allowOutsideCountryPatients) : 0,
      "speciality_datails": specialityDataArray

    }

    axios
      .post(`${API_ENDPOINT}/user/set-physician-appointment-fee/`, params,
        {
          headers: {
            Authorization: token,
          },
        })
      .then(function (response) {
        setDetails("");
        setSpinner(false);
        getUserDetails();
        toastr.success("Update Successful", "Consultation settings updated")
      })
      .catch(function (error) {
        setSpinner(false);
        toastr.error("Update Failed", "Please try again")
      });
  }

  const getCountryList = () => {
    // REQUEST TO GET COUNTRY LIST
    setSpinner(true);
    axios
      .get(`${API_ENDPOINT}/user/get-physician-currency/${physicianId}`, {
        headers: {
          Authorization: token,
        },
      })
      .then(function (response) {
        setCountryListFromAPI(response.data);
        setSpinner(false);
      })
      .catch(function (error) {
        // console.log(error);
        setSpinner(false);
      });
  };

  const populateConsultationFee = () => {
    let amountArray = []
    let patientFeeArray = []
    let specialityId = []
    let countryId = []
    let amountFromApi = []

    multiSpecialityArray.map((item, index) => { //eslint-disable-line
      setDuration(Number(item.duration));
      setCurrency(item.currency);
      countryId.push(item.currency);
      amountArray.push(item.amount)
      amountFromApi.push(item.amount)
      let totalPayable = calculatePatientFee(index)
      patientFeeArray.push(totalPayable)
      specialityId.push(item.specialityID)

    })


    setSpecialityAmount(amountArray)
    setTotalPatientFee(patientFeeArray)
    setSpecialityId(specialityId)
    setAmountFromApi(amountFromApi)

    allCountryOpt.map((item) => { // eslint-disable-line
      if (item.currency === countryId[0]) {
        setCountryID(item.countryID)
      }
    })

  };

  let temp = [];
  const registrationDetails = (// eslint-disable-line
    allRegPhyDetails ? allRegPhyDetails.map((item) => {// eslint-disable-line
      item.status.toLowerCase() === "verified" ? registeredCountry.push(item.countryOfRegistration) : temp.push("")
    }) : ""
  )

  const registrationDetailsComponent = (
    registeredCountry.length > 0 ? (
      [...new Set(registeredCountry)].map(item => { // Use Set to get distinct values
        return (
          <CustomInput
            key={item} // Make sure to set a unique key for each checkbox
            type="checkbox"
            id={`exampleCustomCheckbox_${item}`}
            label={item}
            checked={true}
            disabled
          />
        );
      })
    ) : ""
  );

  const getPhysicianIdentityData = () => {
    setSpinner(true)
    const params = { "user_id": physicianId }

    axios.post(`${API_ENDPOINT}/user/get-physician-identity-data/`, params, {
      headers: {
        Authorization: token,
      }
    })
      .then(function (response) {
        // console.log(response);
        setSpinner(false);
        setAllRegPhyDetails(response.data.phy_reg_details.length > 0 ? response.data.phy_reg_details : "")
      })
      .catch(function (error) {
        /* console.log(error); */
        setSpinner(false);
      });
  }

  const feeHandler = (num) => { // eslint-disable-line
    let decimal = false
    let value = ""
    num % 1 !== 0 ? decimal = true : decimal = false
    if (decimal) {
      value = Math.round(num * 100) / 100;
    } else {
      value = num;
    }
    
    if (value <= 999.99) return Number(value);
    if (value > 999.99) return Number(999.99);
    if (value <= 0) return Number(0);
    if (value === "") return 0;
  };

  const calculatePatientFee = (index) => { // eslint-disable-line

    let commissionFeePercent = Number(specialityPaymentDetail.commissionFeePercent ? specialityPaymentDetail.commissionFeePercent : 0)
    let paymentFeePercent = Number(specialityPaymentDetail.processingFeePercent ? specialityPaymentDetail.processingFeePercent : 0)

    let physicianFee = Number(multiSpecialityArray[index].amount ? multiSpecialityArray[index].amount : 0)
    let isVatApplicable = Number(specialityPaymentDetail.isVatApplicable ? specialityPaymentDetail.isVatApplicable : 0)
    let vatFeePercent = Number(specialityPaymentDetail.vatPercent ? specialityPaymentDetail.vatPercent : 0)

    let totalAdditionalCharges = commissionFeePercent + paymentFeePercent
    let totalAmount = (physicianFee + (physicianFee * totalAdditionalCharges))
    if (isVatApplicable && vatFeePercent > 0) {
      totalAmount = (totalAmount + (totalAmount * vatFeePercent))
    }

    return totalAmount.toFixed(2)
  }

  const calculateTotalFee = (rowData, index) => { // eslint-disable-line

    let commissionFeePercent = Number(specialityPaymentDetail.commissionFeePercent ? specialityPaymentDetail.commissionFeePercent : 0)
    let paymentFeePercent = Number(specialityPaymentDetail.processingFeePercent ? specialityPaymentDetail.processingFeePercent : 0)

    let physicianFee = Number(specialityAmount[index] ? specialityAmount[index] : 0)
    let isVatApplicable = Number(specialityPaymentDetail.isVatApplicable ? specialityPaymentDetail.isVatApplicable : 0)
    let vatFeePercent = Number(specialityPaymentDetail.vatPercent ? specialityPaymentDetail.vatPercent : 0)

    let totalAdditionalCharges = commissionFeePercent + paymentFeePercent
    let totalAmount = (physicianFee + (physicianFee * totalAdditionalCharges))
    if (isVatApplicable && vatFeePercent > 0) {
      totalAmount = (totalAmount + (totalAmount * vatFeePercent))
    }

    let temp = totalPatientFee
    temp[index] = totalAmount.toFixed(2)
    setTotalPatientFee(temp)
  }

  const handleYourFee = (value, rowData, index) => {
    let temp = specialityAmount
    let newValue  = feeHandler(value)
    temp[index] = newValue
    setSpecialityAmount(temp)
    calculateTotalFee(rowData, index)

    // RE-RENDER SPECIALITY ROWS AFTER THE CALCULATION    
    multiSpecialityHandler();
  }

  const multiSpecialityRows = (rowData, index) => {
    return (
      <>
        <br />
        <Row className="margin-bottom">
          <Col xs="3">
            <AvForm>
              <Input
                name="speciality"
                id="speciality"
                value={rowData.speciality}
                disabled
              />
            </AvForm>
          </Col>

          <Col xs="2">
            <AvForm>
              <Input
                type="number"
                value={specialityAmount[index]}
                maxLength={6}
                required
                onChange={(e) => {
                  handleYourFee(Number(e.target.value), rowData, index);

                }}
              />
            </AvForm>
          </Col>
          <Col xs="2">
            <AvForm>
              <Input
                className="form-control"
                type="number"
                name="patientPays"
                id="patientPays"
                value={totalPatientFee[index]}
                disabled
              />
            </AvForm>
          </Col>
        </Row>
      </>
    )

  }

  const multiSpecialityHandler = () => {
    let multiSpecialityLength = multiSpecialityArray.map((item, index) => {
      return multiSpecialityRows(item, index);
    })
    setMultiSpecialityLength(multiSpecialityLength);
  }

  const getCurrencyCode = () => {
    multiSpecialityArray && multiSpecialityArray.map((item) => { // eslint-disable-line
      if(currency === item.currency) setCurrencyCode(item.currencyCode) 
    })
  }

  
  
  useEffect(() => {
    if (multiSpecialityArray) multiSpecialityHandler()
  }, [multiSpecialityArray, specialityAmount, specialityPaymentDetail]); // eslint-disable-line
  
  
  // EFFECT TO GET THE URL PARAMETERS 
  useEffect(() => {
    if (query) {
      setUserId(localStorage.getItem("userId"));
      setPhysicianId(Number(atob(query.get("pid"))));
      setPhysicianName(atob(query.get("pname")));
    }
  }, [query])// eslint-disable-line
  
  
  useEffect(() => {
    if (userId && !allRegPhyDetails) getPhysicianIdentityData();
  }, [details, userId, reloadPhysicianIdentityData]);// eslint-disable-line
  
  
  useEffect(() => {
    if (userDetails) {
      allowPatientsOutsideCheckBox();
    }
  }, [userDetails]);// eslint-disable-line
  
  // API CALLS FOR DROPDOWN ITEMS 
  useEffect(() => {
    if (!countryListFromAPI && userId) getCountryList();
    if (countryListFromAPI !== "") createCurrencyOptions();
  }, [userId, countryListFromAPI]);// eslint-disable-line
  
  
  useEffect(() => {
    if (multiSpecialityArray !== "" && allCountryOpt !== "") populateConsultationFee();
  }, [multiSpecialityArray, allCountryOpt]); // eslint-disable-line
  
  // GET PHYSICIAN DETAILS 
  useEffect(() => { if (userId && !details && !spinner) getUserDetails(); }, [details, userId, reloadPhysicianIdentityData]);// eslint-disable-line
  
  useEffect(() => { if (multiSpecialityArray) getCurrencyCode() }, [multiSpecialityArray, currency]); // eslint-disable-line

  return (
    <div className="accounts-card disable-scrollbars">
      {spinner ? <Spinner color="success" size="12" /> : ""}
      
      <Card sm={12}>
        <CardHeader>
          <SuggestedRatesModal suggestedRatesModal={suggestedRatesModal} setSuggestedRatesModal={setSuggestedRatesModal} />
          <CardTitle
            tag="h5"
            className="account-settings-collapse"
            onClick={() => {
              setCollapseConsultation(!collapseConsultation);
            }}
          >
            <FontAwesomeIcon
              icon={collapseConsultation ? faAngleDown : faAngleRight}
              fixedWidth
              className="align-middle mr-2"
            />
            Consultation Details
          </CardTitle>
          <Collapse isOpen={collapseConsultation}>
            <AvForm>
              <Row>
                <Col className={registrationDetailsComponent && registrationDetailsComponent[0] !== undefined ? "country-of-registration" : "display-none"}>
                  <p><strong>
                    Based on "country of registration to practice medicine", patients from the following countries can book appointments
                    </strong></p>
                  {registrationDetailsComponent}
                </Col>
              </Row>
              <Row>
                <Col className="margin" onClick={() => { handleOutsidePatients() }}>
                  <CustomInput
                    type="checkbox"
                    name="patients-outside-checkbox"
                    checked={allowOutsideCountryPatients === 1 ? true : false}
                  />
                  <Label><span className="patients-outside-checkbox">Allow patients outside "country of registration to practice medicine" to book appointments</span></Label>
                </Col>
              </Row>
              <Row >
                <Col sm="3" md="3">
                  <AvGroup>
                    <Label for="duration">
                      Duration (in mins)</Label>
                    <AvField
                      type="select"
                      name="duration"
                      id="duration"
                      value={duration}
                      onChange={(e) => {

                        setDuration(Number(e.target.value));
                      }}
                    >
                      {durationOptions}
                    </AvField>
                    <div className="warning-label">
                      <FontAwesomeIcon
                        icon={faExclamationTriangle}
                      />
                      <Label className="warning-text">Updating the duration will reset physician availability</Label>
                    </div>
                  </AvGroup>
                </Col>
                <Col sm="3" md="3">
                  <FormGroup>
                    <AvGroup>
                      <Label for="currency">Currency
                      <span className="currency-exclamation"
                          onClick={() => {
                            setSuggestedRatesModal(true)
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faExclamationCircle}
                            className="align-middle"
                          />
                        </span>
                      </Label>
                      <AvField
                        type="select"
                        name="currency"
                        id="currency"
                        value={countryID}
                        onChange={(e) => {
                          let index = e.target.selectedIndex;
                          let label = e.target[index].text;
                          setCountryID(e.target.value)
                          setCurrency(label)
                        }}
                      >
                        {currencyOptions}
                      </AvField>
                    </AvGroup>
                  </FormGroup>
                </Col>
              </Row>

              {/* MULTI SPECIALITY COMPONENT  */}
              <Row className="multi-speciality-rows">
                <Col xs="3">
                  <Label for="speciality">Speciality</Label>
                </Col>
                <Col xs="2">
                  <Label >Care Provider Fee ({getSymbolFromCurrency(currencyCode)})</Label>
                </Col>
                <Col xs="2">
                  <Label for="patientPays">Patient Pays ({getSymbolFromCurrency(currencyCode)})</Label>
                </Col>
              </Row>
              {multiSpecialityLength}
              <br />


              <Row>
                <Col>
                  <Label>Reminder Notification</Label>
                  <br />
                  <CustomInput
                    type="checkbox"
                    id="exampleCustomCheckbox"
                    label="Email"
                    checked={NotificationEmail ? true : false}
                    onClick={() => setNotificationEmail(!NotificationEmail)}
                  />
                  <CustomInput
                    type="checkbox"
                    id="exampleCustomCheckbox2"
                    label="SMS"
                    checked={NotificationSMS ? true : false}
                    onClick={() => setNotificationSMS(!NotificationSMS)}
                  />
                  <CustomInput
                    type="checkbox"
                    id="exampleCustomCheckbox3"
                    label="In App"
                    checked={NotificationInApp ? true : false}
                    onClick={() => setNotificationInApp(!NotificationInApp)}
                    disabled
                  />
                </Col>
              </Row>

              <Row>
                <Col className="account-button-div ">
                  
                  <Button
                    color="success"
                    className="approve-all-document margin-left"
                    type="submit"
                    onClick={updateAllConsultationSettings}
                  >
                    Save
                  </Button>
                  <Button
                    color="success"
                    className="approve-all-document margin-left"
                    type="reset"
                    outline
                    onClick={() => setCollapseConsultation(false)}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </AvForm>
          </Collapse>
        </CardHeader>
      </Card>
    </div>
  );
};

const Account = () => {
  // CONTEXT VARIABLES
  const { physicianName } = useContext(GlobalState);
  const [pathName, setPathName] = useState("");
  let location = useLocation();

  const getUserTypeFromURL = () => {
    let pathName = location.pathname
    let userTypeInURL = pathName.split("/")
    let filtered = userTypeInURL.filter(function (item) { return item !== "" })
    userTypeInURL = filtered[0]
    userTypeInURL = userTypeInURL.toLocaleLowerCase()
    userTypeInURL = userTypeInURL.charAt(0).toUpperCase() + userTypeInURL.slice(1)
    setPathName(userTypeInURL)
  }

  // EFFECT TO CHANGE THE BREADCRUMB BASE ON URL
  useEffect(() => { getUserTypeFromURL() }, [])// eslint-disable-line

  return (
    <>
      <Header>
        <HeaderTitle>Settings</HeaderTitle>
        <Breadcrumb>
          <BreadcrumbItem>
            <a href={`/${pathName.toLowerCase()}`}>{pathName}</a>
          </BreadcrumbItem>
          <BreadcrumbItem active>Consultation Settings</BreadcrumbItem>
          <BreadcrumbItem active>{physicianName}</BreadcrumbItem>
        </Breadcrumb>
      </Header>
      <Row>
        <Col md="3" xl="2">
          <Navigation />
        </Col>
        <Col md="9" xl="10">
          <AccountInfo />
        </Col>
      </Row>
    </>
  );
}

export default connect((store) => ({
  sidebar: store.sidebar,
}))(Account);