import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import "../Account/Account.css";
import { toastr } from "react-redux-toastr";

import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardHeader,
  CardTitle,
  Col,
  Row,
  Spinner,
} from "reactstrap";

import Header from "../../../components/Header";
import HeaderTitle from "../../../components/HeaderTitle";
import Navigation from "../SubNav";
import { GlobalState } from "../../../contextAPI/Context";


const UserProfile = () => {
  // CONTEXT VARIABLES
  const { reloadPhysicianIdentityData, physicianId, setPhysicianId, setPhysicianName } = useContext(GlobalState);

  // LOCAL VARIABLES
  const [userId, setUserId] = useState("")
  const [details, setDetails] = useState("")
  const [spinner, setSpinner] = useState(false)
 
  const [userDetails, setUserDetails] = useState("");

  const [currentEmail, setCurrenctEmail] = useState("");



  // GET URL PARAMETERS 
  function useQuery() { return new URLSearchParams(useLocation().search); }
  let query = useQuery();

  // GET TOKEN
  let token = localStorage.getItem("token");

  // OBTAINING BASE URL BASED ON ENVIRONMENT
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

  // NOTIFICATION PANEL FOR SUCCESS
  const showToastrSuccess = (message, subMessage = "") => { // eslint-disable-line
    const options = {
      timeOut: parseInt(5000),
      showCloseButton: true,
      progressBar: false,
      position: "bottom-left",
    };

    const toastrInstance = toastr.success;
    toastrInstance(message, subMessage, options);
  };



  const getUserDetails = () => {
    setSpinner(true);

    const params = {
      user_id: physicianId
    }

    axios
      .post(`${API_ENDPOINT}/user/get-physician-profile-data/`, params,
        {
          headers: {
            Authorization: token,
          },
        })
      .then(function (response) {
        setSpinner(false);
        setDetails(response.data.data[0]);
        setUserDetails(response.data.account_details[0]);
        
      })
      .catch(function (error) {
        // console.log(error);
        setSpinner(false);
      });
  };




 

  // PROCESS USER DETAILS
  const processUserDetails = () => {
    setCurrenctEmail(userDetails.email);
  };

  // EFFECT TO GET THE URL PARAMETERS 
  useEffect(() => {
    if (query) {
      setUserId(localStorage.getItem("userId"));
      setPhysicianId(Number(atob(query.get("pid"))));
      setPhysicianName(atob(query.get("pname")));
    }
  }, [query])// eslint-disable-line

  // GET PHYSICIAN DETAILS 
  useEffect(() => { if (userId && !details && !spinner) getUserDetails(); }, [details, userId, reloadPhysicianIdentityData]);// eslint-disable-line


  useEffect(() => {
    if (userDetails) {
      processUserDetails();
    }
  }, [userDetails]);// eslint-disable-line


  return (
    <div className="user-profile-card disable-scrollbars">
      <Card sm={12}>
        {spinner ? <Spinner color="success" /> : ""}
        <CardHeader className="current-email-height">
          <CardTitle tag="h5">Email Address</CardTitle>
          <strong className="align-middle">{currentEmail}</strong>
          
        </CardHeader>
       
      </Card>

     

     {/*  <Card>
        <CardHeader>
          
            <AvForm>
             
        
              <Row>
                <Col>
                  <Button
                    color="success"
                    className="approve-all-document margin-left"
                    type="reset"
                    outline
                    onClick={() => setCollapseConsultation(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="success"
                    className="approve-all-document margin-left"
                    type="submit"
                    onClick={updateConsultationFee}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </AvForm>
     
        </CardHeader>
      </Card> */}
    </div>
  );
};

const Account = () => {
  // CONTEXT VARIABLES
  const { physicianName } = useContext(GlobalState);
  const [pathName, setPathName] = useState("");
  let location = useLocation();

  const getUserTypeFromURL = () => {
    let pathName = location.pathname
    let userTypeInURL = pathName.split("/")
    let filtered = userTypeInURL.filter(function (item) { return item !== "" })
    userTypeInURL = filtered[0]
    userTypeInURL = userTypeInURL.toLocaleLowerCase()
    userTypeInURL = userTypeInURL.charAt(0).toUpperCase() + userTypeInURL.slice(1)
    setPathName(userTypeInURL)
  }

  // EFFECT TO CHANGE THE BREADCRUMB BASE ON URL
  useEffect(() => { getUserTypeFromURL() }, [])// eslint-disable-line

  return (
    <>
      <Header>
        <HeaderTitle>Settings</HeaderTitle>
        <Breadcrumb>
          <BreadcrumbItem>
            <a href={`/${pathName.toLowerCase()}`}>{pathName}</a>
          </BreadcrumbItem>
          <BreadcrumbItem active>User Profile</BreadcrumbItem>
          <BreadcrumbItem active>{physicianName}</BreadcrumbItem>
        </Breadcrumb>
      </Header>
      <Row>
        <Col md="3" xl="2">
          <Navigation />
        </Col>
        <Col md="9" xl="10">
          <UserProfile />
        </Col>
      </Row>
    </>
  );
}

export default connect((store) => ({
  sidebar: store.sidebar,
}))(Account);
