import React, { useState, useEffect, useContext } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Collapse, Card, CardBody, Label, CardHeader, Input, Spinner } from "reactstrap";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import DropZone from './DropZone';
import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { GlobalState } from '../../../../contextAPI/Context';
let practiceId = null;

const DocUploadModal = (props) => {
  // DEPENDENCY VARIABLES  
  const {
    uploadDocModal,
    setUploadDocModal,
    userId,
    addMissingDocument,
    setAddMissingDocument,
    physicianRegistrationId,
    // setAddMissingDocumentCountry,
    addMissingDocumentCountry,
    allRegPhyDetails
  } = props;

  // CONTEXT VARIABLES
  const { reloadPhysicianIdentityData, setReloadPhysicianIdentityData, userType } = useContext(GlobalState);

  // GET TOKEN
  let token = localStorage.getItem("token");
  let userID = localStorage.getItem("userId");
  // OBTAINING BASE URL BASED ON ENVIRONMENT
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

  // LOCAL VARIABLES
  const [spinner, setSpinner] = useState(false)
  const [collapse, setCollapse] = useState(false)
  const [collapse2, setCollapse2] = useState(false)
  const [countryList, setCountryList] = useState("")
  const [stateList, setStateList] = useState("")
  const [fileReady, setFileReady] = useState(false)
  const [documentTypeId, setDocumentTypeId] = useState("")
  const [cvFile, setCvFile] = useState("")
  const [qualificationFile, setQualificationFile] = useState("")
  const [otherFile, setOtherFile] = useState("")
  const [country, setCountry] = useState("")
  const [state, setState] = useState("") //eslint-disable-line
  const [countryId, setCountryId] = useState("")
  const [stateId, setStateId] = useState("") //eslint-disable-line
  const [registration, setRegistration] = useState("")
  const [certificateFile, setCertificateFile] = useState("")
  const [dbsFile, setDbsFile] = useState("")
  const [indemnityFile, setIndemnityFile] = useState("")
  const [certificateExpiry, setCertificateExpiry] = useState("")
  const [dbsExpiry, setDbsExpiry] = useState("")
  const [indemnityExpiry, setIndemnityExpiry] = useState("")
  const [certificateLight, setCertificateLight] = useState("")
  const [dbsLight, setDbsLight] = useState("")
  const [indemnityLight, setIndemnityLight] = useState("")
  const [typeIds, setTypeIds] = useState([])
  // let typeIds=[]
  // LOCAL VARIABLES FOR COUNTRY US
  const [stateMedicalLicensureLight, setStateMedicalLicensureLight] = useState("")
  const [npdbLight, setNpdbLight] = useState("")
  const [pliLight, setPliLight] = useState("")
  const [scoreLight, setScoreLight] = useState("")
  const [stateMedicalLicensureExpiry, setStateMedicalLicensureExpiry] = useState("")
  const [npdbReportExpiry, setNpdbReportExpiry] = useState("")
  const [stateMedicalLicensureFile, setStateMedicalLicensureFile] = useState("")
  const [npdbFile, setNpdbFile] = useState("")
  const [professionalLiabilityInsuranceFile, setProfessionalLiabilityInsuranceFile] = useState("")
  const [scoreFile, setScoreFile] = useState("")
  const [professionalLiabilityInsuranceExpiry, setProfessionalLiabilityInsuranceExpiry] = useState("")

  const countryOptions = countryList
    ? countryList.map((key) => {
      return { value: key.id, label: key.country_name };
    })
    : ""
  const stateOptions = stateList
    ? stateList.map((key) => {
      return { value: key.shortCode, label: key.stateName };
    })
    : ""

  const options = [
    { value: 20, label: "CV" },
    { value: 21, label: "Qualification" },
    { value: 25, label: "Other Document" },
    { value: 0, label: "Country Of Registration" },
  ]

  const getCountryList = () => {
    axios.get(API_ENDPOINT + "/external/get-country-list/",
      {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        setCountryList(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const getStateList = () => {
    axios.get(API_ENDPOINT + "/external/get-state-list/",
      {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        setStateList(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const apiCallForDocumentUpload = () => {
    setSpinner(true);
    let file = "";
    // FUNCTION TO DETERMINE FILE BASED ON DOCUMENT TYPE
    const getFile = () => {
      if (documentTypeId === 20) {file = cvFile}
      if (documentTypeId === 21) {file = qualificationFile}
      if (documentTypeId === 25) {file = otherFile}
    }

    getFile();

    // CREATING FORM DATA 
    const FileFormData = new FormData();
    FileFormData.append("file", file[0]);
    FileFormData.set("type", documentTypeId);
    FileFormData.set("userID", userID);
    FileFormData.set("user_id", userId);

    axios
      .post(
        `${API_ENDPOINT}/user/add-document/`,
        FileFormData,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then(function (response) {
        toastr.success(
          "Done",
          "Document added successfully"
        );
        setSpinner(false);
        setReloadPhysicianIdentityData(reloadPhysicianIdentityData + 1)
        toggle();
      })
      .catch(function (error) {
        toastr.error(
          "Error Occurred",
          "File not uploaded "
        );
        setSpinner(false);
      });
  }

  const apiCallForCertificateUpload = () => {
    setSpinner(true);

    // CREATING FORM DATA 
    const FileFormData = new FormData();
    FileFormData.append("file", certificateFile);
    FileFormData.set("type", 22);
    FileFormData.set("userID", userID);
    FileFormData.set("user_id", userId);
    FileFormData.set("phyPracticeID", practiceId);
    FileFormData.set("expiresOn", moment(certificateExpiry).format("YYYY-MM-DD"));

    axios
      .post(
        `${API_ENDPOINT}/user/add-document/`,
        FileFormData,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then(function (response) {
        setSpinner(false);
        setTimeout(() => {
          setReloadPhysicianIdentityData(reloadPhysicianIdentityData + 1)
        }, 100);
        toastr.success(
          "Done",
          "Certificate uploaded"
        );
      })
      .catch(function (error) {
        setSpinner(false);
        toastr.error(
          "Error Occurred",
          "File not uploaded "
        );
      });
  }

  const apiCallForDBSUpload = () => {
    setSpinner(true);

    // CREATING FORM DATA 
    const FileFormData = new FormData();
    FileFormData.append("file", dbsFile);
    FileFormData.set("type", 23);
    FileFormData.set("userID", userID);
    FileFormData.set("user_id", userId);
    FileFormData.set("phyPracticeID", practiceId);
    FileFormData.set("expiresOn", moment(dbsExpiry).format("YYYY-MM-DD"));

    axios
      .post(
        `${API_ENDPOINT}/user/add-document/`,
        FileFormData,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then(function (response) {
        setSpinner(false);
        setTimeout(() => {
          setReloadPhysicianIdentityData(reloadPhysicianIdentityData + 2)
        }, 500);
        toastr.success(
          "Done",
          `${country === "Ireland" ? "Garda Vetting Uploaded" : "DBS Form Uploaded"}`
        );
      })
      .catch(function (error) {
        setSpinner(false);
        toastr.error(
          "Error Occurred",
          "File not uploaded "
        );
        setSpinner(false);
      });
  }

  const apiCallForIndemnityUpload = () => {
    setSpinner(true);

    // CREATING FORM DATA 
    const FileFormData = new FormData();
    FileFormData.append("file", indemnityFile);
    FileFormData.set("type", 24);
    FileFormData.set("userID", userID);
    FileFormData.set("user_id", userId);
    FileFormData.set("phyPracticeID", practiceId);
    FileFormData.set("expiresOn", moment(indemnityExpiry).format("YYYY-MM-DD"));

    axios
      .post(
        `${API_ENDPOINT}/user/add-document/`,
        FileFormData,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then(function (response) {
        setSpinner(false);
        setTimeout(() => {
          setReloadPhysicianIdentityData(reloadPhysicianIdentityData + 3)
        }, 1000);
        toastr.success(
          "Done",
          "Indemnity uploaded"
        );
      })
      .catch(function (error) {
        setSpinner(false);
        toastr.error(
          "Error Occurred",
          "File not uploaded "
        );
        setSpinner(false);
      });
  }
  const apiCallForStateMedicalLicenseUpload = () => {
    setSpinner(true);

    // CREATING FORM DATA 
    const FileFormData = new FormData();
    FileFormData.append("file", stateMedicalLicensureFile);
    FileFormData.set("type", 26);
    FileFormData.set("userID", userID);
    FileFormData.set("user_id", userId);
    FileFormData.set("phyPracticeID", practiceId);
    FileFormData.set("expiresOn", moment(stateMedicalLicensureExpiry).format("YYYY-MM-DD"));

    axios
      .post(
        `${API_ENDPOINT}/user/add-document/`,
        FileFormData,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then(function (response) {
        setSpinner(false);
        setTimeout(() => {
          setReloadPhysicianIdentityData(reloadPhysicianIdentityData + 3)
        }, 1000);
        toastr.success(
          "Done",
          "Medical License uploaded"
        );
      })
      .catch(function (error) {
        setSpinner(false);
        toastr.error(
          "Error Occurred",
          "File not uploaded "
        );
        setSpinner(false);
      });
  }
  const apiCallForNpdbUpload = () => {
    setSpinner(true);

    // CREATING FORM DATA 
    const FileFormData = new FormData();
    FileFormData.append("file", npdbFile);
    FileFormData.set("type", 27);
    FileFormData.set("userID", userID);
    FileFormData.set("user_id", userId);
    FileFormData.set("phyPracticeID", practiceId);
    FileFormData.set("expiresOn", moment(npdbReportExpiry).format("YYYY-MM-DD"));

    axios
      .post(
        `${API_ENDPOINT}/user/add-document/`,
        FileFormData,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then(function (response) {
        setSpinner(false);
        setTimeout(() => {
          setReloadPhysicianIdentityData(reloadPhysicianIdentityData + 3)
        }, 1000);
        toastr.success(
          "Done",
          "NPDB Report uploaded"
        );
      })
      .catch(function (error) {
        setSpinner(false);
        toastr.error(
          "Error Occurred",
          "File not uploaded "
        );
        setSpinner(false);
      });
  }
  const apiCallForPLInsuranceUpload = () => {
    setSpinner(true);

    // CREATING FORM DATA 
    const FileFormData = new FormData();
    FileFormData.append("file", professionalLiabilityInsuranceFile);
    FileFormData.set("type", 28);
    FileFormData.set("userID", userID);
    FileFormData.set("user_id", userId);
    FileFormData.set("phyPracticeID", practiceId);
    FileFormData.set("expiresOn", moment(professionalLiabilityInsuranceExpiry).format("YYYY-MM-DD"));

    axios
      .post(
        `${API_ENDPOINT}/user/add-document/`,
        FileFormData,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then(function (response) {
        setSpinner(false);
        setTimeout(() => {
          setReloadPhysicianIdentityData(reloadPhysicianIdentityData + 3)
        }, 1000);
        toastr.success(
          "Done",
          "NPDB Report uploaded"
        );
      })
      .catch(function (error) {
        setSpinner(false);
        toastr.error(
          "Error Occurred",
          "File not uploaded "
        );
        setSpinner(false);
      });
  }
  const apiCallForScoreUpload = () => {
    setSpinner(true);

    // CREATING FORM DATA 
    const FileFormData = new FormData();
    FileFormData.append("file", scoreFile);
    FileFormData.set("type", 29);
    FileFormData.set("userID", userID);
    FileFormData.set("user_id", userId);
    FileFormData.set("phyPracticeID", practiceId);
    // FileFormData.set("expiresOn", moment(professionalLiabilityInsuranceExpiry).format("YYYY-MM-DD"));

    axios
      .post(
        `${API_ENDPOINT}/user/add-document/`,
        FileFormData,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then(function (response) {
        setSpinner(false);
        setTimeout(() => {
          setReloadPhysicianIdentityData(reloadPhysicianIdentityData + 3)
        }, 1000);
        toastr.success(
          "Done",
          "NPDB Report uploaded"
        );
      })
      .catch(function (error) {
        setSpinner(false);
        toastr.error(
          "Error Occurred",
          "File not uploaded "
        );
        setSpinner(false);
      });
  }

  const apiCallForAddingPractice = () => {
    setSpinner(true);

    // CREATING PARAMETERS
    const params = {
      "userID": userID,
      "user_id": userId,
      "registrationNumber": registration,
      "countryID": countryId,
      "stateID": stateId,
      "stateOfRegistration": state
    }

    axios
      .post(
        `${API_ENDPOINT}/user/add-practice/`,
        params,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then(function (response) {
        // SAVE PRACTICE ID IN GLOBAL SCOPE
        if (response.data.practiceId) practiceId = response.data.practiceId;
        // ON SUCCESS CALL APIS TO UPLOAD OTHER 3 DOCUMENT SET
        if (certificateLight) {apiCallForCertificateUpload();}
        if (dbsLight) {apiCallForDBSUpload();}
        if (indemnityLight) {apiCallForIndemnityUpload();}
        if (stateMedicalLicensureLight) {apiCallForStateMedicalLicenseUpload();}
        if (npdbLight) {apiCallForNpdbUpload();}
        if (pliLight) {apiCallForPLInsuranceUpload();}
        if (scoreLight) {apiCallForScoreUpload();}
        toggle();
      })
      .catch(function (error) {
        toastr.error(
          error.response.data.message,
          "File not uploaded "
        );
        setSpinner(false);
      });
  }

  const apiCallForAddingMissingDocuments = () => {
    setSpinner(true);
    // SAVE PRACTICE ID IN GLOBAL SCOPE
    practiceId = physicianRegistrationId;
    // CALL APIS TO UPLOAD AVAILABLE DOCUMENTs
    if (certificateLight) {apiCallForCertificateUpload();}
    if (dbsLight) {apiCallForDBSUpload();}
    if (indemnityLight) {apiCallForIndemnityUpload();}
    if (stateMedicalLicensureLight) {apiCallForStateMedicalLicenseUpload();}
    if (npdbLight) {apiCallForNpdbUpload();}
    if (pliLight) {apiCallForPLInsuranceUpload();}
    if (scoreLight) {apiCallForScoreUpload();}
    toggle();
  }

  // REGULAR EXPRESSION CHECK FOR REGISTRATION NUMBER
  const alphaNumericCheck = (value) => {
    var result = value.replace(/[^a-z0-9]/gi, '');
    setRegistration(result);
  }

  // FILE VALIDATION 
  const validateFile = (file, filename, docType) => {
    // FILE SIZE VALIDATION 
    let {size} = file
    if (size > 5242880) {
      toastr.error("File Size Exceeded", "Please upload a file lesser than 5MB in size");
    } else {
      // FILE EXTENSION VALIDATION 
      let extension = filename.split('.').pop();
      if (extension.toLowerCase() === "jpg"
        || extension.toLowerCase() === "jpeg"
        || extension.toLowerCase() === "png"
        || extension.toLowerCase() === "pdf"
        || extension.toLowerCase() === "doc"
        || extension.toLowerCase() === "docx"
      ) {
        if (docType === "certificate") {setCertificateFile(file);}
        if (docType === "dbs") {setDbsFile(file);}
        if (docType === "indemnity") {setIndemnityFile(file);}
        if (docType === "stateMedicalLicensure") {setStateMedicalLicensureFile(file);}
        if (docType === "npdbReport") {setNpdbFile(file);}
        if (docType === "professionalLiabilityInsurance") {setProfessionalLiabilityInsuranceFile(file);}
        if (docType === "score") {setScoreFile(file);}
      } else {
        // SETTING FILE VARIABLE EMPTY
        if (docType === "certificate") {setCertificateFile("");}
        if (docType === "dbs") {setDbsFile("");}
        if (docType === "indemnity") {setIndemnityFile("");}
        if (docType === "stateMedicalLicensure") {setStateMedicalLicensureFile("");}
        if (docType === "npdbReport") {setNpdbFile("");}
        if (docType === "professionalLiabilityInsurance") {setProfessionalLiabilityInsuranceFile("");}
        if (docType === "score") {setScoreFile("");}

        // ERROR POPUP
        toastr.error(
          "Unsupported File",
          "File is not supported for the upload"
        );
      }
    }
  }

  const certificationOfRegistrationUpload = (
    <>
      <Row>
        <Col >
          <Label>Expiry Date</Label>
          <DatePicker
            minDate={moment().toDate()}
            selected={certificateExpiry}
            dateFormat="dd MMM yyyy"
            onChange={(date) => { setCertificateExpiry(date) }}
          />
        </Col>
        <Col >
          <br />
          <Label className="identity-file-upload">
            <FontAwesomeIcon icon={faUpload} />
            <span className="margin-left">Upload</span>
            <Input
              type="file"
              onChange={(event) => {
                validateFile(
                  event.target.files ? event.target.files[0] : "",
                  event.target.files ? event.target.files[0].name : "",
                  'certificate'
                )
              }}
            ></Input>
          </Label>
        </Col>
      </Row>
      <Row>
        <Col>
          {certificateFile ? <Label className="file-name">File Name: {certificateFile.name}</Label> : ""}
        </Col>
      </Row>
    </>
  )

  const dbsFormUpload = (
    <>
      <Row>
        <Col >
          <Label>Expiry Date</Label>
          <DatePicker
            minDate={moment().toDate()}
            selected={dbsExpiry}
            dateFormat="dd MMM yyyy"
            onChange={(date) => { setDbsExpiry(date) }}
          />
        </Col>
        <Col >
          <br />
          <Label className="identity-file-upload">
            <FontAwesomeIcon icon={faUpload} />
            <span className="margin-left">Upload</span>
            <Input
              type="file"
              onChange={(event) => {
                validateFile(
                  event.target.files ? event.target.files[0] : "",
                  event.target.files ? event.target.files[0].name : "",
                  'dbs'
                )
              }}
            ></Input>
          </Label>
        </Col>
      </Row>
      <Row>
        <Col>
          {dbsFile ? <Label className="file-name">File Name: {dbsFile.name}</Label> : ""}
        </Col>
      </Row>
    </>
  )

  const indemnityUpload = (
    <>
      <Row>
        <Col >
          <Label>Expiry Date</Label>
          <DatePicker
            minDate={moment().toDate()}
            selected={indemnityExpiry}
            dateFormat="dd MMM yyyy"
            onChange={(date) => { setIndemnityExpiry(date) }}
          />
        </Col>
        <Col >
          <br />
          <Label className="identity-file-upload">
            <FontAwesomeIcon icon={faUpload} />
            <span className="margin-left">Upload</span>
            <Input
              type="file"
              onChange={(event) => {
                validateFile(
                  event.target.files ? event.target.files[0] : "",
                  event.target.files ? event.target.files[0].name : "",
                  'indemnity'
                )
              }}
            ></Input>
          </Label>
        </Col>
      </Row>
      <Row>
        <Col>
          {indemnityFile ? <Label className="file-name">File Name: {indemnityFile.name}</Label> : ""}
        </Col>
      </Row>
    </>
  )
  const stateMedicalLicensureUpload = (
    <>
      <Row>
        <Col >
          <Label>Expiry Date</Label>
          <DatePicker
            minDate={moment().toDate()}
            selected={stateMedicalLicensureExpiry}
            dateFormat="dd MMM yyyy"
            onChange={(date) => { setStateMedicalLicensureExpiry(date) }}
          />
        </Col>
        <Col >
          <br />
          <Label className="identity-file-upload">
            <FontAwesomeIcon icon={faUpload} />
            <span className="margin-left">Upload</span>
            <Input
              type="file"
              onChange={(event) => {
                validateFile(
                  event.target.files ? event.target.files[0] : "",
                  event.target.files ? event.target.files[0].name : "",
                  'stateMedicalLicensure'
                )
              }}
            ></Input>
          </Label>
        </Col>
      </Row>
      <Row>
        <Col>
          {stateMedicalLicensureFile ? <Label className="file-name">File Name: {stateMedicalLicensureFile.name}</Label> : ""}
        </Col>
      </Row>
    </>
  )
  const npdbReportUpload = (
    <>
      <Row>
        <Col >
          <Label>Expiry Date</Label>
          <DatePicker
            minDate={moment().toDate()}
            selected={npdbReportExpiry}
            dateFormat="dd MMM yyyy"
            onChange={(date) => { setNpdbReportExpiry(date) }}
          />
        </Col>
        <Col >
          <br />
          <Label className="identity-file-upload">
            <FontAwesomeIcon icon={faUpload} />
            <span className="margin-left">Upload</span>
            <Input
              type="file"
              onChange={(event) => {
                validateFile(
                  event.target.files ? event.target.files[0] : "",
                  event.target.files ? event.target.files[0].name : "",
                  'npdbReport'
                )
              }}
            ></Input>
          </Label>
        </Col>
      </Row>
      <Row>
        <Col>
          {npdbFile ? <Label className="file-name">File Name: {npdbFile.name}</Label> : ""}
        </Col>
      </Row>
    </>
  )
  const professionalLiabilityInsuranceUpload = (
    <>
      <Row>
        <Col >
          <Label>Expiry Date</Label>
          <DatePicker
            minDate={moment().toDate()}
            selected={professionalLiabilityInsuranceExpiry}
            dateFormat="dd MMM yyyy"
            onChange={(date) => { setProfessionalLiabilityInsuranceExpiry(date) }}
          />
        </Col>
        <Col >
          <br />
          <Label className="identity-file-upload">
            <FontAwesomeIcon icon={faUpload} />
            <span className="margin-left">Upload</span>
            <Input
              type="file"
              onChange={(event) => {
                validateFile(
                  event.target.files ? event.target.files[0] : "",
                  event.target.files ? event.target.files[0].name : "",
                  'professionalLiabilityInsurance'
                )
              }}
            ></Input>
          </Label>
        </Col>
      </Row>
      <Row>
        <Col>
          {professionalLiabilityInsuranceFile ? <Label className="file-name">File Name: {professionalLiabilityInsuranceFile.name}</Label> : ""}
        </Col>
      </Row>
    </>
  )
  const usmleOrComlexUpload = (
    <>
      <Row>
        <Col >
          <br />
          <Label className="identity-file-upload">
            <FontAwesomeIcon icon={faUpload} />
            <span className="margin-left">Upload</span>
            <Input
              type="file"
              onChange={(event) => {
                validateFile(
                  event.target.files ? event.target.files[0] : "",
                  event.target.files ? event.target.files[0].name : "",
                  'score'
                )
              }}
            ></Input>
          </Label>
        </Col>
      </Row>
      <Row>
        <Col>
          {scoreFile ? <Label className="file-name">File Name: {scoreFile.name}</Label> : ""}
        </Col>
      </Row>
    </>
  )

  const standardFileUpload = (
    <>
      <Card className="kyc-bg-color2">
        <CardHeader tag="h5">Upload Document </CardHeader>
        <CardBody>

          {/* DRAG AND DROP COMPONENT  */}
          <DropZone
            setFileReady={setFileReady}
            documentTypeId={documentTypeId}
            setCvFile={setCvFile}
            setQualificationFile={setQualificationFile}
            setOtherFile={setOtherFile}
          />
        </CardBody>
      </Card>
      <Label>The file should be ' jpg, png, pdf, doc or docx ' and less than 5 MB in size</Label>
    </>
  )

  const countryOfRegistration = (
  <CardBody>
    {addMissingDocument ? (
      ""
    ) : (
      <Row>
        <Col>
          <Label tag="h5">Country</Label>
          <Select
            name="country"
            options={countryOptions}
            onChange={(e) => {
              setCountry(e.label);
              setCountryId(e.value);
              let x = allRegPhyDetails[0] ? allRegPhyDetails[0].reg_documents : [];
              // console.log('allRegPhyDetails', x);
              // debugger
              const updatedTypeIds = x.map((document) => document.type_id);
              setTypeIds(updatedTypeIds); // Set the state variable
              // console.log('typeIds', updatedTypeIds);
              // console.log('27', updatedTypeIds.includes(27));
              // console.log('28', updatedTypeIds.includes(28));
              // console.log('29', updatedTypeIds.includes(29));
  }}
/>
        </Col>
        {countryId === 223 ? (
          <Col>
            <Label tag="h5">State</Label>
            <Select
              name="state"
              options={stateOptions}
              onChange={(e) => {
                setState(e.label);
                setStateId(e.value);
              }}
            />
          </Col>
        ) : null}
        <Col>
          <Label tag="h5">{userType === 6 ? 'NMC PIN' : 'Registration Number'}</Label>
          <Input
            className="input-height"
            type="text"
            pattern="^[a-zA-Z0-9_]*$"
            placeholder="Registration No"
            maxLength={24}
            value={registration}
            onChange={(e) => {
              alphaNumericCheck(e.target.value);
            }}
          />
        </Col>
      </Row>
    )}

    {country && countryId === 223 ? (
      <>
        <Card className={stateMedicalLicensureLight ? 'kyc-bg-color doc-upload-margin-top green-shadow' : 'kyc-bg-color doc-upload-margin-top'}>
          <CardHeader tag="h5">State Medical Licensure</CardHeader>
          <CardBody>{stateMedicalLicensureUpload}</CardBody>
        </Card>
        {userType === 3 ? (
          <>
          {typeIds.includes(27)===true ? null :(
            <Card className={npdbLight ? 'kyc-bg-color green-shadow' : 'kyc-bg-color'}>
              <CardHeader tag="h5">NPDB Report</CardHeader>
              <CardBody>{npdbReportUpload}</CardBody>
            </Card>
            )}
            {typeIds.includes(28)===true ? null :(
            <Card className={pliLight ? 'kyc-bg-color green-shadow' : 'kyc-bg-color'}>
              <CardHeader tag="h5">Professional Liability Insurance</CardHeader>
              <CardBody>{professionalLiabilityInsuranceUpload}</CardBody>
            </Card>
            )}
            {typeIds.includes(29)===true ? null :(
            <Card className={scoreLight ? 'kyc-bg-color green-shadow' : 'kyc-bg-color'}>
              <CardHeader tag="h5">USMLE or COMLEX Score</CardHeader>
              <CardBody>{usmleOrComlexUpload}</CardBody>
            </Card>
            )}
          </>
        ) : null}
      </>
    ) : (
      <>
      {country ? ( 
        <Card className={certificateLight ? 'kyc-bg-color doc-upload-margin-top green-shadow' : 'kyc-bg-color doc-upload-margin-top'}>
          <CardHeader tag="h5">Certification Of Registration</CardHeader>
          <CardBody>{certificationOfRegistrationUpload}</CardBody>
        </Card>
        ) : null}
        {userType === 3 && country ? (
          <>
            <Card className={dbsLight ? 'kyc-bg-color green-shadow' : 'kyc-bg-color'}>
              <CardHeader tag="h5">{country === 'Ireland' ? 'Garda Vetting' : 'DBS Form'}</CardHeader>
              <CardBody>{dbsFormUpload}</CardBody>
            </Card>
            {country ? ( 
              <Card className={indemnityLight ? 'kyc-bg-color green-shadow' : 'kyc-bg-color'}>
                <CardHeader tag="h5">Indemnity</CardHeader>
                <CardBody>{indemnityUpload}</CardBody>
              </Card>
            ) : null}
          </>
        ) : null}
      </>
    )}
    <Label>The file should be 'jpg, png, pdf, doc, or docx' and less than 5 MB in size</Label>
  </CardBody>
);

const countryOfRegistrationStandard = (
  <CardBody>
    {addMissingDocument
      ? ""
      : (
        <Row>
          <Col>
            <Label tag="h5">Country</Label>
            <Select
              name="country"
              options={countryOptions}
              onChange={(e) => {
                setCountry(e.label);
                setCountryId(e.value);
              }}

            />
          </Col>
          <Col>
            <Label tag="h5">{userType === 6 ? "NMC PIN" : "Registration Number"}</Label>
            <Input
              className="input-height"
              type="text"
              pattern="^[a-zA-Z0-9_]*$"
              maxLength={24}
              value={registration}
              onChange={(e) => { alphaNumericCheck(e.target.value) }}
            />
          </Col>
        </Row>
      )
    }


    { countryId ===223 ? <>
    <Card className={stateMedicalLicensureLight ? "kyc-bg-color doc-upload-margin-top green-shadow" : "kyc-bg-color doc-upload-margin-top"}>
      <CardHeader tag="h5">State Medical Licensure</CardHeader>
      <CardBody>
        {stateMedicalLicensureUpload}
      </CardBody>
    </Card>
    {userType === 3
      ? <>
        <Card className={npdbLight ? "kyc-bg-color green-shadow" : "kyc-bg-color"}>
          <CardHeader tag="h5"> NPDB Report </CardHeader>
          <CardBody>
            {npdbReportUpload}
          </CardBody>
        </Card>
        <Card className={pliLight ? "kyc-bg-color green-shadow" : "kyc-bg-color"}>
          <CardHeader tag="h5">Professional Liability Insurance </CardHeader>
          <CardBody>
            {professionalLiabilityInsuranceUpload}
          </CardBody>
        </Card>
        <Card className={scoreLight ? "kyc-bg-color green-shadow" : "kyc-bg-color"}>
          <CardHeader tag="h5">USMLE or COMLEX Score </CardHeader>
          <CardBody>
            {usmleOrComlexUpload}
          </CardBody>
        </Card>
      </>
      : ""}
    </> :

    <>
    <Card className={certificateLight ? "kyc-bg-color doc-upload-margin-top green-shadow" : "kyc-bg-color doc-upload-margin-top"}>
      <CardHeader tag="h5">Certification Of Registration </CardHeader>
      <CardBody>
        {certificationOfRegistrationUpload}
      </CardBody>
    </Card>
    {userType === 3
      ? <>
        <Card className={dbsLight ? "kyc-bg-color green-shadow" : "kyc-bg-color"}>
          <CardHeader tag="h5"> {country === "Ireland" ? "Garda Vetting" : "DBS Form"} </CardHeader>
          <CardBody>
            {dbsFormUpload}
          </CardBody>
        </Card>
        <Card className={indemnityLight ? "kyc-bg-color green-shadow" : "kyc-bg-color"}>
          <CardHeader tag="h5">Indemnity </CardHeader>
          <CardBody>
            {indemnityUpload}
          </CardBody>
        </Card>
      </>
      : ""}
<Label>The file should be ' jpg, png, pdf, doc or docx ' and less than 5 MB in size</Label>
      </>}
  </CardBody>
)
  const toggle = () => {
    setUploadDocModal(!uploadDocModal);
    setSpinner(false)
    setCollapse(false)
    setCollapse2(false)
    setFileReady("")
    setDocumentTypeId("")
    setCvFile("")
    setQualificationFile("")
    setOtherFile("")
    setCountry("")
    setCountryId("")
    setRegistration("")
    setCertificateFile("")
    setDbsFile("")
    setIndemnityFile("")
    setCertificateExpiry("")
    setDbsExpiry("")
    setIndemnityExpiry("")
    setAddMissingDocument(false)
    setStateMedicalLicensureExpiry("")
    setNpdbReportExpiry("")
    setStateMedicalLicensureFile("")
    setNpdbFile("")
    setProfessionalLiabilityInsuranceFile("")
    setScoreFile("")
    setProfessionalLiabilityInsuranceExpiry("")




  };

  //SAVE BUTTON ACTIONS
  const confirmation = () => {
    // API CALL FOR DOCUMENT UPLOAD
    if (documentTypeId === 20 || documentTypeId === 21 || documentTypeId === 25) {apiCallForDocumentUpload()}
    // SYNCHRONOUS API CALLS FOR REGISTRATION DOCUMENT UPLOAD
    if (!documentTypeId && !addMissingDocument) {
      apiCallForAddingPractice();
    }
    // SYNCHRONOUS API CALLS FOR MISSING DOCUMENT UPLOAD
    if (!documentTypeId && addMissingDocument) {
      apiCallForAddingMissingDocuments();
    }

  };

  // VALIDATION TO CHECK IF REGISTRATION DOCUMENTS ARE READY TO UPLOAD 
  const runValidation = () => {
    if (addMissingDocument) {
      if ((certificateFile || dbsFile || indemnityFile || stateMedicalLicensureFile || npdbFile || professionalLiabilityInsuranceFile || scoreFile) && (certificateExpiry || dbsExpiry || indemnityExpiry || stateMedicalLicensureExpiry || npdbReportExpiry || professionalLiabilityInsuranceExpiry || scoreFile)) {
        setFileReady(true)
      } else {
        setFileReady(false)
      }
    } else {
      if ((country && registration)
        && (certificateFile || dbsFile || indemnityFile || stateMedicalLicensureFile || npdbFile || professionalLiabilityInsuranceFile || scoreFile)
        && (certificateExpiry || dbsExpiry || indemnityExpiry || stateMedicalLicensureExpiry || npdbReportExpiry || professionalLiabilityInsuranceExpiry)) {
        setFileReady(true)
      } else {
        setFileReady(false)
      }
    }
  }

  // VALIDATION TO TOGGLE THE GREEN LIGHTS
  const toggleLights = () => {
    if (addMissingDocument) {
      // CERTIFICATE LIGHT
      if (certificateFile && certificateExpiry) {
        setCertificateLight(true)
      } else {
        setCertificateLight(false)
      }
      // DBS LIGHT
      if (dbsFile && dbsExpiry) {
        setDbsLight(true)
      } else {
        setDbsLight(false)
      }
      // INDEMNITY LIGHT
      if (indemnityFile && indemnityExpiry) {
        setIndemnityLight(true)
      } else {
        setIndemnityLight(false)
      }
      // STATE MEDICAL LICENSURE LIGHT
      if (stateMedicalLicensureFile && stateMedicalLicensureExpiry) {
        setStateMedicalLicensureLight(true)
      } else {
        setStateMedicalLicensureLight(false)
      }
      // NPDB LIGHT
      if (npdbFile && npdbReportExpiry) {
        setNpdbLight(true)
      } else {
        setNpdbLight(false)
      }
      // PROFESSIONAL LIABILITY INSURANCE LIGHT
      if (professionalLiabilityInsuranceFile && professionalLiabilityInsuranceExpiry) {
        setPliLight(true)
      } else {
        setPliLight(false)
      }
      // USMLE OR COMLEX LIGHT
      if (scoreFile) {
        setScoreLight(true)
      } else {
        setScoreLight(false)
      }
    } else {
      // CERTIFICATE LIGHT
      if (country && registration && certificateFile && certificateExpiry) {
        setCertificateLight(true)
      } else {
        setCertificateLight(false)
      }
      // DBS LIGHT
      if (country && registration && dbsFile && dbsExpiry) {
        setDbsLight(true)
      } else {
        setDbsLight(false)
      }
      // INDEMNITY LIGHT
      if (country && registration && indemnityFile && indemnityExpiry) {
        setIndemnityLight(true)
      } else {
        setIndemnityLight(false)
      }
      // STATE MEDICAL LICENSURE LIGHT
      if (country && registration && stateMedicalLicensureFile && stateMedicalLicensureExpiry) {
        setStateMedicalLicensureLight(true)
      } else {
        setStateMedicalLicensureLight(false)
      }
      // NPDB LIGHT
      if (country && registration && npdbFile && npdbReportExpiry) {
        setNpdbLight(true)
      } else {
        setNpdbLight(false)
      }
      // PROFESSIONAL LIABILITY INSURANCE LIGHT
      if (country && registration && professionalLiabilityInsuranceFile && professionalLiabilityInsuranceExpiry) {
        setPliLight(true)
      } else {
        setPliLight(false)
      }
      // USMLE OR COMLEX LIGHT
      if (country && registration && scoreFile) {
        setScoreLight(true)
      } else {
        setScoreLight(false)
      }
    }

  }

  useEffect(() => { if(!countryList) {getCountryList();} }, [countryList])// eslint-disable-line
  useEffect(() => { if(!stateList) {getStateList();} }, [stateList])// eslint-disable-line

  // EFFECT TO RUN VALIDATION ONCE A FILE IS UPLOADED
  useEffect(() => {
    runValidation();
    toggleLights();
    // eslint-disable-next-line
  }, [
    country,
    registration,
    //--> file variables
    certificateFile,
    dbsFile,
    indemnityFile,
    stateMedicalLicensureFile,
    npdbFile,
    professionalLiabilityInsuranceFile,
    scoreFile,
    //--> expiry variables
    certificateExpiry,
    dbsExpiry,
    indemnityExpiry,
    stateMedicalLicensureExpiry,
    npdbReportExpiry,
    professionalLiabilityInsuranceExpiry
  ])

  // EFFECT PRESELECT COUNTRY OF REGISTRATION IF ATTEMPTED TO ADD MISSING DOCUMENTS
  useEffect(() => {
    if (addMissingDocument) {
      setCollapse(false)
      setCollapse2(true)
      // setAddMissingDocument(addMissingDocumentCountry);
      setCountryId(addMissingDocumentCountry)
    };
  }, [addMissingDocument]) //eslint-disable-line

  return (
    <div>
      <Modal isOpen={uploadDocModal} toggle={toggle}>
        <ModalHeader tag="h4" cssModule={{'modal-title': 'w-100 text-center'}}  toggle={toggle}>Upload Document</ModalHeader>
        <ModalBody className="doc-upload-modal-height disable-scrollbars">
          {addMissingDocument
            ? countryOfRegistrationStandard
            : (
              <Card className="kyc-bg-color2">
                <CardHeader tag="h5">Document Type</CardHeader>
                <CardBody>
                  <Row>
                    <Col xs="6">
                      <Select
                        name="doc-type"
                        options={options}
                        isDisabled={!!fileReady}
                        onChange={(e) => {
                          if (e.value !== 0) {
                            setCollapse(true)
                            setCollapse2(false)
                            setDocumentTypeId(e.value)
                          };
                          if (e.value === 0) {
                            setCollapse(false)
                            setCollapse2(true)
                          };
                        }}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            )}
          <Row>
            <Col>
              <Collapse isOpen={collapse}>

                {/* STANDARD FILE UPLOAD COMPONENT  */}
                {standardFileUpload}
              </Collapse>
              <Collapse isOpen={collapse2}>
                <Card className="kyc-bg-color2">

                  {/* COUNTRY OF REGISTRATION COMPONENT  */}
                  {countryOfRegistration}
                </Card>
              </Collapse>
            </Col>
          </Row>
        </ModalBody>
        {spinner ? <Spinner color="success" /> : ""}
        <ModalFooter>
          {fileReady ? <Button className="yes-button button-width" onClick={confirmation}>Save</Button> : ""}
          {" "}
          <Button outline className="no-button button-width" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DocUploadModal;
