import React, { useState, useEffect, useContext } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Input,
  Button
} from "reactstrap";

import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import moment from 'moment';
import MomentConfig from '../../common/MomentConfig';
import PatientFilters from '../../newComponents/utilities/PatientFilters';
import { GlobalState } from '../../contextAPI/Context';


// const { SearchBar, ClearSearchButton } = Search;
const ExportCSVTable = (props) => {
  const { blankStateMessage } = useContext(GlobalState)
  // LOCAL VARIABLES 
  const { data, searchKeyword, setSearchKeyword ,clearSearchValues, exportTableData} = props
  const [numberOfRecords, setNumberOfRecords] = useState(0)

  const MyExportCSV = (props) => {
    const handleClick = () => {
      // props.onExport();
      exportTableData()
    };
    return (
      <div>
        <button className="btn btn-equipo-blue mt-2" onClick={handleClick}>
          Export
        </button>
      </div>
    );
  };

  const blankState = (
    <Card className="blank-state">
      <CardBody>
        <h3>No records available</h3>
      </CardBody>
    </Card>
  );

  const handleDataChange = ({ dataSize }) => {
    setNumberOfRecords(dataSize ? dataSize : 0);
  }

  useEffect(() => { setNumberOfRecords(data.length) }, [data]);

  return (
    <div className="physicians_table patient_table">
      <Card>
        <ToolkitProvider
          keyField="id"
          data={props.data}
          columns={props.columns}
          search
          exportCSV={{
            fileName: `equipo-Patient-Export-${moment().format(MomentConfig.MOMENT_FORMAT_DATEANDTIME)}.csv`
          }}
        >
          {(props) => (
            <div>
              <CardHeader>
                <Row>
                  <Col>
                    <PatientFilters props={props} />
                  </Col>
                </Row>
                <div className="separator" />
                <Row>
                  <Col sm="2">
                    <div className="number-of-records">
                      <p>Number of records: <strong>{numberOfRecords}</strong></p>
                    </div>
                  </Col>
                  <Col>
                  <div className='c-search-field form-inline justify-content-end' >
                      <div className='form-group mr-2'>
                        {/* <SearchBar {...props.searchProps} className="" /> */}
                        <Input
                                        autoFocus="autofocus"
                                        type="text"
                                        placeholder="Search"
                                        value={searchKeyword}
                                        onChange={
                                            (e) => {
                                                setSearchKeyword(e.target.value)

                                            }
                                        }
                                    >

                                    </Input>
                      </div>
                      {/* <ClearSearchButton {...props.searchProps} className="clear-search-button" /> */}
                      <Button
                                    className="clear-search-button"
                                    onClick={() => { clearSearchValues(); }}

                                    disabled={searchKeyword ? false : true}
                                    title={searchKeyword ? "" : "Nothing To Clear"}
                                >Clear</Button>
                      <div className="export-button">
                        <MyExportCSV {...props.csvProps} />
                      </div>
                    </div>
                  </Col>
                </Row>
                <div className="separator" />
                <div className="float-right pull-right">
                </div>
              </CardHeader>
              <CardBody className="patient-list">
                {blankStateMessage && blankState}
                {!blankStateMessage && <BootstrapTable
                  {...props.baseProps}
                  bootstrap4
                  id={props.key}
                  bordered={false}
                  onDataSizeChange={handleDataChange}
                  pagination={paginationFactory({
                    sizePerPage: 50,
                    sizePerPageList: [50, 100, 150, 200],
                  })}
                />}
              </CardBody>
            </div>
          )}
        </ToolkitProvider>
      </Card>
    </div>
  );
};

export default ExportCSVTable;