import React, { useState, useEffect, useContext } from 'react';
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import { Col, Label, Row, Button, Input } from "reactstrap";
import './DateRangeSelector.css';
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import { GlobalState } from '../../contextAPI/Context';
import axios from 'axios';
import { useLocation } from "react-router-dom";

const PatientFilters = (props) => {
    // CONTEXT VARIABLES 
    const {
        spinner,
        token,
        setPatientName,
        setPatientEmail,
        setPatientCountry,
        setPatientPhone,
        setPatientGroup,
        setPatientGroupList,
        setPatientGender,
        setPatientPostCode,
        setPatientStatus
    } = useContext(GlobalState)
    // LOCAL VARIABLES 
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [postCode, setPostCode] = useState("")
    const [gender, setGender] = useState("")
    const [status, setStatus] = useState("")
    // CORPORATE / GROUP
    const [group, setGroup] = useState("")
    const [groupList, setGroupList] = useState("")
    const [groupDropDown, setGroupDropDown] = useState("")
    // COUNTRY
    const [country, setCountry] = useState("")
    const [countryList, setCountryList] = useState("")
    const [countryDropDown, setCountryDropDown] = useState("")

    const StatusOptions = [
        { value: '1', label: 'Active' },
        { value: '0', label: 'Inactive' }
    ]

    const genderOptions = [
        { value: 'male', label: 'Male' },
        { value: 'female', label: 'Female' },
        { value: 'other', label: 'Other' },
    ]
    const location = useLocation();

    const animatedComponents = makeAnimated();
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

    const getValueFromArray = (array) => {
        return array.map(item => { return item.value })
    }

    const runSearch = () => {
        if (name || email || phone || postCode || gender || status || group || country) {
            setPatientName(name ? name : "")
            setPatientEmail(email ? email : "")
            setPatientPhone(phone ? phone : "")
            setPatientPostCode(postCode ? postCode : "")
            setPatientCountry(country ? getValueFromArray(country) : "")
            setPatientGender(gender ? getValueFromArray(gender) : "")
            setPatientGroup(group ? getValueFromArray(group) : "")
            setPatientStatus(status ? getValueFromArray(status) : "")
        }
    }

    const resetFields = () => {
        setName("")
        setEmail("")
        setPhone("")
        setPostCode("")
        setGender("")
        setStatus("")
        setGroup("")
        setCountry("")
        setPatientName("")
        setPatientEmail("")
        setPatientPhone("")
        setPatientPostCode("")
        setPatientCountry("")
        setPatientGender("")
        setPatientGroup("")
        setPatientStatus("")
    }
    useEffect(() => {
          if (location.pathname === '/patients') { resetFields();} }, [location.pathname]);//eslint-disable-line
          
    const getGroupList = () => {
        axios.get(API_ENDPOINT + "/external/coupon-corporate-group-list/",
            {
                headers: {
                    Authorization: token,
                },
            })
            .then((res) => {
                setGroupList(res.data);
                setPatientGroupList(res.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const getActiveGroupList = () => {
        axios.get(API_ENDPOINT + "/external/corporate-group-list/",
            {
                headers: {
                    Authorization: token,
                },
            })
            .then((res) => {
                setPatientGroupList(res.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const getCountryList = () => {
        axios.get(API_ENDPOINT + "/external/get-country-list/",
            {
                headers: {
                    Authorization: token,
                },
            })
            .then((res) => {
                setCountryList(res.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const createGroupOption = () => {
        var array = groupList
        const options = array.map((item) => {
            return { value: item.id, label: item.name };
        });
        setGroupDropDown(options)
    }

    const createCountryOption = () => {
        var array = countryList
        const options = array.map((item) => {
            return { value: item.country_name, label: item.country_name };
        });
        setCountryDropDown(options)
    }

    // EFFECTS FOR CORPORATE / GROUP
    useEffect(() => { if (token && !groupList) getGroupList(); }, [token]) // eslint-disable-line
    useEffect(() => { if (token && !groupList) getActiveGroupList(); }, [token]) // eslint-disable-line
    useEffect(() => { if (groupList.length > 0) createGroupOption() }, [groupList]) // eslint-disable-line
    // COUNTRY
    useEffect(() => { if (token && !countryList) getCountryList(); }, [token]) // eslint-disable-line
    useEffect(() => { if (countryList.length > 0) createCountryOption() }, [countryList]) // eslint-disable-line


    return (
        <AvForm>
            <AvGroup>
                <Row>
                    <Col sm="2">
                        <Label>Name</Label>
                        <AvField
                            type="text"
                            name="name"
                            placeholder="All"
                            value={name}
                            validate={{
                                maxLength: {value: 120}
                              }} 
                            onChange={(e) => {setName(e.target.value)}}
                        />
                    </Col>
                    <Col sm="2">
                        <Label>Email</Label>
                        <AvField
                            type="text"
                            name="email"
                            placeholder="All"
                            value={email}
                            validate={{
                                maxLength: {value: 254}
                              }} 
                            onChange={(e) => {setEmail(e.target.value)}}
                        />
                    </Col>
                    <Col sm="2">
                        <Label>Phone</Label>
                        <Input
                            className="form-control"
                            type="text"
                            name="phone"
                            placeholder="All"
                            value={phone}
                            onChange={(e) => {
                                if (e.target.value.length <= 12) {
                                    var result = e.target.value.replace(/[^0-9]/gi, '');
                                    setPhone(result);
                                }
                            }}
                        />
                    </Col>
                    <Col sm="2">
                        <Label>Post Code</Label>
                        <Input
                            className="form-control"
                            name="postCode"
                            placeholder="All"
                            value={postCode}
                            onChange={(e) => {
                                if (e.target.value.length <= 10) {
                                    var result = e.target.value.replace(/[^a-z0-9]/gi, '');
                                    setPostCode(result)
                                }
                            }}
                        />
                    </Col>
                    <Col sm="2">
                        <Label>Country</Label>
                        <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            onChange={(event) => { setCountry(event) }}
                            isSearchable
                            placeholder="All"
                            value={country}
                            options={countryDropDown}
                            components={animatedComponents}
                            isMulti
                        />
                    </Col>
                </Row>
                <Row>
                    <Col sm="2" className="second-row-filters" >
                        <Label>Gender</Label>
                        <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            onChange={(event) => { setGender(event) }}
                            isSearchable
                            placeholder="All"
                            value={gender}
                            options={genderOptions}
                            components={animatedComponents}
                            isMulti
                        />
                    </Col>
                    <Col sm="2" className="second-row-filters" >
                        <Label>Healthcare Partners</Label>
                        <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            onChange={(event) => { setGroup(event) }}
                            isSearchable
                            placeholder="All"
                            value={group}
                            options={groupDropDown}
                            components={animatedComponents}
                            isMulti
                        />
                    </Col>
                    <Col sm="2" className="second-row-filters" >
                        <Label>Status</Label>
                        <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            onChange={(event) => { setStatus(event) }}
                            isSearchable
                            placeholder="All"
                            value={status}
                            options={StatusOptions}
                            components={animatedComponents}
                            isMulti
                        />
                    </Col>
                    <Col>
                        <Button
                            type="submit"
                            className="go-buttons"
                            disabled={spinner ? true : false}
                            onClick={() => { runSearch() }}
                        >Go</Button>
                        <Button
                            type="reset"
                            className="reset-buttons"
                            disabled={spinner ? true : false}
                            onClick={() => { resetFields() }}
                        >Reset</Button>
                    </Col>
                </Row>
            </AvGroup>
        </AvForm>
    )
}

export default PatientFilters
