import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const SlotMessageModal = (props) => {
  // DEPENDENCY VARIABLES  
  const {
    slotMessageModal,
    setSlotMessageModal,
  } = props;

  const toggle = () => {
    setSlotMessageModal(!slotMessageModal);
  };

  return (
    <div>
      <Modal isOpen={slotMessageModal} toggle={toggle}>
        <ModalHeader tag="h4" cssModule={{'modal-title': 'w-100 text-center'}}  toggle={toggle}>Attention</ModalHeader>
        <ModalBody>
          Cannot update past slot. Please select a future slot time
        </ModalBody>
        <ModalFooter>
          <Button outline className="no-button button-width" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default SlotMessageModal;
