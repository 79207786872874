import React, { useState, useEffect, useContext } from 'react';
import { GlobalState } from '../../contextAPI/Context';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Input, Label, FormGroup, } from 'reactstrap';
import axios from 'axios';
import { toastr } from 'react-redux-toastr';


const NewMedicationModal = (props) => {
    const { newMedicationModal, setNewMedicationModal, token } = props;

    // CONTEXT VARIABLES 
    const { medicationListReload, setMedicationListReload, } = useContext(GlobalState);


    // LOCAL VARIABLES
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const [newDrugName, setNewDrugName] = useState("");
    const [newDrugCode, setNewDrugCode] = useState("");
    const [newGenericName, setNewGenericName] = useState("");
    const [newGenericNameCode, setNewGenericNameCode] = useState("");
    const [addButtonStatus, setAddButtonStatus] = useState(true);
    const [controlled, setControlled] = useState("")

    let userID = localStorage.getItem("userId");

    const toggle = () => {
        setNewMedicationModal(!newMedicationModal);

        setAddButtonStatus(true);
        setNewDrugName("")
        setNewDrugCode("")
        setNewGenericName("")
        setNewGenericNameCode("")

    }


    const confirmation = () => {
        if (!newDrugName) {
            toastr.error("Drug Name Is Required", "Please enter the drug name !")
        }
        if (!controlled){
            toastr.error("Controlled Status Is Mandatory", "Please select the controlled status !")
        }
        if (newDrugName && controlled) {
            updateMedicationApiCall()
        }
    };


    const updateMedicationApiCall = () => {


        axios.post(`${API_ENDPOINT}/external/create-medication/`, {

            "drug_name": newDrugName,
            "drug_code": newDrugCode,
            "generic_name_code": newGenericNameCode,
            "generic_name": newGenericName,
            "created_by": userID,
            "is_controlled":controlled

        }, {
            headers: {
                Authorization: token,
            }
        })
            .then(function (response) {
                setNewMedicationModal(!newMedicationModal)
                setMedicationListReload(medicationListReload + 1)
                toastr.success("Successfully Added", "Medication details successfully added")
                toggle();
            })
            .catch(function (error) {
                // console.log(error);
                toastr.error("Failed", "Please try again")
            });
    }


    const disableAddButton = () => {

        if (newDrugName) {
            setAddButtonStatus(false)
        }
        else {
            setAddButtonStatus(true)
        }
    }


    useEffect(() => { disableAddButton() }, [newDrugName, newDrugCode]) // eslint-disable-line

    return (
        <div>
            <Modal isOpen={newMedicationModal} toggle={toggle} size="md" >
                <ModalHeader tag="h4" cssModule={{'modal-title': 'w-100 text-center'}}  toggle={toggle}>Add New Medication </ModalHeader>
                <ModalBody className="overflow-modal disable-scrollbars" >
                    <FormGroup>
                        <Row>
                            <Col>

                                <Label for="name">Drug Name <span className="required">*</span></Label>
                                <Input
                                    id="name"
                                    placeholder="Enter the drug name"
                                    value={newDrugName}
                                    onChange={(e) => setNewDrugName(e.target.value)}
                                    minLength="1"
                                    maxLength="120"
                                />
                                <br></br>

                                <Label for="name">Drug Code </Label>
                                <Input
                                    id="code"
                                    placeholder="Enter the drug code"
                                    value={newDrugCode}
                                    onChange={(e) => setNewDrugCode(e.target.value)}
                                    minLength="1"
                                    maxLength="25"
                                />
                                <br></br>

                                <Label for="name">Generic Name </Label>
                                <Input
                                    id="genericName"
                                    placeholder="Enter the generic name"
                                    value={newGenericName}
                                    onChange={(e) => setNewGenericName(e.target.value)}
                                    minLength="1"
                                    maxLength="120"
                                />
                                <br></br>

                                <Label for="name">Generic Code </Label>
                                <Input
                                    id="genericCode"
                                    placeholder="Enter the generic code"
                                    value={newGenericNameCode}
                                    onChange={(e) => setNewGenericNameCode(e.target.value)}
                                    minLength="1"
                                    maxLength="25"
                                />
                                <br></br>

                                <Label for="controlled">Controlled Status <span className="required">*</span> </Label><br></br>
                                <Input
                                    
                                    value="Y"
                                    className="radio-cntrl"
                                    type="radio"
                                    name="radio1"
                                    onChange={(e) => setControlled(e.target.value)}
                                />
                                <Label for="Yes" className="radio-cntrl-label" >Controlled</Label>{" "}
                                <Input
                                    className="radio-un-ctrl"
                                    value="N"
                                    type="radio"
                                    name="radio1"
                                    onChange={(e) => setControlled(e.target.value) }
                                />{" "}
                                <Label for="uncontrolled" className="radio-un-ctrl-label">Uncontrolled</Label>


                            </Col>
                        </Row>
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button
                        className="yes-button button-width"
                        onClick={confirmation}
                        disabled={addButtonStatus}
                        title={addButtonStatus ? "Fill the required fields" : ""}
                    >Add</Button>{' '}
                    <Button outline className="no-button button-width" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default NewMedicationModal;