import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Label } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

const DeleteDocumentModal = (props) => {
  // DEPENDENCY VARIABLES  
  const {
    deleteDocumentModal,
    setDeleteDocumentModal,
    setDocumentDeleteConfirmation,
    documentType,
    documentName,
  } = props;

  // LOCAL VARIABLES
  const [deleteButton, setDeleteButton] = useState(false);
  const [text, setText] = useState("");

  const inputValidator = () => {
    const password = "delete me"
    if (text === password) {
      setDeleteButton(true)
    } else {
      setDeleteButton(false)
    }
  }

  const toggle = () => {
    setDeleteDocumentModal(!deleteDocumentModal);
    setText("");
    setDeleteButton(false);
  };

  const confirmation = () => {
    setDocumentDeleteConfirmation(true);
    toggle();
  };

  useEffect(() => { inputValidator() }, [text]) // eslint-disable-line

  return (
    <div>
      <Modal isOpen={deleteDocumentModal} toggle={toggle}>
        <ModalHeader tag="h4" cssModule={{'modal-title': 'w-100 text-center'}} toggle={toggle}>Delete Document: {documentType} - {documentName} </ModalHeader>
        <ModalBody>
          <Label>
            To confirm deletion, type "<strong>delete me</strong>" in the field
          </Label>
          <Input onChange={(e) => { setText(e.target.value) }} />
          <div className="warning-label margin-top">
            <FontAwesomeIcon
              icon={faExclamationTriangle}
            />
            <Label className="warning-text">Please note this action is irreversible, documents once deleted cannot be retrieved</Label>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button outline className="no-button button-width" onClick={toggle}>
            Cancel
          </Button>
          <Button
            className="yes-button button-width"
            disabled={deleteButton ? false : true}
            title={deleteButton ? "" : "Type 'delete me' in the field"}
            onClick={confirmation}
          >
            Delete
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DeleteDocumentModal;
