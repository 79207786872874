import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const StatusChangeModal = (props) => {
    const { processRefundModal, setProcessRefundModal, setProcessRefundConfirmation } = props;

    const toggle = () => { setProcessRefundModal(!processRefundModal); setProcessRefundConfirmation(false); }
    const confirmation = () => { setProcessRefundModal(!processRefundModal); setProcessRefundConfirmation(true); }

    return (
        <div>
            <Button color="danger" onClick={toggle}></Button>
            <Modal isOpen={processRefundModal} toggle={toggle}>
                <ModalHeader tag="h4" cssModule={{'modal-title': 'w-100 text-center'}}  toggle={toggle}>Change Status</ModalHeader>
                <ModalBody>
                    Are you sure you want to cancel this appointment without a refund?
                </ModalBody>
                <ModalFooter>
                    <Button className="yes-button button-width" onClick={confirmation}>Yes</Button>{' '}
                    <Button outline className="no-button button-width" onClick={toggle}>No</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default StatusChangeModal;