import React, { useState, useContext } from 'react';
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import { Col, Label, Row, Button } from "reactstrap";
import './DateRangeSelector.css';
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import { GlobalState } from '../../contextAPI/Context';

const MedicationFilters = (props) => {
    // CONTEXT VARIABLES 
    const {
        spinner,
        setDrugName,
        setDrugCode,
        setGenericName,
        setGenericNameCode,
        setDrugStatus,
        medicationListReload,
        setMedicationListReload,

    } = useContext(GlobalState)


    // LOCAL VARIABLES 
    const animatedComponents = makeAnimated();
    const [name, setName] = useState("")
    const [code, setCode] = useState("")
    const [genericDrugName, setGenericDrugName] = useState("")
    const [genericDrugCode, setGenericDrugCode] = useState("")

    //  STATUS 
    const [status, setStatus] = useState("")
    const statusDropDown = [
        { value: "1", label: 'Active' },
        { value: "0", label: 'Deleted' }

    ]

    const runSearch = () => {
        if (name || code || status || genericDrugName || genericDrugCode ) {
            setDrugName(name ? name : "")
            setDrugCode(code ? code : "")
            setGenericName(genericDrugName ? genericDrugName : "")
            setGenericNameCode(genericDrugCode ? genericDrugCode : "")
            setDrugStatus(status ? status.value : "")
            setMedicationListReload(medicationListReload + 1)
        }
    }

    const resetFields = () => {
        setName("")
        setCode("")
        setStatus("")
        setGenericDrugName("")
        setGenericDrugCode("")
        setDrugName("")
        setDrugCode("")
        setGenericName("")
        setGenericNameCode("")
        setDrugStatus("")
    }

    return (
        <AvForm>
            <AvGroup>
                <Row>
                    <Col sm="2">
                        <Label>Drug Name</Label>
                        <AvField
                            type="text"
                            name="name"
                            placeholder="All"
                            value={name}
                            validate={{
                                maxLength: { value: 120 }
                            }}
                            onChange={(e) => { setName(e.target.value) }}
                        />
                    </Col>
                    <Col sm="2" >
                        <Label>Drug Code</Label>
                        <AvField
                            type="text"
                            name="DrugCode"
                            placeholder="All"
                            value={code}
                            validate={{
                                maxLength: { value: 25 }
                            }}
                            onChange={(e) => { setCode(e.target.value) }}
                        />
                    </Col>
                    <Col sm="2">
                        <Label>Status</Label>
                        <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            onChange={(event) => { setStatus(event) }}
                            isSearchable
                            placeholder="All"
                            value={status}
                            options={statusDropDown}
                            components={animatedComponents}
                        />
                    </Col>
                    <Col sm="2"  >
                        <Label>Generic Name</Label>
                        <AvField
                            type="text"
                            name="GenericName"
                            placeholder="All"
                            value={genericDrugName}
                            validate={{
                                maxLength: { value: 120 }
                            }}
                            onChange={(e) => { setGenericDrugName(e.target.value) }}
                        />
                    </Col>
                    <Col sm="2"  >
                        <Label>Generic Code</Label>
                        <AvField
                            type="text"
                            name="GenericCode"
                            placeholder="All"
                            value={genericDrugCode}
                            validate={{
                                maxLength: { value: 25 }
                            }}
                            onChange={(e) => { setGenericDrugCode(e.target.value) }}
                        />
                    </Col>
                    <Col >
                        <div className="medication-go-reset-btn">
                        <Row>
                            <Button
                                type="submit"
                                className="go-buttons button-corporate medication-btns"
                                disabled={spinner ? true : false}
                                onClick={() => { runSearch() }}
                            >Go</Button>
                            <Button
                                type="reset"
                                className="reset-buttons button-corporate medication-btns"
                                disabled={spinner ? true : false}
                                onClick={() => { resetFields() }}
                            >Reset</Button>
                        </Row>
                        </div>
                    </Col>
                </Row>
            </AvGroup>
        </AvForm>
    )
}

export default MedicationFilters;
