import React, { useContext } from 'react';
import { GlobalState } from '../../contextAPI/Context';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col,Input,Label, FormGroup, } from 'reactstrap';
import axios from 'axios';
import { toastr } from 'react-redux-toastr';


  

const EditCorporateGroupModal = (props) => {
    const {
        editCorporateGroupModal,
        setEditCorporateGroupModal,
        rowData,
        token,
        editCorporateGroupName,
        editCorporateGroupAddress,
        editCorporateGroupNumber,
        editCorporateGroupId,
        setEditCorporateGroupName,
        setEditCorporateGroupAddress,
        setEditCorporateGroupNumber,
    } = props;

    // CONTEXT VARIABLES 
    const { corporateListReload, setCorporateListReload, } = useContext(GlobalState);
    
    // LOCAL VARIABLES
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;


    const toggle = () => { setEditCorporateGroupModal(!editCorporateGroupModal); }
 
    const confirmation = () => { 
        if(!editCorporateGroupName){
            toastr.error("Name Field Is Required" , "Please enter the healthcare name !")
        }
        
        if(!editCorporateGroupNumber ) {
            toastr.error("Phone Number Field Is Required" , "Please enter the healthcare phone number !")
        }
        
        if(editCorporateGroupName && editCorporateGroupNumber){

            updateCorporateGroupApiCall()
        }
    
    };

    const updateCorporateGroupApiCall = () => {
        
               
        axios.post(`${API_ENDPOINT}/external/update-corporate-group/`, {
            "corporate_id": editCorporateGroupId,
            "corporate_name": editCorporateGroupName,
            "corporate_number":editCorporateGroupNumber,
            "corporate_address":editCorporateGroupAddress ? editCorporateGroupAddress :null,
        }, {
            headers: {
                Authorization: token,
            }
        })
            .then(function (response) {
                // console.log(response);
                setEditCorporateGroupModal(!editCorporateGroupModal)
                setCorporateListReload(corporateListReload + 1)
                toastr.success("Successfully Updated", "Healthcare Partner details successfully updated")
            })
            .catch(function (error) {
                // console.log(error);
                toastr.error("Update Failed", "Please try again")
            });
    }

    
   
     return (
        <div>
            <Modal isOpen={editCorporateGroupModal} toggle={toggle}  size="md" >
                <ModalHeader tag="h4" cssModule={{'modal-title': 'w-100 text-center'}}  toggle={toggle}>Edit - {rowData ? rowData.name : "N/A"} </ModalHeader>
                <ModalBody className="overflow-modal disable-scrollbars" >
                    <FormGroup>
                        <Row>
                            <Col>
                            
                                 
                                <Label for="name">Name <span className="required">*</span> </Label>
                                <Input
                                id="name"
                                value={editCorporateGroupName}
                                placeholder="Enter the Name"
                                onChange={(e) => setEditCorporateGroupName(e.target.value)}
                                minLength= "1"
                                maxLength= "120"
                                />
                                <br></br>

                                <Label for="phone">Telephone <span className="required">*</span> </Label>
                                <Input
                                id="phone"
                                value={editCorporateGroupNumber}
                                placeholder="Enter the Telephone Number"
                                type="number"
                                onChange={(e) => { if (e.target.value.length <= 12) setEditCorporateGroupNumber(e.target.value)}}
                                />
                                <br></br>
        
                                <Label for="address">Address</Label>
                                <Input
                                id="address"
                                type="textarea"
                                
                                placeholder="Enter the Address"
                                value={editCorporateGroupAddress}
                                onChange={(e) => setEditCorporateGroupAddress(e.target.value)}
                                maxLength= "500"
                                />
                                <br></br>

                                

                            </Col>
                        </Row>
                    </FormGroup>
                 </ModalBody>
                <ModalFooter>
                    <Button 
                    className="yes-button button-width"  
                    onClick={confirmation}
                    >Update</Button>{' '}
                    <Button outline className="no-button button-width" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default EditCorporateGroupModal;