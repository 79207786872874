import React, { useState, useEffect, useContext } from 'react'
import { Button, Modal, ModalHeader, ModalBody, Row, Col, Label, ModalFooter, Input } from 'reactstrap'
import { AvForm, AvGroup, AvField, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation'
import moment from 'moment'
import MomentConfig from '../../common/MomentConfig'
import getSymbolFromCurrency from 'currency-symbol-map'
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CancelAppointmentRulesModal from "./CancelAppointmentRulesModal"
import { GlobalState } from "../../contextAPI/Context"
import axios from 'axios';
let rules = "" // eslint-disable-line

const CancelAppointmentModal = (props) => {
    const {
        rowData,
        modalState,
        setModalState,
        userConfirmation,
        setUserConfirmation,
        setCanceledBy,
        setReason,
        setRefundPercentage,
        setActualRefundAmount,
        setAppointmentID,
        appointmentID,
        createdByDropDown,
        setCurrency,
        setInProgressConfirmation,
        setProcessRefundModal,
        processRefundConfirmation,
        setProcessRefundConfirmation,
        setMatching,
        setRefundRulesIds,
    } = props

    // CONTEXT VARIABLES
    const {
        refundRules, setRefundRules // eslint-disable-line
    } = useContext(GlobalState);

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const API_TOKEN = process.env.REACT_APP_API_TOKEN;

    const [localCanceledBy, setLocalCanceledBy] = useState("")
    const [localReason, setLocalReason] = useState("")
    const [processRefund, setProcessRefund] = useState(false)
    const [cancelApptRuleModal, setCancelApptRuleModal] = useState(false)
    const [localRefundPercentage, setLocalRefundPercentage] = useState("")
    const [localActualRefundAmount, setLocalActualRefundAmount] = useState("")
    const [paymentmethod, setPaymentmethod] = useState("")

    const toggle = () => {
        restFields()
        setModalState(!modalState)
        setInProgressConfirmation(false)
    }

    const confirmation = () => {
        // TRIGGER FOR PROCESS REFUND CONFIRMATION MODAL
        if (!processRefund && localReason && rowData.amountPaid > 0) {
            setProcessRefundModal(true)
        }

        if (rules.length > 2) {
            var array = rules
            array.map((item) => { // eslint-disable-line
                if (item.refundAmount === localActualRefundAmount && item.refundPercent === localRefundPercentage) {
                    setMatching(true);
                    setRefundRulesIds(item.ruleId);
                }
            });
        }

        if (localReason && processRefundConfirmation === true) {
            setUserConfirmation(true)
            setAppointmentID(rowData.id)
            setCanceledBy(localCanceledBy ? localCanceledBy : "")
            setReason(localReason ? localReason : "")
            setRefundPercentage(localRefundPercentage ? localRefundPercentage : "")
            setActualRefundAmount(localActualRefundAmount ? localActualRefundAmount : "")
            setCurrency(rowData.currency)
            setInProgressConfirmation(false)
            setProcessRefundModal(false)
            setProcessRefundConfirmation("blank-state")
        }

        if (localReason && rowData.amountPaid === 0) {
            setUserConfirmation(true)
            setAppointmentID(rowData.id)
            setCanceledBy(localCanceledBy ? localCanceledBy : "")
            setReason(localReason ? localReason : "")
            setRefundPercentage(localRefundPercentage ? localRefundPercentage : "")
            setActualRefundAmount(localActualRefundAmount ? localActualRefundAmount : "")
            setCurrency(rowData.currency)
            setInProgressConfirmation(false)
        }

        if (localReason && rowData.amountPaid > 0 && processRefund) {
            setUserConfirmation(true)
            setAppointmentID(rowData.id)
            setCanceledBy(localCanceledBy ? localCanceledBy : "")
            setReason(localReason ? localReason : "")
            setRefundPercentage(localRefundPercentage ? localRefundPercentage : "")
            setActualRefundAmount(localActualRefundAmount ? localActualRefundAmount : "")
            setCurrency(rowData.currency)
            setInProgressConfirmation(false)
        }
    }

    const restFields = () => {
        setUserConfirmation(false)
        setLocalActualRefundAmount("")
        setCanceledBy("")
        setReason("")
        setProcessRefund(false)
        setRefundPercentage("")
        setActualRefundAmount("")
        setLocalCanceledBy("")
        setLocalReason("")
        setLocalRefundPercentage("")
        setAppointmentID("")
        setCurrency("")
        setInProgressConfirmation(false)
        setProcessRefundConfirmation("blank-state")
    }

    const clearRefundValues = () => {
        setLocalRefundPercentage("")
        setRefundPercentage("")
        setLocalActualRefundAmount("")
        setActualRefundAmount("")
        setProcessRefundConfirmation("blank-state")
    }

    const getPercentage = () => {
        var amount = (rowData.amountPaid / 100) * localRefundPercentage
        amount = amount.toFixed(2)
        setLocalActualRefundAmount(amount)
    }

    const limitValueTo100 = (num) => {
        let decimal = false
        let value = ""
        num % 1 !== 0 ? decimal = true : decimal = false
        if (decimal) {
            value = Math.round(num * 100) / 100;
        } else {
            value = num;
        }

        if (value <= 100) setLocalRefundPercentage(value);
        if (value > 100) setLocalRefundPercentage("100");
        if (value <= 0) setLocalRefundPercentage("1");
        if (value === "") setLocalRefundPercentage("");
    }

    const limitRefundAmount = (num) => {
        let decimal = false
        let value = ""
        num % 1 !== 0 ? decimal = true : decimal = false
        if (decimal) {
            value = Math.round(num * 100) / 100;
        } else {
            value = num;
        }
        if (value < rowData.amountPaid) setLocalActualRefundAmount(value)
        if (value >= rowData.amountPaid) setLocalActualRefundAmount(rowData.amountPaid)
        setLocalRefundPercentage("");
    }

    const populateFullRefund = () => {
        setProcessRefund(true)
        setLocalRefundPercentage("100")
        setLocalActualRefundAmount(rowData.amountPaid)
    }

    let apptID = "" // eslint-disable-line
    let apiCallStatus = "ideal" // eslint-disable-line

    const fetchRefundRules = () => {
        apiCallStatus = "loading"

        const param = apptID ? { 'apt_id': apptID } : 0;

        axios.post(API_ENDPOINT + "/user/apt-refund-details/", param, {
            headers: {
                Authorization: API_TOKEN,
            },
        })
            .then((response) => {
                setRefundRules(response.data);
                rules = response.data;
                populateRefundFees();
                apiCallStatus = "loaded";
            })
            .catch((error) => {
                if (error.response.data.status === 400) {
                    apiCallStatus = "no-config-found";
                } else {
                    apiCallStatus = "error";
                };
            });
    };

    const populateRefundFees = () => {
        if (rules.length > 0) {
            var array = rules
            array.map((item) => { // eslint-disable-line
                if (item.isTrue === 1) {
                    setProcessRefund(true);
                    setLocalRefundPercentage(item.refundPercent);
                    setLocalActualRefundAmount(item.refundAmount);
                }
            });
        }
    }



    // EFFECT TO CLEAR ALL LOCAL VARIABLE ONCE THE API CALL IS SUCCESS
    useEffect(() => { if (!userConfirmation) restFields() }, [userConfirmation]) // eslint-disable-line

    useEffect(() => { if (!processRefund) clearRefundValues() }, [processRefund]) // eslint-disable-line

    useEffect(() => {
        if ((localCanceledBy === "17")) {
            setAppointmentID(rowData.id);
            apptID = rowData.id; // eslint-disable-line 
            setPaymentmethod(rowData.method);
            fetchRefundRules();
        }
    }, [localCanceledBy]) // eslint-disable-line


    // EFFECT TO CALCULATE THE PERCENTAGE
    useEffect(() => { getPercentage() }, [localRefundPercentage]) // eslint-disable-line

    // EFFECT TO LISTEN FOR USER CONFIRMATION ON APPOINTMENT CANCELLATION WITHOUT REFUND
    useEffect(() => { if (!processRefundConfirmation) { populateFullRefund() } }, [processRefundConfirmation]) // eslint-disable-line
    useEffect(() => { if (processRefundConfirmation === true) { confirmation() } }, [processRefundConfirmation]) // eslint-disable-line

    return (
        <div>
            <Modal isOpen={modalState} toggle={toggle}>
                <ModalHeader tag="h4" cssModule={{ 'modal-title': 'w-100 text-center' }} toggle={toggle}>Appointment Cancellation</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col xs="5">
                            <table>
                                <tbody>
                                    <tr>
                                        <td>Time</td>
                                        <td>:</td>
                                        <td>{moment(rowData.schStartTime).utc(rowData.schStartTime).local().format(MomentConfig.MOMENT_FORMAT_DATEANDTIME)}</td>
                                    </tr>
                                    <tr>
                                        <td>Patient</td>
                                        <td>:</td>
                                        <td>{rowData.patient_name}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                        <Col>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>Care Provider</td>
                                        <td>:</td>
                                        <td>{rowData.physician_name}</td>
                                    </tr>
                                    <tr>
                                        <td>Speciality</td>
                                        <td>:</td>
                                        <td>{rowData.speciality}</td>
                                    </tr>
                                    <tr>
                                        <td>Amount</td>
                                        <td>:</td>
                                        <td> {`${getSymbolFromCurrency(rowData.currency)}${rowData.amountPaid ? rowData.amountPaid.toFixed(2) : "0"} ${rowData.couponCode ? `(Coupon: ${rowData.couponCode})` : ""}`}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                    <hr />
                    <AvForm>
                        <Row>
                            <Col xs={4}>
                                <AvGroup>

                                    <Label for="canceledBy">Cancelled By</Label>

                                    <AvField
                                        type="select"
                                        name="canceledBy"
                                        value={localCanceledBy}
                                        onChange={(e) => { setLocalCanceledBy(e.target.value); }}
                                    >
                                        {createdByDropDown}
                                    </AvField>

                                    {(localCanceledBy === "17" && paymentmethod !== "coupon" && rules.length > 0 && rules[0].ruleId !== null)  ? 

                                        <span className="currency-exclamation info-icon"
                                            onClick={() => {
                                                setCancelApptRuleModal(true);
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                icon={faExclamationCircle}
                                            /* className="align-middle" */
                                            />
                                        </span>

                                        : ""
                                    }

                                </AvGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <AvGroup>
                                    <Label for="reason">Reason For Cancellation <span className="required">*</span></Label>
                                    <AvField
                                        type="textarea"
                                        name="reason"
                                        id="reason"
                                        className="reason-for-cancellation"
                                        value={localReason}
                                        onChange={(e) => { setLocalReason(e.target.value) }}
                                        validate={{
                                            required: { value: true, errorMessage: 'Please enter the reason for cancellation before proceeding ' },
                                            // pattern: { value: '^[A-Za-z0-9]+$€£', errorMessage: 'Your reason must be composed only with letter and numbers' },
                                            minLength: { value: 1, errorMessage: 'Maximum characters allowed 500' },
                                            maxLength: { value: 500, errorMessage: 'Maximum characters allowed 500' }
                                        }}
                                    />
                                </AvGroup>
                            </Col>
                        </Row>
                        {rowData.amountPaid ? (
                            <div>
                                <hr />
                                <Row>
                                    <Col xs={12}>
                                        <AvCheckboxGroup name="processRefund">
                                            <AvCheckbox
                                                name="processRefund"
                                                label="Process Refund"
                                                value={processRefund}
                                                checked={processRefund ? true : false}
                                                onClick={() => { setProcessRefund(!processRefund) }}
                                            />
                                        </AvCheckboxGroup>
                                    </Col>
                                    <Col xs={5}>
                                        <Label for="refundPercentage">Refund Percentage</Label>
                                        <Input
                                            type="number"
                                            name="refundPercentage"
                                            id="refundPercentage"
                                            value={localRefundPercentage}
                                            onChange={(e) => { limitValueTo100(e.target.value) }}
                                            disabled={!processRefund ? true : false}
                                        />
                                    </Col>
                                    <Col xs={5}>
                                        <Label for="actualRefundAmount">Actual Refund Amount {`(${rowData.currency})`}</Label>
                                        <Input
                                            type="number"
                                            name="actualRefundAmount"
                                            id="actualRefundAmount"
                                            value={localActualRefundAmount && localActualRefundAmount !== "NaN" ? localActualRefundAmount : ""}
                                            onChange={(e) => { limitRefundAmount(e.target.value) }}
                                            disabled={!processRefund ? true : false}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        ) : ""}
                        <br />
                        <ModalFooter>
                            <Button
                                type="submit"
                                className="yes-button cancel-appt-button-width"
                                // disabled={localReason ? false : true}
                                // title={localReason ? "" : "Mandatory field is empty"}
                                onClick={() => { confirmation() }}
                            >Proceed</Button>
                            <Button
                                type="reset"
                                className="no-button cancel-appt-button-width"
                                onClick={() => { toggle(); restFields(); }}
                                outline
                            >Cancel</Button>
                        </ModalFooter>
                    </AvForm>
                </ModalBody>
            </Modal>
            <CancelAppointmentRulesModal
                cancelApptRuleModal={cancelApptRuleModal}
                setCancelApptRuleModal={setCancelApptRuleModal}
                appointmentID={appointmentID}
                paymentmethod={paymentmethod}
            />
        </div>
    )
}

export default CancelAppointmentModal