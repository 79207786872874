import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const StatusChangeModal = (props) => {
    const { modalState, setModalState, setUserConfirmation } = props;

    const toggle = () => setModalState(!modalState);
    const confirmation = () => { setUserConfirmation(true); setModalState(!modalState) };

    return (
        <div>
            <Button color="danger" onClick={toggle}></Button>
            <Modal isOpen={modalState} toggle={toggle}>
                <ModalHeader tag="h4" cssModule={{'modal-title': 'w-100 text-center'}}  toggle={toggle}>Change Status</ModalHeader>
                <ModalBody>
                    Are you sure you want to change the coupon status?
                </ModalBody>
                <ModalFooter>
                    <Button className="yes-button button-width" onClick={confirmation}>Yes</Button>{' '}
                    <Button outline className="no-button button-width" onClick={toggle}>No</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default StatusChangeModal;