import React, { useState, useEffect, useContext } from 'react';
import { GlobalState } from '../../contextAPI/Context';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Input, Label, FormGroup, } from 'reactstrap';
import Select from 'react-select';
import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import validator from 'validator';



const EditPharmacyModal = (props) => {
    const {
        editPharmacyModal,
        setEditPharmacyModal,
        rowData,
        token,
        editPharmacyName,
        editPharmacyCode,
        editPharmacyCity,
        editPharmacyAddress,
        editPharmacyEmail,
        editPharmacyFax,
        editPharmacyEfax,
        editPharmacyId,
        editPharmacyPhone,
        editPharmacyState,
        editPharmacyZipcode,
        editPharmacyCountry,
        setEditPharmacyCode,
        setEditPharmacyName,
        setEditPharmacyCity,
        setEditPharmacyAddress,
        setEditPharmacyEmail,
        setEditPharmacyFax,
        setEditPharmacyEfax,
        setEditPharmacyPhone,
        setEditPharmacyState,
        setEditPharmacyCountry,
        setEditPharmacyZipcode,
        countryDropDown,
        countryIsd,
        countryList
    } = props;

    // CONTEXT VARIABLES 
    const { pharmacyListReload, setPharmacyListReload, } = useContext(GlobalState);

    // LOCAL VARIABLES
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const [countryObject, setCountryObject] = useState("");
    const [countryCode, setCountryCode] = useState([])
    let userID = localStorage.getItem("userId");

    const toggle = () => { setEditPharmacyModal(!editPharmacyModal); }

    const confirmation = () => {
        if (!editPharmacyName) {
            toastr.error("Name Field Is Required", "Please enter the pharmacy name!")
        }

        if (!editPharmacyEmail) {
            toastr.error("Email Field Is Required", "Please enter the pharmacy email!")
        }
        if (!editPharmacyCountry) {
            toastr.error("Country Field Is Required", "Please select the country!")
        }
        if (editPharmacyName && editPharmacyEmail && editPharmacyCountry) {
            let valid = validateEmail()
            if (valid) updatePharmacyApiCall()
        }

    };

    

    const updatePharmacyApiCall = () => {

        var tempDate = new Date();
        var date = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate() + ' ' + tempDate.getHours() + ':' + tempDate.getMinutes() + ':' + tempDate.getSeconds();
        axios.put(`${API_ENDPOINT}/external/pharmacy-details/${editPharmacyId}/`, {
            "code": editPharmacyCode ? editPharmacyCode : null,
            "name": editPharmacyName,
            "city": editPharmacyCity,
            "address": editPharmacyAddress ? editPharmacyAddress : null,
            "email": editPharmacyEmail,
            "fax": editPharmacyFax ? editPharmacyFax : null,
            "efax": editPharmacyEfax ? editPharmacyEfax : null,
            "contactNumber": editPharmacyPhone ? editPharmacyPhone : null,
            "updatedDate": date,
            "state": editPharmacyState,
            "countryID": editPharmacyCountry.value,
            "zipcode": editPharmacyZipcode,
            "updatedBy": userID
        }, {
            headers: {
                Authorization: token,
            }
        })
            .then(function (response) {
                // console.log(response);
                setEditPharmacyModal(!editPharmacyModal)
                setPharmacyListReload(pharmacyListReload + 1)
                toastr.success("Successfully updated", "Pharmacy details successfully updated")
            })
            .catch(function (error) {
                // console.log(error);
                toastr.error("Update Failed", "Please try again")
            });
    }

    const prePopulateCountry = () => {
        countryDropDown.filter((item) => {// eslint-disable-line
            if (item.value === editPharmacyCountry) {
                setCountryObject(item)
            }
        })
        countryIsd.filter((item1)=>{// eslint-disable-line
            if(item1.value === editPharmacyCountry ){
                setCountryCode(item1)
            }
            
        })
        
    }

    const countryIsdCode = (id) => {
       
        var array = countryList
        const option = array.map((item) => {// eslint-disable-line
            if (id === item.id) {
                return {
                    value: item.country_isd_code,
                    label: item.country_isd_code
                }
            }
        });
        setCountryCode(option)
    }

    const validateEmail = () => {
        let valid = validator.isEmail(editPharmacyEmail)
        if (!valid) toastr.error("Email Not Valid", "Please provide a valid email")
        return valid
    }

    useEffect(() => { if (countryDropDown && editPharmacyCountry ) prePopulateCountry(); }, [countryDropDown, editPharmacyCountry ])// eslint-disable-line
    

    return (
        <div>
            <Modal isOpen={editPharmacyModal} toggle={toggle} size="lg" >
                <ModalHeader tag="h4" cssModule={{'modal-title': 'w-100 text-center'}}  toggle={toggle}>Edit - {rowData ? rowData.name : "N/A"} </ModalHeader>
                <ModalBody className="overflow-modal disable-scrollbars" >
                    <FormGroup>
                        <Row>
                            <Col>
                                <Label for="name">Name <span className="required">*</span> </Label>
                                <Input
                                    id="name"
                                    value={editPharmacyName}
                                    placeholder="Enter the Name"
                                    onChange={(e) => setEditPharmacyName(e.target.value)}
                                    minLength="1"
                                    maxLength="120"
                                />
                                <br></br>

                                <Label>Country <span className="required">*</span> </Label>
                                <Select
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    isClearable={true}
                                    onChange={(e) => { setEditPharmacyCountry(e); 
                                        setCountryObject(""); 
                                        if(e && e.value) {
                                            countryIsdCode(e.value); 
                                        } else {
                                            setCountryCode("")
                                        }
                                    }}
                                    isSearchable
                                    placeholder="Select the Country"
                                    value={countryObject ? countryObject : editPharmacyCountry}
                                    options={countryDropDown}
                                />
                                <br></br>

                                <Label for="city" >City/Town </Label>
                                <Input
                                    id="city"
                                    value={editPharmacyCity}
                                    placeholder="Enter the City"
                                    onChange={(e) => setEditPharmacyCity(e.target.value)}
                                    maxLength="24"
                                />
                                <br></br>
                                <Row>
                                    <Col sm="7">
                                        <Label for="state">State/Province/County </Label>
                                        <Input
                                            id="state"
                                            value={editPharmacyState}
                                            placeholder="Enter the State"
                                            onChange={(e) => setEditPharmacyState(e.target.value)}
                                            maxLength="24"
                                        />
                                        <br></br>
                                    </Col>
                                    <Col sm="5">
                                        <Label for="zipcode">Post code </Label>
                                        <Input
                                            id="zipcode"
                                            value={editPharmacyZipcode}
                                            placeholder="Enter the Post code"
                                            onChange={(e) => {if (e.target.value.length <= 10) setEditPharmacyZipcode(e.target.value)}}
                                            
                                        />
                                        <br></br>
                                    </Col>
                                </Row>

                                <Label for="fax">Fax </Label>
                                <Input
                                    id="fax"
                                    value={editPharmacyFax}
                                    placeholder="Enter the Fax"
                                    onChange={(e) => setEditPharmacyFax(e.target.value)}
                                    maxLength="25"
                                />
                                <br></br>

                            </Col>
                            <Col>
                                <Label for="email">Email <span className="required">*</span> </Label>
                                <Input
                                    id="Email"
                                    type="email"
                                    value={editPharmacyEmail}
                                    placeholder="Enter the Email"
                                    onChange={(e) => setEditPharmacyEmail(e.target.value)}
                                    maxLength="254"
                                />
                                <br></br>

                                <Row>
                                    <Col sm="3">
                                        <Label>Country code</Label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            value={countryCode}
                                            isDisabled
                                            placeholder=""
                                        />
                                    </Col>
                                    <Col sm="5">
                                        <Label for="phone">Telephone </Label>
                                        <Input
                                            id="phone"
                                            value={editPharmacyPhone}
                                            placeholder="Telephone Number"
                                            type="number"
                                            onChange={(e) => { if (e.target.value.length <= 12) setEditPharmacyPhone(e.target.value) }}
                                        />
                                        <br></br>
                                    </Col>
                                    <Col>
                                        <Label for="code">Code </Label>
                                        <Input
                                            id="code"
                                            value={editPharmacyCode}
                                            placeholder="Enter the Code"
                                            onChange={(e) => setEditPharmacyCode(e.target.value)}
                                            maxLength="15"
                                        />
                                        <br></br>
                                    </Col>
                                </Row>

                                <Label for="address">Address </Label>
                                <Input
                                    id="address"
                                    type="textarea"
                                    className="pharmacy-address-textarea"
                                    placeholder="Enter the Address"
                                    value={editPharmacyAddress}
                                    onChange={(e) => setEditPharmacyAddress(e.target.value)}
                                    maxLength="500"
                                />
                                <br></br>

                                <Label for="efax">Efax </Label>
                                <Input
                                    id="efax"
                                    value={editPharmacyEfax}
                                    placeholder="Enter the Efax"
                                    onChange={(e) => setEditPharmacyEfax(e.target.value)}
                                    maxLength="150"
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button
                        className="yes-button button-width"
                        onClick={confirmation}
                    >Update</Button>{' '}
                    <Button outline className="no-button button-width" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default EditPharmacyModal;