import React, { useContext } from 'react';
import { GlobalState } from '../../contextAPI/Context';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col,Input,Label, FormGroup, } from 'reactstrap';
import axios from 'axios';
import { toastr } from 'react-redux-toastr';


const EditMedicationModal = (props) => {
    const {
        editMedicationModal,
        setEditMedicationModal,
        rowData,
        token,
        editDrugName, setEditDrugName,
        editDrugCode, setEditDrugCode,
        editGenericNameCode, setEditGenericNameCode,
        editGenericName, setEditGenericName,
        editMedicationId, setEditController,editController
    } = props;

    // CONTEXT VARIABLES 
    const { medicationListReload, setMedicationListReload, } = useContext(GlobalState);
    
    // LOCAL VARIABLES
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

    const toggle = () => { setEditMedicationModal(!editMedicationModal); }
 
    const confirmation = () => { 

        if(!editDrugName){
            toastr.error("Drug Name Is Required" , "Please enter the drug name!")
        }
        
       /*  if(!editDrugCode ) {
            toastr.error("Drug Code is required" , "Please enter the drug code !")
        } */
        if (!editController){
            toastr.error("Controlled Status Is Mandatory", "Please select the controlled status!")
        }
        if(editDrugName && editController){

            updateMedicationApiCall()
        }
    
    };

    const updateMedicationApiCall = () => {
        
        var tempDate = new Date();
        var date = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate() + ' ' + tempDate.getHours() + ':' + tempDate.getMinutes() + ':' + tempDate.getSeconds();
        axios.post(`${API_ENDPOINT}/external/update-medication/`, {
            "medication_id": editMedicationId,
            "drug_name": editDrugName,
            "drug_code":editDrugCode,
            "generic_name_code":editGenericNameCode ? editGenericNameCode :null,
            "generic_name":editGenericName ? editGenericName :null,
            "update_date": date,
            "is_controlled":editController
        }, {
            headers: {
                Authorization: token,
            }
        })
            .then(function (response) {
                setEditMedicationModal(!editMedicationModal)
                setMedicationListReload(medicationListReload + 1)
                toastr.success("Successfully Updated", "Medication details successfully updated")
            })
            .catch(function (error) {
                // console.log(error);
                toastr.error("Update Failed", "Please try again")
            });
    }

    
   
     return (
        <div>
            <Modal isOpen={editMedicationModal} toggle={toggle}  size="md" >
                <ModalHeader tag="h4" cssModule={{'modal-title': 'w-100 text-center'}}  toggle={toggle}>Edit - {rowData.drugName ? rowData.drugName : "N/A"} </ModalHeader>
                <ModalBody className="overflow-modal disable-scrollbars" >
                    <FormGroup>
                        <Row>
                            <Col>
                            
                                 
                                <Label for="name">Drug Name <span className="required">*</span> </Label>
                                <Input
                                value={editDrugName}
                                placeholder="Enter the Drug Name"
                                onChange={(e) => setEditDrugName(e.target.value)}
                                minLength= "1"
                                maxLength= "120"
                                />
                                <br></br>

                                <Label for="phone">Drug Code  </Label>
                                <Input
                                value={editDrugCode}
                                placeholder="Enter the Drug Code"
                                maxLength= "25"
                                onChange={(e) => { setEditDrugCode(e.target.value)}}
                                />
                                <br></br>
        
                                <Label for="address">Generic Name</Label>
                                <Input
                                placeholder="Enter the Generic Name"
                                value={editGenericName}
                                onChange={(e) => setEditGenericName(e.target.value)}
                                maxLength= "120"
                                />
                                <br></br>

                                <Label for="address">Generic Code</Label>
                                <Input
                                placeholder="Enter the Generic Code"
                                value={editGenericNameCode}
                                onChange={(e) => setEditGenericNameCode(e.target.value)}
                                maxLength= "25"
                                />
                                <br></br>


                                <Label for="controlled">Controlled Status <span className="required">*</span></Label><br></br>
                                <Input
                                    checked={editController === "Y" ? true : false}
                                    value="Y"
                                    className="radio-cntrl"
                                    type="radio"
                                    name="radio1"
                                    onChange={(e) => setEditController(e.target.value) }
                                />
                                <Label for="Yes" className="radio-cntrl-label" >Controlled</Label>
                                <Input
                                    className="radio-un-ctrl"
                                    value="N"
                                    checked={editController === "N" ? true : false}
                                    type="radio"
                                    name="radio1"
                                    onChange={(e) => setEditController(e.target.value) }
                                />
                                <Label for="uncontrolled" className="radio-un-ctrl-label">Uncontrolled</Label>

                            </Col>
                        </Row>
                    </FormGroup>
                 </ModalBody>
                <ModalFooter>
                    <Button 
                    className="yes-button button-width"  
                    onClick={confirmation}
                    >Update</Button>{' '}
                    <Button outline className="no-button button-width" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default EditMedicationModal;