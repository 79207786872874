import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const MedicationFlagControllerModal = (props) => {
  const {
    flagModal,
    setFlagModal,
    setFlagConfirmation } = props;

  const toggle = () => {
    
    setFlagModal(!flagModal);
  };

  const confirmation = () => {
    setFlagConfirmation(true);
    setFlagModal(!flagModal);
  };

  return (
    <div>
      <Button color="danger" onClick={toggle}></Button>
      <Modal isOpen={flagModal} toggle={toggle}>
        <ModalHeader tag="h4" cssModule={{'modal-title': 'w-100 text-center'}}  toggle={toggle}>Change Status</ModalHeader>
        <ModalBody>
          Are you sure you want to change the Status
        </ModalBody>
        <ModalFooter>
          <Button className="yes-button button-width" onClick={confirmation}>
            Yes
          </Button>{" "}
          <Button outline className="no-button button-width" onClick={toggle}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default MedicationFlagControllerModal;
