import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Breadcrumb, BreadcrumbItem, Container, Spinner, Button } from "reactstrap";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import { Hux } from "../../hoc/Hux";
import ExportCSVTable from "./MedicationDataTable";
import { toastr } from 'react-redux-toastr'
import { GlobalState } from '../../contextAPI/Context'
import MedicationStatusChangeModal from '../../newComponents/utilities/MedicationStatusChangeModal';
import EditMedicationModal from '../../newComponents/utilities/EditMedicationModal';
import NewMedicationModal from '../../newComponents/utilities/NewMedicationModal';
import MedicationFlagControllerModal from '../../newComponents/utilities/MedicationFlagControllerModal';
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Tables = (props) => {
  // CONTEXT VARIABLES 
  const {
    setToken,
    setSpinner,
    setBlankStateMessage,
    drugName,
    drugCode, 
    genericName,
    genericNameCode,
    drugStatus, 
    medicationListReload, setMedicationListReload,
  } = useContext(GlobalState);

  // LOCAL VARIABLES 
  const [medicationList, setMedicationList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalState, setModalState] = useState(false);
  const [medicationID, setMedicationID] = useState("");
  const [status, setStatus] = useState("");
  const [rowData, setRowData] = useState("");
  const [editMedicationModal, setEditMedicationModal] = useState(false);
  const [newMedicationModal, setNewMedicationModal] = useState(false);
  const [userConfirmation, setUserConfirmation] = useState(false);

  const [editDrugName, setEditDrugName] = useState("");
  const [editDrugCode, setEditDrugCode] = useState("");
  const [editGenericNameCode, setEditGenericNameCode] = useState("");
  const [editGenericName, setEditGenericName] = useState("");
  const [editMedicationId, setEditMedicationId] = useState("");
  const [editController, setEditController] = useState("")

  const [flagModal, setFlagModal] = useState(false);
  const [flagConfirmation, setFlagConfirmation] = useState(false);
  const [flagStatus, setFlagStatus] = useState("");

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

  const fetchMedication = () => {
    setToken(props.authtoken);
    setLoading(true);
    setSpinner(true);;

    const params =
    {
      "drug_name": drugName ? drugName : "",
      "drug_code": drugCode ? drugCode : "",
      "generic_name_code": genericNameCode ? genericNameCode : "",
      "generic_name": genericName ? genericName : "",
      "drug_status": drugStatus ? drugStatus : "",
      "is_pagination": 0,
      "offset": "force"
    }
    
    Axios.post(API_ENDPOINT + "/external/medication-details/", params,{
      headers: {
        Authorization: props.authtoken,
      },
    }).then((res) => {
      setMedicationList(res.data);
      setBlankStateMessage(res.data.length > 0 ? false : true);
      setLoading(false);
      setSpinner(false);
    });

  };

  const changeMedicationStatus = () => {
    let newStatus = "";
    setLoading(true);
    if (status === 0) {
      newStatus = 1
    } else {
      newStatus = 0
    }

    const updatedStatus = {
      medication_id:medicationID,
      status: newStatus,
    };

    Axios.post(
      API_ENDPOINT + `/external/medication-status-update/`,
      updatedStatus,
      {
        headers: {
          Authorization: props.authtoken,
        },
      }
    )
      .then((res) => {
          setUserConfirmation(false);
          setMedicationListReload(medicationListReload+1);
          setLoading(false);
          toastr.success('Status Updated', 'Medication status updated successfully');
        
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }; 
 
  const statusFormatter = (cell, row) => {
    let status = "";
    let badgeclass = " ";
  
    if (cell === 1) {
      status = "Active";
      badgeclass = "badge badge-success statustoggle status-button-width";
    } else {
      status = "Deleted";
      badgeclass = "badge badge-danger statustoggle status-button-width";
    }

    return (
      <span
        className={badgeclass}
        onClick={() => {
          setMedicationID(row.id);
          setStatus(cell);
          setModalState(true);
          
        }}
      >
        {status}
      </span>
    );
  };

  const changeMedicationStatusFlag = () => {
    let newFlag = "";
    setLoading(true);
    if (flagStatus === "Y") {
      newFlag = "N"
    } else {
      newFlag = "Y"
    }

    const updatedStatus = {
      medication_id:medicationID,
      flag: newFlag,
    };

    Axios.post(
      API_ENDPOINT + `/external/medication-flag-update/`,
      updatedStatus,
      {
        headers: {
          Authorization: props.authtoken,
        },
      }
    )
      .then((res) => {
          setFlagConfirmation(false);
          setMedicationListReload(medicationListReload+1);
          setLoading(false);
          toastr.success('Status Updated', 'Medication status updated successfully');
        
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }; 
 

  const ControlFormatter = (cell, row) => {
    let flag = "";
    let badgeclass = " ";

    if(cell === "Y"){
      flag = "Yes";
      badgeclass = "badge badge-success statustoggle status-button-width";
    } else {
      flag="No";
      badgeclass = "badge badge-danger statustoggle status-button-width";
    }

    return (
      <span
        className={badgeclass}
        onClick={() => {
          setMedicationID(row.id);
          setFlagStatus(cell);
          setFlagModal(true);
        }}
      >
        {flag}
      </span>
    );

  }

  

  const basicValidation = (cell) => {
    return cell ? cell : "N/A"
  }

  const statusFormatterCSV = (cell) => {
    return cell ? "Active" : "Inactive"
  }

  const flagFormatterCSV = (cell) => {
  if(cell === "Y"){
    return "Yes"
  }
  if(cell === "N"){
    return "No"
  }
  }


  const editMedication=(id,drugName,code,genericName,genericNameCode,isControlled)=> {
    
    setEditDrugCode(code);
    setEditDrugName(drugName);
    setEditGenericName(genericName);
    setEditGenericNameCode(genericNameCode);
    setEditMedicationId(id);
    setEditController(isControlled)


  }

  const actionFormatter = (cell, row) => {
    return (
      <>
        <Button
          className="money-button"
          outline
          onClick={() => {
            setRowData(row)
            setEditMedicationModal(true)
            editMedication(row.id,row.drugName,row.code,row.genericName,row.genericNameCode,row.isControlled)
          }}
          color="primary"
          title="Edit Medication"
        >
          <FontAwesomeIcon
            icon={faEdit}
            fixedWidth
            className="align-middle"
            title="Edit Medication"
          />
        </Button>
      </>
    )
  }

  const tableColumns = [
    {
      dataField: "drugName",
      text: "Drug Name",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "code",
      text: "Drug Code",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "genericName",
      text: "Generic Name",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "genericNameCode",
      text: "Generic Code",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      formatter: statusFormatter,
      csvFormatter: statusFormatterCSV,
    },
    {
      dataField: "name",
      text: "Created By",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },

    {
      dataField: "isControlled",
      text: "Controlled",
      sort: true,
      formatter: ControlFormatter,
      csvFormatter: flagFormatterCSV,
      hidden:true
    },
    
    {
      text: "Actions",
      formatter: actionFormatter,
      csvExport: false
    },
  ];

  let loader = null;

  if (loading) {
    loader = <Spinner />;
  }

  // DEFAULT API CALL
  // eslint-disable-next-line
  useEffect(() => { fetchMedication() }, [ setBlankStateMessage,
    drugName,
    drugCode,
    genericName,
    genericNameCode,
    drugStatus,
    medicationListReload,]);

  // EFFECT TO HANDLE MEDICATION STATUS CHANGE 
  useEffect(() => { if (userConfirmation)  changeMedicationStatus() }, [userConfirmation])// eslint-disable-line
  useEffect(() => { if (flagConfirmation)  changeMedicationStatusFlag() }, [flagConfirmation])// eslint-disable-line


  return (
    <Hux>
      {loader}
      <Container fluid>
        <Header>
          <HeaderTitle>Medication
          <button className="btn btn-equipo-blue mt-2 pull-right"
              onClick={() => {
                setNewMedicationModal(true)
              }}
            >
              Add New Medication
              </button>
          </HeaderTitle>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/dashboard">Dashboard</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Medication</BreadcrumbItem>
          </Breadcrumb>
        </Header>
        <MedicationStatusChangeModal modalState={modalState} setModalState={setModalState} setUserConfirmation={setUserConfirmation}/>
        <MedicationFlagControllerModal setFlagModal={setFlagModal} flagModal={flagModal} setFlagConfirmation={setFlagConfirmation} />
        <NewMedicationModal
        token={props.authtoken}
        setNewMedicationModal={setNewMedicationModal}
        newMedicationModal={newMedicationModal}
        />
        <EditMedicationModal
          editMedicationModal={editMedicationModal}
          setEditMedicationModal={setEditMedicationModal}
          editDrugName={editDrugName}
          setEditDrugName={setEditDrugName}
          editDrugCode={editDrugCode}
          setEditDrugCode={setEditDrugCode}
          editGenericNameCode={editGenericNameCode}
          setEditGenericNameCode={setEditGenericNameCode}
          editGenericName={editGenericName}
          setEditGenericName={setEditGenericName}
          setEditController={setEditController}
          editMedicationId={editMedicationId}
          editController={editController}
          setEditMedicationId={setEditMedicationId}
          rowData={rowData}
          token={props.authtoken}
        />
        <ExportCSVTable data={medicationList} columns={tableColumns} />
      </Container>
    </Hux>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
    authtoken: state.auth.token,
  };
};

export default connect(mapStateToProps, null)(Tables);