import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const DownloadPrescriptionModal = (props) => {
    const { downloadPrescriptionModal, setDownloadPrescriptionModal, setDownloadConfirmation } = props;

    const toggle = () => setDownloadPrescriptionModal(!downloadPrescriptionModal);
    const confirmation = () => { setDownloadConfirmation(true); setDownloadPrescriptionModal(!downloadPrescriptionModal) };

    return (
        <div>
            <Modal isOpen={downloadPrescriptionModal} toggle={toggle} size="sm">
                <ModalHeader tag="h4" cssModule={{'modal-title': 'w-100 text-center'}}  toggle={toggle}>Download Prescription</ModalHeader>
                <ModalBody>
                    Are you sure you want to download prescription?
                </ModalBody>
                <ModalFooter>
                    <Button className="yes-button button-width" onClick={confirmation}>Yes</Button>{' '}
                    <Button outline className="no-button button-width" onClick={toggle}>No</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default DownloadPrescriptionModal;