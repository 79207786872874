import React, { useState, useEffect, useContext } from 'react';
import { GlobalState } from '../../contextAPI/Context';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Input, Label, FormGroup, } from 'reactstrap';
import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import Select from "react-select";
import ToggleButton from 'react-toggle-button';


const NewRuleConfigurationModal = (props) => {
    const { newRuleConfigrationModal, setNewRuleConfigrationModal, token } = props;

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    let userID = localStorage.getItem("userId");


    // CONTEXT VARIABLES 
    const {
        ruleConfigListReload,
        setRuleConfigListReload,
    } = useContext(GlobalState);


    // LOCAL VARIABLES

    const [newUserType, setNewUserType] = useState("");
    const [newApptType, setNewApptType] = useState("");
    const [newcommisionFeePercent, setNewcommisionFeePercent] = useState("");
    const [newProcessingFeePercent, setNewProcessingFeePercent] = useState("");
    const [newIsVat, setNewIsVat] = useState("");
    const [newStatus, setStatus] = useState("");
    const [newVatPercentage, setNewVatPercentage] = useState("");
    const [addButtonStatus, setAddButtonStatus] = useState(true);

    const [vatStatus, setVatStatus] = useState(false); 
    const [vatPercentageStatus, setVatPercentageStatus] = useState(true);

    const [usersRolesList, setUsersRolesList] = useState([]);
    const [usersRoles, setUsersRoles] = useState("");
    const [apptTypeList, setApptTypeList] = useState([]);
    const [apptType, setApptType] = useState("");


   

    const toggle = () => {
        
        setAddButtonStatus(true);
        setUsersRoles("")
        setApptType("")
        setNewUserType("")
        setNewApptType("")
        setNewcommisionFeePercent("")
        setNewProcessingFeePercent("")
        setNewIsVat("")
        setVatStatus(false);
        setStatus("")
        setNewVatPercentage("")
        setNewRuleConfigrationModal(!newRuleConfigrationModal);

    }

    const FetchAllUsersRoles = () => {
        
        axios.get(API_ENDPOINT + '/external/list-user-roles/appusers', {
          headers: {
            Authorization: props.token,
          },
        })
          .then(res => {
            if (res.status === 200) {
                setUsersRolesList(res.data);
            }
          });
    }

    const FetchApptTypes = () => {
        
        axios.get(API_ENDPOINT + '/external/list-appointment-types/', {
          headers: {
            Authorization: props.token,
          },
        })
          .then(res => {
            if (res.status === 200) {
                setApptTypeList(res.data);
            }
          });
    }

    const UsersRoles = usersRolesList.map((keys) => {
        return { value: keys.id, label: keys.designation }
        
    });
 
    const ApptTypes = apptTypeList.map((key) => {
        return { value: key.id, label: key.name }
    });
 


    const CreatePaymentConfigurationApiCall = () => {
        

        axios.post(`${API_ENDPOINT}/external/create-payment-rule/`, {

            "user_type": newUserType ? Number(newUserType) : "",
            "apt_type":  newApptType ? Number(newApptType) : 4,
            "commision_fee_percent": newcommisionFeePercent ? Number(newcommisionFeePercent) : 0,
            "processing_fee_percent": newProcessingFeePercent ? Number(newProcessingFeePercent) : 0,
            "is_vat": newIsVat ? Number(newIsVat) : 0,
            "vat_percentage": newVatPercentage  ? Number(newVatPercentage) : 0,
            "status": newStatus ? newStatus : 1,
            "created_by": Number(userID),
            "updated_by": Number(userID)

        }, {
            headers: {
                Authorization: token,
            }
        })
            .then(response => {
                if(response.status === 201) {
                    setRuleConfigListReload(ruleConfigListReload + 1);
                    toastr.success("Successfully Added", "Payment configuration successfully added");
                    toggle();
                }
            })
            .catch(function (error) {
                toastr.error(error.response.data.status, error.response.data.message)
            });
    }


    const disableAddButton = () => {
        if (newApptType && newcommisionFeePercent && newProcessingFeePercent) {
            setAddButtonStatus(false)
        }
        else {
            setAddButtonStatus(true)
        }
    }

    const confirmation = () => {

        if (!newApptType) {
            toastr.error("Appt Type is required", "Please select the user type !")
        }
        if (!newcommisionFeePercent) {
            toastr.error("Commission Fee is required", "Please enter the commission fee !")
        }
        if (!newProcessingFeePercent) {
            toastr.error("Processing Fee Is Required", "Please enter the processing fee!")
        }

        const callAPIWithVat = () => {
            if (newApptType && newcommisionFeePercent && newProcessingFeePercent && newVatPercentage) {
                CreatePaymentConfigurationApiCall()
            }
        }

        const callAPIWithOutVat = () => {
            if (newApptType && newcommisionFeePercent && newProcessingFeePercent) {
                CreatePaymentConfigurationApiCall()
            }
        }

        const validateVat = () => {
            if (vatStatus && !newVatPercentage) {
                toastr.error("VAT Is Required", "Please enter the VAT!")
            } else {
                callAPIWithVat()
            }
        }

        vatStatus ? validateVat() : callAPIWithOutVat()

    };

    const ProcessingFeeHandler = (num) => {
        let decimal = false
        let value = ""
        num % 1 !== 0 ? decimal = true : decimal = false
        if (decimal) {
          value = Math.round(num * 100) / 100;
        } else {
          value = num;
        }
    
        if (value <= 100) setNewProcessingFeePercent(value);
        if (value > 100) setNewProcessingFeePercent("100");
        if (value <= 0) setNewProcessingFeePercent("1");
        if (value === "") setNewProcessingFeePercent("");
    };

    const CommissionFeeHandler = (num) => {
        let decimal = false
        let value = ""
        num % 1 !== 0 ? decimal = true : decimal = false
        if (decimal) {
          value = Math.round(num * 100) / 100;
        } else {
          value = num;
        }
    
        if (value <= 100) setNewcommisionFeePercent(value);
        if (value > 100) setNewcommisionFeePercent("100");
        if (value <= 0) setNewcommisionFeePercent("1");
        if (value === "") setNewcommisionFeePercent("");
    };

    const VatFeeHandler = (num) => {
        let decimal = false
        let value = ""
        num % 1 !== 0 ? decimal = true : decimal = false
        if (decimal) {
          value = Math.round(num * 100) / 100;
        } else {
          value = num;
        }
    
        if (value <= 100) setNewVatPercentage(value);
        if (value > 100) setNewVatPercentage("100");
        if (value <= 0) setNewVatPercentage("1");
        if (value === "") setNewVatPercentage("");
    };


    useEffect(() => { disableAddButton() }, [ newApptType,newcommisionFeePercent, newProcessingFeePercent]) // eslint-disable-line
    useEffect(() => { FetchAllUsersRoles(); FetchApptTypes(); }, [])  // eslint-disable-line

    return (
        <div>
            <Modal isOpen={newRuleConfigrationModal} toggle={toggle} size="md" >
                <ModalHeader tag="h4" cssModule={{'modal-title': 'w-100 text-center'}}  toggle={toggle}>Add New Payment Configuration</ModalHeader>
                <ModalBody className="overflow-modal disable-scrollbars" >
                    <FormGroup>
                        <Row>
                            <Col xs="6" >
                                <Label>
                                    User Type
                                </Label>
                                <Select
                                placeholder="Global"
                                options={UsersRoles}
                                value={usersRoles}
                                isSearchable
                                isClearable
                                /* isLoading={TypeList.length > 0 ? false : true} */
                                onChange={(e) => { 
                                    setUsersRoles(e);
                                    if (e && e.value) {
                                        setNewUserType(e.value);
                                    } else {
                                        setUsersRoles("");
                                        setNewUserType("");
                                    }
                                 }}
                                />
                                <br></br>
                                <Label>
                                    Commission Fee (%) <span className="required">*</span>
                                </Label>
                                <Input
                                required
                                onChange={e => { if (e.target.value.length <= 5) CommissionFeeHandler(e.target.value)}}
                                type="number"
                                name="commission_fee"
                                placeholder="Commission fee"
                                value={newcommisionFeePercent}
                                maxLength={3}
                                />
                                <br></br>
                                
                                <Label>Is VAT Applicable</Label> <br></br>
                                <div className="toggle-button">
                                <ToggleButton
                                    inactiveLabel=""
                                    activeLabel=""
                                    onToggle={() => {  setVatStatus(!vatStatus);  setNewIsVat(!vatStatus); setVatPercentageStatus(!vatPercentageStatus); }} 
                                    value={vatStatus}
                                />
                                </div>
                            </Col>
                            <Col xs="6">
                                <Label>
                                    Appointment Type <span className="required">*</span>
                                </Label>
                                <Select
                                placeholder="Select"
                                options={ApptTypes}
                                value={apptType}
                                isSearchable
                                isClearable
                                onChange={(e) => {
                                    setApptType(e);
                                    if (e && e.value) {
                                        setNewApptType(e.value);
                                    } else {
                                        setApptType("");
                                        setNewApptType("");
                                    }
                                }}
                                
                                />
                                <br></br>
                                <Label>
                                    Processing Fee (%) <span className="required">*</span>
                                </Label> 
                                <Input
                                required
                                onChange={e => { if (e.target.value.length <= 5) ProcessingFeeHandler(e.target.value)}}
                                type="number"
                                name="Processing_fee"
                                placeholder="Processing fee"
                                value={newProcessingFeePercent}
                                maxLength={3}
                                />
                                <br></br>
                                { vatStatus ? 
                                    <>
                                        <Label>VAT (%) <span className="required">*</span> </Label>
                                        <Input
                                            required
                                            onChange={e => { if (e.target.value.length <= 5) VatFeeHandler(e.target.value)}}
                                            type="number"
                                            name="vat_fee"
                                            placeholder="VAT fee"
                                            value={newVatPercentage}
                                            maxLength={120}
                                        />
                                    </> : ""
                                }
                                
                            </Col>
                        </Row>
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button
                        className="yes-button button-width"
                     onClick={confirmation}
                     disabled={addButtonStatus}
                     title={addButtonStatus ? "Fill the required fields" : ""}
                    >Add</Button>{' '}
                    <Button outline className="no-button button-width" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default NewRuleConfigurationModal;