import React, { useEffect, useContext, useState } from "react";
import { connect } from "react-redux";
import './Kyc.css'
import axios from 'axios';
import { useLocation } from "react-router-dom";
import { toastr } from 'react-redux-toastr';
import { GlobalState } from "../../../contextAPI/Context";
import {
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    Row,
    Spinner
} from "reactstrap";

import Header from "../../../components/Header";
import HeaderTitle from "../../../components/HeaderTitle";
import Navigation from "../SubNav";
import ProofOfIdentityTable from './Tables/ProofOfIdentityTable';
import ProfessionalDocumentationTable from './Tables/ProfessionalDocumentationTable';
import OtherDocuments from './Tables/OtherDocumentsTable';
import BlankStateCard from './Tables/BlankStateCard';
import SelfieID from './SelfieComponent/SelfieID';
import CountryOfRegistration from './CountryOfRegistration/CountryOfRegistration';
import VerifyPhysicianModal from './Modals/VerifyPhysicianModal';
import DocUploadModal from './Modals/DocUploadModal';
import ProofOfIdUploadModal from './Modals/ProofOfIdUploadModal';

const KycInfo = () => {
    // GET TOKEN
    let token = localStorage.getItem("token");
    // OBTAINING BASE URL BASED ON ENVIRONMENT
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

    // CONTEXT VARIABLES
    const { reloadPhysicianIdentityData, setPhysicianId, setPhysicianName, userType, setUserType } = useContext(GlobalState);

    // LOCAL VARIABLES
    const [spinner, setSpinner] = useState(false)
    const [userId, setUserId] = useState("")
    const [proofOfIdentity, setProofOfIdentity] = useState("");
    const [selfieIdentity, setSelfieIdentity] = useState("");
    const [professionalDocs, setProfessionalDocs] = useState("");
    const [otherDocs, setOtherDocs] = useState("");
    const [allRegPhyDetails, setAllRegPhyDetails] = useState("");
    const [allDocumentsApproved, setAllDocumentsApproved] = useState(false);
    const [tooltip, setTooltip] = useState("");
    const [apiCallFinished, setApiCallFinished] = useState(false);
    const [unverifyPhysician, setUnverifyPhysician] = useState(false);
    const [userStatus, setUserStatus] = useState("");

    // MODAL VARIABLES 
    const [approveDocModal, setApproveDocModal] = useState(false);
    const [userConfirmation, setUserConfirmation] = useState(false);
    // DOCUMENT UPLOAD MODAL VARIABLES 
    const [uploadDocModal, setUploadDocModal] = useState(false);
    const [uploadConfirmation, setUploadConfirmation] = useState(false);// eslint-disable-line
    const [addMissingDocument, setAddMissingDocument] = useState(false);
    const [addMissingDocumentCountry, setAddMissingDocumentCountry] = useState("");
    const [physicianRegistrationId, setPhysicianRegistrationId] = useState(false);
    // PROOF OF ID UPLOAD MODAL VARIABLES
    const [proofOfIdModal, setProofOfIdModal] = useState(false);
    const [proofOfIdConfirmation, setProofOfIdConfirmation] = useState(false);// eslint-disable-line
    const [uploadSelfie, setUploadSelfie] = useState(false);

    // GET URL PARAMETERS 
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();

    const checkAllDocuments = () => {
        setApiCallFinished(false)

        // CHECK FOR DOCUMENTS STATUS
        const checkForDocumentStatus = () => {
            let fail = false;
            let selfieApproved = false;
            let registrationApproved = false;

            proofOfIdentity.map((doc) => {// eslint-disable-line
                if (doc.status.toLowerCase() === "verified") {
                    // DO NOTHING
                } else {
                    fail = true
                }
            });

            selfieIdentity.map((doc) => {// eslint-disable-line
                if (doc.status.toLowerCase() === "verified") {
                    selfieApproved = true
                } else {
                    // DO NOTHING
                }
            });
            // IF THERE IS NO APPROVED SELFIES THEN MARK THE CHECK AS FAILED
            if (!selfieApproved) fail = true

            professionalDocs.map((doc) => {// eslint-disable-line
                if (doc.status.toLowerCase() === "verified") {
                    // DO NOTHING
                } else {
                    // CHECK FOR THE PHYSICIAN'S CV
                    if (doc.type_id === 20) fail = true
                }
            });
            // VERIFY USER BUTTON LOGIC BASED ON USER TYPE
            // eslint-disable-next-line
            {
                userType !== 3
                    ? allRegPhyDetails.filter(
                        (doc) => {// eslint-disable-line
                            doc.reg_documents.filter(
                                (item) => {// eslint-disable-line
                                    if (item.status.toLowerCase() === "verified") {
                                        registrationApproved = true
                                    } else {
                                        // DO NOTHING
                                    }
                                }
                            )
                        }
                    )
                    : allRegPhyDetails.filter((doc) => {// eslint-disable-line
                        if (doc.status.toLowerCase() === "verified") {
                            registrationApproved = true
                        } else {
                            // DO NOTHING
                        }
                    });
            }

            // IF THERE IS NO APPROVED REGISTRATION THEN MARK THE CHECK AS FAILED
            if (!registrationApproved) fail = true

            // BUTTON TOOLTIP SETTER 
            if (fail) {
                setTooltip("Unapproved document found!")
            } else {
                // PHYSICIAN VERIFICATION SUCCESS ACTIONS 
                setTooltip("Verify Care Provider")
                setAllDocumentsApproved(true)
            }

        }

        // CHECK FOR MISSING DOCUMENTS
        if (
            proofOfIdentity.length === 0 ||
            selfieIdentity.length === 0 ||
            professionalDocs.length === 0 ||
            allRegPhyDetails.length === 0
        ) {
            setTooltip("Required document missing!")
        } else {
            checkForDocumentStatus()
        }
    }

    const changePhysicianVerifiedStatus = () => {
        setUnverifyPhysician(false);

        const updatedVerifiedStatus = {
            user_id: userId ? userId : 0,
            is_verified: unverifyPhysician ? 0 : 1,
        };

        axios.post(
            API_ENDPOINT + "/user/phy-user-verification/",
            updatedVerifiedStatus,
            {
                headers: {
                    Authorization: token,
                },
            }
        )
            .then((res) => {
                if (res.status === 201) {
                    // console.log(res)
                    getPhysicianIdentityData();
                    toastr.success("Done", "Care provider verification status updated");
                }
            })
            .catch((error) => {
                console.log(error);
            });

    }

    // API CALL
    const getPhysicianIdentityData = () => {
        setSpinner(true)
        setAllDocumentsApproved(false)

        const params = { "user_id": userId }

        axios.post(`${API_ENDPOINT}/user/get-physician-identity-data/`, params, {
            headers: {
                Authorization: token,
            }
        })
            .then(function (response) {
                // console.log(response);
                setSpinner(false);
                setProofOfIdentity(response.data.documents.proofOfIdentity.length > 0 ? response.data.documents.proofOfIdentity : "")
                setSelfieIdentity(response.data.documents.selfie_proofOfIdentity.length > 0 ? response.data.documents.selfie_proofOfIdentity : "")
                setProfessionalDocs(response.data.documents.qualification.length > 0 ? response.data.documents.qualification : "")
                setOtherDocs(response.data.documents.others.length > 0 ? response.data.documents.others : "")
                setAllRegPhyDetails(response.data.phy_reg_details.length > 0 ? response.data.phy_reg_details : "")
                setUserStatus(response.data.isVerified)
                setUserType(response.data.userType)
                setApiCallFinished(true)
            })
            .catch(function (error) {
                console.log(error);
                setSpinner(false);
            });
    }

    // EFFECT TO GET THE URL PARAMETERS 
    useEffect(() => {
        if (query) {
            setUserId(Number(atob(query.get("pid"))));
            setPhysicianId(Number(atob(query.get("pid"))));
            setPhysicianName(atob(query.get("pname")));
        }
    }, [query])// eslint-disable-line


    // EFFECT TO CLEAR APPROVE DOCUMENT MODAL 
    useEffect(() => { if (!approveDocModal) setUserConfirmation(false) }, [approveDocModal])

    // EFFECT TO CLEAR UPLOAD DOCUMENT MODAL 
    useEffect(() => { if (!uploadDocModal) setUploadConfirmation(false) }, [uploadDocModal])

    // EFFECT TO CALL API WHEN USER CLICK YES
    useEffect(() => { if (userConfirmation) changePhysicianVerifiedStatus() }, [userConfirmation])// eslint-disable-line

    // CREATING COUNTRY OF REGISTRATION OBJECT 
    const RegistrationDetailCards = allRegPhyDetails
        ? allRegPhyDetails.map(details =>
            <CountryOfRegistration
                phyRegDetails={details}
                setUploadDocModal={setUploadDocModal}
                setAddMissingDocument={setAddMissingDocument}
                setPhysicianRegistrationId={setPhysicianRegistrationId}
                setAddMissingDocumentCountry={setAddMissingDocumentCountry}
            />
        )
        : "";

    // API CALL TO GET PHYSICIAN IDENTITY DATA 
    useEffect(() => { if (userId) getPhysicianIdentityData() }, [userId, reloadPhysicianIdentityData])// eslint-disable-line

    // EFFECT TO CHECK PHYSICIAN DOCUMENTS AND ENABLE / DISABLE 'VERIFY PHYSICIAN' BUTTON 
    useEffect(() => { if (apiCallFinished) checkAllDocuments() }, [apiCallFinished, reloadPhysicianIdentityData])// eslint-disable-line

    return (
        <Card>
            {spinner ? <Spinner color="success" /> : ""}
            <CardHeader className="profile-header">
                <CardTitle tag="h5" className="mb-0 id-proof-header">
                    Identity Details
            </CardTitle>
            </CardHeader>
            <CardBody className="pd-halfrem profile-card disable-scrollbars">

                {/* PROOF OF IDENTITY */}
                <Card className="kyc-bg-color2 margin-top">
                    <CardHeader>
                        <CardTitle tag="h5" className="mb-0 id-proof-header">
                            Proof Of Identity
                        <Button
                                className="float-right upload-doc-button-width"
                                color="success"
                                onClick={() => { setProofOfIdModal(true) }}
                            >Upload ID Proof
                        </Button>
                        </CardTitle>
                    </CardHeader>
                    <CardBody>

                        {/* PROOF OF IDENTITY TABLE  */}
                        {proofOfIdentity ? <ProofOfIdentityTable proofOfIdentity={proofOfIdentity} /> : <BlankStateCard />}
                    </CardBody>
                </Card>

                {/* SELFIE WITH PROOF OF ID  */}
                <Card className="kyc-bg-color2">
                    <CardHeader>
                        <CardTitle tag="h5" className="mb-0 id-proof-header">
                            Selfie With Proof Of Identity
                            <Button
                                className="float-right upload-doc-button-width"
                                color="success"
                                onClick={() => {
                                    setProofOfIdModal(true);
                                    setUploadSelfie(true);
                                }}
                            >Upload Selfie
                            </Button>
                        </CardTitle>
                    </CardHeader>
                    <CardBody>

                        {/* SELFIE ID COMPONENT  */}
                        <SelfieID
                            selfieIdentity={selfieIdentity}
                            proofOfIdentity={proofOfIdentity}
                        />
                    </CardBody>
                </Card>

                {/* PROFESSIONAL DOCUMENTATION  */}
                <Card className="kyc-bg-color2">
                    <CardHeader>
                        <CardTitle tag="h5" className="mb-0 id-proof-header">
                            Professional Documentation
                            <Button
                                className="float-right upload-doc-button-width"
                                color="success"
                                onClick={() => { setUploadDocModal(true) }}
                            >Upload Document
                        </Button>
                        </CardTitle>
                    </CardHeader>
                    <CardBody>

                        <Card className="kyc-bg-color">
                            <CardHeader></CardHeader>
                            <CardBody>
                                {/* PROFESSIONAL DOCUMENTATION TABLE */}
                                {professionalDocs ? <ProfessionalDocumentationTable professionalDocs={professionalDocs} /> : <BlankStateCard />}
                            </CardBody>
                        </Card>

                        {/* REGISTRATION DETAILS TO PRACTICE MEDICINE  */}
                        <Card className="kyc-bg-color">
                            <CardHeader>
                                <CardTitle tag="h5" className="mb-0 id-proof-header">
                                    {userType === 3 ? "Registration Details To Practice Medicine" : "Registration Details"}
                                </CardTitle>
                            </CardHeader>
                            <CardBody>

                                {/* COUNTRY OF REGISTRATION TO PRACTICE MEDICINE */}
                                {RegistrationDetailCards ? RegistrationDetailCards : <BlankStateCard />}
                            </CardBody>
                        </Card>

                        {/* OTHER DOCUMENTS  */}
                        <Card className="kyc-bg-color">
                            <CardHeader>
                                <CardTitle tag="h5" className="mb-0 id-proof-header">
                                    Other Documents
                                </CardTitle>
                            </CardHeader>
                            <CardBody>

                                {/* OTHER DOCUMENTS TABLE */}
                                {otherDocs ? <OtherDocuments otherDocs={otherDocs} /> : <BlankStateCard />}
                            </CardBody>
                        </Card>
                    </CardBody>

                    {/* VERIFY / UNVERIFY PHYSICIAN MODAL */}
                    <VerifyPhysicianModal
                        approveDocModal={approveDocModal}
                        setApproveDocModal={setApproveDocModal}
                        setUserConfirmation={setUserConfirmation}
                        userStatus={userStatus}
                        setUnverifyPhysician={setUnverifyPhysician}
                    />

                    {/* DOCUMENT UPLOAD MODAL  */}
                    <DocUploadModal
                        uploadDocModal={uploadDocModal}
                        setUploadDocModal={setUploadDocModal}
                        setUploadConfirmation={setUploadConfirmation}
                        userId={userId}
                        addMissingDocument={addMissingDocument}
                        setAddMissingDocument={setAddMissingDocument}
                        physicianRegistrationId={physicianRegistrationId}
                        setAddMissingDocumentCountry={setAddMissingDocumentCountry}
                        addMissingDocumentCountry={addMissingDocumentCountry}
                        allRegPhyDetails={allRegPhyDetails}
                    />

                    {/* PROOF OF ID UPLOAD MODAL  */}
                    <ProofOfIdUploadModal
                        proofOfIdModal={proofOfIdModal}
                        setProofOfIdModal={setProofOfIdModal}
                        setProofOfIdConfirmation={setProofOfIdConfirmation}
                        userId={userId}
                        uploadSelfie={uploadSelfie}
                        setUploadSelfie={setUploadSelfie}
                    />

                </Card>
                <div className="verify-physician-container">
                    <Row className="verify-document-row">

                        {/* SHOW VERIFY OR UNVERIFY BUTTONS BASED ON DOCUMENT STATUS   */}
                        {userStatus && !unverifyPhysician
                            ? <Button
                                color="danger"
                                className="verify-button"
                                title={tooltip}
                                onClick={() => { setApproveDocModal(true) }}
                            >Unverify User
                        </Button>
                            : <Button
                                color={allDocumentsApproved ? "success" : "light"}
                                className="verify-button"
                                disabled={allDocumentsApproved ? false : true}
                                title={tooltip}
                                onClick={() => { setApproveDocModal(true) }}
                            >Verify User
                            </Button>
                        }

                    </Row>
                </div>
            </CardBody>
        </Card>
    )
};


const Kyc = () => {
    // CONTEXT VARIABLES
    const { physicianName } = useContext(GlobalState);
    const [pathName, setPathName] = useState("");
    let location = useLocation();

    const getUserTypeFromURL = () => {
        let pathName = location.pathname
        let userTypeInURL = pathName.split("/")
        let filtered = userTypeInURL.filter(function (item) { return item !== "" })
        userTypeInURL = filtered[0]
        userTypeInURL = userTypeInURL.toLocaleLowerCase()
        userTypeInURL = userTypeInURL.charAt(0).toUpperCase() + userTypeInURL.slice(1)
        setPathName(userTypeInURL)
    }

    // EFFECT TO CHANGE THE BREADCRUMB BASE ON URL
    useEffect(() => { getUserTypeFromURL() }, [])// eslint-disable-line

    return (
        <>
            <Header>
                <HeaderTitle>Settings</HeaderTitle>
                <Breadcrumb>
                    <BreadcrumbItem>
                    <a href={`/${pathName.toLowerCase()}`}>{pathName}</a>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>Identity</BreadcrumbItem>
                    <BreadcrumbItem active>{physicianName}</BreadcrumbItem>
                </Breadcrumb>
            </Header>
            <Row>
                <Col md="3" xl="2">
                    <Navigation />
                </Col>
                <Col md="9" xl="10">
                    <KycInfo />
                </Col>
            </Row>
        </>
    )
}

export default connect(store => ({
    sidebar: store.sidebar
}))(Kyc);
