import React, { useState, useEffect, useContext } from 'react'
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    Row,
} from "reactstrap";
import RegistrationDetailsTable from '../Tables/RegistrationDetailsTable';
import BlankStateCard from '../Tables/BlankStateCard';
import axios from 'axios';
import ApproveDocModal from '../Modals/ApproveDocModal';
import { toastr } from "react-redux-toastr";
import { GlobalState } from "../../../../contextAPI/Context";

function CountryOfRegistration(props) {
    // DEPENDENCY  VARIABLES
    const {
        phyRegDetails,
        setUploadDocModal,
        setAddMissingDocument,
        setPhysicianRegistrationId,
        setAddMissingDocumentCountry,
        // addMissingDocumentCountry
    } = props;
    // GET TOKEN
    let token = localStorage.getItem("token");
    // OBTAINING BASE URL BASED ON ENVIRONMENT
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    // CONTEXT VARIABLES 
    const { reloadPhysicianIdentityData, setReloadPhysicianIdentityData, userType } = useContext(GlobalState);

    // MODAL VARIABLES 
    const [approveDocModal, setApproveDocModal] = useState(false);
    const [userConfirmation, setUserConfirmation] = useState(false);
    const [docUpdateStatusCode, setDocUpdateStatusCode] = useState("");
    const [regId, setRegId] = useState("");
    const [countryName, setCountryName] = useState("");
    const [regNumber, setRegNumber] = useState("");
    const documentLength = userType === 3 ? 3 : 1;
    const [approveAllButton, setApproveAllButton] = useState(false);

    const approveDocument = () => {
        setRegId(phyRegDetails ? phyRegDetails.reg_id : "")
        setApproveDocModal(true)
        setCountryName(phyRegDetails && phyRegDetails.countryOfRegistration ? phyRegDetails.countryOfRegistration : "")
        setRegNumber(phyRegDetails && phyRegDetails.registrationNumber ? phyRegDetails.registrationNumber : "")
    }

    const approveAllButtonLogic = () => {
        let isThereUnverifiedDocuments = false
        let isThereExpiredDocuments = false
        let verifiedDocumentCount = 0
        if (phyRegDetails.reg_documents.length > 0) {
            phyRegDetails.reg_documents.map(item => {// eslint-disable-line
                if (item.status === "Pending verification") isThereUnverifiedDocuments = true
                if (item.status === "Expired") isThereExpiredDocuments = true
                if (item.status === "Verified") verifiedDocumentCount = verifiedDocumentCount + 1
            })
            isThereUnverifiedDocuments && !isThereExpiredDocuments && verifiedDocumentCount < 3 ? setApproveAllButton(true) : setApproveAllButton(false)
            
        } else {
            // THERE IS NO RECORDS PRESENT, HENCE THE BUTTON SHOULD BE HIDDEN 
            setApproveAllButton(false)
        }
    }

    // API CALL
    const documentStatusUpdate = () => {
        const params = {
            "reg_id": regId ? regId : 0,
            "status": docUpdateStatusCode ? docUpdateStatusCode : 0,
            "reason": ""
        }

        axios.post(`${API_ENDPOINT}/user/batch-document-status-update/`, params, {
            headers: {
                Authorization: token,
            }
        })
            .then(function (response) {
                // console.log(response);
                setReloadPhysicianIdentityData(reloadPhysicianIdentityData + 1);
                setUserConfirmation(false);
                toastr.success("Done", "Document status updated");
            })
            .catch(function (error) {
                console.log(error);
                toastr.error("Error", "Document status updated failed");
            });
    }

    // EFFECT TO CLEAR APPROVE DOCUMENT MODAL 
    useEffect(() => {
        if (!approveDocModal) {
            setDocUpdateStatusCode("");
            setRegId("");
            setCountryName("");
            setRegNumber("");
            setUserConfirmation(false);
        }
    }, [approveDocModal])

    // EFFECT TO CALL API WHEN USER CLICK YES
    useEffect(() => { if (userConfirmation) documentStatusUpdate() }, [userConfirmation])// eslint-disable-line
    useEffect(() => { if (phyRegDetails) approveAllButtonLogic() }, [phyRegDetails])// eslint-disable-line

    return (
        <Card>
            <CardBody>
                <Row>
                    <Col>
                        <CardTitle tag="h5" className="mb-0">
                            <table>
                                <tbody>
                                    <tr>
                                        <td><strong>Country Of Registration</strong></td>
                                        <td>:</td>
                                        <td>
                                            <span className="selfie-with-proof-of-id-data">
                                                {phyRegDetails && phyRegDetails.countryOfRegistration ? phyRegDetails.countryOfRegistration : "N/A"}
                                            </span>
                                        </td>
                                    </tr>
                                    {phyRegDetails && phyRegDetails.stateOfRegistration ? 
                                    <tr>
                                        <td><strong>State</strong></td>
                                        <td>:</td>
                                        <td>
                                            <span className="selfie-with-proof-of-id-data">
                                                {phyRegDetails && phyRegDetails.stateOfRegistration ? phyRegDetails.stateOfRegistration : "N/A"}
                                            </span>
                                        </td>
                                    </tr>
                                    : ""}
                                    <tr>
                                        <td><strong> {userType === 6 ? "NMC PIN" : "Registration Number"}</strong></td>
                                        <td>:</td>
                                        <td>
                                            <span className="selfie-with-proof-of-id-data">
                                                {phyRegDetails && phyRegDetails.registrationNumber ? phyRegDetails.registrationNumber : "N/A"}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </CardTitle>
                    </Col>
                    <Col>

                        {/* HIDE APPROVE ALL BUTTON IF ALL DOCUMENTS ARE APPROVED  */}
                        {
                            approveAllButton
                                ? <Button
                                    color="success"
                                    className={userType === 3 ? "approve-all-document margin-left" : "display-none"}
                                    onClick={() => { approveDocument() }}
                                >
                                    Approve All Documents
                                </Button>
                                : ""
                        }

                        {/* SHOW ADD MISSING DOCUMENT BUTTON IF DOCUMENTS ARE MISSING  */}
                        {
                            phyRegDetails.reg_documents.length < documentLength
                                ? <Button
                                    className="float-right"
                                    color="success"
                                    onClick={() => {
                                        setUploadDocModal(true);
                                        setAddMissingDocument(true);
                                        setAddMissingDocumentCountry(phyRegDetails.countryID)
                                        setPhysicianRegistrationId(phyRegDetails.reg_id)
                                    }}
                                >Add Missing Document
                                </Button>
                                : ""
                        }

                    </Col>
                </Row>
            </CardBody>
            <CardBody>

                {/* REGISTRATION DETAILS TABLE */}
                {phyRegDetails ? <RegistrationDetailsTable phyRegDetails={phyRegDetails} /> : <BlankStateCard />}

                <ApproveDocModal
                    approveDocModal={approveDocModal}
                    setApproveDocModal={setApproveDocModal}
                    setUserConfirmation={setUserConfirmation}
                    setDocUpdateStatusCode={setDocUpdateStatusCode}
                    regId={regId}
                    countryName={countryName}
                    regNumber={regNumber}
                />
            </CardBody>
        </Card>
    )
}

export default CountryOfRegistration
