import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Breadcrumb, BreadcrumbItem, Container, Spinner, Button, TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from "reactstrap";
import Header from "../../components/Header";
import EditRuleConfigurationModal from '../../newComponents/utilities/EditRuleConfigurationModal';
import EditRefundConfigurationModal from '../../newComponents/utilities/EditRefundConfigurationModal';
import RuleConfigurationStatusChangeModal from '../../newComponents/utilities/RuleConfigurationStatusChangeModal';
import RefundConfigurationStatusChangeModal from '../../newComponents/utilities/RefundConfigurationStatusChangeModal';
import HeaderTitle from "../../components/HeaderTitle";
import { Hux } from "../../hoc/Hux";
import ExportCSVTable from "./RuleConfigurationDataTable";
import RefundConfigurationDataTable from "./RefundConfigurationDataTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GlobalState } from '../../contextAPI/Context';
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import classnames from 'classnames';


const Tables = (props) => {
  // CONTEXT VARIABLES 
  const {
    setToken,
    setSpinner,
    setBlankStateMessage,
    setRefundBlankStateMessage,
    ruleConfigListReload,
    refundConfigListReload,
    refundRuleList,
    setRefundRulesList,
  } = useContext(GlobalState);

  // LOCAL VARIABLES FOR PAYMENT CONFIG
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const [activeTab, setActiveTab] = useState('1');
  const [rowData, setRowData] = useState("")
  const [paymentRulesList, setPaymentRulesList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalState, setModalState] = useState(false);

  // EDIT MODAL VARIABLES FOR PAYMENT CONFIG
  const [editUserType, setEditUserType] = useState("");
  const [editApptType, setEditApptType] = useState("");
  const [editCommisionFeePercent, setEditCommisionFeePercent] = useState("");
  const [editProcessingFeePercent, setEditProcessingFeePercent] = useState("");
  const [editIsVat, setEditIsVat] = useState("");
  const [editStatus, setEditStatus] = useState("");
  const [editVatPercentage, setEditVatPercentage] = useState("");
  const [editRuleId, setEditRuleId] = useState("");

  // STATUS CHANGE MODAL VARIABLES FOR PAYMENT CONFIG
  const [UserType, setUserType] = useState("");
  const [ApptType, setApptType] = useState("");
  const [CommisionFeePercent, setCommisionFeePercent] = useState("");
  const [ProcessingFeePercent, setProcessingFeePercent] = useState("");
  const [IsVat, setIsVat] = useState("");
  const [Status, setStatus] = useState("");
  const [VatPercentage, setVatPercentage] = useState("");
  const [RuleId, setRuleId] = useState("");
  const [editRuleConfigrationModal, setEditRuleConfigrationModal] = useState(false);

  // LOCAL VARIABLES FOR REFUND CONFIG
  const [editRefundConfigrationModal, setEditRefundConfigrationModal] = useState(false);
  const [editOperator, setEditOperator] = useState("");
  const [editId, setEditId] = useState("");
  const [editMinHour, setEditMinHour] = useState("");
  const [editMaxHour, setEditMaxHour] = useState("");
  const [editRefundStatus, setEditRefundStatus] = useState("");
  const [editRefundPercent, setEditRefundPercent] = useState("");
  const [refundStatusChangeModal, setRefundStatusChangeModal] = useState(false);

  // STATUS CHANGE MODAL VARIABLES FOR PAYMENT CONFIG
  const [operator, setOperator] = useState("");
  const [Id, setId] = useState("");
  const [minHour, setMinHour] = useState("");
  const [maxHour, setMaxHour] = useState("");
  const [refundStatus, setRefundStatus] = useState("");
  const [refundPercent, setRefundPercent] = useState("");

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  }

  const fetchPaymentRules = () => {
    setToken(props.authtoken);
    setLoading(true);
    setSpinner(true);

    Axios.get(API_ENDPOINT + "/external/list-payment-rules/", {
      headers: {
        Authorization: props.authtoken,
      }
    }).then((res) => {
      setPaymentRulesList(res.data)
      setBlankStateMessage(res.data.length > 0 ? false : true);
      setLoading(false);
      setSpinner(false);
    });
  };

  const fetchRefundRules = () => {
    setToken(props.authtoken);
    setLoading(true);
    setSpinner(true);
    setRefundRulesList([]);
    Axios.get(API_ENDPOINT + "/external/list-refund-rules/", {
      headers: {
        Authorization: props.authtoken,
      }
    }).then((res) => {
      setRefundRulesList(res.data)
      setRefundBlankStateMessage(res.data.length > 0 ? false : true)
      setLoading(false);
      setSpinner(false);
    });
  };

  const basicValidation = (cell) => {
    return cell ? cell : "N/A"
  }

  const basicValidationForNum = (cell) => {
    return cell > 0 ? cell : "0"
  }

  const vatFormatter = (cell, row) => {
    if (row.is_vat) {
      return cell ? cell : "0"
    } else {
      return "N/A"
    }
  }


  const editRuleConfiguration = (user_type, apt_type, commision_fee_percent, processing_fee_percent, is_vat, vat_percentage, status, rule_id) => {

    setEditUserType(user_type);
    setEditApptType(apt_type);
    setEditCommisionFeePercent(commision_fee_percent);
    setEditProcessingFeePercent(processing_fee_percent);
    setEditIsVat(is_vat);
    setEditStatus(status);
    setEditVatPercentage(Number(vat_percentage));
    setEditRuleId(rule_id)

  }

  const editRefundConfiguration = (id, maxHour, minHour, operator, cancelChargePercent, status) => {

    setEditOperator(operator)
    setEditMinHour(minHour)
    setEditMaxHour(maxHour)
    setEditRefundPercent(cancelChargePercent)
    setEditId(id)
    setEditRefundStatus(status)
  }

  const statusChangeOfRuleConfiguration = (user_type, apt_type, commision_fee_percent, processing_fee_percent, is_vat, vat_percentage, status, rule_id) => {

    setUserType(user_type);
    setApptType(apt_type);
    setCommisionFeePercent(commision_fee_percent);
    setProcessingFeePercent(processing_fee_percent);
    setIsVat(is_vat);
    setStatus(status);
    setVatPercentage(vat_percentage);
    setRuleId(rule_id)

  }

  const statusChangeOfRefundConfiguration = (id, maxHour, minHour, operator, cancelChargePercent, status) => {

    setOperator(operator)
    setMinHour(minHour)
    setMaxHour(maxHour)
    setRefundPercent(cancelChargePercent)
    setId(id)
    setRefundStatus(status)
  }

  const statusFormatterForPaymentConfig = (cell, row) => {
    let status = "";
    let badgeclass = " ";

    if (cell === 1) {
      status = "Active";
      badgeclass = "badge badge-success statustoggle status-button-width";
    } else {
      status = "Deleted";
      badgeclass = "badge badge-danger statustoggle status-button-width";
    }

    return (
      <span
        className={badgeclass}
      /* onClick={() => {
        setModalState(true);
        statusChangeOfRuleConfiguration(
          row.user_type,
          row.apt_type,
          row.commision_fee_percent,
          row.processing_fee_percent,
          row.is_vat,
          row.vat_percentage,
          row.status,
          row.rule_id)
      }} */
      >
        {status}
      </span>
    );
  };

  const statusFormatterForRefundConfig = (cell, row) => {
    let status = "";
    let badgeclass = " ";

    if (cell === 1) {
      status = "Active";
      badgeclass = "badge badge-success statustoggle status-button-width";
    } else {
      status = "Deleted";
      badgeclass = "badge badge-danger statustoggle status-button-width";
    }

    return (
      <span
        className={badgeclass}
      >
        {status}
      </span>
    );
  };

  const statusFormatterForCSV = (cell) => {
    return cell ? "Active" : "Inactive"
  }

  const isVatFormatter = (cell, row) => {
    if (cell === 1) {
      return "Yes"
    } else {
      return "N0"
    }
  }


  const actionFormatterForPaymentConfig = (cell, row) => {
    return (
      <>
        <Button
          className="money-button"
          outline
          onClick={() => {
            setRowData(row)
            setEditRuleConfigrationModal(true)
            editRuleConfiguration(
              row.user_type,
              row.apt_type,
              row.commision_fee_percent,
              row.processing_fee_percent,
              row.is_vat,
              row.vat_percentage,
              row.status,
              row.rule_id)
          }}
          color="primary"
          title="Edit Payment Configuration"
        >
          <FontAwesomeIcon
            icon={faEdit}
            fixedWidth
            className="align-middle"
            title="Edit Payment Configuration"
          />
        </Button>
        <Button
          className="delete-btn"
          outline
          onClick={() => {
            setModalState(true);
            statusChangeOfRuleConfiguration(
              row.user_type,
              row.apt_type,
              row.commision_fee_percent,
              row.processing_fee_percent,
              row.is_vat,
              row.vat_percentage,
              row.status,
              row.rule_id)
          }}
          color="danger"
          title="Delete Payment Configuration"
        >
          <FontAwesomeIcon
            icon={faTrash}
            fixedWidth
            className="align-middle"
            title="Delete Payment Configuration"
          />
        </Button>
      </>
    )
  }

  const actionFormatterForRefundConfig = (cell, row) => {
    return (
      <>
        <Button
          className="money-button"
          outline
          onClick={() => {
            setEditRefundConfigrationModal(true)
            editRefundConfiguration(
              row.id,
              row.maxHour,
              row.minHour,
              row.operator,
              row.cancelChargePercent,
              row.status
            )
          }}
          color="primary"
          title="Edit Refund Configuration"
        >
          <FontAwesomeIcon
            icon={faEdit}
            fixedWidth
            className="align-middle"
            title="Edit Refund Configuration"
          />
        </Button>
        <Button
          className="delete-btn"
          outline
          onClick={() => {
            setRefundStatusChangeModal(true)
            statusChangeOfRefundConfiguration(
              row.id,
              row.maxHour,
              row.minHour,
              row.operator,
              row.cancelChargePercent,
              row.status
            )
          }}
          color="danger"
          title="Delete Refund Configuration"
        >
          <FontAwesomeIcon
            icon={faTrash}
            fixedWidth
            className="align-middle"
            title="Delete Refund Configuration"
          />
        </Button>
      </>
    )
  }



  const defaultSorted = [{
    dataField: 'user', // if dataField is not match to any column you defined, it will be ignored.
    order: 'desc' // desc or asc
  }];

  const PaymentTableColumns = [

    {
      dataField: "user",
      text: "User Type",
      sort: true,
      csvExport: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "apt_type_name",
      text: "Appt Type",
      sort: true,
      csvExport: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "commision_fee_percent",
      text: "Commission Fee (%)",
      sort: true,
      csvExport: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "processing_fee_percent",
      text: "Processing Fee (%)",
      sort: true,
      csvExport: true,
      formatter: basicValidationForNum,
      csvFormatter: basicValidationForNum,
    },
    {
      dataField: "is_vat",
      text: "Is VAT Applicable",
      sort: true,
      hidden: true,
      csvFormatter: isVatFormatter,

    },
    {
      dataField: "vat_percentage",
      text: "VAT (%)",
      sort: true,
      csvExport: true,
      formatter: vatFormatter,
      csvFormatter: basicValidationForNum,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      hidden: true,
      csvFormatter: statusFormatterForCSV,
      formatter: statusFormatterForPaymentConfig,
    },

    {
      text: "Actions",
      csvExport: false,
      classes: "action_column",
      formatter: actionFormatterForPaymentConfig,
    }
  ];

  const RefundTableColumns = [

    {
      dataField: "cancellationSlotText",
      text: "Cancellation Period",
      sort: true,
      csvExport: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    /* {
      dataField: "minHour",
      text: "Minimum Hours",
      sort: true,
      csvExport: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "maxHour",
      text: "Maximum Hours",
      sort: true,
      csvExport: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    }, */
    {
      dataField: "cancelChargePercent",
      text: "Cancellation Charge (%)",
      sort: true,
      csvExport: true,
      formatter: basicValidationForNum,
      csvFormatter: basicValidationForNum,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      hidden: true,
      csvFormatter: statusFormatterForCSV,
      formatter: statusFormatterForRefundConfig,
    },

    {
      text: "Actions",
      csvExport: false,
      classes: "action_column",
      formatter: actionFormatterForRefundConfig,
    },
    

  ];

  let loader = null;

  if (loading) {
    loader = <Spinner />;
  }

  // DEFAULT API CALL
  useEffect(() => { fetchPaymentRules() }, [setBlankStateMessage, ruleConfigListReload]); // eslint-disable-line 
  useEffect(() => { fetchRefundRules() }, [setBlankStateMessage, refundConfigListReload]); // eslint-disable-line 


  return (
    <div>
      <Hux>
        {loader}
        <Container fluid>
          <Header>
            <HeaderTitle>System Settings
          {/* <button className="btn btn-equipo-blue mt-2 pull-right"
              onClick={() => {
                setNewRuleConfigrationModal(true)
              }}
            >
              Add New Payment Configuration
              </button> */}

            </HeaderTitle>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to="/dashboard">Dashboard</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>System Settings</BreadcrumbItem>
            </Breadcrumb>
            <div className="settings-tabs">
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '1' })}
                    onClick={() => { toggle('1'); }}
                  >
                    <div className="payment-config-tab" >
                      Payment Configuration
                  </div>
                  </NavLink>
                </NavItem> <div className="opacity-zero">{"."}</div>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '2' })}
                    onClick={() => { toggle('2'); }}
                  >
                    <div className="payment-config-tab" >
                      Refund Configuration
                  </div>
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <Row>
                  <Col sm="12">
                    <ExportCSVTable token={props.authtoken} data={paymentRulesList} columns={PaymentTableColumns} defaultSorted={defaultSorted} />
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="2">
                <Row>
                  <Col sm="12">
                      <RefundConfigurationDataTable
                        token={props.authtoken}
                        data={refundRuleList}
                        columns={RefundTableColumns}
                        defaultSorted={defaultSorted}
                      />
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </Header>


          <RuleConfigurationStatusChangeModal modalState={modalState} setModalState={setModalState}
            UserType={UserType}
            ApptType={ApptType}
            CommisionFeePercent={CommisionFeePercent}
            ProcessingFeePercent={ProcessingFeePercent}
            IsVat={IsVat}
            Status={Status}
            VatPercentage={VatPercentage}
            RuleId={RuleId}
            token={props.authtoken}
          />
          <RefundConfigurationStatusChangeModal modalState={modalState} setModalState={setModalState}
            operator={operator}
            Id={Id}
            minHour={minHour}
            maxHour={maxHour}
            refundStatus={refundStatus}
            refundPercent={refundPercent}
            refundStatusChangeModal={refundStatusChangeModal}
            setRefundStatusChangeModal={setRefundStatusChangeModal}
            token={props.authtoken}
          />


          <EditRuleConfigurationModal
            editUserType={editUserType}
            setEditUserType={setEditUserType}
            editApptType={editApptType}
            setEditApptType={setEditApptType}
            editCommisionFeePercent={editCommisionFeePercent}
            setEditCommisionFeePercent={setEditCommisionFeePercent}
            editProcessingFeePercent={editProcessingFeePercent}
            setEditProcessingFeePercent={setEditProcessingFeePercent}
            editIsVat={editIsVat}
            setEditIsVat={setEditIsVat}
            editStatus={editStatus}
            setEditStatus={setEditStatus}
            editVatPercentage={editVatPercentage}
            setEditVatPercentage={setEditVatPercentage}
            editRuleConfigrationModal={editRuleConfigrationModal}
            setEditRuleConfigrationModal={setEditRuleConfigrationModal}
            editRuleId={editRuleId}
            setEditRuleId={setEditRuleId}
            rowData={rowData}
            token={props.authtoken}
          />

          <EditRefundConfigurationModal
            editRefundConfigrationModal={editRefundConfigrationModal}
            setEditRefundConfigrationModal={setEditRefundConfigrationModal}
            editOperator={editOperator}
            setEditOperator={setEditOperator}
            editId={editId}
            setEditId={setEditId}
            editMinHour={editMinHour}
            setEditMinHour={setEditMinHour}
            editMaxHour={editMaxHour}
            setEditMaxHour={setEditMaxHour}
            editRefundStatus={editRefundStatus}
            setEditRefundStatus={setEditRefundStatus}
            editRefundPercent={editRefundPercent}
            setEditRefundPercent={setEditRefundPercent}
            token={props.authtoken}
          />

        </Container>

      </Hux>


    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
    authtoken: state.auth.token,
  };
};

export default connect(mapStateToProps, null)(Tables);
