import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Hux } from "./../../hoc/Hux";
import { Breadcrumb, BreadcrumbItem, Container } from "reactstrap";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import CouponInput from "./AddCouponInput";

class AddCoupon extends Component {
  render() {
    return (
      <Hux>
        <Container fluid>
          <Header>
            <HeaderTitle>Add New Coupon</HeaderTitle>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to="/dashboard">Dashboard</Link>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <Link to="/coupons">Coupons</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>Add New Coupon</BreadcrumbItem>
            </Breadcrumb>
          </Header>
          <CouponInput></CouponInput>
        </Container>
      </Hux>
    );
  }
}

export default AddCoupon;
