import React, { useState, useEffect, useContext } from 'react';
import { Col, Label, Row, Button, Input } from "reactstrap";
import './DateRangeSelector.css';
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import { GlobalState } from '../../contextAPI/Context';
import axios from 'axios';

const PatientFilters = (props) => {
    // CONTEXT VARIABLES 
    const {
        token,
        spinner,
        setCouponType,
        setCouponName,
        setCouponCorporate,
        setCouponStatus,
        setCouponPhysician,
        setCouponPatient,
        setCouponSearch,
        couponSearch,
        setCoupons
    } = useContext(GlobalState)

    // LOCAL VARIABLES 
    const animatedComponents = makeAnimated();
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

    const [Type, setType] = useState("");
    const [Types, setTypes] = useState([]);
    const [TypeValue, setTypeValue] = useState([]);

    const [Corporate, setCorporate] = useState("");
    const [Corporates, setCorporates] = useState([]);
    const [CorporateValue, setCorporateValue] = useState([]);

    const [status, setStatus] = useState("")
    const [StatusValue, setStatusValue] = useState([])

    const [Name, setName] = useState("");
    const [Physician, setPhysician] = useState("");
    const [Patient, setPatient] = useState("");

    const TypeList = Types.map((key) => {
        return { value: key.id, label: key.displayName }
    });

    const CorporateList = Corporates.map((key) => {
        return { value: key.id, label: key.name }
    });

    const StatusOptions = [
        { value: '1', label: 'Active' },
        { value: '0', label: 'Inactive' }
    ]

    const TypeHandler = (value) => {
        setType(value);
        const temp = value ? value.map(value => value.value) : "";
        setTypeValue(temp ? temp : []);
        // console.log('Type', temp)
    };

    const CorporateHandler = (value) => {
        setCorporate(value);
        const temp = value ? value.value : "";
        setCorporateValue(temp ? temp : []);
    };

    const StatusHandler = (value) => {
        setStatus(value);
        const temp = value ? value.map(value => value.value) : "";
        setStatusValue(temp ? temp : []);
    };

    const runSearch = () => {
        setCoupons([]);
        setCouponSearch(couponSearch + 1);
        setCouponType(TypeValue ? TypeValue : []);
        setCouponCorporate(CorporateValue ? CorporateValue : []);
        setCouponStatus(StatusValue ? StatusValue : []);
        setCouponName(Name ? Name : "");
        setCouponPhysician(Physician ? Physician : "");
        setCouponPatient(Patient ? Patient : "")
    }

    const resetFields = () => {
        setType("")
        setTypeValue([])
        setCorporate("")
        setCorporateValue([])
        setStatus("")
        setStatusValue([])
        setName("")
        setPhysician("")
        setPatient("")
        setCouponType([]);
        setCouponCorporate([]);
        setCouponStatus([]);
        setCouponName("");
        setCouponPhysician("");
        setCouponPatient("");
        setCoupons([]);
    }

    const FetchCouponType = () => {
        axios.get(API_ENDPOINT + '/external/get-all-coupon-types/', {
            headers: {
                Authorization: token,
            },
        })
            .then(res => {
                if (res.status === 200) {
                    setTypes(res.data);
                }
            });
    }

    const FetchCorporate = () => {
        axios.get(API_ENDPOINT + '/external/coupon-corporate-group-list/', {
            headers: {
                Authorization: token,
            },
        })
            .then(res => {
                if (res.status === 200) {
                    setCorporates(res.data);
                }
            });
    }

    // EFFECTS FOR INITIAL PAGE LOAD
    useEffect(() => {
        if (token && Types.length === 0) FetchCouponType();
        if (token && Corporates.length === 0) FetchCorporate();
    }, [token]) // eslint-disable-line

    return (
        <div className="coupon-filter-margin">
            <Row>
                <Col sm="3">
                    <Label>Type</Label>
                    <Select
                        placeholder="All"
                        className="react-select-container"
                        classNamePrefix="react-select"
                        value={Type}
                        options={TypeList}
                        isSearchable
                        isClearable
                        isMulti
                        components={animatedComponents}
                        isLoading={TypeList.length > 0 ? false : true}
                        onChange={(event) => TypeHandler(event)}
                    />
                </Col>
                <Col sm="3">
                    <Label>Healthcare Partners</Label>
                    <Select
                        placeholder="All"
                        className="react-select-container"
                        classNamePrefix="react-select"
                        value={Corporate}
                        options={CorporateList}
                        isSearchable
                        isClearable
                        // isMulti
                        components={animatedComponents}
                        isLoading={CorporateList.length > 0 ? false : true}
                        onChange={(event) => { CorporateHandler(event) }}
                    />
                </Col>
                <Col sm="3">
                    <Label>Status</Label>
                    <Select
                        placeholder="All"
                        className="react-select-container"
                        classNamePrefix="react-select"
                        isSearchable
                        value={status}
                        options={StatusOptions}
                        isClearable
                        isMulti
                        components={animatedComponents}
                        onChange={(event) => { StatusHandler(event) }}
                    />
                </Col>
            </Row>
            <Row>
                <Col sm="3" className="second-row-filters">
                    <Label>Name</Label>
                    <Input
                        name="physician"
                        placeholder="All"
                        value={Name}
                        maxLength={120}
                        onChange={(event) => { setName(event.target.value) }}
                    />
                </Col>
                <Col sm="3" className="second-row-filters" >
                    <Label>Care Provider</Label>
                    <Input
                        name="physician"
                        placeholder="All"
                        value={Physician}
                        maxLength={120}
                        onChange={(event) => { setPhysician(event.target.value) }}
                    />
                </Col>
                <Col sm="3" className="second-row-filters" >
                    <Label>Patient</Label>
                    <Input
                        name="patient"
                        placeholder="All"
                        value={Patient}
                        maxLength={120}
                        onChange={(event) => { setPatient(event.target.value) }}
                    />
                </Col>
                <Col>
                    <Button
                        type="submit"
                        className="go-buttons"
                        disabled={spinner ? true : false}
                        onClick={() => { runSearch() }}
                    >Go</Button>
                    <Button
                        type="reset"
                        className="reset-buttons"
                        disabled={spinner ? true : false}
                        onClick={() => { resetFields() }}
                    >Reset</Button>
                </Col>
            </Row>
        </div>
    )
}

export default PatientFilters
