import React, { createRef, useState, useContext, useEffect } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction' // needed for dayClick
import momentPlugin from '@fullcalendar/moment'
import { Col, Row } from "reactstrap"
import './main.scss'
import { GlobalState } from "../../contextAPI/Context"
import moment from 'moment'
import SlotMessageModal from '../Settings/Schedule/SlotMessageModal'
import ReactTooltip from 'react-tooltip';

const MyCalendar = (props) => {
  // DEPENDENCY VARIABLES
  const {
    timeSlotDetailsCalendar,
    setEnableSlot,
    setSlotStartDateTime,
    setSlotEndDateTime,
    utc_offset,
  } = props
  
  // GLOBAL CONTEXT
  const { week, setWeek, } = useContext(GlobalState);
  
  // LOCAL VARIABLES
  const calendarComponentRef = createRef()
  const [calendarWeekends, setCalendarWeekends] = useState(true) // eslint-disable-line

  // MODAL VARIABLE
  const [slotMessageModal, setSlotMessageModal] = useState(false)

  const handleEventPositioned = (info) => {

    info.el.setAttribute("data-tip", info.event.extendedProps.specialityText || "");
  }

  const handleEventClick = (clickInfo) => {
    setSlotStartDateTime("")
    setSlotEndDateTime("")
    setEnableSlot(false)
    setSlotMessageModal(false)
    let physicianLocalTime = moment.utc().add(utc_offset, 'm').format('DD-MMM-YYYY hh:mm A')
    physicianLocalTime = moment(physicianLocalTime).valueOf()
    let slotStartDateTime = moment(clickInfo.event.start).valueOf()
    let diff = slotStartDateTime - physicianLocalTime
    if (clickInfo.event.backgroundColor === "#7f8389" && diff < 0) setSlotMessageModal(true)

    if (clickInfo.event.backgroundColor === "#7f8389" && diff > 0) {
      setEnableSlot(true)
      setSlotStartDateTime(clickInfo.event.start)
      setSlotEndDateTime(clickInfo.event.end)
    }
  }


  const prev = () => {
    calendarComponentRef.current.calendar.prev()
    setWeek(week - 1)
  }

  const next = () => {
    calendarComponentRef.current.calendar.next()
    setWeek(week + 1)
  }

  const today = () => {
    calendarComponentRef.current.calendar.today()
    setWeek(0)
  }

  useEffect(() => { ReactTooltip.rebuild() })

  return (
    <Row>
      <Col>
        <div className='demo-app'>
      <ReactTooltip className="card-tooltip" />
          <div className='demo-app-calendar'>
            <SlotMessageModal 
            slotMessageModal={slotMessageModal} 
            setSlotMessageModal={setSlotMessageModal} 
            />
            
            <FullCalendar
              firstDay={0}
              defaultView="timeGridWeek"
              eventClick={handleEventClick}
              handleWindowResize={true}
              eventTimeFormat={{
                hour: '2-digit',
                minute: '2-digit'
              }}
              slotLabelFormat={{
                hour: '2-digit',
                minute: '2-digit'
              }}
              customButtons={{
                prevWeek: {
                  text: 'prev',
                  icon: 'left-single-arrow',
                  click: function () {
                    prev();
                  }
                },
                thisWeek: {
                  text: 'This Week',
                  click: function () {
                    today();
                  }
                },
                nextWeek: {
                  text: 'next',
                  icon: 'right-single-arrow',
                  click: function () {
                    next();
                  }
                },
              }}
              header={{
                left: '',
                center: 'prevWeek,thisWeek,nextWeek',
                right: '',
              }}
              minTime='00:00:00'
              maxTime='23:00:00'
              //slotDuration= '00:15:00'
              slotEventOverlap={false}
              eventPositioned={handleEventPositioned}
              Duration='00:30:00'
              slotLabelInterval={30}
              allDaySlot={false}
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, momentPlugin]}
              ref={calendarComponentRef}
              weekends={calendarWeekends}
              events={timeSlotDetailsCalendar}
              defaultTimedEventDuration="00:30:00"
              columnHeaderFormat='ddd, DD MMM YYYY'
            />
          </div>
        </div>
      </Col>
    </Row>
  )
}
export default MyCalendar