import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const ApproveDocModal = (props) => {
  // DEPENDENCY VARIABLES  
  const {
    approveDocModal,
    setApproveDocModal,
    setUserConfirmation,
    userStatus,
    setUnverifyPhysician
  } = props;

  const toggle = () => {
    setApproveDocModal(!approveDocModal);
  };

  const confirmation = () => {
    setUserConfirmation(true);
    if (userStatus) setUnverifyPhysician(true);
    toggle();
  };

  return (
    <div>
      <Modal isOpen={approveDocModal} toggle={toggle}>
        <ModalHeader tag="h4" cssModule={{'modal-title': 'w-100 text-center'}}  toggle={toggle}>{userStatus ? "Unverify User" : "Verify User"}</ModalHeader>
        <ModalBody>
          {userStatus ? "Are you sure you want to mark this user as unverified?" : "Are you sure you want to mark this physician as verified?"}
        </ModalBody>
        <ModalFooter>
          <Button className="yes-button button-width" onClick={confirmation}>
            Yes
          </Button>{" "}
          <Button outline className="no-button button-width" onClick={toggle}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ApproveDocModal;
