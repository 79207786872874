import React, { useState, useEffect, useContext } from 'react';
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import { Col, Label, Row, Button } from "reactstrap";
import './DateRangeSelector.css';
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import { GlobalState } from '../../contextAPI/Context';
import axios from 'axios';

const PharmacyFilters = (props) => {
    // CONTEXT VARIABLES 
    const {
        spinner,
        token,
        setPharmacyName,
        setPharmacyEmail, 
        setPharmacyCode, 
        setPharmacyEfax, 
        setPharmacyAddress, 
        setPharmacyCountry,
        setPharmacyStatus,
        setPharmacyListReload,
        pharmacyListReload, 
    } = useContext(GlobalState)


    // LOCAL VARIABLES 
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [code, setCode] = useState("")
    const [efax, setEfax] = useState("")
    const [address, setAddress] = useState("")
    
    
    // COUNTRY
    const [country, setCountry] = useState("")
    const [countryList, setCountryList] = useState("")
    const [countryDropDown, setCountryDropDown] = useState("")

    //  STATUS 
    const [status, setStatus] = useState("")
    const statusDropDown = [
        { value: "1", label: 'Active' },
        { value: "0", label: 'Inactive' }

    ]

    
    const animatedComponents = makeAnimated();
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

    

    const runSearch = () => {
        if (name || email || code || efax || address || country || status ) {
            setPharmacyName(name ? name : "")
            setPharmacyEmail(email ? email : "")
            setPharmacyCode(code ? code : "")
            setPharmacyEfax(efax ? efax : "")
            setPharmacyAddress(address ? address : "")
            setPharmacyCountry(country ? country.value : 0)
            setPharmacyStatus(status ? status.value : "")
            setPharmacyListReload(pharmacyListReload + 1)
        }
    }

    const resetFields = () => {
        setName("")
        setEmail("")
        setCode("")
        setEfax("")
        setAddress("")
        setCountry("")
        setStatus("")
        setPharmacyName("")
        setPharmacyEmail("")
        setPharmacyCode("")
        setPharmacyEfax("")
        setPharmacyAddress("") 
        setPharmacyCountry("")
        setPharmacyStatus("")
    }

    

    const getCountryList = () => {
        axios.get(API_ENDPOINT + "/external/get-country-list/",
            {
                headers: {
                    Authorization: token,
                },
            })
            .then((res) => {
                setCountryList(res.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    
    const createCountryOption = () => {
        var array = countryList
        const options = array.map((item) => {
            return { value: item.id, label: item.country_name};
        });
        setCountryDropDown(options)
    }

    
    // COUNTRY
    useEffect(() => { if (token && !countryList) getCountryList(); }, [token]) // eslint-disable-line
    useEffect(() => { if (countryList.length > 0) createCountryOption() }, [countryList]) // eslint-disable-line


    return (
        <AvForm>
            <AvGroup>
                <Row>
                    <Col sm="2">
                        <Label>Name</Label>
                        <AvField
                            type="text"
                            name="name"
                            placeholder="All"
                            value={name}
                            validate={{
                                maxLength: {value: 120}
                              }} 
                            onChange={(e) => {setName(e.target.value)}}
                        />
                    </Col>
                    <Col sm="2">
                        <Label>Code</Label>
                        <AvField
                            type="text"
                            name="code"
                            placeholder="All"
                            value={code}
                            validate={{
                                maxLength: {value: 25}
                              }} 
                            onChange={(e) => {setCode(e.target.value)}}
                        />
                    </Col>
                    <Col sm="2">
                        <Label>Email</Label>
                        <AvField
                            type="text"
                            name="email"
                            placeholder="All"
                            value={email}
                            validate={{
                                maxLength: {value: 254}
                              }} 
                            onChange={(e) => {setEmail(e.target.value)}}
                        />
                    </Col>
                    
                    <Col sm="2">
                        <Label>Efax</Label>
                        <AvField
                            type="text"
                            name="efax"
                            placeholder="All"
                            value={efax}
                            validate={{
                                maxLength: {value: 150}
                              }} 
                            onChange={(e) => {setEfax(e.target.value)}}
                        />
                    </Col>
                    
                    <Col sm="2">
                        <Label>Country</Label>
                        <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            onChange={(event) => { setCountry(event)}}
                            isSearchable
                            placeholder="All"
                            value={country}
                            options={countryDropDown}
                            components={animatedComponents}
                        />
                    </Col>
                    <Col sm="2">
                        <Label>Status</Label>
                        <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            onChange={(event) => { setStatus(event)}}
                            isSearchable
                            placeholder="All"
                            value={status}
                            options={statusDropDown}
                            components={animatedComponents}
                        />
                    </Col>
                </Row>
                <Row>
                <Col sm="2" className="second-row-filters Filter-address" >
                        <Label>Address</Label>
                        <AvField
                            type="text"
                            name="address"
                            placeholder="All"
                            value={address}
                            validate={{
                                maxLength: {value: 500}
                              }}
                            onChange={(e) => {setAddress(e.target.value)}}
                        />
                    </Col>
                    <Col>
                        <Button
                            type="submit"
                            className="go-buttons"
                            disabled={spinner ? true : false}
                            onClick={() => { runSearch() }}
                        >Go</Button>
                        <Button
                            type="reset"
                            className="reset-buttons"
                            disabled={spinner ? true : false}
                            onClick={() => { resetFields() }}
                        >Reset</Button>
                    </Col>
                </Row>
            </AvGroup>
        </AvForm>
    )
}

export default PharmacyFilters
