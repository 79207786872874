import React, { useState, useEffect, useContext } from 'react'
import { Row, Col, Card, CardBody, Button, Badge } from 'reactstrap';
import avatar from '../../../../assets/avatar.png';
import moment from 'moment';
import MomentConfig from '../../../../common/MomentConfig';
import axios from 'axios';
import DocumentViewer from '../DocumentViewer/DocumentViewer';
import ApproveDocModal from '../Modals/ApproveDocModal';
import RejectDocModal from '../Modals/RejectDocModal';
import { toastr } from "react-redux-toastr";
import { GlobalState } from "../../../../contextAPI/Context";
import ReactTooltip from 'react-tooltip';
import DeleteDocumentModal from '../Modals/DeleteDocumentModal';

function SelfieID(props) {
    // GET TOKEN
    let token = localStorage.getItem("token");
    let userID = localStorage.getItem("userId");
    // OBTAINING BASE URL BASED ON ENVIRONMENT
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    // CONTEXT VARIABLES 
    const { reloadPhysicianIdentityData, setReloadPhysicianIdentityData } = useContext(GlobalState);
    //LOCAL VARIABLES 
    const [selfieIdentity] = props.selfieIdentity
    const [proofOfIdentity] = props.proofOfIdentity
    const [selfieImage, setSelfieImage] = useState("")
    // DOCUMENT VIEWER VARIABLES 
    const [modalState, setModalState] = useState(false);
    const [fileURL, setFileURL] = useState("");
    const [fileExtension, setFileExtension] = useState("");
    const [fileType, setFileType] = useState("");
    // MODAL VARIABLES 
    const [approveDocModal, setApproveDocModal] = useState(false);
    const [rejectDocModal, setRejectDocModal] = useState(false);
    const [userConfirmation, setUserConfirmation] = useState(false);
    const [statusUpdateNote, setStatusUpdateNote] = useState("");
    const [docUpdateStatusCode, setDocUpdateStatusCode] = useState("");
    const [documentId, setDocumentId] = useState("");
    const [deleteDocumentModal, setDeleteDocumentModal] = useState(false);
    const [documentDeleteConfirmation, setDocumentDeleteConfirmation] = useState(false);
    const documentType = "Selfie with ID proof";
    const [documentName, setDocumentName] = useState("");

    // API CALL TO GET SELFIE IMAGE
    const getSelfieImage = () => {
        axios.get(selfieIdentity.file_url, {
            headers: {
                Authorization: token,
            },
            responseType: 'arraybuffer'
        })
            .then(function (response) {
                let image = btoa(
                    new Uint8Array(response.data)
                        .reduce((data, byte) => data + String.fromCharCode(byte), '')
                );
                setSelfieImage(`data:${response.headers['content-type'].toLowerCase()};base64,${image}`);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    // API CALL
    const documentStatusUpdate = () => {
        const params = {
            "document_id": documentId ? documentId : 0,
            "status": docUpdateStatusCode ? docUpdateStatusCode : 0,
            "reason": statusUpdateNote ? statusUpdateNote : "",
            "is_delete": documentDeleteConfirmation ? 1 : 0,
            "userID": userID ? userID : 0
        }

        axios.post(`${API_ENDPOINT}/user/document-status-update/`, params, {
            headers: {
                Authorization: token,
            }
        })
            .then(function (response) {
                // console.log(response);
                setReloadPhysicianIdentityData(reloadPhysicianIdentityData + 1);
                setUserConfirmation(false);
                setDocumentDeleteConfirmation(false);
                toastr.success("Done", "Document status updated");
            })
            .catch(function (error) {
                console.log(error);
                toastr.error("Error", "Document status updated failed");
            });
    }

    const statusFormatter = (cell) => {
        let color = ""
        let titleText = ""
        function colorSelector(cell) {
            if (cell.toLowerCase() === "verified") { color = "success"; titleText = "Verified"; }
            if (cell.toLowerCase() === "rejected") { color = "danger"; titleText = "Rejected"; }
            if (cell.toLowerCase() === "pending verification") { color = "warning"; titleText = "Pending verification"; }
            if (cell.toLowerCase() === "expired") { color = "light"; titleText = "Expired"; }
        }

        colorSelector(cell);

        return <Badge color={color} title={titleText} className="status-badge"> {cell} </Badge>
    }

    const Actions = () => {
        let row = selfieIdentity ? selfieIdentity : ""
        let fileExtension = row ? row.fileName : ""
        fileExtension = fileExtension ? fileExtension.split('.').pop() : "";
        const viewDocument = () => {
            setModalState(!modalState);
            setFileURL(row ? row.file_url : "");
            setFileExtension(fileExtension ? fileExtension : "");
            setFileType(row ? row.type : "");
        }

        const approveDocument = () => {
            setStatusUpdateNote("")
            setApproveDocModal(true)
            setDocumentId(row.id)
        }

        const rejectDocument = () => {
            setRejectDocModal(true)
            setDocumentId(row.id)
        }

        return (
            <div className="selfie-action-container">
                <Button color="primary" className="proof-of-identity-actions" onClick={() => { viewDocument() }} >View</Button>
                {row.status === "Verified" || row.status === "Expired" ? "" : <Button color="success" className="proof-of-identity-actions" onClick={() => { approveDocument() }} >Approve</Button>}
                {row.status === "Rejected" || row.status === "Verified" ? "" : <Button color="warning" className="proof-of-identity-actions" onClick={() => { rejectDocument() }} >Reject</Button>}
                <Button color="danger" className="proof-of-identity-actions" onClick={() => { setDocumentId(row.id); setDocumentName(row.title); setDeleteDocumentModal(true) }} >Delete</Button>
            </div>
        )
    }

    const basicValidation = (cell) => {
        return cell ? <span data-tip={cell} data-for='selfie-id'> {cell.substring(0, 100)} </span> : "N/A"
    }

    // EFFECT TO CLEAR FILE INFO WHEN THE DOC VIEWER MODAL IS CLOSED
    useEffect(() => {
        if (!modalState) {
            setFileURL("");
            setFileExtension("");
        }
    }, [modalState])

    // EFFECT TO CLEAR APPROVE DOCUMENT MODAL 
    useEffect(() => {
        if (!approveDocModal) {
            setStatusUpdateNote("");
            setDocUpdateStatusCode("");
            setUserConfirmation(false);
        }
    }, [approveDocModal])

    // EFFECT TO CLEAR REJECT DOCUMENT MODAL 
    useEffect(() => {
        if (!statusUpdateNote) {
            setStatusUpdateNote("");
            setDocUpdateStatusCode("");
            setUserConfirmation(false);
        }
    }, [statusUpdateNote])

    // EFFECT TO CALL API WHEN USER CLICK YES
    useEffect(() => { if (userConfirmation) documentStatusUpdate() }, [userConfirmation]) // eslint-disable-line

    // EFFECT TO HANDLE DOCUMENT DELETION  
    useEffect(() => { if (documentDeleteConfirmation) documentStatusUpdate(); setSelfieImage("") }, [documentDeleteConfirmation]) // eslint-disable-line

    // BELOW EFFECT TRIGGER AN API CALL IF FILE URL IS PRESENT IN THE PROPS
    useEffect(() => {
        if (selfieIdentity && selfieIdentity.file_url) {
            getSelfieImage();
        } else {
            setSelfieImage("");
        }
    }, [selfieIdentity]) // eslint-disable-line

    return (
        <Card>
            <ReactTooltip id='selfie-id' className="doc-upload-tooltip" />
            <CardBody>
                <Row>
                    <Col xs="2" className="selfie-image-card">
                        <img
                            className="selfie-with-proof-of-id"
                            alt="selfie"
                            src={selfieImage ? selfieImage : avatar}
                        />
                    </Col>
                    <Col>
                        <table>
                            <tbody>
                                <tr>
                                    <td><strong>Proof of Identity</strong></td>
                                    <td>:</td>
                                    <td>
                                        <span className="selfie-with-proof-of-id-data">
                                            {proofOfIdentity && proofOfIdentity.type ? proofOfIdentity.type : "N/A"}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td><strong>Uploaded On</strong></td>
                                    <td>:</td>
                                    <td>
                                        <span className="selfie-with-proof-of-id-data">
                                            {selfieIdentity && selfieIdentity.uploadedOn ? moment(selfieIdentity.uploadedOn).utc(selfieIdentity.uploadedOn).local().format(MomentConfig.MOMENT_FORMAT_DATEANDTIME) : "N/A"}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td><strong>Status</strong></td>
                                    <td>:</td>
                                    <td>
                                        <span className="selfie-with-proof-of-id-data">
                                            <strong>
                                                {selfieIdentity && selfieIdentity.status ? statusFormatter(selfieIdentity.status) : "N/A"}
                                            </strong>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td><strong>Reason For Rejection</strong></td>
                                    <td>:</td>
                                    <td>
                                        <span className="selfie-with-proof-of-id-data">
                                            <strong className="selfie-rejection-comment">
                                                {selfieIdentity && selfieIdentity.comment ? basicValidation(selfieIdentity.comment) : "N/A"}
                                            </strong>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td><strong>Actions</strong></td>
                                    <td>:</td>
                                    <td>
                                        {selfieIdentity ? <Actions /> : ""}</td>
                                </tr>
                            </tbody>
                        </table>
                    </Col>
                </Row>
                <DocumentViewer
                    modalState={modalState}
                    setModalState={setModalState}
                    fileURL={fileURL}
                    fileExtension={fileExtension}
                    fileType={fileType}
                />
                <ApproveDocModal
                    approveDocModal={approveDocModal}
                    setApproveDocModal={setApproveDocModal}
                    setUserConfirmation={setUserConfirmation}
                    setDocUpdateStatusCode={setDocUpdateStatusCode}
                />
                <RejectDocModal
                    rejectDocModal={rejectDocModal}
                    setRejectDocModal={setRejectDocModal}
                    setUserConfirmation={setUserConfirmation}
                    setStatusUpdateNote={setStatusUpdateNote}
                    setDocUpdateStatusCode={setDocUpdateStatusCode}
                />
                <DeleteDocumentModal
                    deleteDocumentModal={deleteDocumentModal}
                    setDeleteDocumentModal={setDeleteDocumentModal}
                    setDocumentDeleteConfirmation={setDocumentDeleteConfirmation}
                    documentName={documentName}
                    documentType={documentType}
                />
            </CardBody>
        </Card>
    )
}

export default SelfieID
