import React, { useState, useEffect, useContext } from "react";
import { Card, CardHeader, CardBody, Row, Col,Button } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import moment from 'moment';
import MomentConfig from '../../common/MomentConfig';
import { GlobalState } from '../../contextAPI/Context';
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import NewRuleConfigurationModal from '../../newComponents/utilities/NewRuleConfigurationModal';


const { SearchBar, ClearSearchButton } = Search;
const ExportCSVTable = (props) => {
  const { defaultSorted, token } = props
  const { blankStateMessage } = useContext(GlobalState)
  // LOCAL VARIABLES 
  const { data } = props
  const [numberOfRecords, setNumberOfRecords] = useState(0)
  const [newRuleConfigrationModal, setNewRuleConfigrationModal] = useState(false);

  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <div>
        <button className="btn btn-equipo-blue mt-2" onClick={handleClick}>
          Export
        </button>
      </div>
    );
  };

  const blankState = (
    <Card className="blank-state">
      <CardBody>
        <h3>No records available</h3>
      </CardBody>
    </Card>
  );

  const handleDataChange = ({ dataSize }) => {
    setNumberOfRecords(dataSize ? dataSize : 0);
  }

  useEffect(() => { setNumberOfRecords(data.length) }, [data]);

  return (
    <div className="physicians_table adjustment">
      <Card>
        <ToolkitProvider
          keyField="id"
          data={props.data}
          columns={props.columns}
          search
          exportCSV={{
            fileName: `equipo-Payment-Configuration-Export-${moment().format(MomentConfig.MOMENT_FORMAT_DATEANDTIME)}.csv`
          }}
        >
          {(props) => (
            <div>
              <CardHeader>
                <Row>
                  <Col>
                    <Header>
                        <HeaderTitle>
                          {/* <h4 className = "payment-config-header" >
                          Payment Configuration
                          </h4> */}
                            <Button 
                                className="float-right add-refund-btn"
                                title="Add New Payment Configuration"
                                onClick={() => {
                                  setNewRuleConfigrationModal(true)
                                }}
                              >
                                Add New
                            </Button>
                      </HeaderTitle>
                    </Header>
                  </Col>
                </Row>
                <div className="separator" />
                <Row>
                  <Col sm="2">
                    
                    <div className="number-of-records">
                      <p>Number of records: <strong>{numberOfRecords}</strong></p>
                    </div>
                  </Col>
                  <Col>
                    <SearchBar {...props.searchProps} className="search-box" />
                    <ClearSearchButton {...props.searchProps} className="clear-search-box" />
                  </Col>
                  <Col sm="1">
                    <div className="export-button">
                      <MyExportCSV {...props.csvProps} />
                    </div>
                  </Col>
                </Row>
                <div className="separator" />
                <div className="float-right pull-right">
                </div>
              </CardHeader>
              <CardBody className="patient-list">
                {blankStateMessage && blankState}
                {!blankStateMessage && <BootstrapTable
                  {...props.baseProps}
                  bootstrap4
                  id={props.key}
                  bordered={false}
                  defaultSorted={defaultSorted}
                  onDataSizeChange={handleDataChange}
                  pagination={paginationFactory({
                    sizePerPage: 50,
                    sizePerPageList: [50, 100, 150, 200],
                  })}
                />}
              </CardBody>
            </div>
          )}
        </ToolkitProvider>
      </Card>
      
      <NewRuleConfigurationModal
        token={token}
        newRuleConfigrationModal={newRuleConfigrationModal}
        setNewRuleConfigrationModal={setNewRuleConfigrationModal}
      />

    </div>
  );
};

export default ExportCSVTable;