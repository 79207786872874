import React, {   useContext } from 'react';
import { GlobalState } from '../../contextAPI/Context';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import axios from 'axios';
import { toastr } from 'react-redux-toastr';

const RefundConfigurationStatusChangeModal = (props) => {
  const {
    Id,
    minHour,
    maxHour,
    refundStatus,
    refundPercent,
    refundStatusChangeModal,
    setRefundStatusChangeModal,
  } = props;

  const {
    refundConfigListReload, setRefundConfigListReload,
  } = useContext(GlobalState);

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  let userID = localStorage.getItem("userId");


  let newStatus = ""

  const toggle = () => {
    
    setRefundStatusChangeModal(!refundStatusChangeModal);
  };

  const StatusUpdate = () => {
    if(refundStatus === 1) {
      newStatus = 0 
    }
    else {
      newStatus = 1
    }
    updateRuleConfigurationApiCall(); 
  };

  const updateRuleConfigurationApiCall = () => {
    axios.post(`${API_ENDPOINT}/external/update-refund-rule/`, {

      "ruleId": Number(Id),
      "minHour": minHour ? minHour.toString() : "",
      "maxHour": maxHour ? maxHour.toString() : "",
      "cancelChargePercent": Number(refundPercent),
      "status": newStatus,
      "updated_by": Number(userID)
    }, {
        headers: {
            Authorization: props.token,
        }
    })
        .then(function (response) {
          setRefundStatusChangeModal(!refundStatusChangeModal);
          setRefundConfigListReload(refundConfigListReload + 1)
            toastr.success("Successfully Deleted", "Refund configuration deleted successfully")
        })
        .catch(function (error) {
            // console.log(error);
            toastr.error("Update Failed", "Please try again")
        });
    }
 

  return (
    <div>
      <Modal isOpen={refundStatusChangeModal} toggle={toggle}>
        <ModalHeader tag="h4" cssModule={{'modal-title': 'w-100 text-center'}}  toggle={toggle}>Delete Refund Configuration</ModalHeader>
        <ModalBody>
            <div>
              Are you sure you want to delete the refund configuration ?
            </div>
        </ModalBody>
        <ModalFooter>
          <Button className="yes-button button-width" onClick={StatusUpdate}>
            Yes
          </Button>{" "}
          <Button outline className="no-button button-width" onClick={toggle}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default RefundConfigurationStatusChangeModal;
