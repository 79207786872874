import React, { useState, useEffect, useContext } from 'react';
import { GlobalState } from '../../contextAPI/Context';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Label, FormGroup, Row, Col, Container } from 'reactstrap';
import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";


const EditRefundConfigurationModal = (props) => {
    const { editRefundConfigrationModal,
        setEditRefundConfigrationModal,
        editOperator,
        setEditOperator,
        editId,
        setEditId,
        editMinHour,
        setEditMinHour,
        editMaxHour,
        setEditMaxHour,
        editRefundStatus, // eslint-disable-line
        setEditRefundStatus, // eslint-disable-line
        editRefundPercent,
        setEditRefundPercent, token } = props;

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    let userID = localStorage.getItem("userId");


    // CONTEXT VARIABLES 
    const { refundConfigListReload, setRefundConfigListReload } = useContext(GlobalState);


    // LOCAL VARIABLES
    const [addButtonStatus, setAddButtonStatus] = useState(true);
    const [operatorObject, setOperatorObject] = useState("");


    const toggle = () => {

        setAddButtonStatus(true);
        setEditOperator("")
        setEditId("")
        setEditMinHour("")
        setEditMaxHour("")
        setEditRefundPercent("")
        setEditRefundConfigrationModal(!editRefundConfigrationModal);

    }

    const OperatorOptions = [
        { label: "<=", value: "<=" },
        { label: ">", value: ">" },
        { label: "Between", value: "between" },

    ]

    const operatorPopulate = () => {

        OperatorOptions.filter((operator) => { // eslint-disable-line
            if (operator.value === editOperator) {
                setOperatorObject(operator)
            }

        })

    }



    const UpdateRefundConfigurationApiCall = () => {

        axios.post(`${API_ENDPOINT}/external/update-refund-rule/`, {

            "ruleId": Number(editId),
            "minHour": editMinHour ? editMinHour.toString() : "",
            "maxHour": editMaxHour ? editMaxHour.toString() : "",
            "cancelChargePercent": Number(editRefundPercent),
            "status": 1,
            "updated_by": Number(userID)

        }, {
            headers: {
                Authorization: token,
            }
        })
            .then(response => {
                if (response.status === 201) {
                    setRefundConfigListReload(refundConfigListReload + 1);
                    toastr.success("Successfully Updated", "Refund configuration successfully updated");
                    toggle();
                }
            })
            .catch(function (error) {
                toastr.error("Update Failed", error.response.data.message);
            });
    }


    const disableAddButton = () => {
        if (editOperator === "between") {
            if (editOperator && editMinHour && editMaxHour && editRefundPercent) {
                setAddButtonStatus(false)
            }
        }


        if (editOperator === "<=") {
            setEditMaxHour("")
            if (editOperator && editMinHour && editRefundPercent) {
                setAddButtonStatus(false)
            }
        }


        if (editOperator === ">") {
            setEditMaxHour("")
            if (editOperator && editMinHour && editRefundPercent) {
                setAddButtonStatus(false)
            }
        }

        if (!editOperator) setAddButtonStatus(true)
        if (
            (editOperator === "between") &&
            (!editMinHour || !editMaxHour || !editRefundPercent)
        ) {
            setAddButtonStatus(true)
        }

        if (
            (editOperator === "<=") &&
            (!editMinHour || !editRefundPercent)
        ) {
            setAddButtonStatus(true)
        }

        if (
            (editOperator === ">") &&
            (!editMinHour || !editRefundPercent)
        ) {
            setAddButtonStatus(true)
        }

    }


    const RefundPercentFeeHandler = (num) => {
        let decimal = false
        let value = ""
        num % 1 !== 0 ? decimal = true : decimal = false
        if (decimal) {
            value = Math.round(num * 100) / 100;
        } else {
            value = num;
        }

        if (value <= 100) setEditRefundPercent(value);
        if (value > 100) setEditRefundPercent("100");
        if (value <= 0) setEditRefundPercent("0");
        if (value === "") setEditRefundPercent("");
    };

    const minHourHandler = (num) => {

        let decimal = false
        let value = ""
        num % 1 !== 0 ? decimal = true : decimal = false
        if (decimal) {
            toastr.error("Decimal Values Are Not Supported")
        } else {
            value = num;
        }

        if (value <= 100) setEditMinHour(value);
        if (value <= 0) setEditMinHour("0");
        if (value === "") setEditMinHour("");
        decimal = false
    };

    const maxHourHandler = (num) => {

        let decimal = false
        let value = ""
        num % 1 !== 0 ? decimal = true : decimal = false
        if (decimal) {
            toastr.error("Decimal Values Are Not Supported")
        } else {
            value = num;
        }

        if (value <= 100) setEditMaxHour(value);
        if (value <= 0) setEditMaxHour("0");
        if (value === "") setEditMaxHour("");
        decimal = false
    };


    useEffect(() => { if (editOperator) operatorPopulate() }, [editOperator])  // eslint-disable-line
    useEffect(() => { disableAddButton() }, [editOperator, editMinHour, editMaxHour, editRefundPercent])  // eslint-disable-line

    return (
        <div>
            <Modal isOpen={editRefundConfigrationModal} toggle={toggle} size="md">
                <ModalHeader tag="h4" cssModule={{ 'modal-title': 'w-100 text-center' }} toggle={toggle}>Edit Refund Configuration</ModalHeader>
                <ModalBody className="overflow-modal disable-scrollbars" >
                    <FormGroup>
                        
                        <Container className="container">

                            <Row>
                                <Col xs="6" >
                                    <Label>
                                        Operator <span className="required">*</span>
                                    </Label>
                                </Col>
                                <Col xs="6" >

                                    <Select
                                        placeholder="Select"
                                        options={OperatorOptions}
                                        value={operatorObject}
                                        isDisabled
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col xs="6" className="cancel-charge">
                                    <Label>
                                        Cancellation Charge (%) <span className="required">*</span>
                                    </Label>
                                </Col>
                                <Col xs="6" className="cancel-charge" >

                                    <Input
                                        required
                                        onChange={e => { if (e.target.value.length <= 5) RefundPercentFeeHandler(e.target.value) }}
                                        type="number"
                                        name="Refund_fee"
                                        placeholder="Cancellation Charge"
                                        value={editRefundPercent}

                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col xs="6" >
                                    <div >

                                        {editOperator === "between" ?
                                            <Label>
                                                Minimum Hour <span className="required">*</span>
                                            </Label>
                                            : ""}
                                    </div>
                                </Col>
                                <Col xs="6" >

                                    {editOperator === "between" ?
                                        <div >
                                            <Input
                                                required
                                                onChange={e => { if (e.target.value.length <= 3) minHourHandler(e.target.value) }}
                                                type="number"
                                                name="Minimum_hour"
                                                placeholder="Minimum Hour"
                                                value={editMinHour}

                                            />
                                            <div className="warning-label">
                                                <FontAwesomeIcon
                                                    icon={faExclamationTriangle}
                                                />
                                                <Label className="warning-text width-max margin-top">Decimal values are not supported</Label>
                                            </div>
                                        </div>
                                        : ""}
                                </Col>
                            </Row>

                            <Row>
                                <Col xs="6">
                                    {editOperator === "between" ?
                                        <Label>
                                            Maximum Hour <span className="required">*</span>
                                        </Label>
                                        : ""}
                                </Col>
                                <Col xs="6">

                                    {editOperator === "between" ?
                                        <div>
                                            <Input
                                                required
                                                onChange={e => { if (e.target.value.length <= 3) maxHourHandler(e.target.value) }}
                                                type="number"
                                                name="maximum_hour"
                                                placeholder="Maximum Hour"
                                                value={editMaxHour}


                                            />
                                            <div className="warning-label">
                                                <FontAwesomeIcon
                                                    icon={faExclamationTriangle}
                                                />
                                                <Label className="warning-text width-max margin-top ">Decimal values are not supported</Label>
                                            </div>
                                        </div>
                                        : ""}
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="6" >
                                    {editOperator === "<=" ?
                                        <Label>
                                            Maximum Hour <span className="required">*</span>
                                        </Label>

                                        : ""}
                                </Col>
                                <Col xs="6" >

                                    {editOperator === "<=" ?
                                        <div >
                                            <Input
                                                required
                                                onChange={e => { if (e.target.value.length <= 3) minHourHandler(e.target.value) }}
                                                type="number"
                                                name="Minimum_hour"
                                                placeholder="Maximum Hour"
                                                value={editMinHour}

                                            />

                                            <div className="warning-label ">
                                                <FontAwesomeIcon
                                                    icon={faExclamationTriangle}
                                                />
                                                <Label className="warning-text width-max margin-top">Decimal values are not supported</Label>
                                            </div>
                                        </div>
                                        : ""}
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="6">
                                    {editOperator === ">" ?
                                        <Label>
                                            Minimum Hour <span className="required">*</span>
                                        </Label>
                                        : ""}
                                </Col>
                                <Col xs="6">

                                    {editOperator === ">" ?
                                        <div>
                                            <Input
                                                required
                                                onChange={e => { if (e.target.value.length <= 3) minHourHandler(e.target.value) }}
                                                type="number"
                                                name="Minimum_hour"
                                                placeholder="Minimum Hour"
                                                value={editMinHour}

                                            />
                                            <div className="warning-label">
                                                <FontAwesomeIcon
                                                    icon={faExclamationTriangle}
                                                />
                                                <Label className="warning-text width-max margin-top">Decimal values are not supported</Label>
                                            </div>
                                        </div>
                                        : ""}
                                </Col>
                            </Row>


                        </Container>

                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button
                        className="yes-button button-width"
                        onClick={UpdateRefundConfigurationApiCall}
                        disabled={addButtonStatus}
                        title={addButtonStatus ? "Fill the required fields" : ""}
                    >Update</Button>{' '}
                    <Button outline className="no-button button-width" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default EditRefundConfigurationModal;