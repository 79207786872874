import React, { useState, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { toastr } from 'react-redux-toastr'
let documentID = null

function DropZone(props) {
    // DEPENDENCY VARIABLES
    const {
        setFileReady,
        documentTypeId,
        setIdProofFile,
        setSelfieFile,
        setCvFile,
        setQualificationFile,
        setOtherFile,
    } = props

    // ASIAN VALUE TO GLOBAL VARIABLE TO SOLVE A SCOPE ISSUE 
    documentID = documentTypeId

    // LOCAL VARIABLES
    const [fileName, setFileName] = useState("")
    var fileExtension = false
    var tempFile = ""

    const validateFileTypeAndSave = () => {
        // SAVE SELFIE FILE
        if (documentID === 1
            || documentID === 4
            || documentID === 3
            || documentID === 11) {
            setIdProofFile(tempFile)
        }
        // SAVE ID PROOF FILE 
        if (documentID === 19) {
            setSelfieFile(tempFile)
        }
        // SAVE CV FILE
        if (documentID === 20) {
            setCvFile(tempFile)
        }
        // SAVE QUALIFICATION FILE
        if (documentID === 21) {
            setQualificationFile(tempFile)
        }
        // SAVE OTHER DOCUMENT FILE
        if (documentID === 25) {
            setOtherFile(tempFile)
        }
    }

    const validateFileExtension = (filename) => {
        let extension = filename.split('.').pop();
        fileExtension = false

        if (extension.toLowerCase() === "jpg"
            || extension.toLowerCase() === "jpeg"
            || extension.toLowerCase() === "png"
            || extension.toLowerCase() === "pdf"
            || extension.toLowerCase() === "doc"
            || extension.toLowerCase() === "docx"
        ) {
            fileExtension = true
            setFileReady(true)
            setFileName(filename)
        }
    }

    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            let size = file.size
            if (size > 5242880) {
                toastr.error("File Size Exceeded", "Please upload a file lesser than 5MB in size")
            } else {
                validateFileExtension(file.name)
                if (fileExtension) {
                    // SAVE FILES ON A STATE VARIABLE
                    tempFile = acceptedFiles.map((file) => (file));// eslint-disable-line
                    validateFileTypeAndSave();
                } else {
                    toastr.error(
                        "Unsupported File",
                        "File is not supported for the upload"
                    );
                }
            }

        })

    }, [])

    const { getRootProps, getInputProps } = useDropzone({ onDrop, maxFiles: 1 })

    return (
        <>
            <div className="drop-zone-area" {...getRootProps()}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop some files here, or click to select files</p>
            </div>
            <ul>
                {fileName ? <li className="drop-zone-file">{fileName}</li> : ""}
            </ul>
        </>
    )
}

export default DropZone
