import React, { useState, useEffect, useContext } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Spinner, Button } from "reactstrap";
import axios from 'axios';
import PdfViewer from "./PdfViewer";
import BlankStateCard from './BlankStateCard';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudDownloadAlt } from "@fortawesome/free-solid-svg-icons";
import { toastr } from "react-redux-toastr";
import { GlobalState } from '../../../../contextAPI/Context';
import moment from 'moment';
import MomentConfig from '../../../../common/MomentConfig';

const DocumentViewer = (props) => {
  // DEPENDENCY VARIABLES 
  const {
    modalState,
    setModalState,
    fileURL,
    fileExtension,
    fileType,
  } = props;

  // CONTEXT VARIABLES
  const { physicianName } = useContext(GlobalState);

  // GET TOKEN
  let token = localStorage.getItem("token");

  // AXIOS REQUEST CANCELLATION VARIABLE
  var CancelToken = axios.CancelToken;
  var source = CancelToken.source();

  // LOCAL VARIABLES
  const [loading, setLoading] = useState(true);
  const [downloading, setDownload] = useState(false);
  const [document, setDocument] = useState("");
  const [preview, setPreview] = useState("");
  const [fileName, setFileName] = useState("");

  // TOGGLE MODAL 
  const toggle = () => {
    source.cancel('Request canceled by the user');
    setLoading(true);
    setDownload(false);
    setDocument("");
    setPreview("");
    setModalState(!modalState);
  };

  const createFileName = () => {
    let dateTime = moment().format(MomentConfig.MOMENT_FORMAT_DATEANDTIME)
    let documentName = `equipo_${physicianName}_${fileType}_${dateTime}`
    setFileName(documentName)
  }

  // DOWNLOAD BUTTON 
  const downLoadButton = (
    <Button color="success" onClick={() => { downloadFile() }}>
      <FontAwesomeIcon
        icon={faCloudDownloadAlt}
        fixedWidth
        className="align-middle mr-2"
      />
    Download File
    </Button>
  )

  // API CALL TO GET SELFIE IMAGE
  const getSelfieImage = () => {
    axios.get(fileURL, {
      headers: {
        Authorization: token,
      },
      responseType: 'arraybuffer',
      cancelToken: source.token,
    })
      .then(function (response) {
        let image = btoa(
          new Uint8Array(response.data)
            .reduce((data, byte) => data + String.fromCharCode(byte), '')
        );
        if (fileExtension.toLowerCase() === "jpg" || fileExtension.toLowerCase() === "jpeg" || fileExtension.toLowerCase() === "png") setDocument(`data:${response.headers['content-type'].toLowerCase()};base64,${image}`);
        if (fileExtension.toLowerCase() === "pdf") setPreview(Buffer.from(response.data, "binary").toString("base64"));
        setLoading(false);
      })
      .catch(function (thrown) {
        if (axios.isCancel(thrown)) {
          console.log('Request canceled', thrown.message);
        } else {
          // handle error
        }
      });

  }

  // API CALL TO DOWNLOAD FILE
  const downloadFile = () => {
    setDownload(true);
    axios.get(fileURL, {
      headers: {
        Authorization: token,
      },
      responseType: 'arraybuffer',
      cancelToken: source.token,
    })
      .then(function (response) {
        if (response.status === 200) {
          var fileDownload = require("js-file-download");
          fileDownload(response.data, `${fileName}.${fileExtension}`);
          toastr.success("Done", "Document downloaded successfully");
          setDownload(false);
        }
      })
      .catch(function (thrown) {
        if (axios.isCancel(thrown)) {
          console.log('Request canceled', thrown.message);
        } else {
          // handle error
        }
      });

  }

  useEffect(() => { if (fileURL) getSelfieImage() }, [fileURL]) // eslint-disable-line
  // FUNCTION CALL TO MAKE CUSTOM FILE NAME
  useEffect(() => { if (fileURL) createFileName(); }, [fileURL]) // eslint-disable-line

  return (
    <div>
      <Modal isOpen={modalState} toggle={toggle} size="lg">
        <ModalHeader tag="h4" cssModule={{'modal-title': 'w-100 text-center'}}  toggle={toggle} >
          Document Viewer
        </ModalHeader>
        {loading || downloading ? <Spinner color="success" /> : ""}
        <ModalBody>
          <div className="pdf-viewer disable-scrollbars">

            {/* IMAGE VIEWER */}
            {!loading
              && (fileExtension.toLowerCase() === "jpg"
                || fileExtension.toLowerCase() === "jpeg"
                || fileExtension.toLowerCase() === "png")
              ? <img src={document} alt="document-preview" className="image-viewer" />
              : ""}

            {/* PDF VIEWER */}
            {!loading && fileExtension.toLowerCase() === "pdf" ? <PdfViewer url={preview} /> : ""}

            {/* UNSUPPORTED FILE */}
            {!loading
              && fileExtension.toLowerCase() !== "pdf"
              && fileExtension.toLowerCase() !== "jpg"
              && fileExtension.toLowerCase() !== "jpeg"
              && fileExtension.toLowerCase() !== "png"
              ? <div className="doc-viewer-blank-state"><BlankStateCard /></div>
              : ""}
          </div>
        </ModalBody>
        <ModalFooter>
          {!loading ? (<div className="top-download-button">{downLoadButton}</div>) : ""}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DocumentViewer;
