import React, { useState, useEffect, useContext } from 'react';
import { GlobalState } from '../../contextAPI/Context';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col,Input,Label, FormGroup, } from 'reactstrap';
import axios from 'axios';
import { toastr } from 'react-redux-toastr';


const NewCorporateGroupModal = (props) => {
    const { newCorporateGroupModal, setNewCorporateGroupModal, token } = props;

    // CONTEXT VARIABLES 
    const { corporateListReload, setCorporateListReload, } = useContext(GlobalState);
    
    
    // LOCAL VARIABLES
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const [newCorporateGroupName, setNewCorporateGroupName] = useState("");
    const [newCorporateGroupAddress, setNewCorporateGroupAddress] = useState("");
    const [newCorporateGroupPhone, setNewCorporateGroupPhone] = useState("");
    const [addButtonStatus, setAddButtonStatus] = useState(true);


    
    const toggle = () => { setNewCorporateGroupModal(!newCorporateGroupModal); 
        
        setAddButtonStatus(true);
        setNewCorporateGroupName("")
        setNewCorporateGroupAddress("")
        setNewCorporateGroupPhone("")
    
    }

    

    const confirmation = () => { 
        if(!newCorporateGroupName){
            toastr.error("Name Field Is Required" , "Please enter the corporate group name !")
        }
        if(!newCorporateGroupPhone ) {
            toastr.error("Phonbe Number Field Is Required" , "Please enter the corporate group phone number !")
        }
        if(newCorporateGroupName && newCorporateGroupPhone ){
            updateCorporateGroupApiCall()
        }
    };


    const updateCorporateGroupApiCall = () => {
        
        
        axios.post(`${API_ENDPOINT}/external/create-corporate-group/`, {

            "corporate_name": newCorporateGroupName,
            "corporate_address":newCorporateGroupAddress ,
            "corporate_number":newCorporateGroupPhone

        }, {
            headers: {
                Authorization: token,
            }
        })
            .then(function (response) {
                // console.log(response);
                setNewCorporateGroupModal(!newCorporateGroupModal)
                setCorporateListReload(corporateListReload + 1)
                toastr.success("Successfully Added", "Healthcare Partner details successfully added")
                toggle();
            })
            .catch(function (error) {
                // console.log(error);
                toastr.error("Failed", "Please try again")
            });
    }

    
    const disableAddButton = () => {

        if (
            newCorporateGroupName &&
            newCorporateGroupPhone 
        ) {
            setAddButtonStatus(false)
        }
        else {
            setAddButtonStatus(true)
        }
    }


    useEffect(() => { disableAddButton() }, [newCorporateGroupName, newCorporateGroupPhone]) // eslint-disable-line

     return (
        <div>
            <Modal isOpen={newCorporateGroupModal} toggle={toggle}  size="md" >
                <ModalHeader tag="h4" cssModule={{'modal-title': 'w-100 text-center'}}  toggle={toggle}>Add New Healthcare Partner </ModalHeader>
                <ModalBody className="overflow-modal disable-scrollbars" >
                    <FormGroup>
                         <Row>
                             <Col>

                                 <Label for="name">Name <span className="required">*</span></Label>
                                 <Input
                                     id="name"
                                     placeholder="Enter the name"
                                     value={newCorporateGroupName}
                                     onChange={(e) => setNewCorporateGroupName(e.target.value)}
                                     minLength="1"
                                     maxLength="120"
                                 />
                                 <br></br>

                                 <Label for="phone">Telephone <span className="required">*</span> </Label>
                                 <Input
                                     id="phone"
                                     value={newCorporateGroupPhone}
                                     placeholder="Enter the telephone Number"
                                     type="number"
                                     onChange={(e) => {if (e.target.value.length <= 12) setNewCorporateGroupPhone(e.target.value)}}
                                     
                                 />
                                 <br></br>

                                 <Label for="address">Address </Label>
                                 <Input
                                     id="address"
                                     type="textarea"
                                     value={newCorporateGroupAddress}
                                     placeholder="Enter the address"
                                     onChange={(e) => setNewCorporateGroupAddress(e.target.value)}
                                     maxLength="500"
                                 />
                                 <br></br>

                                 
                             </Col>
                         </Row>
                    </FormGroup>
                 </ModalBody>
                <ModalFooter>
                    <Button 
                    className="yes-button button-width"  
                    onClick={confirmation}
                    disabled={addButtonStatus}
                    title={addButtonStatus ? "Fill the required fields" : ""}
                    >Add</Button>{' '}
                    <Button outline className="no-button button-width" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default NewCorporateGroupModal;