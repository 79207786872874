import React, { useState, useContext, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import moment from "moment";
import MomentConfig from "../../common/MomentConfig";
import { GlobalState } from "../../contextAPI/Context"
import "../tables/Tables.css"
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardHeader,
  CardBody,
  Container,
  Spinner,
  Button,
  Row,
  Col,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faEye,
  faMoneyBillAlt,
} from "@fortawesome/free-solid-svg-icons";

import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Hux } from "./../../hoc/Hux";
import DateRangeSelector from "../../newComponents/utilities/DateRangeSelector";
import CancelAppointmentModal from "../../newComponents/utilities/CancelAppointmentModal";
import ConfirmationModal from "../../newComponents/utilities/ConfirmationModal";
import ProcessRefundConfirmationModal from "../../newComponents/utilities/ProcessRefundConfirmationModal";
import { toastr } from "react-redux-toastr";
import getSymbolFromCurrency from "currency-symbol-map";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
var appointmentModal = false;
var recordCount = 0;
var providerID = "";
var couponName = "";
var todayInURL = "";
var chartStartDateInURL = "";
var chartEndDateInURL = "";

const ExportCSVTable = (props) => {
  const {
    data,
    appointmentID,
    actualRefundAmount,
    refundPercentage,
    reason,
    canceledBy,
    userConfirmation,
    token,
    setUserConfirmation,
    setModalState,
    setSpinner,
    showToastrSuccess, // eslint-disable-line
    setAppointmentID,
    setCanceledBy,
    setReason,
    setRefundPercentage,
    setActualRefundAmount,
    makeAPICall,
    showToastrWarning, // eslint-disable-line
    tableColumns,
    blankState,
    defaultSorted,
    currency,
  } = props;
  // LOCAL VARIABLES
  const [numberOfRecords, setNumberOfRecords] = useState(0);
  const [blankStateMessage, setBlankStateMessage] = useState(false);
  const [SpinnerEnable, setSpinnerEnable] = useState(false);

  const [Downloading, setDownloading] = useState(false);

  const { matching, setMatching,  refundRulesIds, setRefundRulesIds } = useContext(GlobalState); // eslint-disable-line
  

  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <div>
        <button className="btn btn-equipo-blue mt-2" onClick={handleClick}>
          Export
        </button>
      </div>
    );
  };

  const handleDataChange = ({ dataSize }) => {
    setNumberOfRecords(dataSize ? dataSize : 0);
  };

  const basicValidation = (cell) => {
    return cell ? cell : "N/A";
  };

  const dobFormatter = (cell) => {
    return cell ? moment(cell).format(MomentConfig.MOMENT_FORMAT_DATE) : "N/A";
  };

  const dateFormatter = (cell) => {
    return moment(cell)
      .utc(cell)
      .local()
      .format(MomentConfig.MOMENT_FORMAT_DATE);
  };

  const currencyValidation = (row) => {
    return `${getSymbolFromCurrency(row.currency)} ${
      row.refundAmount ? row.refundAmount : "0"
    }`;
  };

  const DownloadAttachment = (notetypedid, appointmentid) => {
    setDownloading(true);
    const appdata = {
      appointment_id: appointmentid,
      note_type: notetypedid,
      is_view:1
    };
    axios
      .post(API_ENDPOINT + "/user/view-appointment-notes/", appdata, {
        headers: {
          Authorization: token,
        },
        responseType: "blob",
      })
      .then((response) => {
        if (response.status === 200) {
          var fileDownload = require("js-file-download");
          fileDownload(
            response.data,
            response["headers"]["x-suggested-filename"] || "report.pdf"
          );
          toastr.success("Done", "Document downloaded successfully");
          setDownloading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setDownloading(false);
      });
  };

  const expandRow = {
    renderer: (row) => (
      <div className="appointment-table-expantion-area">
        {Downloading ? <Spinner color="success" /> : ""}
        <Row>
          <Col className="appointment-table-expanded">
            <p>{`Chief Complaint : ${basicValidation(
              row.chiefcomplaint
            )}`}</p>
          </Col>
          <Col className="appointment-table-expanded">
            <p>{`Appointment Booked : ${
              row.schStartTime && row.schEndTime
                ? `${moment(row.schStartTime)
                    .utc(row.schStartTime)
                    .local()
                    .format(MomentConfig.MOMENT_FORMAT_DATEANDTIME)} - ${moment(
                    row.schEndTime
                  )
                    .utc(row.schEndTime)
                    .local()
                    .format("h:mm A")}`
                : "N/A"
            }`}</p>
          </Col>
        </Row>
        <Row>
          <Col className="appointment-table-expanded">
            <p>{`Actual Appointment : ${
              row.appStartTime && row.appEndTime
                ? `${moment(row.appStartTime)
                    .utc(row.appStartTime)
                    .local()
                    .format(MomentConfig.MOMENT_FORMAT_DATEANDTIME)} - ${moment(
                    row.appEndTime
                  )
                    .utc(row.appEndTime)
                    .local()
                    .format("h:mm A")}`
                : "N/A"
            }`}</p>
          </Col>
          <Col className="appointment-table-expanded">
            <p>{`Payment Method : ${basicValidation(row.method)}`}</p>
          </Col>
        </Row>
        <Row>
          <Col className="appointment-table-expanded">
            <p>{`Currency : ${basicValidation(row.currency)}`}</p>
          </Col>
          <Col className="appointment-table-expanded">
            <p>{`Charge Id : ${basicValidation(row.chargeId)}`}</p>
          </Col>
        </Row>
        <Row>
          <Col className="appointment-table-expanded">
            <p>{`Transaction Date : ${dateFormatter(
              row.transactionDatetime
            )}`}</p>
          </Col>
          <Col className="appointment-table-expanded">
            <p>{`Transaction Number : ${basicValidation(
              row.transctionNumber
            )}`}</p>
          </Col>
        </Row>
        <Row>
          <Col className="appointment-table-expanded">
            <p>{`Patient DOB : ${dobFormatter(row.patient_dob)}`}</p>
          </Col>
          <Col className="appointment-table-expanded">
            <p>{`Patient Gender : ${basicValidation(row.patient_gender)}`}</p>
          </Col>
          <Col className="appointment-table-expanded">
            <p>{`Speciality : ${basicValidation(row.speciality)}`}</p>
          </Col>
          {row.is_SickNote === 1 ||
          row.is_ReferralNote === 1 ||
          row.is_Prescription === 1 ? (
            <Col className="appointment-table-expanded download-attachments">
              <p>
                {`Attatchments :`}{" "}
                {row.is_SickNote === 1 ? (
                  <Button
                    className="mr-2 "
                    onClick={() => DownloadAttachment(2, row.id)}
                  >
                    <FontAwesomeIcon icon={faDownload} />
                    Sick Notes
                  </Button>
                ) : null}
                {row.is_ReferralNote === 1 ? (
                  <Button
                    className="mr-2"
                    onClick={() => DownloadAttachment(3, row.id)}
                  >
                    <FontAwesomeIcon icon={faDownload} />
                    Referral Notes
                  </Button>
                ) : null}
                {row.is_Prescription === 1 ? (
                  <Button onClick={() => DownloadAttachment(5, row.id)}>
                    <FontAwesomeIcon icon={faDownload} />
                    Prescription
                  </Button>
                ) : null}
              </p>
            </Col>
          ) : null}
        </Row>
        {row.appStatus.toLowerCase() === "cancelled" ? (
          <Row>
            <Col className="appointment-table-expanded">
              <p>{`Cancelled By : ${basicValidation(row.cancelled_by)}`}</p>
            </Col>
            <Col className="appointment-table-expanded">
              <p>{`Reason For Cancel : ${basicValidation(
                row.reason_for_cancel
              )}`}</p>
            </Col>
          </Row>
        ) : (
          ""
        )}
        {row.appStatus.toLowerCase() === "cancelled" ? (
          <Row>
            <Col className="appointment-table-expanded">
              <p>{`Refund Id : ${basicValidation(row.refundID)}`}</p>
            </Col>
            <Col className="appointment-table-expanded">
              <p>{`Refund Amount : ${currencyValidation(row)}`}</p>
            </Col>
          </Row>
        ) : (
          ""
        )}
      </div>
    ),
  };

  // EFFECTS FOR SHOWING BLANK STATE MESSAGE
  useEffect(() => {
    setNumberOfRecords(data ? data.length : 0);
  }, [data]);
  useEffect(() => {
    setNumberOfRecords(recordCount ? recordCount : 0);
    setBlankStateMessage(!recordCount ? true : false);
  }, [recordCount]); // eslint-disable-line

  useEffect(() => {
    if (numberOfRecords) {
      setNumberOfRecords(numberOfRecords ? numberOfRecords : 0);
    }
  }, [numberOfRecords]);

  const OverlayLoader = () => (
    <div class="overlay">
      <div class="overlay__inner">
        <div class="overlay__content">
          <span class="spinner"></span>
        </div>
      </div>
    </div>
  );

  const cancelAppointmentAPI = () => {
    const params = matching ? {
      apt_id: appointmentID ? parseInt(appointmentID) : 0,
      amount: actualRefundAmount > 0 ? parseFloat(actualRefundAmount) : 0,
      createdby: 0,
      percent: refundPercentage ? parseFloat(refundPercentage) : 0,
      reason: reason ? reason.toString() : 0,
      cancelled_by: canceledBy ? parseInt(canceledBy) : 16,
      currency: currency ? currency : "",
      refundRuleId: refundRulesIds
    } : {
      apt_id: appointmentID ? parseInt(appointmentID) : 0,
      amount: actualRefundAmount > 0 ? parseFloat(actualRefundAmount) : 0,
      createdby: 0,
      percent: refundPercentage ? parseFloat(refundPercentage) : 0,
      reason: reason ? reason.toString() : 0,
      cancelled_by: canceledBy ? parseInt(canceledBy) : 16,
      currency: currency ? currency : "",
    };

    if (userConfirmation ) {
      setSpinner(true);
      setSpinnerEnable(true);
      axios
        .post(API_ENDPOINT + "/user/appointment-cancel/", params, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          setUserConfirmation(false);
          setModalState(false);
          setSpinner(false);
          setSpinnerEnable(false);
          toastr.success(response.data.message);
          /* showToastrSuccess(response.data.message); */
          // RESTING MODAL VARIABLES
          setAppointmentID("");
          setCanceledBy("");
          setReason("");
          setRefundPercentage("");
          setActualRefundAmount("");
          setUserConfirmation("");
          // API CALL TO GET NEW DATA LIST
          makeAPICall();
        })
        .catch((error) => {
          setSpinner(false);
          setSpinnerEnable(false);
          /* showToastrWarning(error.response.data.message); */
          toastr.error(error.response.data.message)
        });
        
    }
  };

  //EFFECT TO HANDLE APPOINTMENT CANCELLATION
  useEffect(() => {
    if (userConfirmation) cancelAppointmentAPI();
  }, [userConfirmation]); // eslint-disable-line

  return (
    <div className="appo-list-table">
      <Card>
        <ToolkitProvider
          keyField="id"
          data={props.appointments}
          columns={tableColumns}
          search
          exportCSV={{
            fileName: `equipo-Appointments-Export-${moment().format(
              MomentConfig.MOMENT_FORMAT_DATEANDTIME
            )}.csv`,
          }}
        >
          {(props) => (
            <div>
              <CardHeader>
                <div className="date-filter-component">
                  <Row>
                    <Col>
                      <DateRangeSelector />
                    </Col>
                  </Row>
                  <div className="separator" />
                  <Row>
                    <Col sm="2">
                      <div className="number-of-records">
                        <p>
                          Number of records: <strong>{numberOfRecords}</strong>
                        </p>
                      </div>
                    </Col>
                    <Col>
                      <SearchBar
                        {...props.searchProps}
                        className="search-box"
                      />
                      <ClearSearchButton
                        {...props.searchProps}
                        className="clear-search-box"
                      />
                    </Col>
                    <Col sm="1">
                      <div className="export-button">
                        <MyExportCSV {...props.csvProps} />
                      </div>
                    </Col>
                  </Row>
                  <div className="separator" />
                </div>
              </CardHeader>
              <CardBody className="appointment-list">
                {blankStateMessage && blankState}
                {!blankStateMessage && (
                  <div className="appointment-list-table">
                    <BootstrapTable
                      expandRow={expandRow}
                      {...props.baseProps}
                      searchable
                      bootstrap4
                      hover
                      bordered={false}
                      defaultSorted={defaultSorted}
                      onDataSizeChange={handleDataChange}
                      pagination={paginationFactory({
                        sizePerPage: 50,
                        sizePerPageList: [50, 100, 150, 200],
                      })}
                    />
                  </div>
                )}
              </CardBody>
            </div>
          )}
        </ToolkitProvider>
        {SpinnerEnable ? <OverlayLoader  className="overlay-z-index" /> : null}
      </Card>
      
    </div>
  );
};

const { SearchBar, ClearSearchButton } = Search;
const Tables = (props) => {
  // CONTEXT VARIABLES
  const {
    token,
    setToken,
    setProviderID,
    setCouponNameSearch,
    appointmentStatus,
    setAppointmentDropDown,
    setAppoFromDate,
    setAppoToDate,
    doctorName,
    groupId,
    couponCode,
    appStatusID,
    setDoctorDropDown,
    setGroupDropDown,
    setCouponDropDown,
    appoFromDate,
    appoToDate,
    setSpinner,
    createdByDropDown,
    setCreatedByDropDown,
    appoSearch,
    today,
    setToday,
    setChartFromDate,
    setChartToDate,
    matching, setMatching,
    setRefundRulesIds,
    docterSpeciality
  } = useContext(GlobalState);

  // LOCAL VARIABLES
  const [appointments, setAppointments] = useState("");
  const [couponList, setCouponList] = useState("");
  const [groupList, setGroupList] = useState("");
  const [physicianList, setPhysicianList] = useState("");
  const [appointmentStatusList, setAppointmentStatusList] = useState("");
  const [cancelledByList, setCancelledByList] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalState, setModalState] = useState(false);
  const [inProgressModal, setInProgressModal] = useState(false);
  const [inProgressConfirmation, setInProgressConfirmation] = useState(false);
  const [rowData, setRowData] = useState("");
  const [processRefundModal, setProcessRefundModal] = useState(false);
  const [processRefundConfirmation, setProcessRefundConfirmation] = useState(
    "blank-state"
  );
  

  // CANCELLATION MODAL VARIABLES
  const [appointmentID, setAppointmentID] = useState("");
  const [canceledBy, setCanceledBy] = useState("");
  const [reason, setReason] = useState("");
  const [refundPercentage, setRefundPercentage] = useState("");
  const [actualRefundAmount, setActualRefundAmount] = useState("");
  const [userConfirmation, setUserConfirmation] = useState(false);
  const [currency, setCurrency] = useState("");

  // TOASTER FUNCTIONS
  function showToastrSuccess(message, subText) {
    const options = {
      timeOut: parseInt(2000),
      showCloseButton: true,
      progressBar: false,
      position: "bottom-left",
    };

    const toastrInstance = toastr.success;

    toastrInstance(message, subText, options);
  }

  function showToastrWarning(message, subText) {
    const options = {
      timeOut: parseInt(5000),
      showCloseButton: true,
      progressBar: false,
      position: "bottom-left",
    };

    const toastrInstance = toastr.error;

    toastrInstance(message, subText, options);
  }

  const blankState = (
    <Card className="blank-state">
      <CardBody>
        <h3>No records available</h3>
      </CardBody>
    </Card>
  );

  const inProgressCheck = (row) => {
    var appStatus = row.appStatus.toLowerCase();
    if (appStatus === "in progress") {
      setInProgressModal(true);
    } else {
      setModalState(true);
    }
  };

  const actionsFormatter = (cell, row) => {
    var appStatus = row.appStatus ? row.appStatus.toLowerCase() : ""
    var transactionStatus = row.transactionStatus ? row.transactionStatus.toLowerCase() : ""
    // var status = appStatus === "cancelled" || appStatus === "refunded" || appStatus === "completed" ? true : false;
    var status = appStatus === "refunded" || transactionStatus === "refunded" || appStatus === "cancelled" ? true : false;

    const saveRowData = (row) => {
      setRowData(row);
    };
    return (
      <div className="action-button-container">
        <Button className="eye-button" outline title="View more">
          <FontAwesomeIcon
            icon={faEye}
            fixedWidth
            className="align-middle"
            title="View More"
          />
        </Button>
        <Button
          className="money-button"
          outline
          onClick={() => {
            inProgressCheck(row);
            saveRowData(row);
          }}
          style={status ? { visibility: "hidden" } : { visibility: "visible" }}
          color="primary"
          title="Cancel Appointment"
        >
          <FontAwesomeIcon
            icon={faMoneyBillAlt}
            fixedWidth
            className="align-middle"
            title="Cancel Appointment"
          />
        </Button>
      </div>
    );
  };

  // TABLE FORMATTERS
  const dateFormatter = (cell) => {
    return moment(cell)
      .utc(cell)
      .local()
      .format(MomentConfig.MOMENT_FORMAT_DATE);
  };

  const dobFormatter = (cell) => {
    return cell ? moment(cell).format(MomentConfig.MOMENT_FORMAT_DATE) : "N/A";
  };

  const slotFormatter = (cell) => {
    return moment(cell).utc(cell).local().format("h:mm A");
  };

  const basicValidation = (cell) => {
    return cell ? cell : "N/A";
  };

  const currencyValidation = (cell, row) => {
    return `${getSymbolFromCurrency(row.currency)} ${
      cell ? cell.toFixed(2) : "0"
    }`;
  };

  const csvCurrencyValidation = (cell, row) => {
    return `${cell ? cell.toFixed(2) : "0"}`;
  };

  // CSV FORMATTERS
  const transactionDatetime = (cell, row) => {
    return `${
      row.transactionDatetime
        ? moment(row.transactionDatetime)
            .utc(row.transactionDatetime)
            .local()
            .format(MomentConfig.MOMENT_FORMAT_DATEANDTIME)
        : "N/A"
    }`;
  };

  const actualAppointmentTime = (cell, row) => {
    return `${
      row.appStartTime && row.appEndTime
        ? `${moment(row.appStartTime)
            .utc(row.appStartTime)
            .local()
            .format(MomentConfig.MOMENT_FORMAT_DATEANDTIME)} - ${moment(
            row.appEndTime
          )
            .utc(row.appEndTime)
            .local()
            .format("h:mm A")}`
        : "N/A"
    }`;
  };

  const appointmentBooked = (cell, row) => {
    return `${
      row.schStartTime && row.schEndTime
        ? `${moment(row.schStartTime)
            .utc(row.schStartTime)
            .local()
            .format(MomentConfig.MOMENT_FORMAT_DATEANDTIME)} - ${moment(
            row.schEndTime
          )
            .utc(row.schEndTime)
            .local()
            .format("h:mm A")}`
        : "N/A"
    }`;
  };

  const tableColumns = [
    {
      dataField: "schStartTime",
      text: "Appt Date",
      formatter: dateFormatter,
      csvFormatter: dateFormatter,
      sort: true,
    },
    {
      dataField: "schStartTime",
      text: "Appt Slot",
      formatter: slotFormatter,
      csvFormatter: slotFormatter,
      sort: true,
    },
    {
      dataField: "patient_name",
      text: "Patient",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "patient_dob",
      text: "DOB",
      sort: true,
      hidden: true,
      formatter: dobFormatter,
      csvFormatter: dobFormatter,
    },
    {
      dataField: "patient_gender",
      text: "Gender",
      sort: true,
      hidden: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "physician_name",
      text: "Care Provider",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "speciality",
      text: "Speciality",
      searchable: true,
      hidden: true,
      csvFormatter: basicValidation,
    },
    {
      dataField: "GroupName",
      text: "Group",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "appStatus",
      text: "Appt Status",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "transactionStatus",
      text: "Payment Status",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "amountToBePaid",
      text: "To Be Paid",
      sort: true,
      formatter: currencyValidation,
      csvFormatter: csvCurrencyValidation,
    },
    {
      dataField: "amountPaid",
      text: "Paid",
      sort: true,
      formatter: currencyValidation,
      csvFormatter: csvCurrencyValidation,
    },
    {
      dataField: "couponCode",
      text: "Coupon",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      text: "Actions",
      csvExport: false,
      formatter: actionsFormatter,
    },
    {
      dataField: "schStartTime",
      text: "Appointment Booked",
      searchable: false,
      hidden: true,
      csvFormatter: appointmentBooked,
    },
    {
      dataField: "appStartTime",
      text: "Actual Appointment",
      searchable: false,
      hidden: true,
      csvFormatter: actualAppointmentTime,
    },
    {
      dataField: "method",
      text: "Payment Method",
      searchable: true,
      hidden: true,
      csvFormatter: basicValidation,
    },
    {
      dataField: "currency",
      text: "Currency",
      searchable: true,
      hidden: true,
      csvFormatter: basicValidation,
    },
    {
      dataField: "chargeId",
      text: "Charge Id",
      searchable: true,
      hidden: true,
      csvFormatter: basicValidation,
    },
    {
      dataField: "transactionDatetime",
      text: "Transaction Date",
      searchable: false,
      hidden: true,
      csvFormatter: transactionDatetime,
    },
    {
      dataField: "transctionNumber",
      text: "Transaction Number",
      searchable: true,
      hidden: true,
      csvFormatter: basicValidation,
    },
    {
      dataField: "cancelled_by",
      text: "Cancelled By",
      searchable: true,
      hidden: true,
      csvFormatter: basicValidation,
    },
    {
      dataField: "reason_for_cancel",
      text: "Reason For Cancel",
      searchable: true,
      hidden: true,
      csvFormatter: basicValidation,
    },
    {
      dataField: "refundID",
      text: "Refund ID",
      searchable: true,
      hidden: true,
      csvFormatter: basicValidation,
    },
    {
      dataField: "refundAmount",
      text: "Refund Amount",
      searchable: true,
      hidden: true,
      csvFormatter: basicValidation,
    },
    {
      dataField: "chiefcomplaint",
      text: "Chief Complaint",
      searchable: true,
      hidden: true,
      csvFormatter: basicValidation,
    },
   
  ];

  const defaultSorted = [
    {
      dataField: "schStartTime",
      order: "desc",
    },
  ];

  const makeAPICall = () => {
    setSpinner(true);
    setLoading(true);
    setToken(props.authtoken ? props.authtoken : "");
    let fromDate = "";
    let toDate = "";

    // WHEN COUPON CODE NAME IS PRESENT URL PARAMETERS
    if (couponName) fromDate = "";
    if (couponName) toDate = "";
    // WHEN chartDateInURL IS PRESENT IN  URL PARAMETERS
    if(chartStartDateInURL) 
      fromDate = chartStartDateInURL;
    if(chartEndDateInURL) 
      toDate = chartEndDateInURL;

    // WHEN todayInURL IS PRESENT IN URL PARAMETERS
    if (todayInURL)
      fromDate = moment().startOf("day").utc().format("YYYY-MM-DD H:mm:ss");
    if (todayInURL)
      toDate = moment().endOf("day").utc().format("YYYY-MM-DD H:mm:ss");

    // DEFAULT LOAD DATE RANGE
    if (!couponName && !todayInURL && chartStartDateInURL === "Invalid date" ) {
      fromDate =
        appoFromDate && appoFromDate !== "Invalid date"    // if (!chartFromDate && !chartToDate)getChartDate();
          ? moment(appoFromDate).utc().format("YYYY-MM-DD H:mm:ss")
          : moment()
          .subtract(30, "days")
          .startOf("day")
          .utc()
          .format("YYYY-MM-DD H:mm:ss");
}

    if (!couponName && !todayInURL && chartEndDateInURL === "Invalid date" ) {
      toDate =
        appoToDate && appoToDate !== "Invalid date"
          ? moment(appoToDate).endOf("day").utc().format("YYYY-MM-DD H:mm:ss")
          : moment().endOf("day").utc().format("YYYY-MM-DD H:mm:ss");
    }

    // CONTEXT VARIABLE SETTERS
    setAppoFromDate(fromDate);
    setAppoToDate(toDate);
    axios
      .post(
        API_ENDPOINT + "/user/appointment-details/",
        {
          from_date: fromDate,
          to_date: toDate,
          coupon_code: couponCode ? couponCode.toString() : couponName,
          appStatusID: appStatusID ? appStatusID.toString() : "",
          group_id: groupId ? groupId.toString() : "",
          physician_name: doctorName ? doctorName.toString() : "",
          provider_id: providerID ? providerID.toString() : "",
          appointmentStatusID: appointmentStatus
            ? appointmentStatus.toString()
            : "",
          specialityId: docterSpeciality ? docterSpeciality.toString() : "",
        },
        {
          headers: {
            Authorization: props.authtoken,
          },
        }
      )
      .then((res) => {
        setAppointments(res.data);
        recordCount = res.data.length;
        setSpinner(false);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setSpinner(false);
        setLoading(false);
      });
  };

  const getCouponList = () => {
    const fromData = {
      type: "",
      couponCode: "",
      status: "",
      patient_name: "",
      corp_grp_id: "",
      provider_name: "",
    };

    axios
      .post(API_ENDPOINT + "/external/coupon-list/", fromData, {
        headers: {
          Authorization: props.authtoken,
        },
      })
      .then((res) => {
        setCouponList(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAppointmentStatusList = () => {
    axios
      .get(API_ENDPOINT + "/external/get-apt-status/", {
        headers: {
          Authorization: props.authtoken,
        },
      })
      .then((res) => {
        setAppointmentStatusList(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCancelledByList = () => {
    axios
      .get(API_ENDPOINT + "/external/get-cancelledby-status/", {
        headers: {
          Authorization: props.authtoken,
        },
      })
      .then((res) => {
        setCancelledByList(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getGroupList = () => {
    axios
      .get(API_ENDPOINT + "/external/corporate-group-list/", {
        headers: {
          Authorization: props.authtoken,
        },
      })
      .then((res) => {
        setGroupList(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getPhysicianList = () => {
    axios
      .get(API_ENDPOINT + "/user/physician-list/", {
        headers: {
          Authorization: props.authtoken,
        },
      })
      .then((res) => {
        setPhysicianList(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const createCouponCodeOption = () => {
    var array = couponList;
    const options = array.map((item) => {
      return { key: item.id, value: item.couponCode, label: item.couponCode };
    });
    setCouponDropDown(options);
  };

  const createGroupOption = () => {
    var array = groupList;
    const options = array.map((item) => {
      return { key: item.id, value: item.id, label: item.name };
    });
    setGroupDropDown(options);
  };

  const createDoctorOption = () => {
    var array = physicianList;
    const options = array.map((item) => {
      return {
        key: item.id,
        id: item.id,
        value: item.full_name,
        label: item.full_name,
      };
    });
    setDoctorDropDown(options);
  };

  const createAppointmentOption = () => {
    var array = appointmentStatusList;
    const options = array.map((item) => {
      return { key: item.id, value: item.id, label: item.displayName };
    });
    setAppointmentDropDown(options);
  };

  const createCancelledByOption = () => {
    var array = cancelledByList;
    const options = array.map((item) => {
      return (
        <option key={item.id} value={item.id}>
          {item.displayName}
        </option>
      );
    });
    setCreatedByDropDown(options);
  };

  // GET VALUES FROM URL
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  const getProviderId = () => {
    providerID = query.get("doc");
  };
  const getCouponName = () => {
    couponName = query.get("code");
  };
  const getDate = () => {
    todayInURL = query.get("appdate");
    setToday(todayInURL);
  };
  
  const getChartDate = () => {
    let startDate = atob(query.get("startDate"));
    let endDate = atob(query.get("endDate"));
    let apiStartDate = moment(startDate).format("YYYY-MM-DD H:mm:ss");
    let apiEndDate = moment(endDate).utc().format("YYYY-MM-DD H:mm:ss");
    chartStartDateInURL = apiStartDate
    chartEndDateInURL = apiEndDate
    setChartFromDate(chartStartDateInURL)
    setChartToDate(chartEndDateInURL)
  };


  // EFFECT FOR GETTING URL PARAMETERS
  useEffect(() => {
    getProviderId();
    getCouponName();
    getChartDate();
    if (!today) getDate();
  });

  // ON LOAD API CALLS TO GET DATA FOR DROPDOWN FILTERS
  useEffect(() => {
    getCouponList();
  }, []); // eslint-disable-line
  useEffect(() => {
    getGroupList();
  }, []); // eslint-disable-line
  useEffect(() => {
    getPhysicianList();
  }, []); // eslint-disable-line
  useEffect(() => {
    getAppointmentStatusList();
  }, []); // eslint-disable-line
  useEffect(() => {
    getCancelledByList();
  }, []); // eslint-disable-line
  useEffect(() => {
    if (couponList.length > 0) createCouponCodeOption();
  }, [couponList]); // eslint-disable-line
  useEffect(() => {
    if (groupList.length > 0) createGroupOption();
  }, [groupList]); // eslint-disable-line
  useEffect(() => {
    if (physicianList.length > 0) createDoctorOption();
  }, [physicianList]); // eslint-disable-line
  useEffect(() => {
    if (appointmentStatusList.length > 0) createAppointmentOption();
  }, [appointmentStatusList]); // eslint-disable-line
  useEffect(() => {
    if (cancelledByList.length > 0) createCancelledByOption();
  }, [cancelledByList]); // eslint-disable-line

  // EFFECT FOR CANCELLING AN APPOINTMENT IN PROGRESS
  useEffect(() => {
    if (inProgressConfirmation) setModalState(true);
  }, [inProgressConfirmation]);

  // API CALL INCASE OF REDIRECTION FROM DOCTOR, COUPON, DASHBOARD PAGES
  useEffect(() => {
    if (!loading && chartStartDateInURL &&  chartEndDateInURL ) {
      makeAPICall();
    }
    // eslint-disable-next-line
  }, [
    appoFromDate,
    appoToDate,
    appStatusID,
    couponCode,
    groupId,
    doctorName,
    chartStartDateInURL,
    chartEndDateInURL,
    appoSearch,
  ]);
  useEffect(() => {
    if (!loading && providerID) {
      setProviderID(providerID);
      makeAPICall();
    }
    // eslint-disable-next-line
  }, [
    appoFromDate,
    appoToDate,
    appStatusID,
    couponCode,
    groupId,
    doctorName,
    providerID,
    appoSearch,
  ]);
  useEffect(() => {
    if (!loading && couponName) {
      setCouponNameSearch(couponName);
      makeAPICall();
    }
    // eslint-disable-next-line
  }, [
    appoFromDate,
    appoToDate,
    appStatusID,
    couponCode,
    groupId,
    doctorName,
    couponName,
    appoSearch,
  ]);
  useEffect(() => {
    if (!loading && todayInURL) {
      makeAPICall();
    }
    // eslint-disable-next-line
  }, [
    appoFromDate,
    appoToDate,
    appStatusID,
    couponCode,
    groupId,
    doctorName,
    todayInURL,
    appoSearch,
  ]);

  // EFFECT TO DETECT NEW SEARCH FROM FILTER AND CLEAR todayInURL VARIABLE
  useEffect(() => {
    if (today === "clear") todayInURL = "";
  }, [today]);

  /* useEffect(() => {
    if (chartFromDate === "clear" && chartToDate === "clear") chartStartDateInURL = "" , chartEndDateInURL = "";
  }, [chartFromDate,chartToDate]); */

  // DEFAULT API CALL
  useEffect(() => {
    if (!loading && !providerID && !couponName && !todayInURL && !chartStartDateInURL && !chartEndDateInURL ) makeAPICall();
    // eslint-disable-next-line
  }, [
    appoFromDate,
    appoToDate,
    appStatusID,
    couponCode,
    groupId,
    doctorName,
    appoSearch,
    providerID,
    couponName,
    todayInURL,
    chartStartDateInURL,
    chartEndDateInURL,
  ]);

  return (
    <Hux>
      {loading && <Spinner />}
      <Container fluid>
        <Header>
          <HeaderTitle>Appointments</HeaderTitle>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/dashboard">Dashboard</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Appointments</BreadcrumbItem>
          </Breadcrumb>
        </Header>

        <ProcessRefundConfirmationModal
          processRefundModal={processRefundModal}
          setProcessRefundModal={setProcessRefundModal}
          setProcessRefundConfirmation={setProcessRefundConfirmation}
        />

        <ConfirmationModal
          inProgressModal={inProgressModal}
          setInProgressModal={setInProgressModal}
          setInProgressConfirmation={setInProgressConfirmation}
        />

        <CancelAppointmentModal
          appointments={appointments}
          rowData={rowData}
          appointmentModal={appointmentModal}
          modalState={modalState}
          setModalState={setModalState}
          userConfirmation={userConfirmation}
          setUserConfirmation={setUserConfirmation}
          setCanceledBy={setCanceledBy}
          setReason={setReason}
          setRefundPercentage={setRefundPercentage}
          setActualRefundAmount={setActualRefundAmount}
          setAppointmentID={setAppointmentID}
          appointmentID={appointmentID}
          createdByDropDown={createdByDropDown}
          setCurrency={setCurrency}
          setInProgressConfirmation={setInProgressConfirmation}
          setProcessRefundModal={setProcessRefundModal}
          processRefundConfirmation={processRefundConfirmation}
          setProcessRefundConfirmation={setProcessRefundConfirmation}
          matching={matching}
          setMatching={setMatching}
          setRefundRulesIds={setRefundRulesIds}
        />

        {appointments && (
          <ExportCSVTable
            appointments={appointments}
            appointmentID={appointmentID}
            actualRefundAmount={actualRefundAmount}
            refundPercentage={refundPercentage}
            reason={reason}
            canceledBy={canceledBy}
            userConfirmation={userConfirmation}
            token={token}
            setUserConfirmation={setUserConfirmation}
            setModalState={setModalState}
            setSpinner={setSpinner}
            showToastrSuccess={showToastrSuccess}
            setAppointmentID={setAppointmentID}
            setCanceledBy={setCanceledBy}
            setReason={setReason}
            setRefundPercentage={setRefundPercentage}
            setActualRefundAmount={setActualRefundAmount}
            makeAPICall={makeAPICall}
            showToastrWarning={showToastrWarning}
            tableColumns={tableColumns}
            blankState={blankState}
            defaultSorted={defaultSorted}
            currency={currency}
          />
        )}
      </Container>
    </Hux>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
    authtoken: state.auth.token,
  };
};

export default connect(mapStateToProps, null)(Tables);
