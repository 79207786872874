import React from 'react';
import Axios from "axios";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { toastr } from 'react-redux-toastr';

const TwoFAStatusChangeModal = (props) => {
    const { twoFAmodalState, setTwoFAModalState, patientID, token, twoFA,setPatientListReload,patientListReload } = props;

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

    const toggle = () => setTwoFAModalState(!twoFAmodalState);

    const confirmation = () => {

        let new2FA = "";
        if (twoFA === 0) {
            new2FA = 1
        } else {
            new2FA = 0
        }

        const params =
        {
            "patient_id": Number(patientID),
            "is2FAEnabled": Number(new2FA)
        }

        Axios.post(API_ENDPOINT + "/user/patient-status-update/", params, {
            headers: {
                Authorization: token,
            }
        }).then((res) => {
            setPatientListReload(patientListReload + 1)
            setTwoFAModalState(!twoFAmodalState);
            toastr.success("Updated Successfully","Two-factor authentication status updated successfully")
        })
        .catch(function (error) {
            toastr.error("Update Failed", "Please try again")
        });

    };

    return (
        <div>
            <Button color="danger" onClick={toggle}></Button>
            <Modal isOpen={twoFAmodalState} toggle={toggle}>
                <ModalHeader tag="h4" cssModule={{'modal-title': 'w-100 text-center'}}  toggle={toggle}>Change Status</ModalHeader>
                <ModalBody>
                   {twoFA === 1 ? "Are you sure you want to disable two-factor authentication" :  
                   "Are you sure you want to enable two-factor authentication"
                   }
                </ModalBody>
                <ModalFooter>
                    <Button className="yes-button button-width" onClick={confirmation}>Yes</Button>{' '}
                    <Button outline className="no-button button-width" onClick={toggle}>No</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default TwoFAStatusChangeModal;