import React, { useState, useEffect, useContext } from 'react';
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import { Col, Label, Row, Button } from "reactstrap";
import './DateRangeSelector.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import Select from "react-select";
import axios from "axios";
import { GlobalState } from '../../contextAPI/Context';

const PatientFilters = () => {
    // CONTEXT VARIABLES 
    const {
        token,
        spinner,
        setPrescriptionFromDate,
        setPrescriptionToDate,
        setPrescriptionPatientName,
        setPrescriptionPatientEmail,
        setPrescriptionStatus,
        setPrescriptionPharmacyId,
        setPrescriptionProviderId,
        prescriptionReload,
        setPrescriptionReload,
        pharmacyLoading,
        setPharmacyLoading,
        setPharmacyDropDownData,
    } = useContext(GlobalState)

    // LOCAL VARIABLES 
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const [pharmacyName, setPharmacyName] = useState("")
    const [doctorName, setDoctorName] = useState("")
    const [patientName, setPatientName] = useState("")
    const [patientEmail, setPatientEmail] = useState("")
    const [fromDate, setFromDate] = useState(moment().subtract(14, 'days').toDate())
    const [toDate, setToDate] = useState(moment().toDate())
    const [physicianList, setPhysicianList] = useState("");
    const [prescriptionStatusList, setPrescriptionStatusList] = useState("");
    const [status, setStatus] = useState("");
    const [statusDropDown, setStatusDropDown] = useState("")
    const [doctorDropDown, setDoctorDropDown] = useState("")
    const [optionsLoading, setOptionsLoading] = useState(true)
    const [pharmacyList, setPharmacyList] = useState("");
    const [pharmacyDropDown, setPharmacyDropDown] = useState("")
    const [callingPharmacyAPI, setCallingPharmacyAPI] = useState(false)

    const fromDateFormatter = (date) => {
        setFromDate(moment(date).toDate())
    }

    const toDateFormatter = (date) => {
        setToDate(moment(date).toDate())
    }

    const balanceDateRanges = () => {
        const from = fromDate.valueOf()
        const to = toDate.valueOf()
        if ((to - from) < 0) setFromDate(toDate)
    }

    const getPharmacyList = () => {
        setCallingPharmacyAPI(true)
        axios
            .get(API_ENDPOINT + "/external/get-active-pharmacies/", {
                headers: {
                    Authorization: token,
                },
            })
            .then((res) => {
                setPharmacyList(res.data);
                setCallingPharmacyAPI(false);
            })
            .catch((error) => {
                console.log(error);
                setCallingPharmacyAPI(false);
            });
    };

    const getPhysicianList = () => {
        axios
            .get(API_ENDPOINT + "/user/physician-list/", {
                headers: {
                    Authorization: token,
                },
            })
            .then((res) => {
                setPhysicianList(res.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const getPrescriptionStatus = () => {
        axios
            .get(API_ENDPOINT + "/external/prescription-workflow-status/", {
                headers: {
                    Authorization: token,
                },
            })
            .then((res) => {
                setPrescriptionStatusList(res.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const createDoctorOption = () => {
        var array = physicianList;
        const options = array.map((item) => {
            return {
                value: item.id,
                label: item.full_name,
            };
        });
        setDoctorDropDown(options);
        setOptionsLoading(false);
    };

    const createStatusOption = () => {
        var array = prescriptionStatusList;
        const options = array.map((item) => {
            return {
                value: item.id,
                label: item.displayName,
            };
        });
        setStatusDropDown(options);
        setOptionsLoading(false);
    };

    const createPharmacyOption = () => {
        var array = pharmacyList;
        const options = array.map((item) => {
            return {
                value: item.id,
                label: item.name,
                address: item.address,
                city: item.city,
                state : item.state,
                country_name : item.country_name,
                zipcode : item.zipcode
            };
        });
        setPharmacyDropDown(options);
        setPharmacyDropDownData(options);
        setPharmacyLoading(false);
    };

    const formatOptionLabel = ({ label, address, city, state, country_name, zipcode }, { context }) => {
        if (context === "value") {
            return <div>{label}</div>;
        } else if (context === "menu") {
            return (
                <div style={{ display: "flex", flexDirection: "column" , color: "#1b3b44"}}>
                    <div>{label}</div>
                    <div style={{ marginLeft: "10px", color: "grey" }}>
                    {address ? `${address}` : "N/A"}{city ? `, ${city}` : ""}{state ? `, ${state}` : ""}{country_name ? `, ${country_name}` : ""}{zipcode ? `, ${zipcode}` : ""}
                    </div>
                </div>
            );
        }
    };

    const runSearch = () => {
        let localFromDate = moment(fromDate).startOf('day')
        let utcFromDate = moment.utc(localFromDate)
        let localToDate = moment(toDate).endOf('day')
        let utcToDate = moment.utc(localToDate)
        setPrescriptionFromDate(moment.utc(utcFromDate).format('YYYY-MM-DD HH:mm'))
        setPrescriptionToDate(moment.utc(utcToDate).format('YYYY-MM-DD HH:mm'))
        setPrescriptionPatientName(patientName)
        setPrescriptionPatientEmail(patientEmail)
        setPrescriptionStatus(status.value)
        setPrescriptionPharmacyId(pharmacyName.value)
        setPrescriptionProviderId(doctorName.value)
        setPrescriptionReload(prescriptionReload + 1)
    }

    const resetFields = () => {
        setPharmacyName("")
        setPatientName("")
        setPatientEmail("")
        setDoctorName("")
        setStatus("")
        setFromDate(moment().subtract(14, 'days').toDate())
        setToDate(moment().toDate())
        setPrescriptionFromDate("")
        setPrescriptionToDate("")
        setPrescriptionPatientName("")
        setPrescriptionPatientEmail("")
        setPrescriptionStatus("")
        setPrescriptionPharmacyId("")
        setPrescriptionProviderId("")
        setPrescriptionReload(0)
    }

    useEffect(() => { resetFields() }, []); // eslint-disable-line
    useEffect(() => { balanceDateRanges() }, [fromDate, toDate]) // eslint-disable-line
    useEffect(() => { if (token && !physicianList) getPhysicianList(); }, [physicianList, token]); // eslint-disable-line
    useEffect(() => { if (token && !pharmacyList && !callingPharmacyAPI) getPharmacyList(); }, [physicianList, token]); // eslint-disable-line
    useEffect(() => { if (token && !status) getPrescriptionStatus(); }, [status, token]); // eslint-disable-line
    useEffect(() => { if (physicianList) createDoctorOption() }, [physicianList]); // eslint-disable-line
    useEffect(() => { if (pharmacyList) createPharmacyOption() }, [pharmacyList]); // eslint-disable-line
    useEffect(() => { if (prescriptionStatusList) createStatusOption() }, [prescriptionStatusList]); // eslint-disable-line
   
    return (
        <AvForm>
            <AvGroup>
                <Row>
                    <Col sm="2">
                        <Label>Appt From Date</Label>
                        <div className="datepicker-wrapper">
                            <DatePicker
                                dateFormat="dd MMM YYY"
                                selected={fromDate}
                                maxDate={moment().toDate()}
                                onChange={(date) => { fromDateFormatter(date) }}
                                todayButton="Today"
                            />
                        </div>
                    </Col>
                    <Col sm="2">
                        <Label>Appt To Date</Label>
                        <div className="datepicker-wrapper">
                            <DatePicker
                                dateFormat="dd MMM YYY"
                                selected={toDate}
                                maxDate={moment().toDate()}
                                onChange={(date) => { toDateFormatter(date) }}
                                todayButton="Today"
                            />
                        </div>
                    </Col>
                    <Col sm="2">
                        <Label>Care Provider Name</Label>
                        <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            onChange={(event) => { setDoctorName(event) }}
                            placeholder="All"
                            isSearchable
                            isLoading={optionsLoading}
                            value={doctorName}
                            options={doctorDropDown}
                        />
                    </Col>
                    <Col sm="2">
                        <Label>Patient Name</Label>
                        <AvField
                            type="text"
                            name="name"
                            placeholder="All"
                            value={patientName}
                            validate={{
                                maxLength: { value: 120 }
                            }}
                            onChange={(e) => { setPatientName(e.target.value) }}
                        />
                    </Col>
                    <Col sm="2">
                        <Label>Patient Email</Label>
                        <AvField
                            type="text"
                            name="email"
                            placeholder="All"
                            value={patientEmail}
                            validate={{
                                maxLength: { value: 254 }
                            }}
                            onChange={(e) => { setPatientEmail(e.target.value) }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col sm="4" className="second-row-filters" >
                        <Label>Pharmacy Name</Label>
                        <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            onChange={(event) => { setPharmacyName(event) }}
                            placeholder="All"
                            isSearchable
                            isLoading={pharmacyLoading}
                            value={pharmacyName}
                            options={pharmacyDropDown}
                            formatOptionLabel={formatOptionLabel}
                            theme={theme => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary: '#dcf9ee',
                                },
                              })}
                        />
                    </Col>
                    <Col sm="2" className="second-row-filters" >
                        <Label>Prescription Status</Label>
                        <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            onChange={(event) => { setStatus(event)}}
                            placeholder="All"
                            isSearchable
                            value={status}
                            options={statusDropDown}
                        />
                    </Col>
                    <Col>
                        <Button
                            type="submit"
                            className="go-buttons"
                            disabled={spinner ? true : false}
                            onClick={() => { runSearch() }}
                        >Go</Button>
                        <Button
                            type="reset"
                            className="reset-buttons"
                            disabled={spinner ? true : false}
                            onClick={() => { resetFields() }}
                        >Reset</Button>
                    </Col>
                </Row>
            </AvGroup>
        </AvForm>
    )
}

export default PatientFilters
