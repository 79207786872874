import React, { useState, useEffect, useContext } from "react";
import { Card, CardHeader, CardBody, Row, Col, Button } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import moment from 'moment';
import MomentConfig from '../../common/MomentConfig';
import { GlobalState } from '../../contextAPI/Context';
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import NewRefundConfigurationModal from '../../newComponents/utilities/NewRefundConfigurationModal';


const { SearchBar, ClearSearchButton } = Search;
const RefundConfigurationDataTable = (props) => {
  const { defaultSorted, token } = props
  const { refundBlankStateMessage } = useContext(GlobalState)
  // LOCAL VARIABLES 
  const { data } = props
  const [numberOfRecords, setNumberOfRecords] = useState(0)
  const [newRefundConfigrationModal, setNewRefundConfigrationModal] = useState(false);

  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <div>
        <button className="btn btn-equipo-blue mt-2" onClick={handleClick}>
          Export
        </button>
      </div>
    );
  };

  const blankState = (
    <Card className="blank-state">
      <CardBody>
        <h3>No records available</h3>
      </CardBody>
    </Card>
  );

  const handleDataChange = ({ dataSize }) => {
    setNumberOfRecords(dataSize ? dataSize : 0);
  }

  useEffect(() => { setNumberOfRecords(data.length) }, [data]);

  return (
    <div className="physicians_table adjustment">
      <Card>
        <ToolkitProvider
          keyField="id"
          data={props.data}
          columns={props.columns}
          search
          exportCSV={{
            fileName: `equipo-Refund-Configuration-Export-${moment().format(MomentConfig.MOMENT_FORMAT_DATEANDTIME)}.csv`
          }}
        >
          {(props) => (
            <div>
              <CardHeader>
                <Row>
                  <Col>
                    <Header>
                        <HeaderTitle>
                          {/* <h4 className = "payment-config-header" >
                          Refund Configuration
                          </h4> */}
                            <Button 
                                title="Add New Refund Configuration"
                                className="float-right add-refund-btn "
                                onClick={() => {
                                  setNewRefundConfigrationModal(true)
                                }}
                              >
                                Add New
                            </Button>
                      </HeaderTitle>
                    </Header>
                  </Col>
                </Row>
                <div className="separator" />
                <Row>
                  <Col sm="2">
                    
                    <div className="number-of-records">
                      <p>Number of records: <strong>{numberOfRecords}</strong></p>
                    </div>
                  </Col>
                  <Col>
                    <SearchBar {...props.searchProps} className="search-box" />
                    <ClearSearchButton {...props.searchProps} className="clear-search-box" />
                  </Col>
                  <Col sm="1">
                    <div className="export-button">
                      <MyExportCSV {...props.csvProps} />
                    </div>
                  </Col>
                </Row>
                <div className="separator" />
                <div className="float-right pull-right">
                </div>
              </CardHeader>
              <CardBody className="patient-list">
                {refundBlankStateMessage && blankState}
                {!refundBlankStateMessage &&
                  <div className="refund-config-table">
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4
                      id={props.key}
                      bordered={false}
                      defaultSorted={defaultSorted}
                      onDataSizeChange={handleDataChange}
                      pagination={paginationFactory({
                        sizePerPage: 50,
                        sizePerPageList: [50, 100, 150, 200],
                      })}
                    />
                  </div>
                }
              </CardBody>
            </div>
          )}
        </ToolkitProvider>
      </Card>
      
      <NewRefundConfigurationModal
        token={token}
        newRefundConfigrationModal={newRefundConfigrationModal}
        setNewRefundConfigrationModal={setNewRefundConfigrationModal}
      />

    </div>
  );
};

export default RefundConfigurationDataTable;