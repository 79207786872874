import React, { useState, useEffect } from "react";
import {
  Col,
  FormGroup,
  Label,
  Row,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button
} from "reactstrap";
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useHistory } from "react-router-dom";
import './CouponStyle.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle, faCheckCircle, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

const CouponInput = (props) => {
  const { newCouponModal, setNewCouponModal ,fetchCoupons} = props;
  // LOCAL VARIABLES
  const animatedComponents = makeAnimated();
  const [Group, setGroup] = useState("");
  const [Groups, setGroups] = useState([]);
  const [GroupValue, setGroupValue] = useState([]);

  const [Type, setType] = useState("");
  const [Types, setTypes] = useState([]);
  const [TypeValue, setTypeValue] = useState([]);

  const [Patient, setPatient] = useState("");
  const [Patients, setPatients] = useState([]);
  const [PatientValue, setPatientValue] = useState([]);

  const [CouponName, setName] = useState("");
  const [CouponDescription, setDescription] = useState("");
  const [CouponCount, setCount] = useState("");
  const [CouponDiscount, setDiscount] = useState("");
  const [CouponExpiry, setExpiry] = useState("");
  const [PatientMaxCount, setPatientMaxCount] = useState("");
  const [ShowPerPatient, setShowPerPatient] = useState(true);
  const [nameValidated, setNameValidated] = useState("blank");

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  let history = useHistory();

  const GroupList = Groups.map((key) => {
    return { value: key.id, label: key.name }
  });

  const TypeList = Types.map((key) => {
    return { value: key.id, label: key.displayName }
  });

  const PatientList = Patients.map((key) => {
    return { value: key.id, label: key.name }
  });

  // const nameHandler = (value) => {
  //   setNameValidated("blank")
  //   if (value.length <= 12) {setName(value);}
  // };
  const nameHandler = (value) => {
    const containsInvalidCharacters = /[^A-Z0-9]/.test(value);

    if (containsInvalidCharacters) {
      toastr.error('Invalid attempt', 'Please try again');
    } else {
      const sanitizedValue = value.replace(/[^A-Z0-9]/g, '');
      setNameValidated("blank");

      if (sanitizedValue.length <= 12) {
        setName(sanitizedValue);
      } else {
        toastr.warning('Name exceeded length','Please limit it to 12 characters or fewer.');
      }
    }
  };

  const descriptionHandler = (value) => {
    if (value.length <= 500) {setDescription(value);}
  };

  const discountHandler = (num) => {
    let decimal = false
    let value = ""
    num % 1 !== 0 ? decimal = true : decimal = false
    if (decimal) {
      value = Math.round(num * 100) / 100;
    } else {
      value = num;
    }

    if (value <= 100) setDiscount(value);
    if (value > 100) setDiscount("100");
    if (value <= 0) setDiscount("1");
    if (value === "") setDiscount("");
  };

  const countHandler = (value) => {
    let decimal = false
    let integer = "";
    value % 1 !== 0 ? decimal = true : decimal = false
    if (!decimal && value < 1) { setCount(""); setPatientMaxCount(""); }
    if (!decimal && value > 0 && value.length < 6) setCount(value);
    if (decimal && value > 0 && value.length < 6) { integer = parseInt(value); setCount(integer); }
  };

  const usageHandler = (value) => {
    let decimal = false
    let integer = "";
    let number = "";
    value % 1 !== 0 ? decimal = true : decimal = false
    if (!decimal && value <= 0) setPatientMaxCount("0");
    if (decimal && value > 0 && value.length < 6 && CouponCount) {
      integer = parseInt(value);
      integer >= CouponCount ? number = CouponCount : number = integer
      setPatientMaxCount(number);
    }
    if (!decimal && value >= 0 && value.length < 6 && CouponCount) {
      number = parseInt(value);
      number >= CouponCount ? number = CouponCount : number = value
      setPatientMaxCount(number);
    }
  };

  const expiryHandler = (date) => {
    setExpiry(date)
  };
  const toggle = () => {
    setNewCouponModal(!newCouponModal);
    clearAllValues();


  }

  const CouponFormData = {
    type: Type ? parseInt(Type.value) : 0,
    group_id: GroupValue.length > 0 ? parseInt(GroupValue) : 0,
    patient_id: PatientValue.length > 0 ? parseInt(PatientValue) : 0,
    coupon_code: CouponName ? CouponName.trim() : "",
    description: CouponDescription ? CouponDescription : "",
    expiry_date: CouponExpiry ? moment(CouponExpiry).format('YYYY-MM-DD') : "",
    value: CouponDiscount ? Number(CouponDiscount) : 0,
    max_count: CouponCount ? parseInt(CouponCount) : 0,
    patient_max_count: PatientMaxCount ? parseInt(PatientMaxCount) : 0
  }

  const cancel = () => { //eslint-disable-line
    clearAllValues();
    history.push("/coupons")
  }

  const validate = () => {
    // VALIDATION FOR GLOBAL COUPON
    if (TypeValue === 19) {
      if (Type && CouponName && CouponDescription && CouponCount && CouponDiscount && CouponExpiry) {
        submitForm(); toggle();
      } else {
        toastr.error("Required Fields Are Empty", "Please try again");
      }
    }

    // VALIDATION FOR CORPORATE COUPON
    if (TypeValue === 20) {
      if (Type && Group && CouponName && CouponDescription && CouponCount && CouponDiscount && CouponExpiry) {
        submitForm(); toggle();
      } else {
        toastr.error("Required Fields Are Empty", "Please try again");
      }
    }

    // VALIDATION FOR PHYSICIAN COUPON
    if (TypeValue === 21) {
      if (Type && Group && CouponName && CouponDescription && CouponCount && CouponDiscount && CouponExpiry) {
        submitForm(); toggle();
      } else {
        toastr.error("Required Fields Are Empty", "Please try again");
      }
    }

    // VALIDATION FOR PATIENT COUPON
    if (TypeValue === 22) {
      if (Type && Patient && CouponName && CouponDescription && CouponCount && CouponDiscount && CouponExpiry) {
        submitForm(); toggle();
      } else {
        toastr.error("Required Fields Are Empty", "Please try again");
      }
    }

    // VALIDATION FOR BLANK FORM
    if (TypeValue.length === 0) {
      toastr.error("Required Fields Are Empty", "Please try again");
    }
  }

  const submitForm = () => {
    axios.post(API_ENDPOINT + '/external/create-coupon/', CouponFormData, {
      headers: {
        Authorization: props.authtoken,
      },
    })
      .then(function (response) {
        history.push("/coupons")
        toastr.success("Coupon Created", "coupon created successfully");
        fetchCoupons()
      })
      .catch(function (error) {
        toastr.error("Coupon Creation Failed");
      });
  };

  const FetchCouponType = () => {
    axios.get(API_ENDPOINT + '/external/get-all-coupon-types/', {
      headers: {
        Authorization: props.authtoken,
      },
    })
      .then(res => {
        if (res.status === 200) {
          setTypes(res.data);
        }
      });
  }

  const FetchGroups = () => {
    // clearAllValues();
    axios.get(API_ENDPOINT + '/external/coupon-group-list/', {
      headers: {
        Authorization: props.authtoken,
      },
    })
      .then(res => {
        if (res.status === 200) {
          setGroups(res.data);
        }
      });
  }

  const FetchCorporateGroups = () => {
    // clearAllValues();
    axios.get(API_ENDPOINT + '/external/corporate-group-list/', {
      headers: {
        Authorization: props.authtoken,
      },
    })
      .then(res => {
        if (res.status === 200) {
          setGroups(res.data);
        }
      });
  }

  const FetchCorporatePatients = () => {
    const formData = { "group_id": Group.value };

    axios.post(API_ENDPOINT + '/user/list-corporate-patients/', formData, {
      headers: {
        Authorization: props.authtoken,
      },
    })
      .then(res => {
        if (res.status === 201) {
          setPatients(res.data);
        }
      });
  }

  const FetchAllPatients = () => {
    // clearAllValues();
    axios.get(API_ENDPOINT + '/user/patient-list/', {
      headers: {
        Authorization: props.authtoken,
      },
    })
      .then(res => {
        if (res.status === 200) {
          setPatients(res.data);
        }
      });
  }

  const validateCouponName = (value) => {
    const formData = { "coupon_code": value.trim().toString() };

    axios.post(API_ENDPOINT + '/external/validate-coupon/', formData, {
      headers: {
        Authorization: props.authtoken,
      },
    })
      .then(function (response) {
        setNameValidated(true);
      })
      .catch(function (error) {
        toastr.error(`${CouponName} coupon already exist!`, "Please try another coupon name");
        setNameValidated(false);
      });
  }

  const GroupHandler = (value) => {
    let temp = [];
    setGroup(value);
    temp.push(value ? value.value : "");
    setGroupValue(temp ? temp : [])
  };

  const TypeHandler = (value) => {
    let temp = [];
    setType(value);
    setPatient("");
    temp.push(value ? value.value : "");
    setTypeValue(temp ? parseInt(temp) : []);
  };

  const PatientHandler = (value) => {
    let temp = [];
    setPatient(value);
    temp.push(value ? value.value : "");
    setPatientValue(temp ? temp : []);
  };

  const clearAllValues = () => {
    setType("");
    setGroup("");
    setGroupValue([]);
    setPatient("");
    setPatients([]);
    setPatientValue([]);
    setName("");
    setDescription("");
    setCount("");
    setDiscount("");
    setExpiry("");
    setPatientMaxCount("");
  }

  const perPatientUsageDisplayLogicForGlobal = () => {
    if (TypeValue === 19) setShowPerPatient(true);
  }

  const perPatientUsageDisplayLogicForCorporate = () => {
    if (TypeValue === 20 && Patient) setShowPerPatient(false);
    if (TypeValue === 20 && !Patient) setShowPerPatient(true);
  }

  const perPatientUsageDisplayLogicForPatient = () => {
    if (TypeValue === 22) setShowPerPatient(false);
  }

  const perPatientUsageDisplayLogicForPhysician = () => {
    if (TypeValue === 21) setShowPerPatient(true);
  }

  // EFFECTS FOR INITIAL LOAD API CALLS
  useEffect(() => { FetchGroups(); FetchCouponType(); }, []) // eslint-disable-line

  // EFFECTS FOR FETCHING PATIENT LIST BASED ON TYPE AND GROUP SELECTION
  useEffect(() => { if (TypeValue === 20) FetchCorporateGroups() }, [TypeValue]) // eslint-disable-line
  useEffect(() => { if (Group && TypeValue === 20) FetchCorporatePatients() }, [Group]) // eslint-disable-line
  useEffect(() => { if (TypeValue === 21) FetchGroups() }, [TypeValue]) // eslint-disable-line
  useEffect(() => { if (TypeValue === 22) FetchAllPatients() }, [TypeValue]) // eslint-disable-line

  // EFFECT TO SHOW/HIDE "MAX ALLOWED USAGE (PER PATIENT)" BASED ON TYPE
  useEffect(() => { perPatientUsageDisplayLogicForCorporate() }, [TypeValue, Patient]) // eslint-disable-line
  useEffect(() => { perPatientUsageDisplayLogicForGlobal() }, [TypeValue]) // eslint-disable-line
  useEffect(() => { perPatientUsageDisplayLogicForPatient() }, [TypeValue]) // eslint-disable-line
  useEffect(() => { perPatientUsageDisplayLogicForPhysician() }, [TypeValue]) // eslint-disable-line

  return (
    <div >
      <Modal isOpen={newCouponModal} toggle={toggle} size="md" >
        <ModalHeader tag="h4" cssModule={{ 'modal-title': 'w-100 text-center' }} toggle={toggle}>Add New Coupon </ModalHeader>
        <ModalBody className="overflow-modal disable-scrollbars" >
          <FormGroup>
            <Row>
              <Col>
                <Label>Type <span className="required"> * </span></Label>
                <Select
                  placeholder="Select"
                  value={Type}
                  options={TypeList}
                  isSearchable
                  isClearable
                  isLoading={TypeList.length > 0 ? false : true}
                  onChange={(event) => TypeHandler(event)}
                />
              </Col>
            </Row>
            {/* <Row> */}
            {/* DYNAMIC DROPDOWN RENDERING LOGIC */}
            {/* TYPE = CORPORATE */}
            {TypeValue === 20 ?
              <>
                {/* <Row> */}
                <Row>
                  <Col><br></br>
                    <Label>Account / Group <span className="required"> * </span></Label>
                    <Select
                      placeholder="Select"
                      value={Group}
                      options={GroupList}
                      isSearchable
                      isClearable
                      isLoading={GroupList.length > 0 ? false : true}
                      onChange={(event) => GroupHandler(event)}
                    />
                  </Col></Row>
                <Row>
                  <Col>
                    <Label>Patient</Label>
                    <Select
                      placeholder="Select"
                      value={Patient}
                      options={PatientList}
                      isSearchable
                      isClearable
                      components={animatedComponents}
                      onChange={(event) => PatientHandler(event)}
                    />
                  </Col>
                </Row>
              </> : ""}


            {/* TYPE = PHYSICIAN */}
            {TypeValue === 21 ?
              <><br></br>
                <Label>Account / Group <span className="required"> * </span></Label>
                <Select
                  placeholder="Select"
                  value={Group}
                  options={GroupList}
                  isSearchable
                  isClearable
                  isLoading={GroupList.length > 0 ? false : true}
                  onChange={(event) => GroupHandler(event)}
                />
              </> : ""}

            {/* TYPE = PATIENT */}
            {TypeValue === 22 ?
              <><br></br>
                <Label>Patient <span className="required"> * </span></Label>
                <Select
                  required
                  placeholder="Select"
                  value={Patient}
                  options={PatientList}
                  isSearchable
                  isClearable
                  isLoading={PatientList.length > 0 ? false : true}
                  components={animatedComponents}
                  onChange={(event) => PatientHandler(event)}
                />
              </> : ""}<br></br>
            <Label>Name <span className="required"> * </span></Label>
            <Row>
              <Col>
                <Input
                  required
                  onChange={event => nameHandler(event.target.value)}
                  onBlur={event => validateCouponName(event.target.value)}
                  type="text"
                  name="coupon_name"
                  className="coupon-margin-bottom"
                  placeholder="Coupon Code ( eg: WELCOME50 )"
                  value={CouponName}
                  maxLength={120}
                />
                {nameValidated === true && CouponName ?
                  <FontAwesomeIcon icon={faCheckCircle} className="success-check" /> :
                  ""}
                {nameValidated === false && CouponName ?
                  <FontAwesomeIcon icon={faExclamationCircle} className="failed-check" /> :
                  ""}
              </Col>
            </Row>
            <Label>Description <span className="required"> * </span></Label>
            <Input
              required
              type="textarea"
              name="coupon_description"
              className="coupon-description coupon-margin-bottom"
              placeholder="Coupon Description"
              value={CouponDescription}
              maxLength={500}
              onChange={event => descriptionHandler(event.target.value)}
            />
            <Label>Max Allowed Usage (Overall)<span className="required"> * </span></Label>
            <Input
              required
              onChange={event => countHandler(event.target.value)}
              type="number"
              name="coupon_count"
              placeholder="Coupon Count"
              className="coupon-margin-bottom"
              value={CouponCount}
            />
            {ShowPerPatient ?
              <>
                <Label>Max Allowed Usage (Per Patient)</Label>
                <Input
                  name="coupon_count_per_patient"
                  className="coupon-margin-bottom"
                  onChange={(event) => usageHandler(event.target.value)}
                  value={PatientMaxCount}
                  placeholder="Max Usage Count"
                />
                {PatientMaxCount < 1 ?
                  <div className="warning-label">
                    <FontAwesomeIcon
                      icon={faExclamationTriangle}
                    />
                    <Label className="warning-text">Leave the above field empty or enter "0" for unlimited usage</Label>
                  </div>
                  : ""}
              </>
              : ""}
            <Label>Discount (%) <span className="required"> * </span></Label>
            <Input
              required
              value={CouponDiscount}
              onChange={event => discountHandler(event.target.value)}
              type="number"
              name="coupon_discount"
              className="coupon-margin-bottom"
              placeholder="Coupon Discount"
            />
            {/* <Row> */}
            <Label>Expires On <span className="required"> * </span></Label>
            <DatePicker
              dateFormat="dd MMM YYY"
              placeholderText="Select date"
              onChange={(date) => expiryHandler(date)}
              minDate={moment().toDate()}
              selected={CouponExpiry}
            />
            {/* </Row> */}
            {/* </Row> */}
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            className="yes-button button-width"
            onClick={validate}
          // disabled={addButtonStatus}
          // title={addButtonStatus ? "Fill the required fields" : ""}
          >Add</Button>{' '}
          <Button outline className="no-button button-width" onClick={toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
    authtoken: state.auth.token,
  };
};

export default connect(mapStateToProps, null)(CouponInput);