import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const ApproveDocModal = (props) => {
  // DEPENDENCY VARIABLES  
  const {
    approveDocModal,
    setApproveDocModal,
    setUserConfirmation,
    setDocUpdateStatusCode,
    regId,
    countryName,
    regNumber,
  } = props;

  const toggle = () => {
    setApproveDocModal(!approveDocModal);
  };

  const confirmation = () => {
    setUserConfirmation(true);
    setDocUpdateStatusCode(24)
    toggle();
  };

  return (
    <div>
      <Modal isOpen={approveDocModal} toggle={toggle}>
        <ModalHeader tag="h4" cssModule={{ 'modal-title': 'w-100 text-center'}} toggle={toggle}> 
        { regId ? 
        <span>Approve All Documents For {countryName} <br /> 
        Registration Number: {regNumber}</span> : "Approve Document" }
         
         </ModalHeader>
        <ModalBody>
          {regId ? "Are you sure you want to approve all documents?" : "Are you sure you want to approve this document?"}
        </ModalBody>
        <ModalFooter>
          <Button className="yes-button button-width" onClick={confirmation}>
            Yes
          </Button>{" "}
          <Button outline className="no-button button-width" onClick={toggle}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ApproveDocModal;
