import React, { useState, useContext } from 'react';
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import { Col, Label, Row, Button } from "reactstrap";
import './DateRangeSelector.css';
import { GlobalState } from '../../contextAPI/Context';

const InsuranceGroupFilters = (props) => {
    // CONTEXT VARIABLES 
    const {
        spinner,
        setInsuranceGroupName,
        groupInsuranceListReload,
        setGroupInsuranceListReload,

    } = useContext(GlobalState)


    // LOCAL VARIABLES 
    const [name, setName] = useState("")

    const runSearch = () => {
        if (name) {
            setInsuranceGroupName(name || "")
            setGroupInsuranceListReload(groupInsuranceListReload + 1)
        }
    }

    const resetFields = () => {
        setName("")
        setInsuranceGroupName("")
    }

    return (
        <AvForm>
            <AvGroup>
                <Row >
                    <Col xs="2">
                        <Label>Name</Label>
                        <AvField
                            type="text"
                            name="name"
                            placeholder="All"
                            value={name}
                            validate={{
                                maxLength: { value: 120 }
                            }}
                            onChange={(e) => { setName(e.target.value) }}
                        />
                    </Col>
                    
                    <Col xs="3" className="action-btn-filter" style={{paddingTop: '10px'}}>
                        <Button
                            type="submit"
                            className="go-buttons button-corporate"
                            disabled={!!spinner}
                            onClick={() => { runSearch() }}
                        >Go</Button>
                        <Button
                            type="reset"
                            className="reset-buttons button-corporate"
                            disabled={!!spinner}
                            onClick={() => { resetFields() }}
                        >Reset</Button>
                    </Col>
                </Row>
            </AvGroup>
        </AvForm>
    )
}

export default InsuranceGroupFilters;
