import React, { useState, useEffect, useContext } from "react";
import "../Profile/Profile.css";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faCamera } from "@fortawesome/free-solid-svg-icons";
import { toastr } from "react-redux-toastr";
import ReactQuill from "react-quill";
import Select from "react-select";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import axios from "axios";

import defaultAvatar from "../../../assets/avatar.png";
import { GlobalState } from "../../../contextAPI/Context";
import signatureIcon from "../../../assets/signature.png";
import { useLocation } from "react-router-dom";
import ReactTooltip from 'react-tooltip';


const ProfileInfo = (props) => {
  // CONTEXT VARIABLES
  const { spinner, reloadPhysicianIdentityData, setReloadPhysicianIdentityData, setPhysicianId } = useContext(GlobalState);

  // LOCAL VARIABLES
  const [userId, setUserId] = useState("")
  const [PhysicianData, setPhysicianData] = useState([]);
  const [Specialities, setSpecialities] = useState([]);
  const [Languages, setLanguages] = useState([]);
  const [Countries, setCountries] = useState([]);

  const [id, setId] = useState("");// eslint-disable-line
  const [Photo, setPhoto] = useState("");
  const [photoPreview, setPhotoPreview] = useState("");
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [npi, setNpi] = useState("");
  const [biography, setBiography] = useState("");
  const [Speciality, setSpeciality] = useState(""); // eslint-disable-line
  const [SecialityArray, setSpecialityArray] = useState([]); // eslint-disable-line
  const [SpecialityValue, setSpecialityValue] = useState("");// eslint-disable-line
  const [personalWebsite, setPersonalWebsite] = useState("");
  const [qualification, setQualification] = useState("");
  const [affiliations, setAffiliations] = useState("");
  const [medicalSchool, setMedicalSchool] = useState("");
  const [LanguageSpoken, setLanguageSpoken] = useState([]);// eslint-disable-line
  const [LanguageSpokenTouched, setLanguageSpokenTouched] = useState(false);// eslint-disable-line
  const [SelectedLanguageSpoken, setSelectedLanguageSpoken] = useState([]);// eslint-disable-line
  const [SelectedSpeciality, setSelectedSpeciality] = useState([]);// eslint-disable-line
  const [LanguageToPost, setLanguageToPost] = useState([]);// eslint-disable-line
  const [FavContact, setFavContact] = useState("");
  const [OfficeCountry, setOfficeCountry] = useState("");
  const [OfficeCountryValue, setOfficeCountryValue] = useState("");// eslint-disable-line
  const [HomeCountry, setHomeCountry] = useState("");
  const [HomeCountryValue, setHomeCountryValue] = useState("");// eslint-disable-line
  const [idCardPassport, setIdCardPassport] = useState("");// eslint-disable-line
  const [taxNumber, setTaxNumber] = useState("");// eslint-disable-line

  const [OfficeCurrency, setOfficeCurrency] = useState("");// eslint-disable-line
  const [OfficeCountryIsdCode, setOfficeCountryIsdCode] = useState("");// eslint-disable-line
  const [HomeCurrency, setHomeCurrency] = useState("");// eslint-disable-line
  const [HomeCountryIsdCode, setHomeCountryIsdCode] = useState("");// eslint-disable-line
  const [phoneNumber, setPhoneNumber] = useState("");

  const [OfficeAutoAddress, setOfficeAutoAddress] = useState("");// eslint-disable-line
  const [OfficeAddress, setOfficeAddress] = useState("");// eslint-disable-line
  const [NewOfficeAddress, setNewOfficeAddress] = useState("");// eslint-disable-line
  const [OfficeAddressTouched, setOfficeAddressTouched] = useState(false);
  const [OfficeState, setOfficeState] = useState("");
  const [OfficeAutoState, setOfficeAutoState] = useState("");
  const [OfficeCity, setOfficeCity] = useState("");
  const [OfficeAutoCity, setOfficeAutoCity] = useState("");
  const [OfficeZipCode, setOfficeZipCode] = useState("");
  const [OfficeAutoZipCode, setOfficeAutoZipCode] = useState("");

  const [HomeAutoAddress, setHomeAutoAddress] = useState("");// eslint-disable-line
  const [HomeAddress, setHomeAddress] = useState("");// eslint-disable-line
  const [NewHomeAddress, setNewHomeAddress] = useState("");// eslint-disable-line
  const [HomeAddressTouched, setHomeAddressTouched] = useState(false);
  const [HomeState, setHomeState] = useState("");
  const [HomeAutoState, setHomeAutoState] = useState("");
  const [HomeCity, setHomeCity] = useState("");
  const [HomeAutoCity, setHomeAutoCity] = useState("");
  const [HomeZipCode, setHomeZipCode] = useState("");
  const [HomeAutoZipCode, setHomeAutoZipCode] = useState("");

  const [HomePhoneNumber, setHomePhoneNumber] = useState("");

  const [validTeleMedicine, setvalidTeleMedicine] = useState(true);

  const [uploadedSignPreview, setuploadedSignPreview] = useState("");
  const [base64Image, setBase64Image] = useState("");
  const [signature, setSignature] = useState("");
  const [apiLoaded, setApiLoaded] = useState(false);// eslint-disable-line

  const [spinnerStyle, setSpinnerStyle] = useState(false);
  const avatar = PhysicianData.profileImageURL
    ? PhysicianData.profileImageURL
    : defaultAvatar;

  // GET TOKEN
  let token = localStorage.getItem("token");
  let userID = localStorage.getItem("userId");

  // OBTAINING BASE URL BASED ON ENVIRONMENT
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

  // IMAGE CONVERSION TO BASE64
  const convertImageToBase64 = (photo) => {
    let file = photo;
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setBase64Image(reader.result);
      // console.log(reader.result);
    };
  };

  // CREATES THE PHOTO PREVIEW URL FOR THE UPLOEDED PHOTO
  const handlePhoto = (photo) => {
    // MAX FILE SIZE SET TO 2MB
    if (photo.size <= 20000000) {
      setPhoto(photo);
      setPhotoPreview(URL.createObjectURL(photo));
    } else {
      toastr.success(
        "Please Use A Smaller Picture",
        "The profile picture size cannot exceed 20MB"
      );
    }
  };

  const handleSignature = (photo) => {
    // MAX FILE SIZE SET TO 2MB
    if (photo.size <= 2000000) {
      setuploadedSignPreview(URL.createObjectURL(photo));
      convertImageToBase64(photo);
    } else {
      toastr.error(
        "Please Use A Smaller Picture",
        "The image size cannot exceed 2MB"
      );
    }
  };

  // NOTIFICATION PANEL FOR SUCCESS
  // eslint-disable-next-line
  const showToastrSuccess = (message) => {
    const options = {
      timeOut: parseInt(5000),
      showCloseButton: true,
      progressBar: false,
      position: "bottom-left",
    };

    const toastrInstance = toastr.success;
    toastrInstance(message, options);
  };

  // NOTIFICATION PANEL FOR WARNING
  // eslint-disable-next-line
  const showToastrWarning = (label, message = "") => {
    const options = {
      timeOut: parseInt(5000),
      showCloseButton: true,
      progressBar: false,
      position: "bottom-left",
    };

    const toastrInstance = toastr.error;
    toastrInstance(label, message, options);
  };

  // VALIDATION FOR REQUIRED  FIELDS
  const runValidation = () => {
    // if (!phoneNumber)
    //   showToastrWarning(
    //     "Contact number (for patients) is empty",
    //     "Please enter your Contact number"
    //   );

    // BYPASSING ALL VALIDATIONS FOR SIGNATURE AND PROFILE PIC UPLOAD 
    // if (!Speciality) {
    //   showToastrWarning("Speciality is empty", "Please select your Speciality");
    // } else if (!qualification) {
    //   showToastrWarning(
    //     "Credentials is empty",
    //     "Please enter your credentials"
    //   );
    // }
    // else if (LanguageSpokenTouched && LanguageToPost.length === 0 || !LanguageSpokenTouched && SelectedLanguageSpoken.length === 0) {
    //   showToastrWarning(
    //     "Languages spoken is empty",
    //     "You should select atleast one language"
    //   );
    // }
    // else if (FavContact === "residence") {
    //   if (HomeCountryValue === "") {
    //     showToastrWarning(
    //       "Residence country is empty",
    //       "Please select your country"
    //     );
    //   } else if (HomePhoneNumber === "") {
    //     showToastrWarning(
    //       "Residence contact number is empty",
    //       "Please enter your contact number"
    //     );
    //   } else if (HomeAddressTouched && NewHomeAddress === "") {
    //     showToastrWarning(
    //       "Residence address is empty",
    //       "Please enter your address"
    //     );
    //   } else if (!HomeAddressTouched && HomeAddress === "") {
    //     showToastrWarning(
    //       "Residence address is empty",
    //       "Please enter your address"
    //     );
    //   } else if (HomeState === "") {
    //     showToastrWarning(
    //       "Residence state is empty",
    //       "Please enter your state"
    //     );
    //   } else if (HomeCity === "") {
    //     showToastrWarning("Residence city is empty", "Please enter your city");
    //   } else if (HomeZipCode === "") {
    //     showToastrWarning(
    //       "Residence post code is empty",
    //       "Please enter your post code"
    //     );
    //   } else {
    //     handleSubmit();
    //   }
    // } else if (FavContact === "office" || FavContact === "") {
    //   if (OfficeCountryValue === "") {
    //     showToastrWarning(
    //       "Office country is empty",
    //       "Please select your country"
    //     );
    //   } else if (phoneNumber === "") {
    //     showToastrWarning(
    //       "Office contact number is empty",
    //       "Please enter your contact number"
    //     );
    //   } else if (OfficeAddressTouched && NewOfficeAddress === "") {
    //     showToastrWarning(
    //       "Office address is empty",
    //       "Please enter your address"
    //     );
    //   } else if (!OfficeAddressTouched && OfficeAddress === "") {
    //     showToastrWarning(
    //       "Office address is empty",
    //       "Please enter your address"
    //     );
    //   } else if (OfficeState === "") {
    //     showToastrWarning("Office state is empty", "Please enter your state");
    //   } else if (OfficeCity === "") {
    //     showToastrWarning("Office city is empty", "Please enter your city");
    //   }
    //   else {
    //     handleSubmit();
    //   }
    // }

    // BYPASSING ALL VALIDATIONS FOR SIGNATURE AND PROFILE PIC UPLOAD 
    handleSubmit();
  };

  const handleSubmit = () => {
    // const bodyFormData = new FormData();

    // bodyFormData.set("id", id);
    // bodyFormData.append("userImge", Photo);
    // bodyFormData.set("name", name);
    // bodyFormData.set("biography", biography);
    // bodyFormData.set("specialty", SpecialityValue);
    // bodyFormData.set("personalWebsite", personalWebsite);
    // bodyFormData.set("qualification", qualification);
    // bodyFormData.set("affiliations", affiliations);
    // bodyFormData.set("medicalSchool", medicalSchool);
    // if (LanguageToPost && LanguageSpokenTouched) {
    //   bodyFormData.set("spokenLanguages", LanguageToPost);
    // } else {
    //   bodyFormData.set("spokenLanguages", LanguageSpoken);
    // }
    // bodyFormData.set("preferredContact", FavContact);
    // bodyFormData.set("officeCountry", OfficeCountryValue);
    // bodyFormData.set("idCardPassport", idCardPassport);
    // bodyFormData.set("taxNumber", taxNumber);
    // bodyFormData.set("resident_country", HomeCountryValue);

    // if (FavContact === "residence") {
    //   bodyFormData.set("currency", HomeCurrency);
    //   bodyFormData.set("countryCode", HomeCountryIsdCode);
    // } else {
    //   bodyFormData.set("currency", OfficeCurrency);
    //   bodyFormData.set("countryCode", OfficeCountryIsdCode);
    // }

    // bodyFormData.set("phoneNumber", phoneNumber);
    // if (NewOfficeAddress) {
    //   bodyFormData.set("address", NewOfficeAddress);
    // } else {
    //   bodyFormData.set("address", OfficeAutoAddress);
    // }
    // bodyFormData.set("city", OfficeCity);
    // bodyFormData.set("state", OfficeState);
    // bodyFormData.set("zipCode", OfficeZipCode);
    // bodyFormData.set("homePhoneNumber", HomePhoneNumber);
    // if (NewHomeAddress) {
    //   bodyFormData.set("homeAddress", NewHomeAddress);
    // } else {
    //   bodyFormData.set("homeAddress", HomeAutoAddress);
    // }
    // bodyFormData.set("homeCity", HomeCity);
    // bodyFormData.set("homeState", HomeState);
    // bodyFormData.set("homeZipCode", HomeZipCode);
    // bodyFormData.set("validTeleMedicine", validTeleMedicine);

    // axios
    //   .post(`${API_ENDPOINT}/physician/edit-physician-details`, bodyFormData, {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //     },
    //   })
    //   .then(function (response) {
    //     showToastrSuccess("Changes are saved successfully ");
    //     setSpinnerStyle(false);
    //     setDetails("");
    //   })
    //   .catch(function (error) {
    //     let apiResponse = error.response.data.title;
    //     showToastrWarning(apiResponse, "Please try again");
    //     setSpinnerStyle(false);
    //     setDetails("");
    //   });

    // API CALL TO SAVE BASE64 SIGNATURE TEXT
    if (Photo || base64Image) {
      setSpinnerStyle(true);

      // CREATING FORM DATA 
      const formData = new FormData();
      formData.append("file", Photo ? Photo : "");
      formData.set("user_id", userId);
      formData.set("userID", userID);
      formData.set("signatureImageString", base64Image ? base64Image : "");

      axios
        .post(
          `${API_ENDPOINT}/user/add-profileimageorsignature/`,
          formData,
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        )
        .then(function (response) {
          // console.log(response);
          setReloadPhysicianIdentityData(reloadPhysicianIdentityData + 1)
          setSpinnerStyle(false);
          toastr.success("Profile Updated Successfully")
        })
        .catch(function (error) {
          // console.log(error);
          setSpinnerStyle(false);
        });
    }
  };

  const SpecialityList = Specialities.map((key) => {
    return { value: key.id, label: key.name };
  });

  const CountryList = Countries.map((key) => {
    return { value: key.id, label: key.country_name };
  });

  const LanguagesList = Languages.map((key) => {
    return { value: key.id, label: key.language };
  });

  const getDefaultLanguageSpokenValue = (array) => {
    
    setLanguageSpoken(array);
    const setLangMap = (key) => {
      let newItem = {
        value: key.id,
        label: key.language,
      };
      SelectedLanguageSpoken.push(newItem);
    };
    const langarray = JSON.parse(array);
    if (langarray) {
      for (let id in langarray) {
        const abc = langarray[id];
        Languages.filter((key) => key.id === abc).map((key) => setLangMap(key));
      }
    }
  };

  const LanguageSpokenHandler = (event) => {
    setLanguageSpokenTouched(true);

    if (event) {
      const aaa = event.map((key) => key.value).join(",");
      setLanguageToPost("[" + aaa + "]");
    } else {
      setLanguageToPost([]);
    }
  };


  const getDefaultSpeciality = (id) => {

    setSpecialityArray(id);

    const setSpecialityMap = (key) => {
      let newItem = {
        value: key.id,
        label: key.name,
      };
      SelectedSpeciality.push(newItem);
    };
    
    const specialityArray = JSON.parse(id);
    if (specialityArray) {
      for (let id in specialityArray) {
        const ab = specialityArray[id];

        Specialities.filter((key) => key.id === ab).map((key) => setSpecialityMap(key));
      }
    }

  };
  

  const SpecialityHandler = (event) => {
    setSpeciality(event);
    setSpecialityValue(event.value);
  };

  const getDefaultOfficeCountry = (id) => {
    setOfficeCountryValue(+id);
    Countries.filter((key) => key.id === +id).map((key) => {// eslint-disable-line
      setOfficeCountry({ value: key.id, label: key.country_name });
      setOfficeCurrency(key.country_currency);
      setOfficeCountryIsdCode(key.country_isd_code);
    });
  };

  const OfficeCountryHandler = (event) => {
    setOfficeCountry(event);
    setOfficeCountryValue(+event.value);

    Countries.filter((key) => key.id === +event.value).map((key) => {// eslint-disable-line
      setOfficeCurrency(key.country_currency);
      setOfficeCountryIsdCode(key.country_isd_code);
    });
  };

  const getDefaultHomeCountry = (id) => {
    setHomeCountryValue(+id);
    Countries.filter((key) => key.id === +id).map((key) => { // eslint-disable-line
      setHomeCountry({ value: key.id, label: key.country_name });
      setHomeCurrency(key.country_currency);
      setHomeCountryIsdCode(key.country_isd_code);
    });
  };

  const HomeCountryHandler = (event) => {
    setHomeCountry(event);
    setHomeCountryValue(+event.value);

    Countries.filter((key) => key.id === +event.value).map((key) => { // eslint-disable-line
      setHomeCurrency(key.country_currency);
      setHomeCountryIsdCode(key.country_isd_code);
    });
  };
// eslint-disable-next-line
  const onChangeGeolocationOffice = (event) => {
    setOfficeAddressTouched(true);
    setOfficeAutoAddress(event);
    setNewOfficeAddress(event);
    setOfficeState("");
    setOfficeCity("");
    setOfficeAutoState("");
    setOfficeAutoCity("");
    setOfficeZipCode("");
    setOfficeAutoZipCode("");
  };
// eslint-disable-next-line
  const onSelectGeolocationOffice = (event) => {
    setTimeout(() => {
      if (OfficeAutoState) {
        if (OfficeAddressTouched) {
          if (event.administrative_area_level_1 === "long_name") {
            setOfficeState("");
          } else {
            setOfficeState(event.administrative_area_level_1);
          }
        } else {
          setOfficeState(OfficeAutoState);
        }
      } else {
        if (OfficeAddressTouched) {
          if (event.administrative_area_level_1 === "long_name") {
            setOfficeState("");
          } else {
            setOfficeState(event.administrative_area_level_1);
          }
        } else {
          setOfficeState("");
        }
      }

      if (OfficeAutoCity) {
        if (OfficeAddressTouched) {
          if (event.locality === "long_name") {
            setOfficeCity("");
          } else {
            setOfficeCity(event.locality);
          }
        } else {
          setOfficeCity(OfficeAutoCity);
        }
      } else {
        if (OfficeAddressTouched) {
          if (event.locality === "long_name") {
            setOfficeCity("");
          } else {
            setOfficeCity(event.locality);
          }
        } else {
          setOfficeCity("");
        }
      }

      if (OfficeAutoZipCode) {
        if (OfficeAddressTouched) {
          if (event.postal_code === "long_name") {
            setOfficeZipCode("");
          } else {
            setOfficeZipCode(event.postal_code);
          }
        } else {
          setOfficeZipCode(OfficeAutoZipCode);
        }
      } else {
        if (OfficeAddressTouched) {
          if (event.postal_code === "long_name") {
            setOfficeZipCode("");
          } else {
            setOfficeZipCode(event.postal_code);
          }
        } else {
          setOfficeZipCode("");
        }
      }
    }, 500);
  };
// eslint-disable-next-line
  const onChangeGeolocationHome = (event) => {
    setHomeAddressTouched(true);
    setHomeAutoAddress(event);
    setNewHomeAddress(event);
    setHomeState("");
    setHomeCity("");
    setHomeZipCode("");
    setHomeAutoState("");
    setHomeAutoCity("");
    setHomeAutoZipCode("");
  };
// eslint-disable-next-line
  const onSelectGeolocationHome = (event) => {
    setTimeout(() => {
      if (HomeAutoState) {
        if (HomeAddressTouched) {
          if (event.administrative_area_level_1 === "long_name") {
            setHomeState("");
          } else {
            setHomeState(event.administrative_area_level_1);
          }
        } else {
          setHomeState(HomeAutoState);
        }
      } else {
        if (HomeAddressTouched) {
          if (event.administrative_area_level_1 === "long_name") {
            setHomeState("");
          } else {
            setHomeState(event.administrative_area_level_1);
          }
        } else {
          setHomeState("");
        }
      }

      if (HomeAutoCity) {
        if (HomeAddressTouched) {
          if (event.locality === "long_name") {
            setHomeCity("");
          } else {
            setHomeCity(event.locality);
          }
        } else {
          setHomeCity(HomeAutoCity);
        }
      } else {
        if (HomeAddressTouched) {
          if (event.locality === "long_name") {
            setHomeCity("");
          } else {
            setHomeCity(event.locality);
          }
        } else {
          setHomeCity("");
        }
      }

      if (HomeAutoZipCode) {
        if (HomeAddressTouched) {
          if (event.postal_code === "long_name") {
            setHomeZipCode("");
          } else {
            setHomeZipCode(event.postal_code);
          }
        } else {
          setHomeZipCode(HomeAutoZipCode);
        }
      } else {
        if (HomeAddressTouched) {
          if (event.postal_code === "long_name") {
            setHomeZipCode("");
          } else {
            setHomeZipCode(event.postal_code);
          }
        } else {
          setHomeZipCode("");
        }
      }
    }, 500);
  };

  const PopulateData = () => {
    if (PhysicianData.speciality && SelectedSpeciality.length === 0) getDefaultSpeciality(PhysicianData.speciality);
    if (PhysicianData) setId(PhysicianData.id);
    if (PhysicianData.qualification) setQualification(PhysicianData.qualification);
    if (PhysicianData.signatureImageString) setSignature(PhysicianData.signatureImageString);
    if (PhysicianData.address) { setOfficeAddress(PhysicianData.address); setOfficeAutoAddress(PhysicianData.address); }
    if (PhysicianData.homeAddress) { setHomeAddress(PhysicianData.homeAddress); setHomeAutoAddress(PhysicianData.homeAddress); }
    if (PhysicianData.spokenLanguages && SelectedLanguageSpoken.length === 0) getDefaultLanguageSpokenValue(PhysicianData.spokenLanguages);
    if (PhysicianData.city) { setOfficeAutoCity(PhysicianData.city); setOfficeCity(PhysicianData.city); }
    if (PhysicianData.homeCity) { setHomeAutoCity(PhysicianData.homeCity); setHomeCity(PhysicianData.homeCity); }
    if (PhysicianData.state) { setOfficeAutoState(PhysicianData.state); setOfficeState(PhysicianData.state); }
    if (PhysicianData.homeState) { setHomeAutoState(PhysicianData.homeState); setHomeState(PhysicianData.homeState); }
    if (PhysicianData.zipcode) setOfficeAutoZipCode(PhysicianData.zipcode);
    if (PhysicianData.homeZipCode) setHomeAutoZipCode(PhysicianData.homeZipCode);
    if (PhysicianData.contactNumber) setPhoneNumber(PhysicianData.contactNumber);
    if (PhysicianData.homePhoneNumber) setHomePhoneNumber(PhysicianData.homePhoneNumber);
    if (PhysicianData.taxNumber) setTaxNumber(PhysicianData.taxNumber);
    if (PhysicianData.personalWebsite) setPersonalWebsite(PhysicianData.personalWebsite);
    if (PhysicianData.biography) setBiography(PhysicianData.biography);
    if (PhysicianData.medicalSchool) setMedicalSchool(PhysicianData.medicalSchool);
    if (PhysicianData.affiliations) setAffiliations(PhysicianData.affiliations);
    if (PhysicianData.idCardPassport) setIdCardPassport(PhysicianData.idCardPassport);
    if (PhysicianData.name) setName(PhysicianData.name);
    if (PhysicianData.title) setTitle(PhysicianData.title);
    if (PhysicianData.first_name) setFirstName(PhysicianData.first_name);
    if (PhysicianData.middle_name) setMiddleName(PhysicianData.middle_name);
    if (PhysicianData.last_name) setLastName(PhysicianData.last_name);
    if (PhysicianData.npi) setNpi(PhysicianData.npi);

    if (PhysicianData.preferredContact && PhysicianData.preferredContact === "residence") {
      setFavContact("residence");
    } else {
      setFavContact("office");
    }

    if (!PhysicianData.address) {
      setOfficeState(PhysicianData.state);
      setOfficeCity(PhysicianData.city);
    } else if (!PhysicianData.address && !PhysicianData.state) {
      setOfficeCity(PhysicianData.city);
    } else if (!PhysicianData.address && !PhysicianData.city) {
      setOfficeState(PhysicianData.state);
    }

    // if (PhysicianData.name) setCurrency(PhysicianData.name);
    // if (PhysicianData.name) setCountryIsdCode(PhysicianData.name);

    if (PhysicianData.isTelemedicine) {
      let status = parseInt(PhysicianData.isTelemedicine);
      status === 1 ? setvalidTeleMedicine(true) : setvalidTeleMedicine(false);
    }
  };

  /* const PopulateSpecialities = () => {
    if (PhysicianData) {
      if (PhysicianData.speciality)
        getDefaultSpeciality(PhysicianData.speciality);
    }
  }; */

  const PopulateCountries = () => {
    if (PhysicianData) {
      // if (PhysicianData.resident_country)
      //   getDefaultCountry(PhysicianData.resident_country);

      if (PhysicianData.medicineRegistrationCountry)
        getDefaultOfficeCountry(PhysicianData.medicineRegistrationCountry);

      if (PhysicianData.resident_country)
        getDefaultHomeCountry(PhysicianData.resident_country);
    }
  };

  useEffect(() => {
    setLanguages(props.Languages);
    setCountries(props.Countries);
    setSpecialities(props.Specialities);
    setPhysicianData(props.PhysicianData);
  }, [props]);

  useEffect(() => {
    if (PhysicianData) {
      PopulateData();
    }
  }, [PhysicianData, Languages, Specialities]);// eslint-disable-line

  /* useEffect(() => {
    if (PhysicianData) {
      PopulateSpecialities();
    }
  }, [PhysicianData, Specialities]); */// eslint-disable-line

  useEffect(() => {
    if (PhysicianData) {
      PopulateCountries();
    }
  }, [PhysicianData, Countries]);// eslint-disable-line

  // GET URL PARAMETERS 
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();

  // EFFECT TO GET THE URL PARAMETERS 
  useEffect(() => {
    if (query) {
      setUserId(Number(atob(query.get("pid"))));
      setPhysicianId(Number(atob(query.get("pid"))));
    }
  }, [query])// eslint-disable-line


  return (
    <>
      <ReactTooltip className="doc-upload-tooltip" />
      <Card>
        <Spinner
          style={spinner ? { display: "block" } : { display: "none" }}
          className="physician-spinner"
        />
        <CardHeader className="profile-header">
          <CardTitle tag="h5" className="mb-0">
            {name ? `${name}'s` : ""} Profile
        </CardTitle>
        </CardHeader>
        <CardBody className="pd-halfrem profile-card disable-scrollbars">
          <AvForm>
            <div className="general_personal_details">
              <Row>
                <Col sm="3">
                  <strong>Profile Picture</strong>
                  <Row>
                    <FormGroup>
                      <div className="text-center">
                        <div className="profile-pic-upload profile-adjustment">
                          <Col>
                            <div className="image-holder">

                              <div className="widthheight128">
                                <Label className="custom-file-upload" title="Update your profile pic">
                                  <FontAwesomeIcon icon={faCamera} />
                                  {` `}
                                  <Input
                                    type="file"
                                    onChange={(event) => {
                                      handlePhoto(event.target.files[0]);
                                    }}
                                  ></Input>
                                </Label>
                                <img
                                  alt="Profile pic"
                                  src={photoPreview ? photoPreview : avatar}
                                  className="rounded-circle "
                                  width="128"
                                  height="128"
                                />
                              </div>
                            </div>
                            <p className="image-uploader-text">
                              For best results, use an image at least 128px by 128px in jpg format
                          </p>
                          </Col>

                        </div>
                      </div>
                    </FormGroup>
                  </Row>
                  <hr />
                  <strong>Signature</strong>
                  <Row>
                    <FormGroup>
                      <div className="text-center">
                        <div className="profile-pic-upload signature-adjustment">
                          <Col>
                            <div className="image-holder">
                              <div className="widthheight256128">
                                <Label className="custom-file-upload" title="Update your signature">
                                  <FontAwesomeIcon icon={faCamera} />
                                  {` `}
                                  <Input
                                    type="file"
                                    onChange={(event) => {
                                      handleSignature(event.target.files[0]);
                                    }}
                                  ></Input>
                                </Label>
                                {signature && !uploadedSignPreview && (
                                  <img
                                    alt="signature"
                                    src={signature}
                                    className="img-responsive mt-2"
                                    width="256"
                                    height="128"
                                  />
                                )}
                                {uploadedSignPreview && (
                                  <img
                                    alt="uploadedSign"
                                    src={uploadedSignPreview}
                                    className="img-responsive mt-2"
                                    width="256"
                                    height="128"
                                  />
                                )}
                                {!signature && !uploadedSignPreview && apiLoaded && (
                                  <img
                                    alt="signatureIcon"
                                    src={signatureIcon}
                                    className="img-responsive mt-2"
                                    width="256"
                                    height="128"
                                  />
                                )}
                                {!PhysicianData.signatureImageString && !uploadedSignPreview && (
                                  <img
                                    alt="signatureIcon"
                                    src={signatureIcon}
                                    className="img-responsive mt-2"
                                    width="256"
                                    height="128"
                                  />
                                )}
                                {/* {!apiLoaded && <div className="sign-placeholder" />} */}
                              </div>
                            </div>
                            <p className="image-uploader-text">
                              For best results, use an image at least 256px by 128px in jpg format
                          </p>
                          </Col>
                        </div>
                      </div>
                    </FormGroup>
                  </Row>
                </Col>
                <Col>
                  <FormGroup>
                    <Row>
                      <Col md="2">
                        <Label for="name">Title</Label>
                        <span data-tip={title}>
                          <Input
                            disabled
                            type="text"
                            name="name"
                            id="name"
                            value={title}
                            maxLength={127}
                            onChange={(e) => {
                              setName(e.target.value);
                            }}
                          />
                        </span>
                      </Col>
                      <Col>
                        <Label for="name">First Name</Label>
                        <span data-tip={firstName}>
                          <Input
                            disabled
                            type="text"
                            name="name"
                            id="name"
                            value={firstName}
                            maxLength={127}
                            onChange={(e) => {
                              setFirstName(e.target.value);
                            }}
                          />
                        </span>
                      </Col>
                      <Col>
                        <Label for="name">Middle Name</Label>
                        <span data-tip={middleName}>
                          <Input
                            disabled
                            type="text"
                            name="name"
                            id="name"
                            value={middleName}
                            maxLength={127}
                            onChange={(e) => {
                              setMiddleName(e.target.value);
                            }}
                          />
                        </span>
                      </Col>
                      <Col>
                        <Label for="name">Last Name</Label>
                        <span data-tip={lastName}>
                          <Input
                            disabled
                            type="text"
                            name="name"
                            id="name"
                            value={lastName}
                            maxLength={127}
                            onChange={(e) => {
                              setLastName(e.target.value);
                            }}
                          />
                        </span>
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Label for="biography">Profile</Label>
                    <ReactQuill
                      readOnly
                      placeholder="Profile"
                      name="biography"
                      value={biography}
                      maxLength={300}
                      onChange={(value) => {
                        setBiography(value);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
            <div className="professional_practice_details">
              <h5>Professional Information</h5>
              <Row>
                <Col md="6" lg="6">
                  <FormGroup>
                    <Label for="speciality">Speciality * </Label>
                    <Select
                      isDisabled
                      className="react-select-container"
                      classNamePrefix="react-select"
                      options={SpecialityList}
                      onChange={(event) => SpecialityHandler(event)}
                      value={SelectedSpeciality}
                      isSearchable
                      isMulti
                      placeholder="Select Speciality "
                    />
                  </FormGroup>
                </Col>
                
                {PhysicianData.appUserCountry === 223 && PhysicianData.userType === 3 ? <>
                <Col md="3" lg="3" >
                  <FormGroup>
                    <Label for="npi">NPI</Label>
                    <Input
                      disabled
                      type="number"
                      name="npi"
                      id="npi"
                      value={npi}
                      onChange={(event) => setNpi(event)}
                    />
                  </FormGroup>
                </Col>
                <Col md="3" lg="3" >
                <FormGroup>
                  <Label for="Website">Website</Label>
                  <Input
                    disabled
                    type="text"
                    name="personalWebsite"
                    id="personalWebsite"
                    value={personalWebsite}
                    maxLength={127}
                    onChange={(event) => setPersonalWebsite(event)}
                  />
                </FormGroup>
              </Col> </> : 
              <Col md="6" lg="6">
                  <FormGroup>
                    <Label for="personalWebsite">Website</Label>
                    <Input
                      disabled
                      type="text"
                      name="personalWebsite"
                      id="personalWebsite"
                      value={personalWebsite}
                      maxLength={127}
                      onChange={(event) => setPersonalWebsite(event)}
                    />
                  </FormGroup>
                </Col>}

                <Col md="6" lg="6">
                  <FormGroup>
                    <Label for="qualification">Credentials *</Label>
                    <AvField
                      disabled
                      type="textarea"
                      name="qualification"
                      id="qualification"
                      className="text-area"
                      value={qualification}
                      required
                      maxLength={50}
                      onChange={(e) => {
                        setQualification(e.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="6" lg="6">
                  <FormGroup>
                    <Label for="affiliations">Affiliations</Label>
                    <AvField
                      disabled
                      type="textarea"
                      name="affiliations"
                      id="affiliations"
                      className="text-area"
                      value={affiliations}
                      maxLength={127}
                      onChange={(e) => {
                        setAffiliations(e.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>

                <Col md="6" lg="6">
                  <FormGroup>
                    <Label for="medicalSchool">Medical School</Label>
                    <Input
                      disabled
                      type="text"
                      name="medicalSchool"
                      id="medicalSchool"
                      value={medicalSchool}
                      maxLength={100}
                      onChange={(e) => {
                        setMedicalSchool(e.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="6" lg="6">
                  <FormGroup>
                    <Label>Languages Spoken *</Label>
                    <Select
                      isDisabled
                      onChange={(event) => LanguageSpokenHandler(event)}
                      closeMenuOnSelect={false}
                      isMulti
                      defaultValue={SelectedLanguageSpoken}
                      options={LanguagesList}
                    />
                  </FormGroup>
                </Col>
                {/*
                <Col md="6" lg="6">
                  <FormGroup>
                    <Label for="idCardPassport">ID Card / Passport</Label>
                    <Input
                      disabled
                      type="text"
                      name="idCardPassport"
                      id="idCardPassport"
                      value={idCardPassport}
                      maxLength={15}
                      onChange={(e) => {
                        setIdCardPassport(e.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="6" lg="6">
                  <FormGroup>
                    <Label for="taxNumber">Tax Number</Label>
                    <Input
                      disabled
                      type="text"
                      name="taxNumber"
                      id="taxNumber"
                      value={taxNumber}
                      maxLength={25}
                      onChange={(e) => {
                        setTaxNumber(e.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
                 <Col md="6" lg="6">
                <FormGroup>
                  <Label for="country">Country Of Residence</Label>
                  <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={CountryList}
                    onChange={(event) => CountryHandler(event)}
                    value={Country}
                    isSearchable
                    placeholder="Select Country "
                  />
                </FormGroup>
              </Col>
              <Col md="6" lg="6">
                <FormGroup>
                  <Label for="currency">Currency</Label>
                  <Input
                    type="text"
                    name="currency"
                    id="currency"
                    value={Currency}
                    disabled
                  />
                </FormGroup>
              </Col> */}
              </Row>
            </div>
            <Row>
              <Col md="6">
                <div className="office_contact_details">
                  <h5>Office Contact Details</h5>
                  <FontAwesomeIcon
                    icon={faStar}
                    color={FavContact !== "residence" ? "#38c485" : "#9e9e9e"}
                    title={FavContact !== "residence" ? "Preferred Contact" : ""}
                  // onClick={() => {
                  //   if (FavContact !== "office") setFavContact("office");
                  // }}
                  />
                  <Row>
                    <Col md="12" lg="12">
                      <FormGroup>
                        <Label for="office_address">Country</Label>
                        <Select
                          isDisabled
                          className="react-select-container"
                          classNamePrefix="react-select"
                          options={CountryList}
                          onChange={(event) => OfficeCountryHandler(event)}
                          value={OfficeCountry}
                          isSearchable
                          placeholder="Select Country "
                        />
                      </FormGroup>
                      <FormGroup>
                        <Row>
                          <Col>
                            <Label for="officephoneNumber">Country Code</Label>
                            <Input
                              disabled
                              name="phoneNumber"
                              id="phoneNumber"
                              value={OfficeCountryIsdCode}
                            />
                          </Col>
                          <Col>
                            <Label for="officephoneNumber">Contact Number</Label>
                            <Input
                              disabled
                              type="number"
                              name="phoneNumber"
                              id="phoneNumber"
                              value={phoneNumber}
                              maxLength={16}
                              onChange={(e) => {
                                setPhoneNumber(e.target.value);
                              }}
                            />
                          </Col>
                        </Row>
                      </FormGroup>
                      <FormGroup>
                        <Label for="officeAddress">Address </Label>
                        <p for="officeAddress">{PhysicianData.address ? PhysicianData.address : "N/A"} </p>
                      </FormGroup>
                      <Row xs="12">
                        <Col >
                        <Label for="officestate">State/ Province/ County </Label>

                        </Col>
                        <Col  >
                        <Label for="officecity">City/ Town </Label>

                        </Col>
                        <Col  >
                        <Label for="officezipcode">Post Code </Label>

                        </Col>
                      </Row>

                      <Row xs="12">
                        <Col >
                          <Input
                            disabled
                            value={
                              OfficeAutoState ? OfficeAutoState : OfficeState
                            }
                            onChange={(event) => {
                              setOfficeState(event.target.value);
                              setOfficeAutoState(event.target.value);
                            }}
                          />

                        </Col>
                        <Col  >
                          <Input
                            disabled
                            value={OfficeAutoCity ? OfficeAutoCity : OfficeCity}
                            onChange={(event) => {
                              setOfficeCity(event.target.value);
                              setOfficeAutoCity(event.target.value);
                            }}
                          />
                        </Col>
                        <Col  >
                          <Input
                            disabled
                            value={OfficeAutoZipCode ? OfficeAutoZipCode : OfficeZipCode}
                            onChange={(event) => {
                              setOfficeZipCode(event.target.value);
                              setOfficeAutoZipCode(event.target.value);
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md="6">
                <div className="home_contact_details">
                  <h5>Residence Contact Details</h5>
                  <FontAwesomeIcon
                    icon={faStar}
                    color={FavContact === "residence" ? "#38c485" : "#9e9e9e"}
                    title={FavContact === "residence" ? "Preferred Contact" : ""}
                  // onClick={() => {
                  //   if (FavContact !== "residence")
                  //     setFavContact("residence");
                  // }}
                  />
                  <Row>
                    <Col md="12" lg="12">
                      <FormGroup>
                        <Label for="home_country">Country</Label>
                        <Select
                          isDisabled
                          className="react-select-container"
                          classNamePrefix="react-select"
                          options={CountryList}
                          onChange={(event) => HomeCountryHandler(event)}
                          value={HomeCountry}
                          isSearchable
                          placeholder="Select Country "
                        />
                      </FormGroup>
                      <FormGroup>
                        <Row>
                          <Col sm="4">
                            <Label for="homephoneNumber">Country Code</Label>
                            <Input
                              disabled
                              name="homephoneNumber"
                              id="homephoneNumber"
                              value={HomeCountryIsdCode}
                            />
                          </Col>
                          <Col>
                            <Label for="homephoneNumber">Contact Number</Label>
                            <Input
                              disabled
                              type="number"
                              name="homephoneNumber"
                              id="homephoneNumber"
                              value={HomePhoneNumber}
                              maxLength={16}
                              onChange={(e) => {
                                setHomePhoneNumber(e.target.value);
                              }}
                            />
                          </Col>
                        </Row>
                      </FormGroup>
                      <FormGroup>
                        <Label for="HomeAddress">Address </Label>
                        <p for="HomeAddress">{PhysicianData.homeAddress ? PhysicianData.homeAddress : "N/A"} </p>
                      </FormGroup>
                   
                      <Row xs="12">
                        <Col >
                          <Label for="homestate">State/ Province/ County </Label>

                        </Col>
                        <Col  >
                          <Label for="homecity">City/ Town  </Label>

                        </Col>
                        <Col  >
                          <Label for="homezipcode">Post Code </Label>

                        </Col>
                      </Row>
                      <Row xs="12">
                        <Col >
                        <Input
                            disabled
                            value={HomeAutoState ? HomeAutoState : HomeState}
                            onChange={(event) => {
                              setHomeState(event.target.value);
                              setHomeAutoState(event.target.value);
                            }}
                          />

                        </Col>
                        <Col  >
                        <Input
                            disabled
                            value={HomeAutoCity ? HomeAutoCity : HomeCity}
                            onChange={(event) => {
                              setHomeCity(event.target.value);
                              setHomeAutoCity(event.target.value);
                            }}
                          />
                        </Col>
                        <Col  >
                        <Input
                            disabled
                            value={
                              HomeAutoZipCode ? HomeAutoZipCode : HomeZipCode
                            }
                            onChange={(event) => {
                              setHomeZipCode(event.target.value);
                              setHomeAutoZipCode(event.target.value);
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <AvGroup check className="mb-2 display-none">
              <div className="margin-1em">
                <AvField
                  type="checkbox"
                  id="checkbox"
                  label="validForTelemedicine"
                  name="validForTelemedicineCheck"
                  value={validTeleMedicine}
                  required
                  onChange={(e) => {
                    setvalidTeleMedicine(!validTeleMedicine);
                  }}
                />
              </div>
            </AvGroup>
            <div className="verify-physician-container">
              <Row className="verify-document-row">
                <Button
                  disabled={spinnerStyle}
                  color="success"
                  type="submit"
                  className="verify-button"
                  onClick={() => {
                    runValidation();
                  }}
                >
                  Save Changes
          </Button>
              </Row>
            </div>
          </AvForm>
        </CardBody>
      </Card>
      {spinnerStyle && (
        <Spinner color="success" />
      )}
    </>
  );
};

export default ProfileInfo;
