import React, { useState, useContext, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Collapse, Card, CardBody, Label, CardHeader, Spinner } from "reactstrap";
import Select from 'react-select';
import DropZone from './DropZone';
import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { GlobalState } from '../../../../contextAPI/Context';

const DocUploadModal = (props) => {
  // DEPENDENCY VARIABLES  
  const {
    proofOfIdModal,
    setProofOfIdModal,
    setProofOfIdConfirmation,
    userId,
    uploadSelfie,
    setUploadSelfie,
  } = props;

  // CONTEXT VARIABLES
  const { reloadPhysicianIdentityData, setReloadPhysicianIdentityData } = useContext(GlobalState);

  // GET TOKEN
  let token = localStorage.getItem("token");
  let userID = localStorage.getItem("userId");
  // OBTAINING BASE URL BASED ON ENVIRONMENT
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

  // LOCAL VARIABLES
  const [spinner, setSpinner] = useState(false);
  const [collapse, setCollapse] = useState(false)
  const [selfieCollapse, setSelfieCollapse] = useState(false)
  const [fileReady, setFileReady] = useState(false)
  const [documentTypeId, setDocumentTypeId] = useState("")
  const [documentTypeIdForAPI, setDocumentTypeIdForAPI] = useState("")
  const [idProofFile, setIdProofFile] = useState("")
  const [selfieFile, setSelfieFile] = useState("")

  const IdOptions = [
    { value: 1, label: "ID Card" },
    { value: 4, label: "Passport" },
    { value: 3, label: "Drivers License" },
    { value: 11, label: "Residence Permit" },
  ]

  // eslint-disable-next-line
  const options = [
    { value: 19, label: "Selfie with proof of ID" },
  ]

  const documentUpload = (
    <>
      <Card className="kyc-bg-color2">
        <CardHeader tag="h5">Upload Document </CardHeader>
        <CardBody>

          {/* DRAG AND DROP COMPONENT  */}
          <DropZone
            setFileReady={setFileReady}
            documentTypeId={documentTypeId}
            setIdProofFile={setIdProofFile}
            setSelfieFile={setSelfieFile}
          />
        </CardBody>
      </Card>
      <Label>The file should be ' jpg, png or pdf ' and less than 5 MB in size</Label>
    </>
  )

  const selfieUpload = (
    <>
      {/* <Card className="kyc-bg-color2">
        <CardHeader tag="h5">Document Type</CardHeader>
        <CardBody>
          <Row>
            <Col xs="6">
              <Select
                name="doc-type"
                options={options}
                defaultValue={[options[0]]}
                isDisabled
              />
            </Col>
          </Row>
        </CardBody>
      </Card> */}
      <Card className="kyc-bg-color2">
        <CardHeader tag="h5">Selfie Upload</CardHeader>
        <CardBody>

          {/* DRAG AND DROP COMPONENT  */}
          <DropZone
            setFileReady={setFileReady}
            documentTypeId={documentTypeId}
            setIdProofFile={setIdProofFile}
            setSelfieFile={setSelfieFile}
          />
        </CardBody>
      </Card>
      <Label>The file should be ' jpg, jpeg or png ' and less than 5 MB in size</Label>
    </>
  )

  const toggle = () => {
    setProofOfIdModal(!proofOfIdModal);
    setCollapse(false);
    setSelfieCollapse(false);
    setFileReady(false);
    setDocumentTypeId("");
    setDocumentTypeIdForAPI("");
    setIdProofFile("");
    setSelfieFile("");
    setProofOfIdConfirmation(false);
    setUploadSelfie(false);
  };

  const confirmation = () => {
    setProofOfIdConfirmation(true);
    if (uploadSelfie) {
      apiCallForSelfieUpload();
    } else {
      apiCallForIdProofUpload();
    }
  };
  
// eslint-disable-next-line
  const handleNext = () => {
    setCollapse(false);
    setSelfieCollapse(true);
    setDocumentTypeId(19);
  }

  const apiCallForIdProofUpload = () => {
    setSpinner(true);
    // CREATING FORM DATA 
    const FileFormData = new FormData();
    FileFormData.append("file", idProofFile[0]);
    FileFormData.set("type", documentTypeIdForAPI);
    FileFormData.set("userID", userID);
    FileFormData.set("user_id", userId);

    axios
      .post(
        `${API_ENDPOINT}/user/add-document/`,
        FileFormData,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then(function (response) {
        // SECOND API CALL TO UPLOAD SELFIE DOCUMENT
        // apiCallForSelfieUpload();
        setSpinner(false);
        toggle();
        setReloadPhysicianIdentityData(reloadPhysicianIdentityData + 1)
      })
      .catch(function (error) {
        toastr.error(
          "Error Occurred",
          "File not uploaded "
        );
        setSpinner(false);
      });
  }

  const apiCallForSelfieUpload = () => {
    setSpinner(true);
    // CREATING FORM DATA 
    const FileFormData = new FormData();
    FileFormData.append("file", selfieFile[0]);
    FileFormData.set("type", 19);
    FileFormData.set("userID", userID);
    FileFormData.set("user_id", userId);
    axios
      .post(
        `${API_ENDPOINT}/user/add-document/`,
        FileFormData,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then(function (response) {
        toastr.success(
          "Done",
          "Document added successfully"
        );
        setSpinner(false);
        toggle();
        setReloadPhysicianIdentityData(reloadPhysicianIdentityData + 1)
      })
      .catch(function (error) {
        toastr.error(
          "Error Occurred",
          "File not uploaded "
        );
        setSpinner(false);

      });
  }

  // EFFECT TO SHOW SELFIE UPLOAD DIRECTLY 
  useEffect(() => {

    if (uploadSelfie) {
      setSelfieCollapse(true)
      setDocumentTypeId(19)
    }

  }, [uploadSelfie])

  return (
    <div>
      <Modal isOpen={proofOfIdModal} toggle={toggle}>
        <ModalHeader tag="h4" cssModule={{'modal-title': 'w-100 text-center'}}  toggle={toggle}>Upload Document</ModalHeader>
        <ModalBody className="doc-upload-modal-height disable-scrollbars">
          <Collapse isOpen={!selfieCollapse}>
            <Card className="kyc-bg-color2">
              <CardHeader tag="h5">Document Type</CardHeader>
              <CardBody>
                <Row>
                  <Col xs="6">
                    <Select
                      name="doc-type"
                      options={IdOptions}
                      isDisabled={fileReady ? true : false}
                      onChange={(e) => {
                        setCollapse(true);
                        setDocumentTypeId(e.value);
                        setDocumentTypeIdForAPI(e.value);
                      }}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Collapse>
          <Collapse isOpen={collapse}>
            <Row>
              <Col>

                {/* STANDARD FILE UPLOAD COMPONENT  */}
                {documentUpload}
              </Col>
            </Row>
          </Collapse>
          <Collapse isOpen={selfieCollapse}>
            <Row>
              <Col>

                {/* SELFIE FILE UPLOAD COMPONENT  */}
                {selfieUpload}
              </Col>
            </Row>
          </Collapse>
        </ModalBody>
        {spinner ? <Spinner color="success" /> : ""}
        <ModalFooter>
          {/* {!selfieCollapse && fileReady ? <Button className="next-button button-width" color="success" onClick={() => { handleNext() }}>Next</Button> : ""}
          {" "} */}
          {fileReady ? <Button className="yes-button button-width" onClick={confirmation}>Save</Button> : ""}
          {" "}
          <Button outline className="no-button button-width" onClick={toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DocUploadModal;
