import React, { useContext, useEffect } from "react";
import { useLocation, NavLink } from "react-router-dom";
import classNames from "classnames";
import { IconContext } from "react-icons";
import { MdDashboard,MdMedicalServices } from "react-icons/md";
import { FaPrescriptionBottleAlt, FaUserMd,FaPills,FaHandshake, FaCalendarCheck } from "react-icons/fa";
import { HiUsers } from "react-icons/hi";
import { GiHeartBottle } from "react-icons/gi";
import { RiNurseFill, RiHandHeartFill, RiCoupon2Fill, RiSettings4Fill,RiMoneyDollarBoxFill } from "react-icons/ri";
import { GlobalState } from "../contextAPI/Context";
import "./Layout.css";
import ReactTooltip from "react-tooltip";

import equipologo from "../assets/img/logos/equipo_logo_060117_white.png";
import equiposmalllogo from "../assets/img/logos/NVC_Equipo_LOGO.png";


const category = [
{
name: "Dashboard",
path: "/dashboard",
icon: <MdDashboard style={{fontSize:"18px"}}/>,
},
{
name: "Patients",
path: "/patients",
icon: <HiUsers style={{fontSize:"18px"}}/>,
},
{
name: "Doctors",
path: "/doctors",
icon: <FaUserMd style={{fontSize:"18px"}}/>,
},
{
name: "Nurses",
path: "/nurses",
icon: <RiNurseFill style={{fontSize:"18px"}}/>,
},
{
name: "Therapists",
path: "/therapists",
icon: <GiHeartBottle style={{fontSize:"18px"}}/>,
},
{
name: "Carers",
path: "/carers",
icon: <RiHandHeartFill style={{fontSize:"18px"}}/>,
},
{
name: "Appointments",
path: "/appointments",
icon: <FaCalendarCheck style={{fontSize:"18px"}} />,
},
{
name: "Prescriptions",
path: "/prescriptions",
icon: <FaPrescriptionBottleAlt style={{fontSize:"18px"}} />,
},
{
name: "Pharmacy",
path: "/pharmacy",
icon: <MdMedicalServices style={{fontSize:"18px"}} />,
},
{
name: "Insurances",
path: "/insurances",
icon: <RiMoneyDollarBoxFill style={{fontSize:"18px"}}/>,
},
{
name: "Coupons",
path: "/coupons",
icon: <RiCoupon2Fill style={{fontSize:"18px"}} />,
},
{
name: "Healthcare Partners",
path: "/healthcare-Partners",
icon: <FaHandshake style={{fontSize:"18px"}}/>,
},
{
name: "Medication",
path: "/medication",
icon: <FaPills style={{fontSize:"18px"}}/>,
},
{
name: "System Settings",
path: "/system-settings",
icon: <RiSettings4Fill style={{fontSize:"18px"}}/>,
},
];
const SidebarItem = ({ name, icon, to }) => {
return (
<>
<ReactTooltip className="card-tooltip" id="side-bar" />
<li className="sidebar-item" data-tip={name} data-for="side-bar">
<NavLink
exact
to={to}
className="sidebar-link"
activeStyle={{ color: "black" }}
activeClassName="side-bar-active-link"
>
<IconContext.Provider
value={{
className: "align-middle mr-2",
}}
>
{icon}
</IconContext.Provider>
<span className="align-middle side-bar-text">{name}</span>
</NavLink>
</li>
</>
);
};

const Sidebar = (props) => {
// GLOBAL CONTEXT VARIABLES 
const { hideMenu, setHideMenu } = useContext(GlobalState)

// SIDEBAR AUTO HIDE LOGIC
let location = useLocation()
const handleSidebarToggle = () => {
if (location.pathname === "/dashboard") { setHideMenu(true) }
if (location.pathname === "/patients") { setHideMenu(true) }
if (location.pathname === "/doctors") { setHideMenu(true) }
if (location.pathname === "/nurses") { setHideMenu(true) }
if (location.pathname === "/therapists") { setHideMenu(true) }
if (location.pathname === "/carers") { setHideMenu(true) }
if (location.pathname === "/appointments") { setHideMenu(true) }
if (location.pathname === "/prescriptions") { setHideMenu(true) }
if (location.pathname === "/pharmacy") { setHideMenu(true) }
if (location.pathname === "/insurances") { setHideMenu(true) }
if (location.pathname === "/coupons") { setHideMenu(true) }
if (location.pathname === "/healthcare-Partners") { setHideMenu(true) }
if (location.pathname === "/medication") { setHideMenu(true) }
if (location.pathname === "/system-settings") { setHideMenu(true) }
// console.log('pathname', location.pathname)
}

useEffect(() => { handleSidebarToggle() }, [location]) // eslint-disable-line
useEffect(() => { setHideMenu(true) }, []) // eslint-disable-line
return (
<>
<nav className={classNames("sidebar", hideMenu && "toggled")}>
<div className="sidebar-content">
<a className={classNames("sidebar-brand")} href="/">
{hideMenu && window.innerWidth > 1024 ? (
<img src={equiposmalllogo} className="img-fluid" alt="logo" />
) : (
<img
src={equipologo}
alt="Admin App Logo"
className="img-fluid"
width="200"
style={{width:125, height:55,marginRight:20}}
/>
)}

</a>
<ul className="sidebar-nav">
{category.map((category, index) => (
<SidebarItem
key={index.toString()}
name={category.name}
to={category.path}
icon={category.icon}
/>
))}
</ul>

{/* POWERED BY EQUIPO */}
{/* <div className="equipo-logo">
{hideMenu ? "" : <p className="powered-by">powered by</p>}
<a href="https://equipo.io/" target="_blank" rel="noopener noreferrer">
<img
src={equipoLogo}
className="img-fluid mb-2"
alt="equipo logo"
/>
</a>
</div> */}

</div>
</nav>
</>
);
};

export default Sidebar;

