import React, { useState, useEffect, useContext } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  Label,
  Row,
  Input
} from "reactstrap";
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import './CouponStyle.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle, faCheckCircle, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { GlobalState } from '../../contextAPI/Context';
import ToggleButton from 'react-toggle-button';

const CouponInput = (props) => {
  // CONTEXT VARIABLES
  const { couponEditData } = useContext(GlobalState)

  // LOCAL VARIABLES
  const animatedComponents = makeAnimated();
  const [Group, setGroup] = useState("");
  const [Groups, setGroups] = useState([]);

  const [Type, setType] = useState("");
  const [Types, setTypes] = useState([]);
  const [TypeValue, setTypeValue] = useState([]);

  const [Patient, setPatient] = useState("");
  const [Patients, setPatients] = useState([]);

  const [CouponName, setName] = useState("");
  const [CouponDescription, setDescription] = useState("");
  const [CouponCount, setCount] = useState("");
  const [CouponDiscount, setDiscount] = useState("");
  const [CouponExpiry, setExpiry] = useState("");
  const [CouponStatus, setCouponStatus] = useState(true);
  const [PatientMaxCount, setPatientMaxCount] = useState("");
  const [ShowPerPatient, setShowPerPatient] = useState(true);
  const [NameValidated, setNameValidated] = useState("blank");
  const [PhysicianName, setPhysicianName] = useState("");

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

  const GroupList = Groups.map((key) => {
    return { value: key.id, label: key.name }
  });

  const TypeList = Types.map((key) => {
    return { value: key.id, label: key.displayName }
  });

  const PatientList = Patients.map((key) => {
    return { value: key.id, label: key.name }
  });

  // const nameHandler = (value) => {
  //   setNameValidated("blank")
  //   if (value.length <= 12) setName(value);
  // };
  const nameHandler = (value) => {
    const containsInvalidCharacters = /[^A-Z0-9%-]/.test(value);

    if (containsInvalidCharacters) {
      toastr.error('Invalid attempt', 'Please try again');
    } else {
      const sanitizedValue = value.replace(/[^A-Z0-9%-]/g, '');
      setNameValidated("blank");

      if (sanitizedValue.length <= 12) {
        setName(sanitizedValue);
      } else {
        toastr.warning('Name exceeded length','Please limit it to 12 characters or fewer.');
      }
    }
  };
  const descriptionHandler = (value) => {
    if (value.length <= 500) {setDescription(value);}
  };

  const discountHandler = (num) => {
    let decimal = false
    let value = ""
    num % 1 !== 0 ? decimal = true : decimal = false
    if (decimal) {
      value = Math.round(num * 100) / 100;
    } else {
      value = num;
    }

    if (value <= 100) setDiscount(value);
    if (value > 100) setDiscount("100");
    if (value <= 0) setDiscount("1");
    if (value === "") setDiscount("");
  };

  const countHandler = (value) => {
    let decimal = false
    let integer = "";
    value % 1 !== 0 ? decimal = true : decimal = false
    if (CouponCount && value < CouponCount) { setPatientMaxCount(""); }
    if (!decimal && value < 1) { setCount(""); setPatientMaxCount(""); }
    if (!decimal && value > 0 && value.length < 6) setCount(value);
    if (decimal && value > 0 && value.length < 6) { integer = parseInt(value); setCount(integer); }
  };

  const usageHandler = (value) => {
    let decimal = false
    let integer = "";
    let number = "";
    value % 1 !== 0 ? decimal = true : decimal = false
    if (!decimal && value <= 0) setPatientMaxCount("0");
    if (decimal && value > 0 && value.length < 6 && CouponCount) {
      integer = parseInt(value);
      integer >= CouponCount ? number = CouponCount : number = integer
      setPatientMaxCount(number);
    }
    if (!decimal && value >= 0 && value.length < 6 && CouponCount) {
      number = parseInt(value);
      number >= CouponCount ? number = CouponCount : number = value
      setPatientMaxCount(number);
    }
  };

  const expiryHandler = (date) => {
    setExpiry(date)
  };

  const validate = () => {
    // VALIDATION LOGIC FOR UNUSED COUPONS 
    if (couponEditData.used === 0) {
      // VALIDATION FOR GLOBAL COUPON
      if (TypeValue === 19) {
        if (Type && CouponName && CouponDescription && CouponCount && CouponDiscount && CouponExpiry) {
          submitForm()
        } else {
          toastr.error("Required Fields Are Empty", "Please try again");
        }
      }

      // VALIDATION FOR CORPORATE COUPON
      if (TypeValue === 20) {
        if (Type && Group && CouponName && CouponDescription && CouponCount && CouponDiscount && CouponExpiry) {
          submitForm()
        } else {
          toastr.error("Required Fields Are Empty", "Please try again");
        }
      }

      // VALIDATION FOR PHYSICIAN COUPON
      if (TypeValue === 21) {
        if (Type && Group && CouponName && CouponDescription && CouponCount && CouponDiscount && CouponExpiry) {
          submitForm()
        } else {
          toastr.error("Required Fields Are Empty", "Please try again");
        }
      }

      // VALIDATION FOR PATIENT COUPON
      if (TypeValue === 22) {
        if (Type && Patient && CouponName && CouponDescription && CouponCount && CouponDiscount && CouponExpiry) {
          submitForm()
        } else {
          toastr.error("Required Fields Are Empty", "Please try again");
        }
      }

      // VALIDATION FOR BLANK FORM
      if (TypeValue.length === 0) {
        toastr.error("Required Fields Are Empty", "Please try again");
      }
    }

    // VALIDATION LOGIC FOR USED COUPONS 
    if (couponEditData.used !== 0) {
      // VALIDATION FOR GLOBAL COUPON
      if (TypeValue === 19) {
        if (CouponDescription && CouponCount && CouponDiscount && CouponExpiry) {
          submitForm()
        } else {
          toastr.error("Required Fields Are Empty", "Please try again");
        }
      }

      // VALIDATION FOR CORPORATE COUPON
      if (TypeValue === 20) {
        if (CouponName && CouponDescription && CouponCount && CouponDiscount && CouponExpiry) {
          submitForm()
        } else {
          toastr.error("Required Fields Are Empty", "Please try again");
        }
      }

      // VALIDATION FOR PHYSICIAN COUPON
      if (TypeValue === 21) {
        if (CouponName && CouponDescription && CouponCount && CouponDiscount && CouponExpiry) {
          submitForm()
        } else {
          toastr.error("Required Fields Are Empty", "Please try again");
        }
      }

      // VALIDATION FOR PATIENT COUPON
      if (TypeValue === 22) {
        if (CouponName && CouponDescription && CouponCount && CouponDiscount && CouponExpiry) {
          submitForm()
        } else {
          toastr.error("Required Fields Are Empty", "Please try again");
        }
      }

      // VALIDATION FOR BLANK FORM
      if (TypeValue.length === 0) {
        toastr.error("Required Fields Are Empty", "Please try again");
      }
    }
  }

  const CouponFormData = {
    "coupon_id": couponEditData.id ? couponEditData.id : 0,
    "description": CouponDescription ? CouponDescription : "",
    "expiry_date": CouponExpiry ? moment(CouponExpiry).format("YYYY-MM-DD") : "",
    "value": CouponDiscount ? Number(CouponDiscount) : 0,
    "max_count": CouponCount ? Number(CouponCount) : 0,
    "patient_max_count": PatientMaxCount ? Number(PatientMaxCount) : 0,
    "type_id": TypeValue ? Number(TypeValue) : 0,
    "group_id": Group ? Number(Group.value) : 0,
    "patient_id": Patient && Patient !== "All" ? Number(Patient.value) : 0,
    "coupon_code": CouponName ? CouponName.trim() : "",
    "used_count": couponEditData.used ? Number(couponEditData.used) : 0
  }

  const submitForm = () => {
    // console.log('CouponFormData', CouponFormData)
    axios.post(API_ENDPOINT + '/external/update-coupon/', CouponFormData, {
      headers: {
        Authorization: props.authtoken,
      },
    })
      .then(function (response) {
        toastr.success("Coupon Updated Successfully");
        setTimeout(() => {
          window.location = "/coupons"
        }, 1000);
      })
      .catch(function (error) {
        toastr.error("Coupon Update Failed", error.response.data.message);
      });
  };


  const FetchCouponType = () => {
    axios.get(API_ENDPOINT + '/external/get-all-coupon-types/', {
      headers: {
        Authorization: props.authtoken,
      },
    })
      .then(res => {
        if (res.status === 200) {
          setTypes(res.data);
        }
      });
  }

  const FetchGroups = () => {
    axios.get(API_ENDPOINT + '/external/coupon-group-list/', {
      headers: {
        Authorization: props.authtoken,
      },
    })
      .then(res => {
        if (res.status === 200) {
          setGroups(res.data);
        }
      });
  }

  const FetchCorporateGroups = () => {
    axios.get(API_ENDPOINT + '/external/corporate-group-list/', {
      headers: {
        Authorization: props.authtoken,
      },
    })
      .then(res => {
        if (res.status === 200) {
          setGroups(res.data);
        }
      });
  }

  const FetchCorporatePatients = () => {
    const formData = { "group_id": Group.value };

    axios.post(API_ENDPOINT + '/user/list-corporate-patients/', formData, {
      headers: {
        Authorization: props.authtoken,
      },
    })
      .then(res => {
        if (res.status === 201) {
          setPatients(res.data);
        }
      });
  }

  const FetchAllPatients = () => {
    axios.get(API_ENDPOINT + '/user/patient-list/', {
      headers: {
        Authorization: props.authtoken,
      },
    })
      .then(res => {
        if (res.status === 200) {
          setPatients(res.data);
        }
      });
  }

  const validateCouponName = (value) => {
    const formData = { "coupon_code": value.trim().toString() };

    if (couponEditData.couponCode !== CouponName) {
      axios.post(API_ENDPOINT + '/external/validate-coupon/', formData, {
        headers: {
          Authorization: props.authtoken,
        },
      })
        .then(function (response) {
          setNameValidated(true);
        })
        .catch(function (error) {
          toastr.error(`${CouponName} Coupon Already Exist!`, "Please try another coupon name");
          setNameValidated(false);
        });
    }
  }

  const GroupHandler = (value) => {
    let temp = [];
    setGroup(value);
    temp.push(value ? value.value : "");
  };

  const TypeHandler = (value) => {
    let temp = [];
    setType(value);
    setGroup("");
    setPatient("");
    setPatientMaxCount("");
    temp.push(value ? value.value : "");
    setTypeValue(temp ? parseInt(temp) : []);
  };

  const PatientHandler = (value) => {
    setPatient(value);
    setPatientMaxCount("")
  };

  const updatedStatus = {
    coupon_id: couponEditData.id,
    status: couponEditData.status === 1 ? 0 : 1,
  };

  const updateCouponStatus = () => {
    axios.post(
      API_ENDPOINT + "/external/coupon-status-update/",
      updatedStatus,
      {
        headers: {
          Authorization: props.authtoken,
        },
      }
    )
      .then((res) => {
        if (res.data.message === "Updated Coupon Status") {
          toastr.success("Status Updated");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const perPatientUsageDisplayLogicForGlobal = () => {
    if (TypeValue === 19) setShowPerPatient(true);
  }

  const perPatientUsageDisplayLogicForCorporate = () => {
    if (TypeValue === 20 && Patient && Patient.value) {
      setShowPerPatient(false);
    }
    if (TypeValue === 20 && !Patient) {
      setShowPerPatient(true);
    }
  }

  const perPatientUsageDisplayLogicForPatient = () => {
    if (TypeValue === 22) setShowPerPatient(false);
  }

  const perPatientUsageDisplayLogicForPhysician = () => {
    if (TypeValue === 21) setShowPerPatient(true);
  }

  // EFFECT FOR POPULATING AUTO TYPE FIELD FOR UNUSED COUPONS
  useEffect(() => {
    if (couponEditData.used === 0) {
      let value = ""
      value = couponEditData ? { "value": couponEditData.type_id, "label": couponEditData.type } : "";
      TypeHandler(value)
    }
  }, [couponEditData])

  // EFFECT FOR POPULATING AUTO GROUP FIELD FOR UNUSED COUPONS
  useEffect(() => {
    if (couponEditData.used === 0 && couponEditData.type_id !== 20) {
      let value = ""
      value = couponEditData ? { "value": couponEditData.groupID, "label": couponEditData.physician_name } : "";
      GroupHandler(value)
    }
  }, [couponEditData])

  // EFFECT FOR POPULATING AUTO GROUP FIELD FOR UNUSED "CORPORATE" COUPONS
  useEffect(() => {
    if (couponEditData.used === 0 && couponEditData.type_id === 20) {
      let value = ""
      value = couponEditData ? { "value": couponEditData.groupID, "label": couponEditData.corporate_name } : "";
      GroupHandler(value)
    }
  }, [couponEditData])

  // EFFECT FOR POPULATING AUTO PATIENT FIELD FOR UNUSED COUPONS
  useEffect(() => {
    if (couponEditData.used === 0) {
      let value = ""
      value = couponEditData ? { "value": couponEditData.patientID, "label": couponEditData.patient_name } : "";
      PatientHandler(value)
    }
  }, [couponEditData])

  // EFFECTS FOR CALLING ADDITIONAL API CALLS IF COUPON USAGE IS 0 
  useEffect(() => { if (couponEditData.used === 0) { FetchGroups(); FetchCouponType(); } }, [couponEditData])// eslint-disable-line

  // EFFECTS FOR FETCHING PATIENT LIST BASED ON TYPE AND GROUP SELECTION
  useEffect(() => { if (TypeValue === 20 && couponEditData.used === 0) FetchCorporateGroups() }, [TypeValue])// eslint-disable-line
  useEffect(() => { if (Group && TypeValue === 20 && couponEditData.used === 0) FetchCorporatePatients() }, [Group])// eslint-disable-line
  useEffect(() => { if (TypeValue === 21 && couponEditData.used === 0) FetchGroups() }, [TypeValue])// eslint-disable-line
  useEffect(() => { if (TypeValue === 22 && couponEditData.used === 0) FetchAllPatients() }, [TypeValue])// eslint-disable-line

  // EFFECT FOR POPULATING INPUT FIELDS FOR USED COUPONS
  useEffect(() => {
    if (couponEditData && couponEditData.used !== 0) {
      setType(couponEditData.type ? couponEditData.type : "")
      setTypeValue(couponEditData.type_id ? couponEditData.type_id : "")
      setName(couponEditData.couponCode ? couponEditData.couponCode : "")
      setDescription(couponEditData.couponDescription ? couponEditData.couponDescription : "")
      setCount(couponEditData.allowed_count ? couponEditData.allowed_count : "")
      setPatientMaxCount(couponEditData.patientMaxUsageCount ? couponEditData.patientMaxUsageCount : "")
      setDiscount(couponEditData.discount_percent ? couponEditData.discount_percent : "")
      setExpiry(couponEditData.expiryDate ? moment(couponEditData.expiryDate).toDate() : "")
      setPatient(couponEditData.patient_name ? couponEditData.patient_name : "")
      setGroup(couponEditData.corporate_name ? couponEditData.corporate_name : "")
      setCouponStatus(couponEditData.status === 1 ? true : false)
      setPhysicianName(couponEditData.physician_name ? couponEditData.physician_name : "")
    }
  }, [couponEditData])

  // EFFECT FOR POPULATING INPUT FIELDS FOR UNUSED COUPONS
  useEffect(() => {
    if (couponEditData && couponEditData.used === 0) {
      setName(couponEditData.couponCode ? couponEditData.couponCode : "")
      setDescription(couponEditData.couponDescription ? couponEditData.couponDescription : "")
      setCount(couponEditData.allowed_count ? couponEditData.allowed_count : "")
      setPatientMaxCount(couponEditData.patientMaxUsageCount ? couponEditData.patientMaxUsageCount : "")
      setDiscount(couponEditData.discount_percent ? couponEditData.discount_percent : "")
      setExpiry(couponEditData.expiryDate ? moment(couponEditData.expiryDate).toDate() : "")
      setCouponStatus(couponEditData.status === 1 ? true : false)
      setPhysicianName(couponEditData.physician_name ? couponEditData.physician_name : "")
    }
  }, [couponEditData])

  // EFFECT TO SHOW/HIDE "MAX ALLOWED USAGE (PER PATIENT)" BASED ON TYPE
  useEffect(() => { perPatientUsageDisplayLogicForCorporate() }, [TypeValue, Patient])// eslint-disable-line
  useEffect(() => { perPatientUsageDisplayLogicForGlobal() }, [TypeValue])// eslint-disable-line
  useEffect(() => { perPatientUsageDisplayLogicForPatient() }, [TypeValue])// eslint-disable-line
  useEffect(() => { perPatientUsageDisplayLogicForPhysician() }, [TypeValue])// eslint-disable-line

  return (
    <div className="coupon_input">
      <Row>
        <Col lg="6">
          <Card>
            <CardHeader>
              <CardTitle tag="h5">Coupon Details</CardTitle>
            </CardHeader>
            <CardBody>
              <FormGroup>
                <Label>Type <span className="required"> * </span></Label>
                {couponEditData.used !== 0
                  ? <Input
                    disabled
                    value={Type}
                  />
                  : <Select
                    placeholder="Select"
                    className="react-select-container"
                    classNamePrefix="react-select"
                    value={Type}
                    options={TypeList}
                    isSearchable
                    isClearable
                    isLoading={TypeList.length > 0 ? false : true}
                    onChange={(event) => TypeHandler(event)}
                  />
                }
              </FormGroup>

              {/* DYNAMIC DROPDOWN RENDERING LOGIC */}
              {/* TYPE = CORPORATE */}
              {TypeValue === 20 && couponEditData.used === 0 ?
                <>
                  <FormGroup>
                    <Label>Account / Group <span className="required"> * </span></Label>
                    <Select
                      placeholder="Select"
                      className="react-select-container"
                      classNamePrefix="react-select"
                      value={Group}
                      options={GroupList}
                      isSearchable
                      isClearable
                      isLoading={GroupList.length > 0 ? false : true}
                      onChange={(event) => GroupHandler(event)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Patient</Label>
                    <Select
                      placeholder="Select"
                      className="react-select-container"
                      classNamePrefix="react-select"
                      value={Patient}
                      options={PatientList}
                      isSearchable
                      isClearable
                      // isMulti
                      components={animatedComponents}
                      onChange={(event) => PatientHandler(event)}
                    />
                  </FormGroup>
                </>
                : ""}
              {TypeValue === 20 && couponEditData.used !== 0 && couponEditData.corporate_name ?
                <FormGroup>
                  <Label>Account / Group <span className="required"> * </span></Label>
                  <Input
                    disabled
                    value={Group}
                  />
                </FormGroup>
                : ""}

              {TypeValue === 20 && couponEditData.used !== 0 && couponEditData.patient_name ?
                <FormGroup>
                  <Label>Patient<span className="required"> * </span></Label>
                  <Input
                    disabled
                    value={Patient}
                  />
                </FormGroup>
                : ""}

              {/* TYPE = PHYSICIAN */}
              {TypeValue === 21 && couponEditData.used === 0 ? <FormGroup>
                <Label>Account / Group <span className="required"> * </span></Label>
                <Select
                  placeholder="Select"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  value={Group}
                  options={GroupList}
                  isSearchable
                  isClearable
                  isLoading={GroupList.length > 0 ? false : true}
                  onChange={(event) => GroupHandler(event)}
                />
              </FormGroup> : ""}
              {TypeValue === 21 && couponEditData.used !== 0 ? <FormGroup>
                <Label>Care Provider <span className="required"> * </span></Label>
                <Input
                  disabled
                  value={PhysicianName}
                />
              </FormGroup> : ""}

              {/* TYPE = PATIENT */}
              {TypeValue === 22 && couponEditData.used === 0 ?
                <FormGroup>
                  <Label>Patient <span className="required"> * </span></Label>
                  <Select
                    required
                    placeholder="Select"
                    className="react-select-container"
                    classNamePrefix="react-select"
                    value={Patient}
                    options={PatientList}
                    isSearchable
                    isClearable
                    // isMulti
                    isLoading={PatientList.length > 0 ? false : true}
                    components={animatedComponents}
                    onChange={(event) => PatientHandler(event)}
                  />
                </FormGroup>
                : ""}
              {TypeValue === 22 && couponEditData.used !== 0 ?
                <FormGroup>
                  <Label>Patient <span className="required"> * </span></Label>
                  <Input
                    disabled
                    value={Patient}
                  />
                </FormGroup>
                : ""}

              <Label>Name <span className="required"> * </span></Label>
              <Row>
                <Col>
                  {couponEditData.used !== 0
                    ? <Input
                      disabled
                      className="coupon-margin-bottom"
                      value={CouponName}
                    />
                    : (<>
                      <Input
                        onChange={event => nameHandler(event.target.value)}
                        onBlur={event => validateCouponName(event.target.value)}
                        type="text"
                        name="coupon_name"
                        className="coupon-margin-bottom"
                        placeholder="Coupon Code ( eg: FREE50%, SO-FIFTY )"
                        value={CouponName}
                        maxLength={120}
                      />
                      {NameValidated === true && CouponName ?
                        <FontAwesomeIcon icon={faCheckCircle} className="success-check" /> :
                        ""}
                      {NameValidated === false && CouponName ?
                        <FontAwesomeIcon icon={faExclamationCircle} className="failed-check" /> :
                        ""}
                    </>)
                  }
                </Col>
              </Row>
              <Label>Description <span className="required"> * </span></Label>
              <Input
                type="textarea"
                name="coupon_description"
                className="coupon-description coupon-margin-bottom"
                placeholder="Coupon Description"
                value={CouponDescription}
                maxLength={500}
                onChange={event => descriptionHandler(event.target.value)}
              />
              <Row>
                <Col>
                  <Label>Max Allowed Usage (Overall)<span className="required"> * </span></Label>
                  <Label className="right-align">Total Used: {couponEditData ? couponEditData.used : "0"}</Label>
                  <Input
                    onChange={event => countHandler(event.target.value)}
                    type="number"
                    name="coupon_count"
                    placeholder="Coupon Count"
                    className="coupon-margin-bottom"
                    value={CouponCount}
                  />

                </Col>
              </Row>
              {ShowPerPatient ?
                <>
                  <Label>Max Allowed Usage (Per Patient)</Label>
                  <Input
                    name="coupon_count_per_patient"
                    className="coupon-margin-bottom"
                    placeholder="Max Usage Count"
                    value={PatientMaxCount}
                    onChange={(event) => usageHandler(event.target.value)}
                  />
                  {PatientMaxCount < 1 ?
                    <div className="warning-label">
                      <FontAwesomeIcon
                        icon={faExclamationTriangle}
                      />
                      <Label className="warning-text">Leave the above field empty or enter "0" for unlimited usage</Label>
                    </div>
                    : ""}
                </>
                : ""}
              <Label>Discount (%) <span className="required"> * </span></Label>
              <Input
                type="number"
                name="coupon_discount"
                className="coupon-margin-bottom"
                placeholder="Coupon Discount"
                value={CouponDiscount}
                onChange={event => discountHandler(event.target.value)}
              />
              <FormGroup>
                <Label>Expires On <span className="required"> * </span></Label>
                <DatePicker
                  dateFormat="dd MMM YYY"
                  placeholderText="Select date"
                  onChange={(date) => expiryHandler(date)}
                  minDate={moment().toDate()}
                  selected={CouponExpiry}
                  todayButton="Today"
                />
              </FormGroup>
              <div>
                <Label>Active / Deactivate </Label>
                <ToggleButton
                  inactiveLabel=""
                  activeLabel=""
                  value={CouponStatus}
                  onToggle={() => { setCouponStatus(!CouponStatus); updateCouponStatus(); }}
                />
              </div>
              <a href="/coupons"><button className="btn btn-equipo-blue mt-2 min-width right-align cancel-coupon" type="reset">Back</button></a>
              <button className="btn btn-equipo-blue mt-2 min-width right-align" type="submit" onClick={() => validate()}>Save</button>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
    authtoken: state.auth.token,
  };
};

export default connect(mapStateToProps, null)(CouponInput);