import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Breadcrumb, BreadcrumbItem, Container, Spinner, Button } from "reactstrap";
import CorporateStatusChangeModal from '../../newComponents/utilities/CorporateStatusChangeModal';
import Header from "../../components/Header";
import EditCorporateGroupModal from '../../newComponents/utilities/EditCorporateGroupModal';
import NewCorporateGroupModal from '../../newComponents/utilities/NewCorporateGroupModal';
import HeaderTitle from "../../components/HeaderTitle";
import { Hux } from "../../hoc/Hux";
import ExportCSVTable from "./CorporateGroupDataTable";
import { toastr } from 'react-redux-toastr'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GlobalState } from '../../contextAPI/Context';
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from 'react-tooltip';


const Tables = (props) => {
  // CONTEXT VARIABLES 
  const {
    setToken,
    setSpinner,
    setBlankStateMessage,
    corporateName, 
    corporateStatus, 
    corporateAddress,
    corporateListReload,
    setCorporateListReload, 

  } = useContext(GlobalState);

  // LOCAL VARIABLES
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const [rowData, setRowData] = useState("")
  const [corporateList, setCorporateList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalState, setModalState] = useState(false);
  const [corporateID, setCorporateID] = useState("");
  const [status, setStatus] = useState("");
  const [userConfirmation, setUserConfirmation] = useState(false);
  const [VerificationChange, setVerificationChange] = useState(false);// eslint-disable-line
  const [editCorporateGroupModal, setEditCorporateGroupModal] = useState(false);

  const [editCorporateGroupName, setEditCorporateGroupName] = useState("");
  const [editCorporateGroupAddress, setEditCorporateGroupAddress] = useState("");
  const [editCorporateGroupNumber, setEditCorporateGroupNumber] = useState("");
  const [editCorporateGroupId, setEditCorporateId] = useState("");

  const [newCorporateGroupModal, setNewCorporateGroupModal] = useState(false);



  const fetchCorporateGroup = () => {
    setToken(props.authtoken);
    setLoading(true);
    setSpinner(true);


    const params =
    {
      "group_name": corporateName ? corporateName : "",
      "is_pagination": 0,
      "offset": "force",
      "group_address": corporateAddress ? corporateAddress : "",
      "group_status": corporateStatus ? corporateStatus : "",
    }

    Axios.post(API_ENDPOINT + "/external/corporate-group-details/", params, {
      headers: {
        Authorization: props.authtoken,
      }
    }).then((res) => {
      setCorporateList(res.data)
      setBlankStateMessage(res.data.length > 0 ? false : true);
      setLoading(false);
      setSpinner(false);
    });
  };

  const basicValidation = (cell) => {
    return cell ? cell : "N/A"
  }

  const basicValidationForNum = (cell) => {
    return cell ? cell : "0"
  }

  const changeCorporateStatus = () => {
    let newStatus = "";
    setLoading(true);
    if (status === 0) {
      newStatus = 1
    } else {
      newStatus = 0
    }

    const updatedStatus = {
      corporate_id:corporateID,
      status: newStatus,
    };
    
    Axios.post(
      API_ENDPOINT + `/external/corporate-status-update/`,updatedStatus,
      {
        headers: {
          Authorization: props.authtoken,
        },
      }
    )
      .then((res) => {
          fetchCorporateGroup();
          setUserConfirmation(false);
          setCorporateListReload(corporateListReload+1);
          setLoading(false);
          toastr.success('Status Updated', 'Healthcare status updated successfully');
        
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const editCorporateGroup=(id,name,address,phoneNumber)=> {
    
    setEditCorporateGroupName(name);
    setEditCorporateGroupAddress(address);
    setEditCorporateId(id);
    setEditCorporateGroupNumber(phoneNumber);

  }

  const statusFormatter = (cell, row) => {
    let status = "";
    let badgeclass = " ";
  
    if (cell === 1) {
      status = "Active";
      badgeclass = "badge badge-success statustoggle status-button-width";
    } else {
      status = "Inactive";
      badgeclass = "badge badge-danger statustoggle status-button-width";
    }

    return (
      <span
        className={badgeclass}
        onClick={() => {
          setCorporateID(row.id);
          setStatus(cell);
          setModalState(true);
        }}
      >
        {status}
      </span>
    );
  };

  const statusFormatterForCSV = (cell) => {
    return cell ? "Active" : "Inactive"
  }

  const actionFormatter = (cell, row) => {
    return (
      <>
        <Button
          className="money-button"
          outline
          onClick={() => {
            setRowData(row)
            setEditCorporateGroupModal(true)
            editCorporateGroup(row.id,row.name,row.address,row.phoneNumber)
          }}
          color="primary"
          title="Edit Healthcare Partner"
        >
          <FontAwesomeIcon
            icon={faEdit}
            fixedWidth
            className="align-middle"
            title="Edit Healthcare Partner"
          />
        </Button>
      </>
    )
  }

  const CompletedApptFormatter = () => {
    return (
      <p>
        Completed Appts
        <span className="email-exclamation" data-tip="Total completed appts for current year">
          <FontAwesomeIcon
            icon={faExclamationCircle}
            className="align-middle"
          />
        </span>
        <ReactTooltip className="doc-upload-tooltip" />
      </p>
    )
  }

  
  const tableColumns = [

    {
      dataField: "name",
      text: "Name",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "address",
      text: "Address",
      sort: true,
      csvExport: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "phoneNumber",
      text: "Phone Number",
      sort: true,
      csvExport: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "appt_count",
      headerFormatter:CompletedApptFormatter,
      text: "Completed Appt",
      sort: true,
      csvExport: true,
      formatter: basicValidationForNum,
      csvFormatter: basicValidationForNum,
    },
    {
      dataField: "active_patient_count",
      text: "Active Patients",
      sort: true,
      csvExport: true,
      formatter: basicValidationForNum,
      csvFormatter: basicValidationForNum,
    },
    {
      dataField: "inactive_patient_count",
      text: "Inactive Patients",
      sort: true,
      csvExport: true,
      formatter: basicValidationForNum,
      csvFormatter: basicValidationForNum,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      csvFormatter: statusFormatterForCSV,
      formatter: statusFormatter,
    },
    {
      text: "Actions",
      csvExport: false,
      formatter: actionFormatter,
    }
  ];

  let loader = null;

  if (loading) {
    loader = <Spinner />;
  }

  // DEFAULT API CALL
  useEffect(() => { fetchCorporateGroup() },
  // eslint-disable-next-line
    [
      corporateName,
      corporateStatus,
      corporateAddress,
      corporateListReload,
      setBlankStateMessage
    ]);

    // EFFECT TO HANDLE CORPORATE STATUS CHANGE 
    useEffect(() => { if (userConfirmation && !VerificationChange) changeCorporateStatus() }, [userConfirmation])// eslint-disable-line

  
  return (
    <Hux>
      {loader}
      <Container fluid>
        <Header>
          <HeaderTitle>Healthcare Partners
          <button className="btn btn-equipo-blue mt-2 pull-right"
              onClick={() => {
                setNewCorporateGroupModal(true)
              }}
            >
              Add New Healthcare Partner
              </button>
          </HeaderTitle>
          <Breadcrumb>
            <BreadcrumbItem>
            <Link to="/dashboard">Dashboard</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Healthcare Partners</BreadcrumbItem>
          </Breadcrumb>
        </Header>
        <CorporateStatusChangeModal modalState={modalState} setModalState={setModalState} setUserConfirmation={setUserConfirmation}  />
          

        <EditCorporateGroupModal
          editCorporateGroupModal={editCorporateGroupModal}
          setEditCorporateGroupModal={setEditCorporateGroupModal}
          editCorporateGroupName={editCorporateGroupName}
          editCorporateGroupAddress={editCorporateGroupAddress}
          editCorporateGroupNumber={editCorporateGroupNumber}
          editCorporateGroupId={editCorporateGroupId}
          setEditCorporateGroupName={setEditCorporateGroupName}
          setEditCorporateGroupAddress={setEditCorporateGroupAddress}
          setEditCorporateId={setEditCorporateId}
          setEditCorporateGroupNumber={setEditCorporateGroupNumber}
          rowData={rowData}
          token={props.authtoken}

        />
        <NewCorporateGroupModal
        token={props.authtoken}
        newCorporateGroupModal={newCorporateGroupModal}
        setNewCorporateGroupModal={setNewCorporateGroupModal}
        />

        <ExportCSVTable  data={corporateList} columns={tableColumns}  />
      </Container>
    </Hux>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
    authtoken: state.auth.token,
  };
};

export default connect(mapStateToProps, null)(Tables);
