import React, { useState, useEffect, useContext} from "react";
import { Line } from "react-chartjs-2";
import { connect } from "react-redux";
import Axios from "axios";
import { Spinner} from 'reactstrap';
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,  } from "reactstrap";
  import { GlobalState } from "../../../contextAPI/Context";
import { useHistory } from "react-router-dom";
import { RefreshCw } from "react-feather";
import "../Default/Index.css"
import moment from 'moment';

const ApptTrendsGraph = ({theme},props) => {

  const { setAppStatusID, } = useContext(GlobalState);
    let appoStatus = "3"
    const [chartData, setChartData] = useState({});
    const [Loading, setLoading] = useState(true);
    const [data, setData] = useState("");
    let history = useHistory();

    const chart = () => {

        const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

        const apptCount  = []
        const eachYearMonth = []
    
      Axios.get(API_ENDPOINT + "/user/get-appointmnet-monthly-trend/", {
        headers: {
          Authorization: props.authtoken,
        },
        })
        .then((res) => {
          if (res.data.length > 0) {
            /* apptTrendData.map((item)=> {
              setApptCount(item.apptCount)
              setEachMonth(item.eachMonth)
            }) */
            setData(res.data);
            for (const dataobj of res.data){
              apptCount.push(dataobj.apptCount)
              eachYearMonth.push(dataobj.eachYearMonth)
              setLoading(false);
            }
            
            setChartData ( {
              labels: eachYearMonth,
              datasets: [
                {
                  label: "Completed Appts",
                  fill: false,
                  backgroundColor: theme.primary,
                  borderColor: theme.primary,
                  borderWidth: 2,
                  data: apptCount,
                }]
                
            });
          } else {
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
      }
    
    let loader = null;
    if (Loading) {
        loader = <Spinner />;
      }
    
    useEffect(() => {
        chart();
      }, []); // eslint-disable-line

      const refresh = () => {
        chart();
        setLoading(true);
        setTimeout(() => {
        setLoading(false);
        }, 3000);
      };

      

      /* const alertHello = () => {
        alert('hello!');
      } */


      const getDateFromIndex = (index) => {
        let selectedDate =  data[index].apptFirstDate
        let startDate = moment(selectedDate).startOf('month').format("YYYY-MM-DD H:mm:ss");  
        let endDate = moment(selectedDate).endOf('month').format("YYYY-MM-DD H:mm:ss");
        history.push(`/appointments/?startDate=${btoa(startDate)}&endDate=${btoa(endDate)}`);
      }

      const options = {
        maintainAspectRatio: false,
         /* legend: {
          onClick: []
        }, */

        tooltips: {
          intersect: true,
          
        },
        hover: {
          intersect: true
        },
        plugins: {
          filler: {
            propagate: false
          }
        },

        onClick: (e, element) => {
          if (element.length > 0) {
            var ind = element[0]._index;
            // alert(ind);
            getDateFromIndex(ind)
            setAppStatusID(appoStatus)
          }
        }, 

        scales: {
          xAxes: [{
            
            gridLines: {
              color: "rgba(0,0,0,0.0)"
            },
            scaleLabel:{
                display:true,
                labelString:"Months",
                fontSize:15,
                fontColor:  "#3e4676",
                fontFamily: "Jost, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, sans-serif",
                padding: 10,
            },
          }],
          yAxes: [{
            ticks: {
              beginAtZero: true,
                min: 0
            },
            display: true,
            gridLines: {
              color: "rgba(0,0,0,0)",
              fontColor: "#fff"
            },
            scaleLabel:{
                display:true,
                labelString:"Completed Appt Count",
                fontSize:15,
                fontColor:  "#3e4676",
                fontFamily: "Jost, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, sans-serif",
                padding: 10,
            },
          }]
        }
      };

      useEffect(() => {
        setAppStatusID(appoStatus)
      }, []); // eslint-disable-line
      
    return(
        <Card className="flex-fill w-100 appt-trend-card">
      <CardHeader>
        <div className="card-actions float-right">
          <span className="cursor-pointer mr-1" onClick={() => refresh()} >
          <RefreshCw />
          </span>{" "}
        </div>
        <CardTitle tag="h5" className="todays-appt-card-header">
          Appointment Trend
        </CardTitle>
      </CardHeader>
      <CardBody className="py-3 ">
        <div className="chart chart-sm line-graph appt-trend-graph pb-5">
            {loader}
          <Line data={chartData} options={options} />
        </div>
      </CardBody>
    </Card>
    )
}

export default connect(store => ({
    theme: store.theme.currentTheme
  }))(ApptTrendsGraph);