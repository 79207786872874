import React, { useState, useEffect, useContext } from "react";
import { useLocation, Link } from "react-router-dom";
import { GlobalState } from '../../contextAPI/Context';

import {
  Card,
  ListGroup,
  ListGroupItem,
} from "reactstrap";

const SubNav = () => {
  // CONTEXT VARIABLE 
  const { physicianId, physicianName } = useContext(GlobalState);
  // LOCAL VARIABLES 
  let location = useLocation();
  let getPathName = location.pathname;
  const [pathName, setPathName] = useState("");

  const getUserTypeFromURL = () => {
    let pathName = location.pathname
    let userTypeInURL = pathName.split("/")
    let filtered = userTypeInURL.filter(function (item) { return item !== "" })
    userTypeInURL = filtered[0]
    userTypeInURL = userTypeInURL.toLocaleLowerCase()
    setPathName(userTypeInURL)
  }

  switch (location.pathname) {
    case `/${pathName}/consultation-settings/`:
      getPathName = "ConsultationSettings";
      break;
    case `/${pathName}/profile/`:
      getPathName = "Profile";
      break;
    case `/${pathName}/user-profile/`:
      getPathName = "UserProfile";
      break;
    case `/${pathName}/availability/`:
      getPathName = "Hours";
      break;
    case `/${pathName}/schedule/`:
      getPathName = "Schedule";
      break;
    case `/${pathName}/templates/`:
      getPathName = "Templates";
      break;
    case `/${pathName}/bank-account/`:
      getPathName = "Bank";
      break;
    case `/${pathName}/identity/`:
      getPathName = "Identity";
      break;
    default:
      getPathName = "Profile";
      break;
  }

  // EFFECT TO CHANGE THE BREADCRUMB BASE ON URL
  useEffect(() => { getUserTypeFromURL() }, []) // eslint-disable-line

  return (
    <Card>
      <ListGroup flush>
        <Link to={`/${pathName}/profile/?pid=${btoa(physicianId)}&pname=${btoa(physicianName)}`}>
          <ListGroupItem action active={getPathName === "Profile" ? true : false}>
            Professional Profile
          </ListGroupItem>
        </Link>
        <Link to={`/${pathName}/identity/?pid=${btoa(physicianId)}&pname=${btoa(physicianName)}`}>
          <ListGroupItem action active={getPathName === 'Identity' ? true : false}>
            Identity
        </ListGroupItem>
        </Link>
        <Link to={`/${pathName}/user-profile/?pid=${btoa(physicianId)}&pname=${btoa(physicianName)}`}>
          <ListGroupItem action active={getPathName === 'UserProfile' ? true : false}>
            User Profile
        </ListGroupItem>
        </Link>
        <Link to={`/${pathName}/consultation-settings/?pid=${btoa(physicianId)}&pname=${btoa(physicianName)}`}>
          <ListGroupItem action active={getPathName === "ConsultationSettings" ? true : false}>
          Consultation Settings
          </ListGroupItem>
        </Link>
        <Link to={`/${pathName}/availability/?pid=${btoa(physicianId)}&pname=${btoa(physicianName)}`}>
          <ListGroupItem action active={getPathName === "Hours" ? true : false}>
            Availability
          </ListGroupItem>
        </Link>
        <Link to={`/${pathName}/schedule/?pid=${btoa(physicianId)}&pname=${btoa(physicianName)}`}>
          <ListGroupItem action active={getPathName === "Schedule" ? true : false}>
            Schedule
          </ListGroupItem>
        </Link>
        {/* <Link to="/${pathName}/templates">
          <ListGroupItem action active={getPathName === "Templates" ? true : false} >
            Templates
          </ListGroupItem>
        </Link>
        <Link to="/${pathName}/bank-account">
          <ListGroupItem action active={getPathName === 'Bank' ? true : false}>
            Bank Details
        </ListGroupItem>
        </Link> */}
      </ListGroup>
    </Card>
  );
};

export default SubNav;
