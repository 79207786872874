import React, { useState, useEffect, useContext } from "react";
import { Breadcrumb, BreadcrumbItem, Col, Row, Spinner } from "reactstrap";
import axios from "axios";
import ProfileInfo from "../Profile/ProfileInfo";
import Header from "../../../components/Header";
import HeaderTitle from "../../../components/HeaderTitle";
import Navigation from "../SubNav";
import { GlobalState } from "../../../contextAPI/Context";
import { useLocation } from "react-router-dom";

const Profile = () => {
  // GET TOKEN
  let token = localStorage.getItem("token");

  // OBTAINING BASE URL BASED ON ENVIRONMENT
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

  // CONTEXT VARIABLES
  const { physicianName, setPhysicianId, setPhysicianName, reloadPhysicianIdentityData, userType, setUserType, reload, setReload } = useContext(GlobalState);

  // LOCAL VARIABLES
  const [spinner, setSpinner] = useState(false)
  const [userId, setUserId] = useState("")
  const [Languages, setLanguages] = useState([]);
  const [Specialities, setSpecialities] = useState([]);
  const [Countries, setCountries] = useState([]);
  const [PhysicianData, setPhysicianData] = useState([]);
  const [pathName, setPathName] = useState("");

  // GET URL PARAMETERS 
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  let location = useLocation();

  const fetchLanguages = () => {
    axios
      .get(`${API_ENDPOINT}/external/get-language-list/`, {
        headers: {
          Authorization: token,
        },
      })
      .then(function (response) {
        setLanguages(response.data);
      })
      .catch(function (error) {
        // console.log(error);
      });
  };

  const fetchCountries = () => {
    axios
      .get(`${API_ENDPOINT}/external/get-country-list/`, {
        headers: {
          Authorization: token,
        },
      })
      .then(function (response) {
        // console.log(response.data)
        setCountries(response.data);
      })
      .catch(function (error) {
        // console.log(error);
      });
  };

  const fetchSpecialities = () => {
    axios
      .get(`${API_ENDPOINT}/external/speciality-list/${userType}`, {
        headers: {
          Authorization: token,
        },
      })
      .then(function (response) {
        setSpecialities(response.data);
      })
      .catch(function (error) {
        // console.log(error);
      });
  };

  const fetchPhysicianData = () => {
    setSpinner(true);

    const params = { "user_id": Number(userId) }
    axios
      .post(`${API_ENDPOINT}/user/get-physician-profile-data/`, params, {
        headers: {
          Authorization: token,
        },
      })
      .then(function (response) {
        // console.log('response', response.data.data[0])
        setPhysicianData(response.data.data[0]);
        setUserType(response.data.data[0].userType);
        setSpinner(false);
        setReload(reload + 1);
        console.log('reloAD',reload)
      })
      .catch(function (error) {
        // console.log(error);
        setSpinner(false);
      });
  };

  const getUserTypeFromURL = () => {
    let pathName = location.pathname
    let userTypeInURL = pathName.split("/")
    let filtered = userTypeInURL.filter(function (item) { return item !== "" })
    userTypeInURL = filtered[0]
    userTypeInURL = userTypeInURL.toLocaleLowerCase()
    userTypeInURL = userTypeInURL.charAt(0).toUpperCase() + userTypeInURL.slice(1)
    setPathName(userTypeInURL)
  }

  useEffect(() => {
    fetchCountries();
    fetchLanguages();
    if (userType) fetchSpecialities();
    if (userId) fetchPhysicianData()
  }, [userId, reloadPhysicianIdentityData, userType]); // eslint-disable-line

  // EFFECT TO GET THE URL PARAMETERS 
  useEffect(() => {
    if (query) {
      setUserId(Number(atob(query.get("pid"))));
      setPhysicianId(Number(atob(query.get("pid"))));
      setPhysicianName(atob(query.get("pname")));
    }
  }, [query]) // eslint-disable-line

  // EFFECT TO CHANGE THE BREADCRUMB BASE ON URL
  useEffect(() => { getUserTypeFromURL() }, []) // eslint-disable-line

  return (
    <>
      <Header>
        <HeaderTitle>Settings</HeaderTitle>
        <Breadcrumb>
          <BreadcrumbItem>
          <a href={`/${pathName.toLowerCase()}`}>{pathName}</a>
          </BreadcrumbItem>
          <BreadcrumbItem active>Professional Profile</BreadcrumbItem>
          <BreadcrumbItem active>{physicianName}</BreadcrumbItem>
        </Breadcrumb>
      </Header>

      <Row>
        <Col md="3" xl="2">
          <Navigation />
        </Col>
        <Col md="9" xl="10">
        {spinner ? <Spinner color="success" /> : ""}
          <ProfileInfo
            Countries={Countries}
            Specialities={Specialities}
            Languages={Languages}
            PhysicianData={PhysicianData}
          />
        </Col>
      </Row>
    </>
  );
};

export default Profile;
