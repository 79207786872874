import React, { useState, useContext, useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, Container, Row, Col, Card, Button, Badge } from "reactstrap";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import { Hux } from "./../../hoc/Hux";
import "../../pages/dashboards/Default/Index.css";
import './PrescriptionInfo.css';
import PrescriptionInfoTabs from "./PrescriptionInfoTabs";
import { GlobalState } from '../../contextAPI/Context';
import axios from "axios";
import moment from 'moment';
import MomentConfig from '../../common/MomentConfig'
import ConfirmationModal from './ConfirmationModal';
import SendPrescriptionModal from './SendPrescriptionModal';
import EditPrescriptionModal from '../utilities/EditPrescriptionModal';

const ViewPrescriptionDetails = () => {
  // CONTEXT VARIABLES 
  const {
    prescriptionRowData, setPrescriptionRowData,
    confirmationModal, setConfirmationModal,
    prescriptionReload, setStatusChangeConfirmation,
    editPrescriptionModal, setEditPrescriptionModal,
  } = useContext(GlobalState);

  // LOCAL VARIABLES 
  let query = useQuery();
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  let token = localStorage.getItem('token');
  const appointmentId = atob(query.get("aid"))
  const [appointmentSlot, setAppointmentSlot] = useState("")
  const [emailPharmacyModal, setEmailPharmacyModal] = useState(false);
  const [badgeColor, setBadgeColor] = useState("");

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const dateFormatter = () => {
    let value = prescriptionRowData.schStartTime
    let dateTime = `${value ? moment(value).utc(value).local().format(MomentConfig.MOMENT_FORMAT_DATEANDTIME) : "N/A"}`
    setAppointmentSlot(dateTime)
  }

  const fetchPrescription = () => {

    const params =
    {
      "from_date": "",
      "to_date": "",
      "patient_name": "",
      "pharmacy_id": "",
      "provider_id": "",
      "is_pagination": 0,
      "offset": "force",
      appointment_id: appointmentId
    }

    axios.post(API_ENDPOINT + "/user/get-prescription-data/", params, {
      headers: {
        Authorization: token,
      }
    }).then((res) => {
      setPrescriptionRowData(res.data[0]);
    });
  };

  const badgeColorChanger = () => {
    let color = ""
    switch (prescriptionRowData.prescription_status_id) {
      case 29:
        color = "success"
        break;
      case 30:
        color = "warning"
        break;
      case 31:
        color = "danger"
        break;
      case 32:
        color = "warning"
        break;
      case 33:
        color = "warning"
        break;
      default:
        color = "primary"
    }
    setBadgeColor(color)
  }


  // GETS APPOINTMENT DETAILS INCASE OF PAGE REFRESH
  useEffect(() => {
    if (!prescriptionRowData) fetchPrescription();
    if (prescriptionRowData) dateFormatter();

  }, [prescriptionRowData, appointmentId])// eslint-disable-line

  // GETS APPOINTMENT DETAILS INCASE PRESCRIPTION STATUS CHANGE 
  useEffect(() => { if (prescriptionRowData) fetchPrescription() }, [prescriptionReload])// eslint-disable-line

  useEffect(() => { if (prescriptionRowData) badgeColorChanger(); }, [prescriptionRowData])// eslint-disable-line

  return (
    <Hux>
      <Container fluid>
        <Header>
          <HeaderTitle>Prescription Information</HeaderTitle>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/prescriptions">Prescriptions</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Prescription info</BreadcrumbItem>
          </Breadcrumb>
        </Header>
        <Card className="prescription-info-container">

          <Row>
            <Col>
              <div className="prescription-info-header-container">
                <h4 className="prescription-info-header">{`
              ${prescriptionRowData.patient_name ? prescriptionRowData.patient_name : ""} 
              - ${appointmentSlot}
              - Doctor : ${prescriptionRowData.provider_name ? prescriptionRowData.provider_name : ""}
              `}</h4>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>

              <PrescriptionInfoTabs prescriptionRowData={prescriptionRowData} />

            </Col>
          </Row>
          
          <hr />
          
          <div className="prescription-info-buttons-container">
          <Row>
            <Col>
              <table>
                <tbody>
                  <tr>
                    <td> <h5 className="bold-text">Prescription Status</h5></td>
                    <td> : </td>
                    <td> <Badge className="pharmacy-badge" color={badgeColor}>{prescriptionRowData.prescription_status ? prescriptionRowData.prescription_status : "Created"}</Badge> </td>
                    <td> <strong></strong> </td>
                  </tr>
                </tbody>
              </table>
            </Col>

            <Col>
              <Link to="/prescriptions">
                <Button className="prescription-button-width float-right" color="primary">Back</Button>
              </Link>
                {(prescriptionRowData.pharmacy_email || prescriptionRowData.pharmacy_efax) && prescriptionRowData.pharmacy_status && prescriptionRowData.prescription_status_id !== 29 ?
                <div className="float-right">
                  {/* <Button className="prescription-button-width" color="primary" onClick={() => { alert("api not integrated") }}>Notify Patient</Button>  */}

                  {prescriptionRowData.prescription_status_id === 30 || prescriptionRowData.prescription_status_id === 32 || prescriptionRowData.prescription_status_id === 33
                    ? ""
                    : <Button className="prescription-button-width" color="warning" onClick={() => { setConfirmationModal(true) }}>Pharmacy Approved</Button>
                  }

                  <Button className="prescription-button-width" color="success" onClick={() => { setEmailPharmacyModal(true) }}>Send To Pharmacy </Button>
                </div>
                : ""}
            </Col>
          </Row>
          </div>
          <ConfirmationModal confirmationModal={confirmationModal} setConfirmationModal={setConfirmationModal} setStatusChangeConfirmation={setStatusChangeConfirmation} />
          <SendPrescriptionModal rowData={prescriptionRowData} emailPharmacyModal={emailPharmacyModal} setEmailPharmacyModal={setEmailPharmacyModal} />
          <EditPrescriptionModal rowData={prescriptionRowData} editPrescriptionModal={editPrescriptionModal} setEditPrescriptionModal={setEditPrescriptionModal} token={token} />


        </Card>
      </Container>
    </Hux>
  );
}

export default ViewPrescriptionDetails;