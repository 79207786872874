import React, { useState, useEffect, useContext } from 'react';
import { GlobalState } from '../../contextAPI/Context';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Input, Label, FormGroup, } from 'reactstrap';
import axios from 'axios';
import { toastr } from 'react-redux-toastr';


const NewInsuranceGroupModal = (props) => {
    const { newInsuranceGroupModal, setNewInsuranceGroupModal, token } = props;

    // CONTEXT VARIABLES 
    const { groupInsuranceListReload, setGroupInsuranceListReload, } = useContext(GlobalState);


    // LOCAL VARIABLES
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const [newInsuranceGroupName, setNewInsuranceGroupName] = useState("");
    const [newInsuranceGroupDescription, setNewInsuranceGroupDescription] = useState("");
    const [addButtonStatus, setAddButtonStatus] = useState(true);



    const toggle = () => {
        setNewInsuranceGroupModal(!newInsuranceGroupModal);

        setAddButtonStatus(true);
        setNewInsuranceGroupName("")
        setNewInsuranceGroupDescription("")

    }



    const confirmation = () => {
        if (!newInsuranceGroupName) {
            toastr.error("Name Field Is Required", "Please enter insurance group name !")
        }

        if (newInsuranceGroupName) {
            add_InsuranceGroupDetails()
        }
    };


    const add_InsuranceGroupDetails = () => {


        axios.post(`${API_ENDPOINT}/user/add-new-group-insurance-details/`, {

            "groupInsuranceName": newInsuranceGroupName,
            "groupInsuranceDescription": newInsuranceGroupDescription

        }, {
            headers: {
                Authorization: token,
            }
        })
            .then(function (response) {
                // console.log(response);
                setNewInsuranceGroupModal(!newInsuranceGroupModal)
                setGroupInsuranceListReload(groupInsuranceListReload + 1)
                toastr.success("Successfully Added", "Insurance group details successfully added")
                toggle();
            })
            .catch(function (error) {
                // console.log(error);
                toastr.error("Failed", "Please try again")
            });
    }


    const disableAddButton = () => {

        if (
            newInsuranceGroupName
        ) {
            setAddButtonStatus(false)
        }
        else {
            setAddButtonStatus(true)
        }
    }


    useEffect(() => { disableAddButton() }, [newInsuranceGroupName]) // eslint-disable-line

    return (
        <div>
            <Modal isOpen={newInsuranceGroupModal} toggle={toggle} size="md" >
                <ModalHeader tag="h4" cssModule={{ 'modal-title': 'w-100 text-center' }} toggle={toggle}>Add New Insurance </ModalHeader>
                <ModalBody className="overflow-modal disable-scrollbars" >
                    <FormGroup>
                        <Row>
                            <Col>

                                <Label for="group_insurance_name">Name<span className="required">*</span></Label>
                                <Input
                                    id="group_insurance_name"
                                    placeholder="Name"
                                    value={newInsuranceGroupName}
                                    onChange={(e) => setNewInsuranceGroupName(e.target.value)}
                                    minLength="1"
                                    maxLength="120"
                                />
                                <br></br>

                                <Label for="group_insurance_description">Description</Label>
                                <Input
                                    id="group_insurance_description"
                                    type="textarea"
                                    value={newInsuranceGroupDescription}
                                    placeholder="Description"
                                    onChange={(e) => { setNewInsuranceGroupDescription(e.target.value) }}

                                />
                                <br></br>


                            </Col>
                        </Row>
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button
                        className="yes-button button-width"
                        onClick={confirmation}
                        disabled={addButtonStatus}
                        title={addButtonStatus ? "Fill the required fields" : ""}
                    >Add</Button>{' '}
                    <Button outline className="no-button button-width" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default NewInsuranceGroupModal;