import React, { useState, useEffect, useContext } from 'react';
import { GlobalState } from '../../contextAPI/Context';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Label, FormGroup, Col, Row, Container } from 'reactstrap';
import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";


const NewRuleConfigurationModal = (props) => {
    const { newRefundConfigrationModal, setNewRefundConfigrationModal, token } = props;
    // CONTEXT VARIABLES 
    const { refundConfigListReload, setRefundConfigListReload, refundRuleList } = useContext(GlobalState);

    // LOCAL VARIABLES
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    let userID = localStorage.getItem("userId");
    const [newOperator, setNewOperator] = useState("");
    const [newMinHour, setNewMinHour] = useState("");
    const [newMaxHour, setNewMaxHour] = useState("");
    const [newRefundPercent, setNewRefundPercent] = useState("");
    const [addButtonStatus, setAddButtonStatus] = useState(true);
    const defaultOperatorOptions = [
        { label: "<=", value: "<=" },
        { label: ">", value: ">" },
        { label: "Between", value: "between" },
    ];
    const [OperatorOptions, setOperatorOptions] = useState([
        { label: "<=", value: "<=" },
        { label: ">", value: ">" },
        { label: "Between", value: "between" },
    ]);

    const toggle = () => {

        setAddButtonStatus(true);
        setNewOperator("")
        setNewMinHour("")
        setNewMaxHour("")
        setNewRefundPercent("")
        setNewRefundConfigrationModal(!newRefundConfigrationModal);

    }


    const CreateRefundConfigurationApiCall = () => {

        axios.post(`${API_ENDPOINT}/external/create-refund-rule/`, {

            "operator": newOperator.value,
            "minHour": newMinHour ? newMinHour : "",
            "maxHour": newMaxHour ? newMaxHour : "",
            "cancelChargePercent": Number(newRefundPercent),
            "status": 1,
            "created_by": Number(userID),
            "updated_by": Number(userID)

        }, {
            headers: {
                Authorization: token,
            }
        })
            .then(response => {
                if (response.status === 201) {
                    setRefundConfigListReload(refundConfigListReload + 1);
                    toastr.success("Successfully Added", "Refund configuration successfully added");
                    toggle();
                }
            })
            .catch(function (error) {
                toastr.error("Failed To Add", error.response.data.message)
            });
    }


    const disableAddButton = () => {
        if (newOperator && newOperator.value === "between") {
            if (newOperator && newMinHour && newMaxHour && newRefundPercent) {
                setAddButtonStatus(false)
            }
        }


        if (newOperator && newOperator.value === "<=") {
            setNewMaxHour("")
            if (newOperator && newMinHour && newRefundPercent) {
                setAddButtonStatus(false)
            }
        }


        if (newOperator && newOperator.value === ">") {
            setNewMaxHour("")
            if (newOperator && newMinHour && newRefundPercent) {
                setAddButtonStatus(false)
            }
        }

        if (!newOperator) setAddButtonStatus(true)

        if (
            (newOperator && newOperator.value === "between") &&
            (!newMinHour || !newMaxHour || !newRefundPercent)
        ) {
            setAddButtonStatus(true)
        }

        if (
            (newOperator && newOperator.value === "<=") &&
            (!newMinHour || !newRefundPercent)
        ) {
            setAddButtonStatus(true)
        }

        if (
            (newOperator && newOperator.value === ">") &&
            (!newMinHour || !newRefundPercent)
        ) {
            setAddButtonStatus(true)
        }

    }



    const RefundPercentFeeHandler = (num) => {
        let decimal = false
        let value = ""
        num % 1 !== 0 ? decimal = true : decimal = false
        if (decimal) {
            value = Math.round(num * 100) / 100;
        } else {
            value = num;
        }

        if (value <= 100) setNewRefundPercent(value);
        if (value > 100) setNewRefundPercent("100");
        /* if (value <= 0) setNewRefundPercent("0"); */
        if (value === "") setNewRefundPercent("");
    };

    const handleDynamicDropdown = () => {
        let tempOptions = OperatorOptions
        refundRuleList.map(
            (rule) => { // eslint-disable-line
                tempOptions.map(
                    (option, index) => { // eslint-disable-line
                        if (option.value === rule.operator) {
                            if (option.value !== 'between') tempOptions.splice(index, 1);
                        }
                    }
                )
            }
        )
        setOperatorOptions(tempOptions)
        if (tempOptions.length === 1) setNewOperator(tempOptions[0])
    }

    const minHourHandler = (num) => {

        let decimal = false
        let value = ""
        num % 1 !== 0 ? decimal = true : decimal = false
        if (decimal) {
            toastr.error("Decimal Values Are Not Supported")
        } else {
            value = num;
        }

        if (value <= 100) setNewMinHour(value);
        if (value <= 0) setNewMinHour("0");
        if (value === "") setNewMinHour("");
        decimal = false
    };

    const maxHourHandler = (num) => {

        let decimal = false
        let value = ""
        num % 1 !== 0 ? decimal = true : decimal = false
        if (decimal) {
            toastr.error("Decimal Values Are Not Supported")
        } else {
            value = num;
        }

        if (value <= 100) setNewMaxHour(value);
        if (value <= 0) setNewMaxHour("0");
        if (value === "") setNewMaxHour("");
        decimal = false
    };


    useEffect(() => { disableAddButton() }, [newOperator, newMinHour, newMaxHour, newRefundPercent]) // eslint-disable-line

    useEffect(() => {
        if (refundRuleList.length === 0) setOperatorOptions(defaultOperatorOptions);
        if (refundRuleList.length > 0) handleDynamicDropdown();
    }, [refundRuleList]) // eslint-disable-line

    return (
        <div>
            <Modal isOpen={newRefundConfigrationModal} toggle={toggle}  >
                <ModalHeader tag="h4" cssModule={{ 'modal-title': 'w-100 text-center' }} toggle={toggle} >Add New Refund Configuration</ModalHeader>
                <ModalBody className="overflow-modal disable-scrollbars" >
                    <FormGroup>
                        <Container className="container">
          
                           <Row>
                                <Col xs="6" >
                                    <Label>
                                        Operator <span className="required">*</span>
                                    </Label>
                                </Col>
                                <Col xs="6" >

                                    <Select
                                        placeholder="Select"
                                        options={OperatorOptions}
                                        value={newOperator}
                                        isSearchable
                                        isClearable
                                        /* isLoading={TypeList.length > 0 ? false : true} */
                                        onChange={(e) => {

                                            setNewOperator(e)
                                        }}
                                    />
                                </Col>
                            </Row>
                            
                           <Row>
                                <Col xs="6" className="cancel-charge">
                                    <Label>
                                        Cancellation Charge (%) <span className="required">*</span>
                                    </Label>
                                </Col>
                                <Col xs="6" className="cancel-charge">

                                    <Input
                                        required
                                        onChange={e => { if (e.target.value.length <= 3) RefundPercentFeeHandler(e.target.value) }}
                                        type="number"
                                        name="Refund_fee"
                                        placeholder="Cancellation Charge"
                                        value={newRefundPercent}

                                    />
                                </Col>
                            </Row>

                           <Row>
                                <Col xs="6" >
                                    <div >

                                        {newOperator && newOperator.value === "between" ?
                                            <Label>
                                                Minimum Hour <span className="required">*</span>
                                            </Label>
                                            : ""}
                                    </div>
                                </Col>
                                <Col xs="6" >

                                    {newOperator && newOperator.value === "between" ?
                                        <div >
                                            <Input
                                                required
                                                onChange={e => { if (e.target.value.length <= 3) minHourHandler(e.target.value) }}
                                                type="number"
                                                name="min_hour"
                                                placeholder="Minimum hour"
                                                value={newMinHour}

                                            />
                                            <div className="warning-label">
                                                <FontAwesomeIcon
                                                    icon={faExclamationTriangle}
                                                />
                                                <Label className="warning-text width-max margin-top">Decimal values are not supported</Label>
                                            </div>
                                        </div>
                                        : ""}
                                </Col>
                            </Row>
                            
                           <Row>
                                <Col xs="6">
                                {newOperator && newOperator.value === "between" ?
                                    <Label>
                                        Maximum Hour <span className="required">*</span>
                                    </Label>
                                    : ""}
                                </Col>
                                <Col xs="6">

                                {newOperator && newOperator.value === "between" ?
                                    <div>
                                        <Input
                                            required
                                            onChange={e => { if (e.target.value.length <= 3) maxHourHandler(e.target.value) }}
                                            type="number"
                                            name="max_hour"
                                            placeholder="Maximum hour"
                                            value={newMaxHour}


                                        />
                                        <div className="warning-label">
                                            <FontAwesomeIcon
                                                icon={faExclamationTriangle}
                                            />
                                            <Label className="warning-text width-max margin-top">Decimal values are not supported</Label>
                                        </div>
                                    </div>
                                    : ""}
                                </Col>
                            </Row>
                           <Row>
                                <Col xs="6" >
                                {newOperator && newOperator.value === "<=" ?
                                    <Label>
                                        Maximum Hour <span className="required">*</span>
                                    </Label>

                                    : ""}
                                </Col>
                                <Col xs="6" >

                                {newOperator && newOperator.value === "<=" ?
                                    <div>
                                        <Input
                                            required
                                            onChange={e => { if (e.target.value.length <= 3) minHourHandler(e.target.value) }}
                                            type="number"
                                            name="min_hour"
                                            placeholder="Maximum hour"
                                            value={newMinHour}

                                        />

                                        <div className="warning-label">
                                            <FontAwesomeIcon
                                                icon={faExclamationTriangle}
                                            />
                                            <Label className="warning-text width-max margin-top">Decimal values are not supported</Label>
                                        </div>
                                    </div>
                                    : ""}
                                </Col>
                            </Row>
                           <Row>
                                <Col xs="6">
                                {newOperator && newOperator.value === ">" ?
                                    <Label>
                                        Minimum Hour <span className="required">*</span>
                                    </Label>
                                    : ""}
                                </Col>
                                <Col xs="6">

                                {newOperator && newOperator.value === ">" ?
                                    <div>
                                        <Input
                                            required
                                            onChange={e => { if (e.target.value.length <= 3) minHourHandler(e.target.value) }}
                                            type="number"
                                            name="min_hour"
                                            placeholder="Minimum hour"
                                            value={newMinHour}

                                        />
                                        <div className="warning-label">
                                            <FontAwesomeIcon
                                                icon={faExclamationTriangle}
                                            />
                                            <Label className="warning-text width-max margin-top">Decimal values are not supported</Label>
                                        </div>
                                    </div>
                                    : ""}
                                </Col>
                            </Row>


                        </Container>
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button
                        className="yes-button button-width"
                        onClick={CreateRefundConfigurationApiCall}
                        disabled={addButtonStatus}
                        title={addButtonStatus ? "Fill the required fields" : ""}
                    >Add</Button>{' '}
                    <Button outline className="no-button button-width" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default NewRuleConfigurationModal;
