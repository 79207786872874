import React, { useState, useEffect, useContext } from 'react';
import { GlobalState } from '../../contextAPI/Context';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter,} from 'reactstrap';
import Select from 'react-select';
import axios from 'axios';
import { toastr } from 'react-redux-toastr';

const ChangeGroupModal = (props) => {
    const { statusChangeModal, setStatusChangeModal, rowData, token } = props;
    // CONTEXT VARIABLES 
    const { patientGroupList, patientListReload, setPatientListReload } = useContext(GlobalState);
    // LOCAL VARIABLES 
    const [groupOptions, setGroupOptions] = useState("");
    const [newGroupId, setNewGroupId] = useState("");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

    const toggle = () => { setStatusChangeModal(!statusChangeModal); setNewGroupId(); }
    const confirmation = () => { updateGroupApiCall() };

    const createGroupOptions = () => {
        var array = patientGroupList
        const options = array.map((item) => {
            return { value: item.id, label: item.name };
        });
        setGroupOptions(options)
    }

    const updateGroupApiCall = () => {
        axios.post(`${API_ENDPOINT}/user/update-patient-group/`, {
            "patient_id": rowData.id,
            "group_id": newGroupId
        }, {
            headers: {
                Authorization: token,
            }
        })
            .then(function (response) {
                // console.log(response);
                setStatusChangeModal(!statusChangeModal)
                setPatientListReload(patientListReload + 1)
                toastr.success("Patient Group Updated Successfully")
            })
            .catch(function (error) {
                // console.log(error);
                toastr.error("Update Failed", "Please try again")
            });
    }

    useEffect(() => { if (patientGroupList) createGroupOptions() }, [patientGroupList])// eslint-disable-line

    return (
        <div>
            <Modal isOpen={statusChangeModal} toggle={toggle} size="sm" >
                <ModalHeader tag="h4" cssModule={{'modal-title': 'w-100 text-center'}}  toggle={toggle}>Healthcare Partner - {rowData ? rowData.patient_name : "N/A"}</ModalHeader>
                <ModalBody>
                    <table>
                        <tbody>
                            <tr>
                                <td>Current Group</td>
                                <td> : </td>
                                <td> {rowData.GroupName ? <strong>{rowData.GroupName}</strong> : "N/A"} </td>
                            </tr>
                            <tr className="group-selector-table-spacer"></tr>
                            <tr >
                                <td> New Group</td>
                                <td> : </td>
                                <td className="group-selector"> <Select options={groupOptions} onChange={event => { setNewGroupId(event.value) }} /> </td>
                            </tr>
                            <tr className="group-selector-table-spacer"></tr>
                        </tbody>
                    </table>
                </ModalBody>
                <ModalFooter>
                    <Button
                        className="yes-button button-width"
                        disabled={newGroupId ? false : true}
                        title={newGroupId ? "" : "Choose a new group and update"}
                        onClick={confirmation}
                    >Update</Button>{' '}
                    <Button outline className="no-button button-width" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default ChangeGroupModal;