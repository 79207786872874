import React from "react"
import { Chrono } from "react-chrono";

const TimeLine = (props) => {
    const { items, timeLineCustomData } = props

    return (
        <div style={{ width: "100%", margin: "auto", padding: '15px' }} className="timeline-view-container">
            <Chrono 
            items={items} 
            mode="VERTICAL_ALTERNATING" 
            hideControls
            >
                {timeLineCustomData.map((timelineData) => {
                    return (<div className="chrono-custom-content" dangerouslySetInnerHTML={{ __html: timelineData }}/>)
                })}
            </Chrono>
        </div>
    )
}

export default TimeLine