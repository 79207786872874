import React, { useState, useEffect, useContext } from 'react'
import BootstrapTable from 'react-bootstrap-table-next';
import moment from 'moment';
import MomentConfig from '../../../../common/MomentConfig';
import { Button, Badge } from 'reactstrap';
import DocumentViewer from '../DocumentViewer/DocumentViewer';
import axios from 'axios';
import ApproveDocModal from '../Modals/ApproveDocModal';
import RejectDocModal from '../Modals/RejectDocModal';
import { toastr } from "react-redux-toastr";
import { GlobalState } from "../../../../contextAPI/Context";
import ReactTooltip from 'react-tooltip';
import DeleteDocumentModal from '../Modals/DeleteDocumentModal';

function RegistrationDetailsTable(props) {
    // GET TOKEN
    let token = localStorage.getItem("token");
    let userID = localStorage.getItem("userId");
    // OBTAINING BASE URL BASED ON ENVIRONMENT
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    // CONTEXT VARIABLES 
    const { reloadPhysicianIdentityData, setReloadPhysicianIdentityData, userType } = useContext(GlobalState);
    // LOCAL VARIABLES
    const reg_documents = props.phyRegDetails.reg_documents;
    // DOCUMENT VIEWER VARIABLES 
    const [modalState, setModalState] = useState(false);
    const [fileURL, setFileURL] = useState("");
    const [fileExtension, setFileExtension] = useState("");
    const [fileType, setFileType] = useState("");
    // MODAL VARIABLES 
    const [approveDocModal, setApproveDocModal] = useState(false);
    const [rejectDocModal, setRejectDocModal] = useState(false);
    const [userConfirmation, setUserConfirmation] = useState(false);
    const [statusUpdateNote, setStatusUpdateNote] = useState("");
    const [docUpdateStatusCode, setDocUpdateStatusCode] = useState("");
    const [documentId, setDocumentId] = useState("");
    const [deleteDocumentModal, setDeleteDocumentModal] = useState(false);
    const [documentDeleteConfirmation, setDocumentDeleteConfirmation] = useState(false);
    const documentType = userType === 3 ? "Registration Details To Practice Medicine" : "Registration Details";
    const [documentName, setDocumentName] = useState("");

    const dateFormatter = (cell) => {
        return cell ? moment(cell).utc(cell).local().format(MomentConfig.MOMENT_FORMAT_DATEANDTIME) : ""
    }

    const expiryFormatter = (cell) => {
        return cell ? moment(cell).local().format(MomentConfig.MOMENT_FORMAT_DATE) : ""
    }

    const basicValidation = (cell) => {
        return cell ? <span data-tip={cell} data-for="reg-doc"> {cell.substring(0, 100)} </span> : "N/A"
    }

    const statusFormatter = (cell) => {
        let color = ""
        let titleText = ""
        function colorSelector(cell) {
            if (cell.toLowerCase() === "verified") { color = "success"; titleText = "Verified"; }
            if (cell.toLowerCase() === "rejected") { color = "danger"; titleText = "Rejected"; }
            if (cell.toLowerCase() === "pending verification") { color = "warning"; titleText = "Pending verification"; }
            if (cell.toLowerCase() === "expired") { color = "light"; titleText = "Expired"; }
        }

        colorSelector(cell);

        return <Badge color={color} title={titleText} className="status-badge"> {cell} </Badge>
    }

    const actionFormatter = (cell, row) => {
        let fileExtension = row.fileName
        fileExtension = fileExtension ? fileExtension.split('.').pop() : "";

        const viewDocument = () => {
            setModalState(!modalState);
            setFileURL(row.file_url);
            setFileExtension(fileExtension);
            setFileType(row.type);
        }

        const approveDocument = () => {
            setStatusUpdateNote("")
            setApproveDocModal(true)
            setDocumentId(row.id)
        }

        const rejectDocument = () => {
            setRejectDocModal(true)
            setDocumentId(row.id)
        }

        return (
            <div className="proof-of-identity-action-container">
                <Button color="primary" className="proof-of-identity-actions" onClick={() => { viewDocument() }} >View</Button>
                {row.status === "Verified" || row.status === "Expired" ? "" : <Button color="success" className="proof-of-identity-actions" onClick={() => { approveDocument() }} >Approve</Button>}
                {row.status === "Rejected" || row.status === "Verified" ? "" : <Button color="warning" className="proof-of-identity-actions" onClick={() => { rejectDocument() }} >Reject</Button>}
                <Button color="danger" className="proof-of-identity-actions" onClick={() => { setDocumentId(row.id); setDocumentName(row.title); setDeleteDocumentModal(true) }} >Delete</Button>
            </div>
        )
    }

    const columns = [{
        dataField: 'type',
        text: 'Type'
    }, {
        dataField: 'title',
        text: 'Document',
        formatter: basicValidation,
    }, {
        dataField: 'expiresOn',
        text: 'Expires On',
        formatter: expiryFormatter,
    }, {
        dataField: 'uploadedOn',
        text: 'Uploaded On',
        formatter: dateFormatter,
    },
    {
        dataField: 'status',
        text: 'Status',
        formatter: statusFormatter,
    },
    {
        dataField: 'comment',
        text: 'Reason For Rejection',
        formatter: basicValidation,
    },
    {
        text: 'Actions',
        formatter: actionFormatter,
    }];

    // API CALL
    const documentStatusUpdate = () => {
        const params = {
            "document_id": documentId ? documentId : 0,
            "status": docUpdateStatusCode ? docUpdateStatusCode : 0,
            "reason": statusUpdateNote ? statusUpdateNote : "",
            "is_delete": documentDeleteConfirmation ? 1 : 0,
            "userID": userID ? userID : 0
        }

        axios.post(`${API_ENDPOINT}/user/document-status-update/`, params, {
            headers: {
                Authorization: token,
            }
        })
            .then(function (response) {
                // console.log(response);
                setReloadPhysicianIdentityData(reloadPhysicianIdentityData + 1);
                setUserConfirmation(false);
                setDocumentDeleteConfirmation(false);
                toastr.success("Done", "Document status updated");
            })
            .catch(function (error) {
                console.log(error);
                toastr.error("Error", "Document status updated failed");
            });
    }

    // EFFECT TO CLEAR FILE INFO WHEN THE DOC VIEWER MODAL IS CLOSED
    useEffect(() => {
        if (!modalState) {
            setFileURL("");
            setFileExtension("");
        }
    }, [modalState])

    // EFFECT TO CLEAR APPROVE DOCUMENT MODAL 
    useEffect(() => {
        if (!approveDocModal) {
            setStatusUpdateNote("");
            setDocUpdateStatusCode("");
            setUserConfirmation(false);
        }
    }, [approveDocModal])

    // EFFECT TO CLEAR REJECT DOCUMENT MODAL 
    useEffect(() => {
        if (!statusUpdateNote) {
            setStatusUpdateNote("");
            setDocUpdateStatusCode("");
            setUserConfirmation(false);
        }
    }, [statusUpdateNote])

    // EFFECT TO CALL API WHEN USER CLICK YES
    useEffect(() => { if (userConfirmation) documentStatusUpdate() }, [userConfirmation]) // eslint-disable-line

    // EFFECT TO HANDLE DOCUMENT DELETION  
    useEffect(() => { if (documentDeleteConfirmation) documentStatusUpdate(); }, [documentDeleteConfirmation]) // eslint-disable-line

    return (
        <div className="table-bg-color">
            <ReactTooltip id='reg-doc' className="doc-upload-tooltip" />
            <BootstrapTable
                keyField='reg_documents'
                data={reg_documents}
                columns={columns}
                bordered={false}
                bootstrap4
            />
            <DocumentViewer
                modalState={modalState}
                setModalState={setModalState}
                fileURL={fileURL}
                fileExtension={fileExtension}
                fileType={fileType}
            />
            <ApproveDocModal
                approveDocModal={approveDocModal}
                setApproveDocModal={setApproveDocModal}
                setUserConfirmation={setUserConfirmation}
                setDocUpdateStatusCode={setDocUpdateStatusCode}
            />
            <RejectDocModal
                rejectDocModal={rejectDocModal}
                setRejectDocModal={setRejectDocModal}
                setUserConfirmation={setUserConfirmation}
                setStatusUpdateNote={setStatusUpdateNote}
                setDocUpdateStatusCode={setDocUpdateStatusCode}
            />
            <DeleteDocumentModal
                deleteDocumentModal={deleteDocumentModal}
                setDeleteDocumentModal={setDeleteDocumentModal}
                setDocumentDeleteConfirmation={setDocumentDeleteConfirmation}
                documentName={documentName}
                documentType={documentType}
            />
        </div>
    )
}

export default RegistrationDetailsTable
