import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';
import moment from 'moment';

const PatientInsuranceViewModal = (props) => {

  // DEPENDENCY VARIABLES
  const {
    patientInsuranceViewModal,
    setPatientInsuranceViewModal,
    rowData

  } = props

  const [spinner, setSpinner] = useState(false);// eslint-disable-line



  const toggle = () => {
    setPatientInsuranceViewModal(!patientInsuranceViewModal);
  }


  return (
    <div >
      <Modal isOpen={patientInsuranceViewModal} size="md" className="custom-modal">
        <ModalHeader tag="h4" toggle={toggle} className="modal-header" cssModule={{ 'modal-title': 'w-100 text-center' }} >
          View Insurance Details
        </ModalHeader>
        <ModalBody>
          {spinner ? (
            <div className="text-center">
              <Spinner />
            </div>
          ) : (
            <div className="table-responsive">
              <table className="table table-bordered">
                <tbody>
                  <tr className="table-primary">
                    <td><b>Holder Name :</b></td>
                    <td>{rowData.holderName}</td>
                  </tr>
                  <tr className="">
                    <td><b>Insurance Group Name :</b></td>
                    <td>{rowData.insuranceGroup}</td>
                  </tr>
                  <tr className="table-primary">
                    <td><b>Policy Number :</b></td>
                    <td>{rowData.policyNumber}</td>
                    </tr>
                    <tr className="">
                      <td><b>Start Date :</b></td>
                      <td>{rowData.startDate ? moment(rowData.startDate).format("DD MMM YYYY") : "N/A"}</td>
                    </tr>
                    <tr className="table-primary">
                      <td><b>Expiry Date :</b></td>
                      <td>{rowData.expiryDate ? moment(rowData.expiryDate).format("DD MMM YYYY") : "N/A"}</td>
                    </tr>

                  </tbody>
              </table>
            </div>

          )}
        </ModalBody>
        <ModalFooter className="modal-footer">
          <Button outline className="modal-button" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>


    </div>

  );
}

export default PatientInsuranceViewModal;