import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Breadcrumb, BreadcrumbItem, Container, Spinner, Button } from "reactstrap";
import Header from "../../components/Header";
import EditInsuranceGroupModal from '../../newComponents/utilities/EditInsuranceGroupModal';
import NewInsuranceGroupModal from '../../newComponents/utilities/NewInsuranceGroupModal';
import HeaderTitle from "../../components/HeaderTitle";
import { Hux } from "../../hoc/Hux";
import ExportCSVTable from "./InsuranceGroupDataTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GlobalState } from '../../contextAPI/Context';
import { faEdit } from "@fortawesome/free-solid-svg-icons";



const Tables = (props) => {
  // CONTEXT VARIABLES 
  const {
    setToken,
    setSpinner,
    setBlankStateMessage,
    insuranceGroupName, 
    groupInsuranceOffset,
    sortColumnForInsuranceDetails,
    groupInsuranceSearchKeyword,
    sortOrderForInsuranceDetails,
    groupInsuranceListReload,

  } = useContext(GlobalState);

  // LOCAL VARIABLES
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const [rowData, setRowData] = useState("")
  const [insuranceGroupList, setInsuranceGroupList] = useState([]);
  const [loading, setLoading] = useState(true);
  
  const [editInsuranceGroupModal, setEditInsuranceGroupModal] = useState(false);
  const [editInsuranceGroupName, setEditInsuranceGroupName] = useState("");
  const [editInsuranceGroupDescription, setEditInsuranceGroupDescription] = useState("");
  const [editInsuranceGroupId, setEditInsuranceGroupId] = useState("");

  const [newInsuranceGroupModal, setNewInsuranceGroupModal] = useState(false);



  const fetch_InsuranceGroupDetails = () => {
    setToken(props.authtoken);
    setLoading(true);
    setSpinner(true);


    const params =
    {
      "group_insurance_name": insuranceGroupName || "",
      "is_pagination": 0,
      "record_limit": 0,
      "offset": groupInsuranceOffset || 0,
      "search_keyword": groupInsuranceSearchKeyword || "",
      "sortColumn": sortColumnForInsuranceDetails || "",
      "sortOrder": sortOrderForInsuranceDetails || "",
    }

    Axios.post(API_ENDPOINT + "/user/get-group-insurance-details/", params, {
      headers: {
        Authorization: props.authtoken,
      }
    }).then((res) => {
      console.log('res',res)
      setInsuranceGroupList(res.data.data)
      setBlankStateMessage(!(res.data.data.length > 0));
      setLoading(false);
      setSpinner(false);
    });
  };

  const basicValidation = (cell) => {
    return cell || "N/A"
  }



  const editInsuranceGroupDetails=(insurance_grp_id,insurance_grp_name,insurance_grp_description)=> {
    
    setEditInsuranceGroupName(insurance_grp_name);
    setEditInsuranceGroupId(insurance_grp_id);
    setEditInsuranceGroupDescription(insurance_grp_description);

  }


  const actionFormatter = (cell, row) => {
    return (
      <>
        <Button
          className="money-button"
          outline
          onClick={() => {
            setRowData(row)
            setEditInsuranceGroupModal(true)
            editInsuranceGroupDetails(row.insurance_grp_id,row.insurance_grp_name,row.insurance_grp_description)
          }}
          color="primary"
          title="Edit Insurance Group Details"
        >
          <FontAwesomeIcon
            icon={faEdit}
            fixedWidth
            className="align-middle"
            title="Edit Insurance Group Details"
          />
        </Button>
      </>
    )
  }

  
  const tableColumns = [

    {
      dataField: "insurance_grp_name",
      text: "Name",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "insurance_grp_description",
      text: "Description",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      text: "Actions",
      csvExport: false,
      formatter: actionFormatter,
    }
  ];

  let loader = null;

  if (loading) {
    loader = <Spinner />;
  }

  // DEFAULT API CALL
  useEffect(() => { fetch_InsuranceGroupDetails() },
  // eslint-disable-next-line
    [
      insuranceGroupName,
      groupInsuranceListReload,
      setBlankStateMessage
    ]);

  
  return (
    <Hux>
      {loader}
      <Container fluid>
        <Header>
          <HeaderTitle>Insurances
          <button className="btn btn-equipo-blue mt-2 pull-right"
              onClick={() => {
                setNewInsuranceGroupModal(true)
              }}
            >
              Add Insurance Group
              </button>
          </HeaderTitle>
          <Breadcrumb>
            <BreadcrumbItem>
            <Link to="/dashboard">Dashboard</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Insurances</BreadcrumbItem>
          </Breadcrumb>
        </Header>

        <EditInsuranceGroupModal
          editInsuranceGroupModal={editInsuranceGroupModal}
          setEditInsuranceGroupModal={setEditInsuranceGroupModal}
          editInsuranceGroupName={editInsuranceGroupName}
          editInsuranceGroupDescription={editInsuranceGroupDescription}
          editInsuranceGroupId={editInsuranceGroupId}
          setEditInsuranceGroupName={setEditInsuranceGroupName}
          setEditInsuranceGroupId={setEditInsuranceGroupId}
          setEditInsuranceGroupDescription={setEditInsuranceGroupDescription}
          rowData={rowData}
          token={props.authtoken}

        />
        <NewInsuranceGroupModal
        token={props.authtoken}
        newInsuranceGroupModal={newInsuranceGroupModal}
        setNewInsuranceGroupModal={setNewInsuranceGroupModal}
        />

        <ExportCSVTable  data={insuranceGroupList} columns={tableColumns}  />
      </Container>
    </Hux>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
    authtoken: state.auth.token,
  };
};

export default connect(mapStateToProps, null)(Tables);
