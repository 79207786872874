import React, { useState, useContext, useEffect } from 'react';
import { GlobalState } from '../../contextAPI/Context';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from 'react-select';
import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import AdvancedPharmacySearchModal from "./AdvancedPharmacySearchModal";

const EditPrescriptionModal = (props) => {
    // DEPENDENCY VARIABLES 
    const { editPrescriptionModal, setEditPrescriptionModal, rowData, token } = props;
    // CONTEXT VARIABLES 
    const { spinner, setSpinner, prescriptionReload, setPrescriptionReload, pharmacyDropDownData, pharmacyLoading, setPharmacyDropDownData, setPharmacyLoading } = useContext(GlobalState);
    // LOCAL VARIABLES 
    const [newPharmacyId, setNewPharmacyId] = useState("");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const userId = localStorage.getItem('userId');
    const [pharmacyList, setPharmacyList] = useState("");
    const [advancedSearchModal, setAdvancedSearchModal] = useState(false);

    const toggle = () => {
        setEditPrescriptionModal(false);
        setNewPharmacyId("");
    }

    const formatOptionLabel = ({ label, address, city, state, country_name, zipcode }, { context }) => {
        if (context === "value") {
            return <div>{label}</div>;
        } else if (context === "menu") {
            return (
                <div style={{ display: "flex", flexDirection: "column", color: "#1b3b44" }}>
                    <div>{label}</div>
                    <div style={{ marginLeft: "10px", color: "grey" }}>
                        {address ? `${address}` : "N/A"}{city ? `, ${city}` : ""}{state ? `, ${state}` : ""}{country_name ? `, ${country_name}` : ""}{zipcode ? `, ${zipcode}` : ""}
                    </div>
                </div>
            );
        }
    };

    const confirmation = () => { updatePharmacyApiCall() };

    const updatePharmacyApiCall = () => {
        axios.post(`${API_ENDPOINT}/user/update-prescription-pharmacy/`, {
            "appointment_id": rowData.appointment_id,
            "pharmacy_id": newPharmacyId,
            "uploader_id": Number(userId)
        }, {
            headers: {
                Authorization: token,
            }
        })
            .then(function (response) {
                // console.log(response);
                setEditPrescriptionModal(false)
                setPrescriptionReload(prescriptionReload + 1)
                toastr.success("Pharmacy Updated Successfully")
            })
            .catch(function (error) {
                // console.log(error);
                toastr.error("Update Failed", "Please try again")
            });
    }

    const getPharmacyList = () => {
        setSpinner(true)
        axios
            .get(API_ENDPOINT + "/external/get-active-pharmacies/", {
                headers: {
                    Authorization: token,
                },
            })
            .then((res) => {
                setPharmacyList(res.data);
                setSpinner(false);
            })
            .catch((error) => {
                console.log(error);
                setSpinner(false);
            });
    };

    const createPharmacyOption = () => {
        var array = pharmacyList;
        const options = array.map((item) => {
            return {
                value: item.id,
                label: item.name,
                address: item.address,
                city: item.city,
                state: item.state,
                country_name: item.country_name,
                zipcode: item.zipcode

            };
        });
        setPharmacyDropDownData(options);
        setPharmacyLoading(false);
    };

    useEffect(() => { if (token && !pharmacyDropDownData && !spinner) getPharmacyList(); }, [pharmacyDropDownData, token]);// eslint-disable-line
    useEffect(() => { if (pharmacyList) createPharmacyOption() }, [pharmacyList]);// eslint-disable-line

    return (
        <div>
            <Modal isOpen={editPrescriptionModal} toggle={toggle} >
                <ModalHeader tag="h4" cssModule={{'modal-title': 'w-100 text-center'}}  toggle={toggle}>Change Pharmacy - {rowData ? rowData.patient_name : "N/A"}</ModalHeader>
                <ModalBody>
                    <table>
                        <tbody>
                            <tr>
                                <td>Current Pharmacy</td>
                                <td> : </td>
                                <td> {rowData.pharmacy_name ? <strong>{rowData.pharmacy_name}</strong> : "N/A"} </td>
                            </tr>
                            <tr>
                                <td> </td>
                                <td> </td>
                                <td>
                                    {rowData.pharmacy_address ? `${rowData.pharmacy_address}` : "N/A"}{rowData.pharmacy_city ? `, ${rowData.pharmacy_city}` : ""}{rowData.pharmacy_state ? `, ${rowData.pharmacy_state}` : ""}{rowData.pharmacy_country ? `, ${rowData.pharmacy_country}` : ""}{rowData.pharmacy_zip ? `, ${rowData.pharmacy_zip}` : ""}
                                    {/* {rowData.pharmacy_name ? <span className="pharmacy-address-color">{rowData.pharmacy_address ? `${rowData.pharmacy_address},` : "N/A"}{rowData.pharmacy_country ? `${rowData.pharmacy_country}` : "N/A"}</span> : "N/A"} */}

                                </td>
                            </tr>
                            <tr className="group-selector-table-spacer"></tr>
                            <tr >
                                <td> New Pharmacy</td>
                                <td> : </td>
                                <td className="pharmacy-selector">
                                    <Select
                                        options={pharmacyDropDownData}
                                        isLoading={pharmacyLoading}
                                        onChange={event => { setNewPharmacyId(event.value) }}
                                        formatOptionLabel={formatOptionLabel}
                                        theme={theme => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary: '#dcf9ee',
                                            },
                                        })}
                                    />

                                </td>

                            </tr>
                            <tr>
                                <td>

                                </td>
                                <td>

                                </td>
                                <td>
                                    <p
                                        className="float-right cursor-pointer advanced-search-icon"
                                        onClick={() => { setAdvancedSearchModal(true) }}
                                    >
                                        <FontAwesomeIcon icon={faSearch} /> {" "}
                                        Advanced Search
                                </p>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </ModalBody>
                <ModalFooter>
                    <Button
                        className="yes-button button-width"
                        disabled={newPharmacyId ? false : true}
                        title={newPharmacyId ? "" : "Choose a new pharmacy and update"}
                        onClick={confirmation}
                    >Update</Button>{' '}
                    <Button outline className="no-button button-width" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>

            <div>
                <AdvancedPharmacySearchModal
                    advancedSearchModal={advancedSearchModal}
                    setAdvancedSearchModal={setAdvancedSearchModal}
                    rowData={rowData}
                    prescriptionReload={prescriptionReload}
                    setPrescriptionReload={setPrescriptionReload}
                    setEditPrescriptionModal={setEditPrescriptionModal}
                />
            </div>
        </div>
    );
}

export default EditPrescriptionModal;