import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Breadcrumb, BreadcrumbItem, Container, Spinner, Button } from "reactstrap";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import { Hux } from "../../hoc/Hux";
import ExportCSVTable from "./PharmacyDataTable";
import { toastr } from 'react-redux-toastr'
import { GlobalState } from '../../contextAPI/Context'
import StatusChangeModal from '../../newComponents/utilities/StatusChangeModal';
import EditPharmacyModal from '../../newComponents/utilities/EditPharmacyModal';
import NewPharmacyModal from '../../newComponents/utilities/NewPharmacyModal';
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Tables = (props) => {
  // CONTEXT VARIABLES 
  const {
    token,
    setToken,
    setBlankStateMessage,
    pharmacyName,
    pharmacyEmail,
    pharmacyCode,
    pharmacyEfax,
    pharmacyAddress,
    pharmacyCountry,
    pharmacyStatus,
    pharmacyListReload, 
    setPharmacyListReload,
    pharmacyCity, 
    pharmacyState, 
    pharmacyZipcode, 
  } = useContext(GlobalState);

  // LOCAL VARIABLES 
  const [PharmacyList, setPharmacy] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalState, setModalState] = useState(false);
  const [pharmacyID, setPharmacyID] = useState("");
  const [status, setStatus] = useState("");
  const [userConfirmation, setUserConfirmation] = useState(false);
  const [VerificationChange, setVerificationChange] = useState(false);// eslint-disable-line
  const [rowData, setRowData] = useState("");
  const [editPharmacyModal, setEditPharmacyModal] = useState(false);
  const [newPharmacyModal, setNewPharmacyModal] = useState(false);

  const [editPharmacyName, setEditPharmacyName] = useState("");
  const [editPharmacyCode, setEditPharmacyCode] = useState("");
  const [editPharmacyCity, setEditPharmacyCity] = useState("");
  const [editPharmacyAddress, setEditPharmacyAddress] = useState("");
  const [editPharmacyEmail, setEditPharmacyEmail] = useState("");
  const [editPharmacyFax, setEditPharmacyFax] = useState("");
  const [editPharmacyEfax, setEditPharmacyEfax] = useState("");
  const [editPharmacyId, setEditPharmacyId] = useState("");
  const [editPharmacyPhone, setEditPharmacyPhone] = useState("");
  const [editPharmacyState, setEditPharmacyState] = useState("");
  const [editPharmacyCountry, setEditPharmacyCountry] = useState("");
  const [editPharmacyZipcode, setEditPharmacyZipcode] = useState("");


  // COUNTRY
  const [countryList, setCountryList] = useState("")
  const [countryDropDown, setCountryDropDown] = useState("")
  const [countryIsd, setCountryIsd] = useState("")

  let userID = localStorage.getItem("userId");
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

  const fetchPharmacy = () => {
    setToken(props.authtoken);
    setLoading(true);

    const params =
    {
      "pharmacy_name": pharmacyName ? pharmacyName : "",
      "pharmacy_code": pharmacyCode ? pharmacyCode : "",
      "pharmacy_email": pharmacyEmail ? pharmacyEmail : "",
      "pharmacy_efax": pharmacyEfax ? pharmacyEfax : "",
      "pharmacy_address": pharmacyAddress ? pharmacyAddress : "",
      "pharmacy_status": pharmacyStatus ? pharmacyStatus : "",
      "pharmacy_country": pharmacyCountry ? pharmacyCountry : 0,
      "is_pagination": 0,
      "pharmacy_city": pharmacyCity ? pharmacyCity : "",
      "pharmacy_state": pharmacyState ? pharmacyState : "",
      "pharmacy_zipcode": pharmacyZipcode ? pharmacyZipcode : "",
      "offset": "force"
    }
    
    Axios.post(API_ENDPOINT + "/user/get-pharmacy-data/", params,{
      headers: {
        Authorization: props.authtoken,
      },
    }).then((res) => {
      setPharmacy(res.data);
      setBlankStateMessage(res.data.length > 0 ? false : true);
      setLoading(false);
    });

  };

  const changePharmacyStatus = () => {
    let newStatus = "";
    setLoading(true);
    if (status === 0) {
      newStatus = 1
    } else {
      newStatus = 0
    }

    const updatedStatus = {
      createdBy:userID,
      status: newStatus,
    };

    Axios.put(
      API_ENDPOINT + `/external/pharmacy-details/${pharmacyID}/`,
      updatedStatus,
      {
        headers: {
          Authorization: props.authtoken,
        },
      }
    )
      .then((res) => {
          fetchPharmacy();
          setUserConfirmation(false);
          setPharmacyListReload(pharmacyListReload+1);
          setLoading(false);
          toastr.success('Status Updated', 'Pharmacy status updated successfully');
        
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }; 

  const statusFormatter = (cell, row) => {
    let status = "";
    let badgeclass = " ";
  
    if (cell === 1) {
      status = "Active";
      badgeclass = "badge badge-success statustoggle status-button-width";
    } else {
      status = "Inactive";
      badgeclass = "badge badge-danger statustoggle status-button-width";
    }

    return (
      <span
        className={badgeclass}
        onClick={() => {
          setPharmacyID(row.id);
          setStatus(cell);
          setModalState(true);
        }}
      >
        {status}
      </span>
    );
  };

  const getCountryList = () => {
    Axios.get(API_ENDPOINT + "/external/get-country-list/",
        {
            headers: {
                Authorization: token,
            },
        })
        .then((res) => {
            setCountryList(res.data);
            
        })
        .catch((error) => {
            console.log(error);
        });
  }

  const createCountryOption = () => {
    var array = countryList
    const options = array.map((item) => {
        return { value: item.id, label: item.country_name};
    });
    setCountryDropDown(options)

    var array1 = countryList
    const options1 = array1.map((item1) => {
        return { value: item1.id, label: item1.country_isd_code};
    });
    setCountryIsd(options1)
  }


  // COUNTRY
  useEffect(() => { if (token && !countryList) getCountryList(); }, [token, countryList])// eslint-disable-line
  useEffect(() => { if (countryList.length > 0) createCountryOption() }, [countryList])// eslint-disable-line


  const basicValidation = (cell) => {
    return cell ? cell : "N/A"
  }

  const statusFormatterCSV = (cell) => {
    return cell ? "Active" : "Inactive"
  }


  const editPharmacy=(id,code,name,city,address,email,fax,efax,contactNumber,state, countryID,zipcode)=> {
    
    setEditPharmacyCode(code);
    setEditPharmacyName(name);
    setEditPharmacyCity(city);
    setEditPharmacyAddress(address);
    setEditPharmacyEmail(email);
    setEditPharmacyFax(fax);
    setEditPharmacyEfax(efax);
    setEditPharmacyId(id);
    setEditPharmacyPhone(contactNumber);
    setEditPharmacyState(state);
    setEditPharmacyCountry(countryID);
    setEditPharmacyZipcode(zipcode);
  }

  const actionFormatter = (cell, row) => {
    return (
      <>
        <Button
          className="money-button"
          outline
          onClick={() => {
            setRowData(row)
            setEditPharmacyModal(true)
            editPharmacy(row.id,row.code,row.name,row.city,row.address,row.email,row.fax,row.efax,row.contactNumber,row.state, row.countryID, row.zipcode)
          }}
          color="primary"
          title="Edit Pharmacy"
        >
          <FontAwesomeIcon
            icon={faEdit}
            fixedWidth
            className="align-middle"
            title="Edit Pharmacy"
          />
        </Button>
      </>
    )
  }

  const tableColumns = [
    {
      dataField: "code",
      text: "Code",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "country_name",
      text: "Country",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "state",
      text: "State/ Province/ County ",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "city",
      text: "City/ Town",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "address",
      text: "Address",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "fax",
      text: "Fax",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "efax",
      text: "Efax",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "contactNumber",
      text: "Telephone",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    
    {
      dataField: "status",
      text: "Status",
      sort: true,
      formatter: statusFormatter,
      csvFormatter: statusFormatterCSV,
    },
    
    {
      text: "Actions",
      formatter: actionFormatter,
      csvExport: false
    },
  ];

  let loader = null;

  if (loading) {
    loader = <Spinner />;
  }

  // DEFAULT API CALL
  // eslint-disable-next-line
  useEffect(() => { fetchPharmacy() }, [pharmacyName,
    pharmacyEmail,
    pharmacyCode,
    pharmacyEfax,
    pharmacyAddress,
    pharmacyCountry,
    pharmacyListReload,]);

  // EFFECT TO HANDLE PHARMACY STATUS CHANGE 
  useEffect(() => { if (userConfirmation && !VerificationChange) changePharmacyStatus() }, [userConfirmation]) // eslint-disable-line

  return (
    <Hux>
      {loader}
      <Container fluid>
        <Header>
          <HeaderTitle>Pharmacy
          <button className="btn btn-equipo-blue mt-2 pull-right"
            onClick={() => {
              setNewPharmacyModal(true)
              }}
          >
                Add New Pharmacy
              </button>
          </HeaderTitle>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/dashboard">Dashboard</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Pharmacy</BreadcrumbItem>
          </Breadcrumb>
        </Header>
        <StatusChangeModal modalState={modalState} setModalState={setModalState} setUserConfirmation={setUserConfirmation}  />
        <EditPharmacyModal
          editPharmacyName={editPharmacyName}
          editPharmacyCode={editPharmacyCode}
          editPharmacyCity={editPharmacyCity}
          editPharmacyAddress={editPharmacyAddress}
          editPharmacyEmail={editPharmacyEmail}
          editPharmacyFax={editPharmacyFax}
          editPharmacyEfax={editPharmacyEfax}
          editPharmacyId={editPharmacyId}
          editPharmacyPhone={editPharmacyPhone}
          editPharmacyZipcode={editPharmacyZipcode}
          setEditPharmacyCode={setEditPharmacyCode}
          setEditPharmacyName={setEditPharmacyName}
          setEditPharmacyCity={setEditPharmacyCity}
          setEditPharmacyAddress={setEditPharmacyAddress}
          setEditPharmacyEmail={setEditPharmacyEmail}
          setEditPharmacyFax={setEditPharmacyFax}
          setEditPharmacyEfax={setEditPharmacyEfax}
          setEditPharmacyId={setEditPharmacyId}
          setEditPharmacyPhone={setEditPharmacyPhone}
          setEditPharmacyState={setEditPharmacyState}
          setEditPharmacyCountry={setEditPharmacyCountry}
          setEditPharmacyZipcode={setEditPharmacyZipcode}
          editPharmacyCountry={editPharmacyCountry}
          editPharmacyState={editPharmacyState}
          rowData={rowData}
          editPharmacyModal={editPharmacyModal}
          setEditPharmacyModal={setEditPharmacyModal}
          token={props.authtoken}
          countryDropDown={countryDropDown}
          countryIsd={countryIsd}
          countryList={countryList}
        />
        <NewPharmacyModal
        token={props.authtoken}
        newPharmacyModal={newPharmacyModal}
        setNewPharmacyModal={setNewPharmacyModal}
        />
        <ExportCSVTable data={PharmacyList} columns={tableColumns} />
      </Container>
    </Hux>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
    authtoken: state.auth.token,
  };
};

export default connect(mapStateToProps, null)(Tables);