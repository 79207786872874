import React, { useContext } from 'react';
import { GlobalState } from '../../contextAPI/Context';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col,Input,Label, FormGroup, } from 'reactstrap';
import axios from 'axios';
import { toastr } from 'react-redux-toastr';


  

const EditInsuranceGroupModal = (props) => {
    const {
        editInsuranceGroupModal,
        setEditInsuranceGroupModal,
        rowData,
        token,
        editInsuranceGroupName,
        editInsuranceGroupDescription,
        editInsuranceGroupId,
        setEditInsuranceGroupName,
        setEditInsuranceGroupDescription,
    } = props;

    // CONTEXT VARIABLES 
    const { groupInsuranceListReload, setGroupInsuranceListReload, } = useContext(GlobalState);
    
    // LOCAL VARIABLES
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;


    const toggle = () => { setEditInsuranceGroupModal(!editInsuranceGroupModal); }
 
    const confirmation = () => { 
        if(!editInsuranceGroupName){
            toastr.error("Name Field Is Required" , "Please enter insurance group name !")
        }
        
        
        if(editInsuranceGroupName){

            update_InsuranceGroupDetails()
        }
    
    };

    const update_InsuranceGroupDetails = () => {
        
               
        axios.post(`${API_ENDPOINT}/user/update-group-insurance-details/`, {
            "groupInsuranceId": editInsuranceGroupId,
            "groupInsuranceName": editInsuranceGroupName,
            "groupInsuranceDescription":editInsuranceGroupDescription
        }, {
            headers: {
                Authorization: token,
            }
        })
            .then(function (response) {
                // console.log(response);
                setEditInsuranceGroupModal(!editInsuranceGroupModal)
                setGroupInsuranceListReload(groupInsuranceListReload + 1)
                toastr.success("Successfully Updated", "Insurance group details successfully updated")
            })
            .catch(function (error) {
                // console.log(error);
                toastr.error("Update Failed", "Please try again")
            });
    }

    
   
     return (
        <div>
            <Modal isOpen={editInsuranceGroupModal} toggle={toggle}  size="md" >
                <ModalHeader tag="h4" cssModule={{'modal-title': 'w-100 text-center'}}  toggle={toggle}>Edit - {rowData ? rowData.name : "N/A"} </ModalHeader>
                <ModalBody className="overflow-modal disable-scrollbars" >
                    <FormGroup>
                        <Row>
                            <Col>
                            
                                 
                                <Label for="group_insurance_name">Name<span className="required">*</span></Label>
                                <Input
                                id="group_insurance_name"
                                value={editInsuranceGroupName}
                                placeholder="Name"
                                onChange={(e) => setEditInsuranceGroupName(e.target.value)}
                                minLength= "1"
                                maxLength= "120"
                                />
                                <br></br>

                                <Label for="group_insurance_description">Description</Label>
                                <Input
                                id="group_insurance_description"
                                type="textarea"
                                value={editInsuranceGroupDescription}
                                placeholder="Description"
                                onChange={(e) =>  setEditInsuranceGroupDescription(e.target.value)}
                                />
                                <br></br>
        

                            </Col>
                        </Row>
                    </FormGroup>
                 </ModalBody>
                <ModalFooter>
                    <Button 
                    className="yes-button button-width"  
                    onClick={confirmation}
                    >Update</Button>{' '}
                    <Button outline className="no-button button-width" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default EditInsuranceGroupModal;