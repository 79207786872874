import React, { useContext, useEffect, useState } from 'react'
import { GlobalState } from '../../contextAPI/Context'
import axios from "axios";
import { Spinner, Row, Col } from 'reactstrap'
import moment from 'moment'
import MomentConfig from '../../common/MomentConfig'
import TimeLine from './TimeLine';

const TimeLineTab = () => {
    // CONTEXT VARIABLES 
    const { spinner, setSpinner, prescriptionRowData, prescriptionReload } = useContext(GlobalState)

    // LOCAL VARIABLES 
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT
    let token = localStorage.getItem('token')
    const [timeLineData, setTimeLineData] = useState("")

    const timeLineCustomData = timeLineData ? timeLineData.map(
        item => {
            if (item.status_id === 33) {
                return (
                    `<p>
                        By <b>${item.createdUser}</b>
                    </p>
                    <p>
                        From <b>${item.pharmacy_from_name ? item.pharmacy_from_name : "Pharmacy Not Assigned"}</b> to <b>${item.pharmacy_to_name}</b>
                    </p>
                    <p>
                        On <b>${moment.utc(item.createDate).local().format(MomentConfig.MOMENT_FORMAT_DATEANDTIME)} </b>
                    </p>`
                )
            } else if (item.status_id === 32) {
                if (item.pharmacy_to_name) {
                    return (
                        `<p>
                           To <b>${item.pharmacy_to_name}</b>
                       </p>`
                    )
                } else {
                    return (
                        `<p>
                        <b>Pharmacy Not Assigned</b> 
                    </p>`
                    )
                }
            } else if (item.status_id === 27 || item.status_id === 28) {
                return (
                    `<p>
                        By <b>${item.createdUser}</b>
                    </p>
                    <p>
                        To <b>${item.to_contact}</b>
                    </p>
                    <p>
                        On <b>${moment.utc(item.createDate).local().format(MomentConfig.MOMENT_FORMAT_DATEANDTIME)} </b>
                    </p>`
                )
            } else {
                return (
                    `<p>
                        By <b>${item.createdUser}</b>
                    </p>
                    <p>
                        On <b>${moment.utc(item.createDate).local().format(MomentConfig.MOMENT_FORMAT_DATEANDTIME)} </b>
                    </p>`
                )
            }
        }
    ) : "";

    const timeLineComponent = timeLineData ? timeLineData.map(
        item => {
            if (item.status_id === 33) {
                return (
                    {
                        title: `${moment.utc(item.createDate).local().format(MomentConfig.MOMENT_FORMAT_DATEANDTIME)}`,
                        cardTitle: `${item.prescription_status}`
                    }
                )
            } else {
                return (
                    {
                        title: `${moment.utc(item.createDate).local().format(MomentConfig.MOMENT_FORMAT_DATEANDTIME)}`,
                        cardTitle: `${item.send_title ? item.send_title : item.prescription_status}`
                    }
                )
            }

        }
    ) : ""

    const getPrescriptionTimeline = () => {
        setSpinner(true);
        axios.post(`${API_ENDPOINT}/external/prescription-timeline/`, {
            "appointment_id": prescriptionRowData.appointment_id
        }, {
            headers: {
                Authorization: token,
            }
        })
            .then(function (response) {
                // console.log(response);
                setSpinner(false);
                setTimeLineData(response.data)
            })
            .catch(function (error) {
                // console.log(error);
                setSpinner(false);
            });
    }

    useEffect(() => { if (prescriptionReload && timeLineData) setTimeLineData("") }, [prescriptionReload]) // eslint-disable-line
    useEffect(() => { if (prescriptionRowData && !timeLineData) getPrescriptionTimeline() }, [prescriptionRowData, timeLineData, prescriptionReload]) // eslint-disable-line

    return (
        <>
            <div className="timeline-view-container disable-scrollbars">
                <Row>
                    <Col>
                        {spinner ? <Spinner color="success" /> : ""}
                        {timeLineComponent ? <TimeLine items={timeLineComponent} timeLineCustomData={timeLineCustomData} /> : ""}
                    </Col>
                </Row>

            </div>
        </>
    )
}

export default TimeLineTab
