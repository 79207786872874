import React, { useState, useContext, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, FormGroup, Label, Row, Col } from 'reactstrap';
import axios from "axios";
import { toastr } from 'react-redux-toastr';
import { GlobalState } from '../../contextAPI/Context';


const SendPrescriptionModal = (props) => {
    // DEPENDENCY VARIABLES 
    const { rowData, emailPharmacyModal, setEmailPharmacyModal } = props;
    // CONTEXT VARIABLES 
    const { setSpinner, prescriptionReload, setPrescriptionReload } = useContext(GlobalState);
    // LOCAL VARIABLES
    let token = localStorage.getItem('token');
    let userId = localStorage.getItem('userId');
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

    const [sendMail, setSendMail] = useState(true);
    const [sendFax, setSendFax] = useState(false);

    const sendViaSelectedMethod = () => {
        sendMail ? sendEmailToPharmacy() : sendFaxToPharmacy()
    }

    const sendEmailToPharmacy = () => {
        setSpinner(true);

        const params =
        {
            "to_email": rowData.pharmacy_email,
            "appointment_id": rowData.appointment_id,
            "note_type": rowData.note_type,
            "uploader_id": Number(userId)
        }

        axios.post(API_ENDPOINT + "/external/pharmacy-mail/", params, {
            headers: {
                Authorization: token,
            }
        }).then((res) => {
            setSpinner(false);
            setPrescriptionReload(prescriptionReload + 1)
            toggle();
            toastr.success("Success", "Email sent to pharmacy")
        }).catch(function (error) {
            console.log(error);
            setSpinner(false);
            toastr.error("Email Not Sent", error.response.data.message)
        });
    };

    const sendFaxToPharmacy = () => {
        setSpinner(true);

        const params =
        {
            "appointment_id": rowData.appointment_id,
            "note_type": rowData.note_type,
            "uploader_id": Number(userId)
        }

        axios.post(API_ENDPOINT + "/external/prescription-fax/", params, {
            headers: {
                Authorization: token,
            }
        }).then((res) => {
            setSpinner(false);
            setPrescriptionReload(prescriptionReload + 1)
            toggle();
            toastr.success("Success", "E-fax sent to pharmacy")
        }).catch(function (error) {
            console.log(error);
            setSpinner(false);
            toastr.error("Efax Not Sent", error.response.data.message)
        });
    };

    const toggle = () => {
        setEmailPharmacyModal(false);
        setSendMail(true);
        setSendFax(false);
    }

    const confirmation = () => sendViaSelectedMethod();

    // EFFECT TO PRE SELECT RADIO BUTTON 
    useEffect(() => {
        if (rowData.pharmacy_email && !rowData.pharmacy_efax) { setSendMail(true); setSendFax(false); }
        if (!rowData.pharmacy_email && rowData.pharmacy_efax) { setSendFax(true); setSendMail(false); }
        if (rowData.pharmacy_email && rowData.pharmacy_efax) { setSendMail(true); setSendFax(false); }
    }, [rowData])

    return (
        <div>
            <Modal isOpen={emailPharmacyModal} toggle={toggle}>
                <ModalHeader tag="h4" cssModule={{'modal-title': 'w-100 text-center'}}  toggle={toggle}>Send Prescription </ModalHeader>
                <ModalBody>

                    <Row sm="6">
                        <Col sm={rowData.pharmacy_email ? "" : "8"}>
                            <FormGroup check>
                                <Label check>
                                    <Input
                                        type="radio"
                                        name="radio2"
                                        checked={sendMail}
                                        disabled={rowData.pharmacy_email ? false : true}
                                        title={rowData.pharmacy_email ? "Send prescription via email" : "Email address not available"}
                                        onClick={() => { setSendMail(true); setSendFax(false) }}
                                    />{' '}
                            Email {rowData.pharmacy_email ? "" : "(Email address not available)"}
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col sm={rowData.pharmacy_efax ? "" : "8"}>
                            <FormGroup check>
                                <Label >
                                    <Input
                                        type="radio"
                                        name="radio2"
                                        checked={sendFax}
                                        disabled={rowData.pharmacy_efax ? false : true}
                                        title={rowData.pharmacy_efax ? "Send prescription via E-fax" : "E-fax address not available"}
                                        onClick={() => { setSendFax(true); setSendMail(false) }}
                                    />{' '}
                                E-fax {rowData.pharmacy_efax ? "" : "(E-fax address not available)"}
                                </Label>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            {sendMail && rowData.pharmacy_email ? <span>Are you sure you want to <b>email</b> prescription to ({rowData.pharmacy_email})</span> : ""}
                            {sendFax && rowData.pharmacy_efax ? <span>Are you sure you want to <b>e-fax</b> prescription to ({rowData.pharmacy_efax})</span> : ""}
                        </Col>
                    </Row>

                </ModalBody>
                <ModalFooter>
                    <Button className="yes-button button-width" onClick={confirmation}>Yes</Button>{' '}
                    <Button outline className="no-button button-width" onClick={toggle}>No</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default SendPrescriptionModal;