import React, { useState, useEffect, useContext } from 'react';
import { GlobalState } from '../../contextAPI/Context';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Input, Label, FormGroup, } from 'reactstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import validator from 'validator';

const NewPharmacyModal = (props) => {
    const { newPharmacyModal, setNewPharmacyModal, token } = props;

    // CONTEXT VARIABLES 
    const { pharmacyListReload, setPharmacyListReload, } = useContext(GlobalState);

    // LOCAL VARIABLES
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const animatedComponents = makeAnimated();
    const [newPharmacyCode, setNewPharmacyCode] = useState("");
    const [newPharmacyName, setNewPharmacyName] = useState("");
    const [newPharmacyCity, setNewPharmacyCity] = useState("");
    const [newPharmacyAddress, setNewPharmacyAddress] = useState("");
    const [newPharmacyCountry, setNewPharmacyCountry] = useState("");
    const [newPharmacyEmail, setNewPharmacyEmail] = useState("");
    const [newPharmacyFax, setNewPharmacyFax] = useState("");
    const [newPharmacyEfax, setNewPharmacyEfax] = useState("");
    const [newPharmacyPhone, setNewPharmacyPhone] = useState("");
    const [newPharmacyState, setNewPharmacyState] = useState("");
    const [newPharmacyZipcode, setNewPharmacyZipcode] = useState("");
    const [addButtonStatus, setAddButtonStatus] = useState(true);
    let userID = localStorage.getItem("userId");

    // COUNTRY
    const [countryList, setCountryList] = useState("")
    const [countryDropDown, setCountryDropDown] = useState("")
    const [countryCode, setCountryCode] = useState([])

    const toggle = () => {
        setNewPharmacyModal(!newPharmacyModal);
        setAddButtonStatus(true);
        setNewPharmacyCode("")
        setNewPharmacyName("")
        setNewPharmacyCity("")
        setNewPharmacyAddress("")
        setNewPharmacyCountry("")
        setNewPharmacyEmail("")
        setNewPharmacyFax("")
        setNewPharmacyEfax("")
        setNewPharmacyPhone("")
        setNewPharmacyState("")
        setNewPharmacyZipcode("")
    }



    const confirmation = () => {
        if (!newPharmacyName) {
            toastr.error("Name Field is Required", "Please enter the pharmacy name !")
        }
        if (!newPharmacyEmail) {
            toastr.error("Email Field Is Required", "Please enter the pharmacy email !")
        }
        if (newPharmacyName && newPharmacyEmail) {
            let valid = validateEmail()
            if (valid) updatePharmacyApiCall()
        }
    };

    // && newPharmacyFax && newPharmacyEfax

    const updatePharmacyApiCall = () => {

        var tempDate = new Date();
        var date = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate() + ' ' + tempDate.getHours() + ':' + tempDate.getMinutes() + ':' + tempDate.getSeconds();
        axios.post(`${API_ENDPOINT}/external/pharmacy-details/`, {
            "code": newPharmacyCode ? newPharmacyCode : null,
            "name": newPharmacyName,
            "city": newPharmacyCity,
            "address": newPharmacyAddress ? newPharmacyAddress : null,
            "email": newPharmacyEmail,
            "fax": newPharmacyFax ? newPharmacyFax : null,
            "efax": newPharmacyEfax ? newPharmacyEfax : null,
            "contactNumber": newPharmacyPhone ? newPharmacyPhone : null,
            "createdDate": date,
            "state": newPharmacyState,
            "countryID": newPharmacyCountry.value,
            "createdBy": userID,
            "status": 1,
            "zipcode": newPharmacyZipcode

        }, {
            headers: {
                Authorization: token,
            }
        })
            .then(function (response) {
                // console.log(response);
                setNewPharmacyModal(!newPharmacyModal)
                setPharmacyListReload(pharmacyListReload + 1)
                toastr.success("Successfully added", "Pharmacy details successfully updated")
                toggle();
            })
            .catch(function (error) {
                // console.log(error);
                toastr.error("Update Failed", "Please try again")
            });
    }

    const getCountryList = () => {
        axios.get(API_ENDPOINT + "/external/get-country-list/",
            {
                headers: {
                    Authorization: token,
                },
            })
            .then((res) => {
                setCountryList(res.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }


    const createCountryOption = () => {
        var array = countryList
        const options = array.map((item) => {
            return { value: item.id, label: item.country_name };
        });
        setCountryDropDown(options)

    }

    const countryIsdCode = (id) => {
        var array = countryList
        const option = array.map((item) => { // eslint-disable-line
            if (id === item.id) {
                return {
                    value: item.country_isd_code,
                    label: item.country_isd_code
                }
            }
        });

        setCountryCode(option)
    }

    const validateEmail = () => {
        let valid = validator.isEmail(newPharmacyEmail)
        if (!valid) toastr.error("Email Not valid", "Please provide a valid email")
        return valid
    }

    const disableAddButton = () => {

        if (
            newPharmacyName &&
            newPharmacyEmail &&
            newPharmacyCountry
            /* newPharmacyCity &&
            newPharmacyAddress &&
            newPharmacyCode &&
            newPharmacyPhone &&
            newPharmacyState &&
            addButtonStatus && 
            newPharmacyFax &&
            newPharmacyEfax &&
            newPharmacyZipcode */ ) {
            setAddButtonStatus(false)
        }
        else {
            setAddButtonStatus(true)
        }
    }

    useEffect(() => { if (token && !countryList) getCountryList(); }, [token])// eslint-disable-line
    useEffect(() => { if (countryList.length > 0) createCountryOption() }, [countryList])// eslint-disable-line
    // eslint-disable-next-line
    useEffect(() => { disableAddButton() }, [
        newPharmacyName,
        newPharmacyEmail,
        newPharmacyCountry
        /* newPharmacyCity,
        newPharmacyAddress,
        newPharmacyCode,
        newPharmacyPhone ,
        newPharmacyState,
        addButtonStatus ,
        newPharmacyFax,
        newPharmacyZipcode */ ])

    return (
        <div>
            <Modal isOpen={newPharmacyModal} toggle={toggle} size="lg" >
                <ModalHeader tag="h4" cssModule={{'modal-title': 'w-100 text-center'}}  toggle={toggle}>Add New Pharmacy </ModalHeader>
                <ModalBody className="overflow-modal disable-scrollbars" >
                    <FormGroup>
                        <Row>
                            <Col>
                                <Label for="name">Name <span className="required">*</span> </Label>
                                <Input
                                    id="name"
                                    placeholder="Enter the name"
                                    value={newPharmacyName}
                                    onChange={(e) => setNewPharmacyName(e.target.value)}
                                    minLength="1"
                                    maxLength="120"
                                />
                                <br></br>

                                <Label>Country <span className="required">*</span></Label>
                                <Select
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    onChange={(e) => { 
                                        setNewPharmacyCountry(e); 
                                        if(e && e.value) {
                                            countryIsdCode(e.value); 
                                        } else {
                                            setCountryCode("")
                                        }
                                     }}
                                    isSearchable
                                    isClearable
                                    value={newPharmacyCountry}
                                    placeholder="Select the country"
                                    options={countryDropDown}
                                    components={animatedComponents}                                    
                                />
                                <br></br>

                                <Label for="city" >City/Town </Label>
                                <Input
                                    id="city"
                                    placeholder="Enter the city"
                                    value={newPharmacyCity}
                                    onChange={(e) => setNewPharmacyCity(e.target.value)}
                                    maxLength="24"
                                />
                                <br></br>
                                <Row>
                                    <Col sm="7">
                                        <Label for="state">State/Province/County </Label>
                                        <Input
                                            id="state"
                                            value={newPharmacyState}
                                            placeholder="Enter the state"
                                            onChange={(e) => setNewPharmacyState(e.target.value)}
                                            maxLength="24"
                                        />
                                        <br></br>
                                    </Col>
                                    <Col sm="5">
                                        <Label for="zipcode">Post Code </Label>
                                        <Input
                                            id="zipcode"
                                            value={newPharmacyZipcode}
                                            placeholder="Enter the Post code"
                                            onChange={(e) => {if (e.target.value.length <= 10) setNewPharmacyZipcode(e.target.value)}}
                                        />
                                        <br></br>
                                    </Col>
                                </Row>

                                <Label for="fax">Fax  </Label>
                                <Input
                                    id="fax"
                                    placeholder="Enter the fax"
                                    value={newPharmacyFax}
                                    onChange={(e) => setNewPharmacyFax(e.target.value)}
                                    maxLength="25"
                                />
                                <br></br>

                            </Col>
                            <Col>
                                <Label for="email">Email <span className="required">*</span>  </Label>
                                <Input
                                    id="Email"
                                    type="email"
                                    value={newPharmacyEmail}
                                    placeholder="Enter the email"
                                    onChange={(e) => setNewPharmacyEmail(e.target.value)}
                                    maxLength="254"
                                />
                                <br></br>

                                <Row>
                                    <Col sm="3">
                                        <Label>Country code</Label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            value={countryCode}
                                            isDisabled
                                            placeholder=""
                                        />
                                    </Col>
                                    <Col sm="5">
                                        <Label for="phone">Telephone </Label>
                                        <Input
                                            id="phone"
                                            className="phone-number-input"
                                            value={newPharmacyPhone}
                                            placeholder="Telephone Number"
                                            type="number"
                                            onChange={(e) => { if (e.target.value.length <= 12) setNewPharmacyPhone(e.target.value) }}
                                        />
                                        <br></br>
                                    </Col>
                                    <Col>
                                        <Label for="code">Code </Label>
                                        <Input
                                            id="code"
                                            placeholder="Enter the code"
                                            value={newPharmacyCode}
                                            onChange={(e) => setNewPharmacyCode(e.target.value)}
                                            maxLength="25"
                                        />
                                        <br></br>
                                    </Col>
                                </Row>

                                <Label for="address">Address </Label>
                                <Input
                                    id="address"
                                    type="textarea"
                                    value={newPharmacyAddress}
                                    className="pharmacy-address-textarea"
                                    placeholder="Enter the address"
                                    onChange={(e) => setNewPharmacyAddress(e.target.value)}
                                    maxLength="500"
                                />
                                <br></br>

                                <Label for="efax">Efax </Label>
                                <Input
                                    id="efax"
                                    placeholder="Enter the efax"
                                    value={newPharmacyEfax}
                                    onChange={(e) => setNewPharmacyEfax(e.target.value)}
                                    maxLength="150"
                                />

                            </Col>
                        </Row>
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button
                        className="yes-button button-width"
                        onClick={confirmation}
                        disabled={addButtonStatus}
                        title={addButtonStatus ? "Fill the required fields" : ""}
                    >Add</Button>{' '}
                    <Button outline className="no-button button-width" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default NewPharmacyModal;