import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const SuggestedRatesModal = (props) => {
  // DEPENDENCY VARIABLES  
  const {
    suggestedRatesModal,
    setSuggestedRatesModal,
  } = props;

  const toggle = () => {
    setSuggestedRatesModal(!suggestedRatesModal);
  };

  return (
    <div>
      <Modal isOpen={suggestedRatesModal} toggle={toggle} size="sm">
        <ModalHeader tag="h4" cssModule={{'modal-title': 'w-100 text-center'}}  toggle={toggle}  >Suggested Rates</ModalHeader>
        <ModalBody>
          <div className="currency-info">
            <p><strong>For Primary Care / GP (for 15 mins consult)</strong></p>
            <p>
              <img
                className="flag-icon"
                alt="United States"
                src="http://purecatamphetamine.github.io/country-flag-icons/3x2/GB.svg" />
              UK - £35 to £40
              </p>
            <p>
              <img
                className="flag-icon"
                alt="United States"
                src="http://purecatamphetamine.github.io/country-flag-icons/3x2/IE.svg" />
              Ireland - €35 to €40
              </p>
            <br />
            <p><strong>For Specialists (for 15 mins consult)</strong></p>
            <p>
              <img
                className="flag-icon"
                alt="United States"
                src="http://purecatamphetamine.github.io/country-flag-icons/3x2/GB.svg" />
              UK - £40 to £60
              </p>
            <p>
              <img
                className="flag-icon"
                alt="United States"
                src="http://purecatamphetamine.github.io/country-flag-icons/3x2/IE.svg" />
              Ireland - €40 to €60
              </p>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button outline className="no-button button-width" onClick={toggle}>
            Dismiss
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default SuggestedRatesModal;
