import React, { createContext, useState } from 'react'

export const GlobalState = createContext()

export const GlobalStateProvider = (props) => {

    // COMMON
    const [token, setToken] = useState("")
    const [spinner, setSpinner] = useState(false)
    const [blankStateMessage, setBlankStateMessage] = useState(false)
    const [refundBlankStateMessage, setRefundBlankStateMessage] = useState(false)
    const [hideMenu, setHideMenu] = useState(true)
    const [details, setDetails] = useState("")
    const [userType, setUserType] = useState("")
    const [reload, setReload] = useState(0)

    // APPOINTMENTS  
    const [appoFromDate, setAppoFromDate] = useState("")
    const [appoToDate, setAppoToDate] = useState("")
    const [appStatusID, setAppStatusID] = useState("")
    const [couponCode, setCouponCode] = useState("")
    const [groupId, setGroupId] = useState("")
    const [doctorName, setDoctorName] = useState("")
    const [couponDropDown, setCouponDropDown] = useState([])
    const [groupDropDown, setGroupDropDown] = useState([])
    const [doctorDropDown, setDoctorDropDown] = useState([])
    const [appointmentDropDown, setAppointmentDropDown] = useState([])
    const [createdByDropDown, setCreatedByDropDown] = useState([])
    const [appointmentStatus, setAppointmentStatus] = useState([])
    const [providerID, setProviderID] = useState("")
    const [couponNameSearch, setCouponNameSearch] = useState("")
    const [appoSearch, setAppoSearch] = useState(1)
    const [today, setToday] = useState("")
    const [chartFromDate, setChartFromDate] = useState("");
    const [chartToDate, setChartToDate] = useState("");
    const [refundRules, setRefundRules] = useState("");
    const [refundRulesIds, setRefundRulesIds] = useState("");
    const [matching, setMatching] = useState(false);
    const [docterSpeciality, setDocterSpeciality] = useState(false);

    // PATIENTS
    const [patientName, setPatientName] = useState("")
    const [patientEmail, setPatientEmail] = useState("")
    const [patientCountry, setPatientCountry] = useState("")
    const [patientPhone, setPatientPhone] = useState("")
    const [patientGroupList, setPatientGroupList] = useState("")
    const [patientGroup, setPatientGroup] = useState("")
    const [patientGender, setPatientGender] = useState("")
    const [patientPostCode, setPatientPostCode] = useState("")
    const [patientStatus, setPatientStatus] = useState("")
    const [patientListReload, setPatientListReload] = useState(0)

    // PHYSICIANS
    const [physicianName, setPhysicianName] = useState("")
    const [physicianEmail, setPhysicianEmail] = useState("")
    const [physicianSpeciality, setPhysicianSpeciality] = useState("")
    const [physicianStatus, setPhysicianStatus] = useState("")
    const [physicianId, setPhysicianId] = useState("")
    const [reloadPhysicianIdentityData, setReloadPhysicianIdentityData] = useState(0)
    const [week, setWeek] = useState(0)

    // COUPONS
    const [couponType, setCouponType] = useState([])
    const [couponCorporate, setCouponCorporate] = useState([])
    const [couponStatus, setCouponStatus] = useState([])
    const [couponName, setCouponName] = useState("")
    const [couponPhysician, setCouponPhysician] = useState("")
    const [couponPatient, setCouponPatient] = useState("")
    const [couponSearch, setCouponSearch] = useState(1)
    const [couponEditData, setCouponEditData] = useState("")
    const [couponList, setCoupons] = useState([]);

    // PRESCRIPTIONS
    const [prescriptionFromDate, setPrescriptionFromDate] = useState("")
    const [prescriptionToDate, setPrescriptionToDate] = useState("")
    const [prescriptionPatientName, setPrescriptionPatientName] = useState("")
    const [prescriptionPatientEmail, setPrescriptionPatientEmail] = useState("")
    const [prescriptionStatus, setPrescriptionStatus] = useState("")
    const [prescriptionPharmacyId, setPrescriptionPharmacyId] = useState("")
    const [prescriptionProviderId, setPrescriptionProviderId] = useState("")
    const [prescriptionReload, setPrescriptionReload] = useState(0)
    const [pharmacyLoading, setPharmacyLoading] = useState(true)
    const [pharmacyDropDownData, setPharmacyDropDownData] = useState("")
    const [prescriptionRowData, setPrescriptionRowData] = useState("")
    const [confirmationModal, setConfirmationModal] = useState(false);
    const [statusChangeConfirmation, setStatusChangeConfirmation] = useState(false);
    const [editPrescriptionModal, setEditPrescriptionModal] = useState(false);

    // PHARMACY
    const [pharmacyName, setPharmacyName] = useState("")
    const [pharmacyEmail, setPharmacyEmail] = useState("")
    const [pharmacyCode, setPharmacyCode] = useState("")
    const [pharmacyEfax, setPharmacyEfax] = useState("")
    const [pharmacyAddress, setPharmacyAddress] = useState("")
    const [pharmacyCity, setPharmacyCity] = useState("")
    const [pharmacyCountry, setPharmacyCountry] = useState("")
    const [pharmacyFax, setPharmacyFax] = useState("")
    const [pharmacyPhone, setPharmacyPhone] = useState("")
    const [pharmacyId, setPharmacyId] = useState("")
    const [pharmacyStatus, setPharmacyStatus] = useState("")
    const [pharmacyState, setPharmacyState] = useState("")
    const [pharmacyZipcode, setPharmacyZipcode] = useState("")
    const [pharmacyListReload, setPharmacyListReload] = useState(0)

    // CORPORATE GROUPS  <OR> // HEALTHCARE PARTNERS
    const [corporateName, setCorporateName] = useState("")
    const [corporateStatus, setCorporateStatus] = useState("")
    const [corporateAddress, setCorporateAddress] = useState("")
    const [corporateListReload, setCorporateListReload] = useState(0)

    // MEDICATION 
    const [drugName, setDrugName] = useState("")
    const [drugCode, setDrugCode] = useState("")
    const [genericName, setGenericName] = useState("")
    const [genericNameCode, setGenericNameCode] = useState("")
    const [drugStatus, setDrugStatus] = useState("")
    const [medicationListReload, setMedicationListReload] = useState(0)

    // RULE CONFIGURATION
    const [ruleConfigListReload, setRuleConfigListReload] = useState(0)
    const [refundConfigListReload, setRefundConfigListReload] = useState(0)
    const [refundRuleList, setRefundRulesList] = useState([]);

    // INSURANCE GROUP
    const [insuranceGroupName, setInsuranceGroupName] = useState("")
    const [sortColumnForInsuranceDetails, setSortColumnForInsuranceDetails] = useState("")
    const [sortOrderForInsuranceDetails, setSortOrderForInsuranceDetails] = useState("");
    const [groupInsuranceSearchKeyword, setGroupInsuranceSearchKeyword] = useState("");
    const [groupInsuranceSizePerPage, setGroupInsuranceSizePerPage] = useState(50);
    const [groupInsuranceOffset, setGroupInsuranceOffset] = useState(0);
    const [groupInsuranceListReload, setGroupInsuranceListReload] = useState(0)
    
    return (
        <GlobalState.Provider value={{ 
            appoFromDate, setAppoFromDate,
            appointmentDropDown, setAppointmentDropDown,
            appointmentStatus, setAppointmentStatus,
            appoSearch, setAppoSearch,
            appoToDate, setAppoToDate,
            appStatusID, setAppStatusID,
            matching, setMatching,
            refundRules, setRefundRules,
            refundRulesIds, setRefundRulesIds,
            blankStateMessage, setBlankStateMessage,
            chartFromDate, setChartFromDate,
            chartToDate, setChartToDate,
            confirmationModal, setConfirmationModal,
            corporateAddress, setCorporateAddress,
            corporateListReload, setCorporateListReload,
            corporateName, setCorporateName,
            corporateStatus, setCorporateStatus,
            couponCode, setCouponCode,
            couponCorporate, setCouponCorporate,
            couponDropDown, setCouponDropDown,
            couponEditData, setCouponEditData,
            couponList, setCoupons,
            couponName, setCouponName,
            couponNameSearch, setCouponNameSearch,
            couponPatient, setCouponPatient,
            couponPhysician, setCouponPhysician,
            couponSearch, setCouponSearch,
            couponStatus, setCouponStatus,
            couponType, setCouponType,
            createdByDropDown, setCreatedByDropDown,
            details, setDetails,
            doctorDropDown, setDoctorDropDown,
            doctorName, setDoctorName,
            drugCode, setDrugCode,
            drugName, setDrugName,
            drugStatus, setDrugStatus,
            editPrescriptionModal, setEditPrescriptionModal,
            genericName, setGenericName,
            genericNameCode, setGenericNameCode,
            groupDropDown, setGroupDropDown,
            groupId, setGroupId,
            hideMenu, setHideMenu,
            medicationListReload, setMedicationListReload,
            patientCountry, setPatientCountry,
            patientEmail, setPatientEmail,
            patientGender, setPatientGender,
            patientGroup, setPatientGroup,
            patientGroupList, setPatientGroupList,
            patientListReload, setPatientListReload,
            patientName, setPatientName,
            patientPhone, setPatientPhone,
            patientPostCode, setPatientPostCode,
            patientStatus, setPatientStatus,
            pharmacyAddress, setPharmacyAddress,
            pharmacyCity, setPharmacyCity,
            pharmacyCode, setPharmacyCode,
            pharmacyCountry, setPharmacyCountry,
            pharmacyDropDownData, setPharmacyDropDownData,
            pharmacyEfax, setPharmacyEfax,
            pharmacyEmail, setPharmacyEmail,
            pharmacyFax, setPharmacyFax,
            pharmacyId, setPharmacyId,
            pharmacyListReload, setPharmacyListReload,
            pharmacyLoading, setPharmacyLoading,
            pharmacyName, setPharmacyName,
            pharmacyPhone, setPharmacyPhone,
            pharmacyState, setPharmacyState,
            pharmacyStatus, setPharmacyStatus,
            pharmacyZipcode, setPharmacyZipcode,
            physicianEmail, setPhysicianEmail,
            physicianId, setPhysicianId,
            physicianName, setPhysicianName,
            physicianSpeciality, setPhysicianSpeciality,
            physicianStatus, setPhysicianStatus,
            prescriptionFromDate, setPrescriptionFromDate,
            prescriptionPatientEmail, setPrescriptionPatientEmail,
            prescriptionPatientName, setPrescriptionPatientName,
            prescriptionPharmacyId, setPrescriptionPharmacyId,
            prescriptionProviderId, setPrescriptionProviderId,
            prescriptionReload, setPrescriptionReload,
            prescriptionRowData, setPrescriptionRowData,
            prescriptionStatus, setPrescriptionStatus,
            prescriptionToDate, setPrescriptionToDate,
            providerID, setProviderID,
            refundConfigListReload, setRefundConfigListReload,
            refundRuleList, setRefundRulesList,
            reload, setReload,
            reloadPhysicianIdentityData, setReloadPhysicianIdentityData,
            ruleConfigListReload, setRuleConfigListReload,
            spinner, setSpinner,
            statusChangeConfirmation, setStatusChangeConfirmation,
            refundBlankStateMessage, setRefundBlankStateMessage,
            docterSpeciality, setDocterSpeciality,
            today, setToday,
            token, setToken,
            userType, setUserType,
            week, setWeek,
            insuranceGroupName, setInsuranceGroupName,
            sortColumnForInsuranceDetails, setSortColumnForInsuranceDetails,
            sortOrderForInsuranceDetails, setSortOrderForInsuranceDetails,
            groupInsuranceSearchKeyword, setGroupInsuranceSearchKeyword,
            groupInsuranceSizePerPage, setGroupInsuranceSizePerPage,
            groupInsuranceOffset, setGroupInsuranceOffset,
            groupInsuranceListReload, setGroupInsuranceListReload
        }}>
            {props.children}
        </GlobalState.Provider>
    )
}