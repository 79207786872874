import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from "react-router-dom";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import { Col, Label, Row, Button } from "reactstrap";
import './DateRangeSelector.css';
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import { GlobalState } from '../../contextAPI/Context';
import axios from 'axios';

const PatientFilters = (props) => {
    // CONTEXT VARIABLES 
    const {
        token,
        spinner,
        setPhysicianName,
        setPhysicianEmail,
        setPhysicianSpeciality,
        setPhysicianStatus,
        setReload
    } = useContext(GlobalState)
    
    // LOCAL VARIABLES 
    let query = useLocation();
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [userType, setUserType] = useState("")

    // SPECIALITY
    const [speciality, setSpeciality] = useState("")
    const [specialityList, setspecialityList] = useState("")
    const [specialityDropDown, setSpecialityDropDown] = useState("")

    // STATUS
    const [status, setStatus] = useState("")
    const StatusOptions = [
        { value: '1', label: 'Active' },
        { value: '0', label: 'Inactive' }
    ]

    const animatedComponents = makeAnimated();
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

    const getValueFromArray = (array) => {
        return array.map(item => { return item.value })
    }

    const runSearch = () => {
        if (name || email || speciality || status) {
            setPhysicianName(name ? name : "")
            setPhysicianEmail(email ? email : "")
            setPhysicianSpeciality(speciality ? getValueFromArray(speciality) : "")
            setPhysicianStatus(status ? getValueFromArray(status) : "")
            setReload(0)
        }
    }

    const resetFields = () => {
        setName("")
        setEmail("")
        setStatus("")
        setSpeciality("")
        setPhysicianName("")
        setPhysicianEmail("")
        setPhysicianSpeciality("")
        setPhysicianStatus("")
    }

    const getUserTypeFromURL = () => {
        let userTypeInURL = query.pathname
        userTypeInURL = userTypeInURL.toLocaleLowerCase();
        // SETS USER TYPE BASE ON URL
        if (userTypeInURL === "/doctors") setUserType(3);
        if (userTypeInURL === "/nurses") setUserType(6);
        if (userTypeInURL === "/therapists") setUserType(7);
        if (userTypeInURL === "/carers") setUserType(8);
      }

    const getspecialityList = () => {
        axios.get(`${API_ENDPOINT}/external/speciality-list/${userType}`,
            {
                headers: {
                    Authorization: token,
                },
            })
            .then((res) => {
                setspecialityList(res.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const createSpecialityOption = () => {
        var array = specialityList
        const options = array.map((item) => {
            return { value: item.id, label: item.name };
        });
        setSpecialityDropDown(options)
    }

    // EFFECTS FOR CORPORATE / GROUP
    useEffect(() => { getUserTypeFromURL() }, []) // eslint-disable-line
    useEffect(() => { if (token && !specialityList && userType) getspecialityList(); }, [token, userType]) // eslint-disable-line
    useEffect(() => { if (specialityList.length > 0) createSpecialityOption() }, [specialityList]) // eslint-disable-line

    return (
        <AvForm>
            <AvGroup>
                <Row>
                    <Col sm="3">
                        <Label>Name</Label>
                        <AvField
                            type="text"
                            name="name"
                            placeholder="All"
                            value={name}
                            validate={{
                                maxLength: {value: 120}
                              }} 
                            onChange={(e) => { setName(e.target.value) }}
                        />
                    </Col>
                    <Col sm="3">
                        <Label>Email</Label>
                        <AvField
                            type="text"
                            name="email"
                            placeholder="All"
                            value={email}
                            validate={{
                                maxLength: {value: 254}
                              }} 
                            onChange={(e) => { setEmail(e.target.value) }}
                        />
                    </Col>
                    <Col sm="3">
                        <Label>Speciality</Label>
                        <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            onChange={(event) => { setSpeciality(event) }}
                            isSearchable
                            placeholder="All"
                            value={speciality}
                            options={specialityDropDown}
                            components={animatedComponents}
                            isMulti
                        />
                    </Col>
                </Row>
                <Row>
                    <Col sm="3" className="second-row-filters" >
                        <Label>Status</Label>
                        <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            onChange={(event) => { setStatus(event) }}
                            isSearchable
                            placeholder="All"
                            value={status}
                            options={StatusOptions}
                            components={animatedComponents}
                            isMulti
                        />
                    </Col>
                    <Col>
                        <Button
                            type="submit"
                            className="go-buttons"
                            disabled={spinner ? true : false}
                            onClick={() => { runSearch() }}
                        >Go</Button>
                        <Button
                            type="reset"
                            className="reset-buttons"
                            disabled={spinner ? true : false}
                            onClick={() => { resetFields() }}
                        >Reset</Button>
                    </Col>
                </Row>
            </AvGroup>
        </AvForm>
    )
}

export default PatientFilters
