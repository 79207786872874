import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Breadcrumb, BreadcrumbItem, Container, Spinner } from "reactstrap";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import { Hux } from "./../../hoc/Hux";
import ExportCSVTable from "./PhysicianDataTable";
import { toastr } from 'react-redux-toastr'
import moment from 'moment';
import MomentConfig from '../../common/MomentConfig';
import { GlobalState } from '../../contextAPI/Context'
import StatusChangeModal from '../../newComponents/utilities/StatusChangeModal';

const Tables = (props) => {
  // CONTEXT VARIABLES 
  const {
    setToken,
    setBlankStateMessage,
    physicianName,
    physicianEmail,
    physicianSpeciality,
    physicianStatus,
    setPhysicianId,
    setPhysicianName, reload, setPhysicianEmail,
    setPhysicianSpeciality,
    setPhysicianStatus
  } = useContext(GlobalState);

  // LOCAL VARIABLES 
  const [physicianList, setPhysicians] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalState, setModalState] = useState(false);
  const [physicianID, setPhysicianID] = useState("");
  const [status, setStatus] = useState("");
  const [userConfirmation, setUserConfirmation] = useState(false);
  const [VerphysicianID, setVerphysicianID] = useState("");// eslint-disable-line
  const [Verstatus, setVerstatus] = useState("");// eslint-disable-line
  const [VerificationChange, setVerificationChange] = useState(false);
  let history = useHistory();
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

  const fetchPhysicians = () => {
    setToken(props.authtoken);
    setLoading(true);
    const params =
    {
      "provider_name": physicianName ? physicianName.toString() : "",
      "provider_email": physicianEmail ? physicianEmail.toString() : "",
      "provider_speciality": physicianSpeciality ? physicianSpeciality.toString() : "",
      "status": physicianStatus ? physicianStatus.toString() : "",
      "list_all": 0,
      "user_type": 6
    }

    Axios.post(API_ENDPOINT + "/user/physician-details/", params, {
      headers: {
        Authorization: props.authtoken,
      },
    }).then((res) => {
      setPhysicians(res.data);
      setBlankStateMessage(res.data.length > 0 ? false : true);
      setLoading(false);
    });
  };

  const changePhysicianStatus = () => {
    let newStatus = "";
    setLoading(true);
    if (status === 0) {
      newStatus = 1
    } else {
      newStatus = 0
    }

    const updatedStatus = {
      provider_id: physicianID,
      status: newStatus,
    };

    Axios.post(
      API_ENDPOINT + "/user/provider-status-update/",
      updatedStatus,
      {
        headers: {
          Authorization: props.authtoken,
        },
      }
    )
      .then((res) => {
        if (res.status === 201) {
          fetchPhysicians();
          setUserConfirmation(false);
          setLoading(false);
          toastr.success('Status Updated', 'Care Provider status updated successfully');
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };


  const changePhysicianVerifiedStatus = () => {
    let newverifiedstatus = "";

    if (Verstatus === 0) {
      newverifiedstatus = 1
    } else {
      newverifiedstatus = 0
    }

    const updatedverifiedStatus = {
      user_id: VerphysicianID,
      is_verified: newverifiedstatus,
    };


    Axios.post(
      API_ENDPOINT + "/user/phy-user-verification/",
      updatedverifiedStatus,
      {
        headers: {
          Authorization: props.authtoken,
        },
      }
    )
      .then((res) => {

        if (res.status === 201) {
          // console.log(res)
          fetchPhysicians();
          setUserConfirmation(false);
          setLoading(false);
          setVerificationChange(false)
          toastr.success('Verification Updated', 'Care Provider verification status updated successfully');
        }
      })
      .catch((error) => {
        console.log(error);
      });


  }

  const statusFormatter = (cell, row) => {
    let status = "";
    let badgeclass = " ";
    let Isverifiedbadgeclass = "";// eslint-disable-line
    let Isverifiedstatus = "";// eslint-disable-line
    if (cell === 1) {
      status = "Active";
      badgeclass = "badge badge-success statustoggle status-button-width";
    } else {
      status = "Inactive";
      badgeclass = "badge badge-danger statustoggle status-button-width";
    }

    if (row.isVerified === 1) {
      Isverifiedbadgeclass = "badge badge-info statustoggle status-button-width mt-1"
      Isverifiedstatus = "Verified"
    } else {
      Isverifiedbadgeclass = "badge badge-warning statustoggle status-button-width mt-1"
      Isverifiedstatus = "Pending"
    }
    return (
      <span
        className={badgeclass}
        onClick={() => {
          setPhysicianID(row.provider_id);
          setStatus(cell);
          setModalState(true);
        }}
      >
        {status}
      </span>
    );
  };

  const verifiedFormatter = (cell, row) => {
    let status = "";// eslint-disable-line
    let badgeclass = " ";// eslint-disable-line
    let Isverifiedbadgeclass = "";
    let Isverifiedstatus = "";
    if (cell === 1) {
      status = "Active";
      badgeclass = "badge badge-success statustoggle status-button-width";
    } else {
      status = "Inactive";
      badgeclass = "badge badge-danger statustoggle status-button-width";
    }

    if (row.isVerified === 1) {
      Isverifiedbadgeclass = "badge badge-info status-button-width mt-1"
      Isverifiedstatus = "Verified"
    } else {
      Isverifiedbadgeclass = "badge badge-warning status-button-width mt-1"
      Isverifiedstatus = "Pending"
    }
    return (
      <span
        className={Isverifiedbadgeclass}
      // onClick={() => {
      //   setModalState(true);
      //   setVerificationChange(true)
      //   setVerphysicianID(row.user_id);
      //   setVerstatus(row.isVerified);
      // }}
      >
        {Isverifiedstatus}
      </span>
    );
  };

  const basicValidation = (cell) => {
    return cell ? cell : "N/A"
  }

  const dateFormatter = (cell) => {
    return `${cell ? moment(cell).utc(cell).local().format(MomentConfig.MOMENT_FORMAT_DATEANDTIME) : "N/A"}`
  }

  const statusFormatterCSV = (cell) => {
    return cell ? "Active" : "Inactive"
  }

  const verifiedFormatterCSV = (cell) => {
    return cell ? "Verified" : "Pending"
  }

  const viewActions = (row) => {
    setPhysicianId(row.user_id)
    setPhysicianName(row.name)
    history.push(`/nurses/profile/?pid=${btoa(row.user_id)}&pname=${btoa(row.name)}`);
  }
  const resetFields = () => {
    setPhysicianName("");
    setPhysicianEmail("");
    setPhysicianSpeciality("");
    setStatus("");
    setPhysicianStatus("");
  };
  
  useEffect(() => {
    if (physicianName === "") {
      fetchPhysicians();
    }
  }, [physicianName]);// eslint-disable-line
  
  
  const actionFormatter = (cell, row) => {
    return (
      <>
        <span className="view-button" onClick={() => { viewActions(row) }}>
          View
        </span>
        <span className="separator-pipe">|</span>
        <a
          href={`/appointments/?doc=${cell}`}
          target="_blank"
          rel="noopener noreferrer"
        // className="view-appt-btn"
        >Appt</a>
      </>
    )
  }


  const tableColumns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "contactnumber",
      text: "Phone",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "Speciality",
      text: "Speciality",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      formatter: statusFormatter,
      csvFormatter: statusFormatterCSV,
    },
    {
      dataField: "isVerified",
      text: "Verified",
      sort: true,
      formatter: verifiedFormatter,
      csvFormatter: verifiedFormatterCSV,
    },
    {
      dataField: "latest_apt",
      text: "Last Appt",
      sort: true,
      formatter: dateFormatter,
      csvFormatter: dateFormatter,
    },
    {
      dataField: "provider_id",
      text: "Actions",
      formatter: actionFormatter,
      csvExport: false
    },
  ];

  let loader = null;

  if (loading) {
    loader = <Spinner />;
  }

  // DEFAULT API CALL
  useEffect(() => {
    if (reload) {
      resetFields();
    } else {
      fetchPhysicians();
    }
  }, [reload,physicianName, physicianEmail, physicianSpeciality, physicianStatus]); // eslint-disable-line
  
  // useEffect(() => {
  //   fetchPhysicians();
  // }, [physicianName, physicianEmail, physicianSpeciality, physicianStatus]);
   

  // EFFECT TO HANDLE PHYSICIAN STATUS CHANGE 
  useEffect(() => { if (userConfirmation && !VerificationChange) changePhysicianStatus() }, [userConfirmation])// eslint-disable-line
  useEffect(() => { if (userConfirmation && VerificationChange) changePhysicianVerifiedStatus() }, [userConfirmation])// eslint-disable-line

  return (
    <Hux>
      {loader}
      <Container fluid>
        <Header>
          <HeaderTitle>Nurses</HeaderTitle>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/dashboard">Dashboard</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Nurses</BreadcrumbItem>
          </Breadcrumb>
        </Header>
        <StatusChangeModal modalState={modalState} setModalState={setModalState} setUserConfirmation={setUserConfirmation} VerificationChange={VerificationChange} setVerificationChange={setVerificationChange} />
        <ExportCSVTable data={physicianList} columns={tableColumns} />
      </Container>
    </Hux>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
    authtoken: state.auth.token,
  };
};

export default connect(mapStateToProps, null)(Tables);
