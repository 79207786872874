/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const DeleteConfirmationModal = (props) => {
    const {
        deleteModalState,
        setDeleteModalState,
        setDeleteApproved,
        fileName,
        modalHeading,
        modalMessage,
        callbackData
    } = props;

    const toggle = () => setDeleteModalState(!deleteModalState);
    const confirm = () => setDeleteApproved(callbackData ? callbackData : true);

    return (
        <div>
            <Modal isOpen={deleteModalState} toggle={toggle} >
                <ModalHeader toggle={toggle}>{modalHeading ? modalHeading : 'Delete File'}</ModalHeader>
                {modalMessage ? 
                    <ModalBody>{modalMessage}</ModalBody> :
                    <ModalBody>
                        Are you sure you want to delete <strong>"{fileName}"</strong>?
                    </ModalBody>
                }
                <ModalFooter>
                    <Button /* color="primary" */  className=" copy-btn-config-modal btn-width-fix" onClick={confirm}>Yes</Button>{' '}
                    <Button /* color="secondary" */ className=" delete-btn-config-modal btn-width-fix" onClick={toggle}>No</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default DeleteConfirmationModal;