import React from 'react'
import moment from 'moment'
import MomentConfig from '../../../common/MomentConfig'

const AppointmentCard = (props) => {
    // DEPENDENCY VARIABLE
    const { appointment, utc_offset } = props

    return (
        <div className="slot-chips">
            <strong>{moment(appointment.appTime).add(utc_offset, 'm').format(MomentConfig.MOMENT_FORMAT_DATEANDTIME)}</strong>
        </div>
    )
}

export default AppointmentCard
