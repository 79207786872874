import React from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction' // needed for dayClick
import { Col, Row } from "reactstrap";
import './main.scss'
import ReactTooltip from 'react-tooltip'

export default class MyCalendar extends React.Component {

  calendarComponentRef = React.createRef()
  state = {
    calendarWeekends: true
  }


  handleEventPositioned(info) {
    info.el.setAttribute("data-tip", info.event.extendedProps.specialityText || "");
  }

  componentDidUpdate(){ 
    ReactTooltip.rebuild(); 
  }


  render() {
    return (
      <Row>
        <Col>
            <div className='demo-app'>
              <div className='demo-app-calendar'>
                <FullCalendar
                  firstDay= {0}
                  defaultView="timeGridWeek"
                  eventClick={this.handleEventClick}
                  eventPositioned={this.handleEventPositioned}
                  handleWindowResize={true}
                  eventTimeFormat={{
                    hour: '2-digit',
                    minute: '2-digit'
                  }}
                  slotLabelFormat={{
                    hour: '2-digit',
                    minute: '2-digit'
                  }}
                  header={{
                   left: '',
                   center: '',
                   right: ''
                  }}
                  columnHeaderText= {function(date) {
                    if (date.getDay() === 0) {
                      return 'Sun';
                    } else if (date.getDay() === 1) {
                      return 'Mon';
                    } else if (date.getDay() === 2) {
                      return 'Tue';
                    } else if (date.getDay() === 3) {
                      return 'Wed';
                    } else if (date.getDay() === 4) {
                      return 'Thu';
                    } else if (date.getDay() === 5) {
                      return 'Fri';
                    } else if (date.getDay() === 6) {
                      return 'Sat';
                    }
                  }}
                  minTime= '00:00:00'
                  maxTime= '23:00:00'
                  //slotDuration= '00:15:00'
                  slotEventOverlap = {false}
                  Duration= '00:30:00'
                  slotLabelInterval= {30}
                  allDaySlot={false}
                  plugins={[ dayGridPlugin, timeGridPlugin, interactionPlugin ]}
                  ref={ this.calendarComponentRef }
                  weekends={ this.state.calendarWeekends }
                  events={ this.props.timeSlotDetailsCalendar }
                  dateClick={ this.handleDateClick }
                  defaultTimedEventDuration= "00:30:00"
                  eventColor= '#2c845a'
                  />
                   <ReactTooltip/>
              </div>
            </div>
        </Col>
        </Row>
    )
  }

 

  toggleWeekends = () => {
    this.setState({ // update a property
      calendarWeekends: !this.state.calendarWeekends
    })
  }

  handleEventClick = (clickInfo) => {
    this.props.removeCalendarSlot({ 
      start: clickInfo.event.start.toISOString(),
      end: clickInfo.event.end.toISOString()
    })
  }
  handleDateClick = (arg) => {
    return false;
    // var dataExists = this.props.timeSlotDetailsCalendar.some(event => event.start === arg.date.toISOString())
    //   if(!dataExists) {
    //     this.props.addCalendarSlot({ 
    //       start: new Date(arg.date),
    //       end: new Date(arg.date.getTime() + 30*60000)
    //     })
    //   }
  }
}