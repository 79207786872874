import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Breadcrumb, BreadcrumbItem, Container, Spinner, Button } from "reactstrap";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import { Hux } from "./../../hoc/Hux";
import ExportCSVTable from "./../../pages/tables/DataTable";
import { toastr } from "react-redux-toastr";
// import moment from 'moment';
// import MomentConfig from '../../common/MomentConfig';
import { GlobalState } from '../../contextAPI/Context'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import CouponStatusChangeModal from '../utilities/CouponStatusChangeModal';
import AddCouponInput from "./AddCouponInput";

const CouponsTable = (props) => {
  // CONTEXT VARIABLES 
  const {
    spinner,
    setSpinner,
    setToken,
    setBlankStateMessage,
    couponType,
    couponName,
    couponCorporate,
    couponStatus,
    couponPhysician,
    couponPatient,
    couponSearch,
    setCouponEditData,
    couponList,
    setCoupons
  } = useContext(GlobalState);

  // LOCAL VARIABLES 
  // const [couponList, setCoupons] = useState([]);
  const [groups, setGroups] = useState([]); // eslint-disable-line
  const [couponId, setCouponId] = useState("");
  const [status, setStatus] = useState("");
  const [modalState, setModalState] = useState(false);
  const [userConfirmation, setUserConfirmation] = useState(false);
  const [newCouponModal, setNewCouponModal] = useState(false);


  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

  const fromData = {
    type: couponType ? couponType.toString() : "",
    couponCode: couponName ? couponName.toString() : "",
    status: couponStatus ? couponStatus.toString() : "",
    patient_name: couponPatient ? couponPatient.toString() : "",
    corp_grp_id: couponCorporate ? couponCorporate.toString() : "",
    provider_name: couponPhysician ? couponPhysician.toString() : "",
  };

  const fetchCoupons = () => {
    setSpinner(true);
    setToken(props.authtoken);
    axios.post(API_ENDPOINT + "/external/coupon-list/", fromData, {
      headers: {
        Authorization: props.authtoken,
      },
    }).then((res) => {
      setCoupons(res.data);
      setSpinner(false);
      res.data.length > 0 ? setBlankStateMessage(false) : setBlankStateMessage(true)
    });
  };

  const changeCouponStatus = () => {

    const updatedStatus = {
      coupon_id: couponId,
      status: status,
    };

    axios.post(
      API_ENDPOINT + "/external/coupon-status-update/",
      updatedStatus,
      {
        headers: {
          Authorization: props.authtoken,
        },
      }
    )
      .then((res) => {
        if (res.data.message === "Updated Coupon Status") {
          fetchCoupons();
          toastr.success(
            "Status Updated",
            "Coupon status updated successfully"
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setStatus("");
    setUserConfirmation(false);
  };


  const StatusFormatter = (cell, row) => {
    let status = "";
    let badgeclass = " ";
    if (cell === 1) {
      status = "Active";
      badgeclass = "badge badge-success statustoggle p-2";
    } else {
      status = "Inactive";
      badgeclass = "badge badge-danger statustoggle p-2";
    }
    return (
      <div className="edit-coupon">
        <span
          className={badgeclass}
          onClick={() => {
            setModalState(!modalState);
            setCouponId(row.id);
            if (cell === 0) {
              setStatus(1)
            } else {
              setStatus(0)
            }
          }
          }
        >
          {status}
        </span>
        <Link to="/edit-coupon">
          <Button
            outline
            color="primary"
            title="Edit Coupon"
            className="edit-button"
            onClick={() => { setCouponEditData(row) }}
          >
            <FontAwesomeIcon
              icon={faPencilAlt}
              fixedWidth
              className="align-middle"
              title="Edit Coupon"
            ></FontAwesomeIcon>
          </Button>
        </Link>
      </div>
    );
  };

  const basicValidation = (cell) => {
    return cell ? cell : "N/A"
  }

  const CSVusedFormatter = (cell) => {
    return cell ? cell : "0"
  }

  const dateFormatter = (cell) => {
    if (cell) {
      const dateParts = cell.split('-');
      if (dateParts.length === 3) {
        const year = dateParts[0];
        const month = new Date(cell).toLocaleString('default', { month: 'short' });
        const day = dateParts[2];
        return `${day} ${month} ${year}`;
      }
    }
    return "N/A";
  }

  const statusFormatterCSV = (cell) => {
    return cell ? "Active" : "Inactive"
  }

  const usedFormatter = (cell, row) => {
    const codeName = row.couponCode
    return cell ? (<a href={`/appointments/?code=${codeName}`} target="_blank" rel="noopener noreferrer">{cell}</a>) : "0"
  }

  const tableColumns = [
    {
      dataField: "type",
      text: "Type",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "couponCode",
      text: "Name",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "corporate_name",
      text: "Healthcare Partners",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "physician_name",
      text: "Care Provider",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "patient_name",
      text: "Patient",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "discount_percent",
      text: "Discount (%)",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "allowed_count",
      text: "Allowed",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "patientMaxUsageCount",
      text: "Allowed Per Patient",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "used",
      text: "Used",
      sort: true,
      formatter: usedFormatter,
      csvFormatter: CSVusedFormatter,
    },
    {
      dataField: "expiryDate",
      text: "Expires On",
      sort: true,
      formatter: dateFormatter,
      csvFormatter: dateFormatter,
    },
    {
      dataField: "status",
      text: "Status",
      csvFormatter: statusFormatterCSV,
      sort: true,
      hidden: true,
    },
    {
      dataField: "status",
      text: "Actions",
      formatter: StatusFormatter,
      csvExport: false,
    },
  ];

  let loader = null;

  if (spinner) {
    loader = <Spinner />;
  }


  // DEFAULT API CALL
  useEffect(() => { fetchCoupons(); }, [couponType, couponName, couponCorporate, couponStatus, couponPhysician, couponPatient, couponSearch]) // eslint-disable-line
  useEffect(() => { if (groups && groups.length) fetchCoupons(); }, [groups]); // eslint-disable-line
  // EFFECT FOR HANDLING USER STATUS CHANGE BASED ON USER CONFIRMATION
  useEffect(() => { if (userConfirmation) changeCouponStatus() }, [userConfirmation]) // eslint-disable-line

  return (
    <Hux>
      {loader}
      <Container fluid>
        <Header>
          <HeaderTitle>
            Coupons
            {/* <Link to="/add-coupon"> */}
            <button className="btn btn-equipo-blue mt-2 pull-right"
              onClick={() => {
                setNewCouponModal(true)
              }}
            >
              Add New Coupon
            </button>
            {/* </Link> */}
          </HeaderTitle>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/dashboard">Dashboard</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Coupons</BreadcrumbItem>
          </Breadcrumb>
        </Header>
        <CouponStatusChangeModal modalState={modalState} setModalState={setModalState} setUserConfirmation={setUserConfirmation} />
        <ExportCSVTable data={couponList} columns={tableColumns} />
        <AddCouponInput newCouponModal={newCouponModal} setNewCouponModal={setNewCouponModal} fetchCoupons={fetchCoupons} />
      </Container>
    </Hux>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
    authtoken: state.auth.token,
  };
};

export default connect(mapStateToProps, null)(CouponsTable);
