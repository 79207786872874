import React from 'react'
import { Card, CardBody, CardHeader } from "reactstrap"

function BlankStateCard() {
    return (
        <Card className="blank-state">
            <CardHeader></CardHeader>
            <CardBody>
                <h4>No Document Found</h4>
            </CardBody>
        </Card>
    )
}

export default BlankStateCard
