import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch, } from "react-router-dom";

import Dashboard from "./../layouts/Dashboard";
import Index from "../pages/dashboards/Default/index";
import AllPatients from "./../pages/tables/AllPatients";
import AllPrescription from "./../pages/tables/AllPrescription";
import AllPhysicians from "./../pages/tables/AllPhysicians";
import AllNurses from "./../pages/tables/AllNurses";
import AllTherapists from "./../pages/tables/AllTherapists";
import AllCarers from "./../pages/tables/AllCarers";
import AllPharmacy from "./../pages/tables/AllPharmacy";
import AllCorporateGroups from "./../pages/tables/AllCorporateGroups";
import AllMedication from "./../pages/tables/AllMedication";
import AllRuleConfiguration from "./../pages/tables/AllRuleConfiguration";
import Appointments from "./../pages/tables/Appointments";
import AllCoupons from "./../newComponents/Coupons/CouponsTable";
import AddCoupon from "./../newComponents/Coupons/AddCoupon";
import EditCoupon from "./../newComponents/Coupons/EditCoupon";
import { connect } from "react-redux";
import Logout from "./../pages/auth/Logout";
import * as actions from "./../redux/actions/index";
import SignIn from "./../pages/auth/SignIn";
import Profile from "./../newComponents/Settings/Profile/Profile";
import UserProfile from "./../newComponents/Settings/UserProfile/UserProfile";
import Account from "./../newComponents/Settings/Account/Account";
import AvailableHours from "./../newComponents/Settings/AvailableHours/AvailableHours";
import Schedule from './../newComponents/Settings/Schedule/Schedule';
import Page404 from "./../newComponents/ErrorPages/Page404";
import Kyc from "./../newComponents/Settings/Kyc/Kyc";
import ViewPrescriptionInfo from "./../newComponents/viewPrescriptionInfo/ViewPrescriptionInfo";
import Insurance from "../pages/tables/Insurances";

class Routes extends Component {
  componentDidMount() {
    this.props.onTryAutoSignIn();
  }

  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/">
            {this.props.isAuthenticated === true ? <Dashboard><Index /></Dashboard> : <SignIn />}
          </Route>
          <Route exact path="/dashboard">
            {this.props.isAuthenticated === true ? <Dashboard><Index /></Dashboard> : <SignIn />}
          </Route>
          <Route exact path="/patients">
            {this.props.isAuthenticated === true ? <Dashboard><AllPatients /></Dashboard> : <SignIn />}
          </Route>
          <Route exact path="/doctors">
            {this.props.isAuthenticated === true ? <Dashboard><AllPhysicians /></Dashboard> : <SignIn />}
          </Route>
          <Route exact path="/nurses">
            {this.props.isAuthenticated === true ? <Dashboard><AllNurses /></Dashboard> : <SignIn />}
          </Route>
          <Route exact path="/therapists">
            {this.props.isAuthenticated === true ? <Dashboard><AllTherapists /></Dashboard> : <SignIn />}
          </Route>
          <Route exact path="/carers">
            {this.props.isAuthenticated === true ? <Dashboard><AllCarers /></Dashboard> : <SignIn />}
          </Route>
          <Route exact path="/prescriptions">
            {this.props.isAuthenticated === true ? <Dashboard><AllPrescription /></Dashboard> : <SignIn />}
          </Route>
          <Route exact path="/coupons">
            {this.props.isAuthenticated === true ? <Dashboard><AllCoupons /></Dashboard> : <SignIn />}
          </Route>
          <Route exact path="/healthcare-Partners">
            {this.props.isAuthenticated === true ? <Dashboard><AllCorporateGroups /></Dashboard> : <SignIn />}
          </Route>
          <Route exact path="/add-coupon">
            {this.props.isAuthenticated === true ? <Dashboard><AddCoupon /></Dashboard> : <SignIn />}
          </Route>
          <Route exact path="/edit-coupon">
            {this.props.isAuthenticated === true ? <Dashboard><EditCoupon /></Dashboard> : <SignIn />}
          </Route>
          <Route exact path="/logout">
            {this.props.isAuthenticated === true ? <Logout /> : <SignIn />}
          </Route>
          <Route path="/appointments">
            {this.props.isAuthenticated === true ? <Dashboard><Appointments /></Dashboard> : <SignIn />}
          </Route>
          <Route path="/doctors/profile">
            {this.props.isAuthenticated === true ? <Dashboard><Profile /></Dashboard> : <SignIn />}
          </Route>
          <Route path="/doctors/consultation-settings">
            {this.props.isAuthenticated === true ? <Dashboard><Account /></Dashboard> : <SignIn />}
          </Route>
          <Route path="/doctors/user-profile">
            {this.props.isAuthenticated === true ? <Dashboard><UserProfile /></Dashboard> : <SignIn />}
          </Route>
          <Route path="/doctors/availability">
            {this.props.isAuthenticated === true ? <Dashboard><AvailableHours /></Dashboard> : <SignIn />}
          </Route>
          <Route path="/doctors/schedule">
            {this.props.isAuthenticated === true ? <Dashboard><Schedule /></Dashboard> : <SignIn />}
          </Route>
          <Route path="/doctors/templates">
            {this.props.isAuthenticated === true ? <Dashboard><Profile /></Dashboard> : <SignIn />}
          </Route>
          <Route path="/doctors/bank-account">
            {this.props.isAuthenticated === true ? <Dashboard><Profile /></Dashboard> : <SignIn />}
          </Route>
          <Route path="/doctors/identity">
            {this.props.isAuthenticated === true ? <Dashboard><Kyc /></Dashboard> : <SignIn />}
          </Route>
          <Route path="/nurses/profile">
            {this.props.isAuthenticated === true ? <Dashboard><Profile /></Dashboard> : <SignIn />}
          </Route>
          <Route path="/nurses/consultation-settings">
            {this.props.isAuthenticated === true ? <Dashboard><Account /></Dashboard> : <SignIn />}
          </Route>
          <Route path="/nurses/availability">
            {this.props.isAuthenticated === true ? <Dashboard><AvailableHours /></Dashboard> : <SignIn />}
          </Route>
          <Route path="/nurses/user-profile">
            {this.props.isAuthenticated === true ? <Dashboard><UserProfile /></Dashboard> : <SignIn />}
          </Route>
          <Route path="/nurses/schedule">
            {this.props.isAuthenticated === true ? <Dashboard><Schedule /></Dashboard> : <SignIn />}
          </Route>
          <Route path="/nurses/templates">
            {this.props.isAuthenticated === true ? <Dashboard><Profile /></Dashboard> : <SignIn />}
          </Route>
          <Route path="/nurses/bank-account">
            {this.props.isAuthenticated === true ? <Dashboard><Profile /></Dashboard> : <SignIn />}
          </Route>
          <Route path="/nurses/identity">
            {this.props.isAuthenticated === true ? <Dashboard><Kyc /></Dashboard> : <SignIn />}
          </Route>
          <Route path="/therapists/profile">
            {this.props.isAuthenticated === true ? <Dashboard><Profile /></Dashboard> : <SignIn />}
          </Route>
          <Route path="/therapists/consultation-settings">
            {this.props.isAuthenticated === true ? <Dashboard><Account /></Dashboard> : <SignIn />}
          </Route>
          <Route path="/therapists/user-profile">
            {this.props.isAuthenticated === true ? <Dashboard><UserProfile /></Dashboard> : <SignIn />}
          </Route>
          <Route path="/therapists/availability">
            {this.props.isAuthenticated === true ? <Dashboard><AvailableHours /></Dashboard> : <SignIn />}
          </Route>
          <Route path="/therapists/schedule">
            {this.props.isAuthenticated === true ? <Dashboard><Schedule /></Dashboard> : <SignIn />}
          </Route>
          <Route path="/therapists/templates">
            {this.props.isAuthenticated === true ? <Dashboard><Profile /></Dashboard> : <SignIn />}
          </Route>
          <Route path="/therapists/bank-account">
            {this.props.isAuthenticated === true ? <Dashboard><Profile /></Dashboard> : <SignIn />}
          </Route>
          <Route path="/therapists/identity">
            {this.props.isAuthenticated === true ? <Dashboard><Kyc /></Dashboard> : <SignIn />}
          </Route>
          <Route path="/carers/profile">
            {this.props.isAuthenticated === true ? <Dashboard><Profile /></Dashboard> : <SignIn />}
          </Route>
          <Route path="/carers/consultation-settings">
            {this.props.isAuthenticated === true ? <Dashboard><Account /></Dashboard> : <SignIn />}
          </Route>
          <Route path="/carers/user-profile">
            {this.props.isAuthenticated === true ? <Dashboard><UserProfile /></Dashboard> : <SignIn />}
          </Route>
          <Route path="/carers/availability">
            {this.props.isAuthenticated === true ? <Dashboard><AvailableHours /></Dashboard> : <SignIn />}
          </Route>
          <Route path="/carers/schedule">
            {this.props.isAuthenticated === true ? <Dashboard><Schedule /></Dashboard> : <SignIn />}
          </Route>
          <Route path="/carers/templates">
            {this.props.isAuthenticated === true ? <Dashboard><Profile /></Dashboard> : <SignIn />}
          </Route>
          <Route path="/carers/bank-account">
            {this.props.isAuthenticated === true ? <Dashboard><Profile /></Dashboard> : <SignIn />}
          </Route>
          <Route path="/carers/identity">
            {this.props.isAuthenticated === true ? <Dashboard><Kyc /></Dashboard> : <SignIn />}
          </Route>
          <Route path="/pharmacy">
            {this.props.isAuthenticated === true ? <Dashboard><AllPharmacy /></Dashboard> : <SignIn />}
          </Route>
          <Route path="/medication">
            {this.props.isAuthenticated === true ? <Dashboard><AllMedication /></Dashboard> : <SignIn />}
          </Route>
          <Route path="/view-prescription-info">
            {this.props.isAuthenticated === true ? <Dashboard><ViewPrescriptionInfo /></Dashboard> : <SignIn />}
          </Route>
          <Route path="/system-settings">
            {this.props.isAuthenticated === true ? <Dashboard><AllRuleConfiguration /></Dashboard> : <SignIn />}
          </Route>
          <Route path="/insurances">
            {this.props.isAuthenticated === true ? <Dashboard><Insurance /></Dashboard> : <SignIn />}
          </Route>
          <Route>
            <Page404 />
          </Route>
        </Switch>
      </Router>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
    authtoken: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onTryAutoSignIn: () => {
      dispatch(actions.authStateCheck());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Routes);
