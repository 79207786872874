import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Breadcrumb, BreadcrumbItem, Container, Spinner } from "reactstrap";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import { Hux } from "./../../hoc/Hux";
import ExportCSVTable from "./PatientDataTable";
// import ExportCSVTable from "./PatientExportDataTable";
import { toastr } from 'react-redux-toastr';
import StatusChangeModal from '../../newComponents/utilities/StatusChangeModal';
import ChangeGroupModal from '../../newComponents/utilities/ChangeGroupModal';
import TwoFAStatusChangeModal from "../../newComponents/utilities/TwoFAStatusChangeModal"
import moment from 'moment';
import MomentConfig from '../../common/MomentConfig';
import { GlobalState } from '../../contextAPI/Context';
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PatientInsuranceViewModal from "../../newComponents/utilities/PatientInsuranceViewModal";


const Tables = (props) => {
  // CONTEXT VARIABLES 
  const {
    setToken,
    patientName,
    patientEmail,
    patientCountry,
    patientPhone,
    patientGroup,
    patientGender,
    patientPostCode,
    patientStatus,
    setBlankStateMessage,
    patientListReload,
    setPatientListReload,
    setPatientName,
    setPatientEmail,
    setPatientCountry,
    setPatientPhone,
    setPatientGroup,
    // setPatientGroupList,
    setPatientGender,
    setPatientPostCode,
    setPatientStatus
  } = useContext(GlobalState);

  // LOCAL VARIABLES 
  const [patientList, setPatients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalState, setModalState] = useState(false);
  const [twoFAmodalState, setTwoFAModalState] = useState(false);
  const [patientID, setPatientID] = useState("");
  const [status, setStatus] = useState("");
  const [twoFA, setTwoFA] = useState("");
  const [userConfirmation, setUserConfirmation] = useState(false);
  const [statusChangeModal, setStatusChangeModal] = useState(false);
  const [patientInsuranceViewModal, setPatientInsuranceViewModal] = useState(false);
  const [rowData, setRowData] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [patientOffset, setPatientOffset] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [isGlobalSearch, setIsGlobalSearch] = useState(false);
  const [patientSizePerPage, setPatientSizePerPage] = useState(50);
  const [patientPage, setPatientPage] = useState(1);//eslint-disable-line

  const fileExportName = "Patients";
  const fileName = `Equipo-${fileExportName}-Export-${moment().format(
    MomentConfig.MOMENT_FORMAT_DATEANDTIME
  )}.csv`;
  
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

  const fetchPatients = () => {
    setToken(props.authtoken);
    setLoading(true);
    const params =
    {
      "patient_name": patientName ? patientName.toString() : "",
      "patient_email": patientEmail ? patientEmail.toString() : "",
      "patient_country": patientCountry ? patientCountry.toString() : "",
      "patient_phone": patientPhone ? patientPhone.toString() : "",
      "group_id": patientGroup ? patientGroup.toString() : "",
      "patient_gender": patientGender ? patientGender.toString() : "",
      "patient_postcode": patientPostCode ? patientPostCode.toString() : "",
      "status": patientStatus ? patientStatus.toString() : "",
      "record_limit": patientSizePerPage,
      "offset": patientOffset ? patientOffset.toString() : "0",
      "search_keyword": searchKeyword || "",
      "sortOrder": sortOrder || "",
      "sortColumn": sortColumn || "",
      "is_pagination": "1",
    }

    Axios.post(API_ENDPOINT + "/user/patient-details/", params, {
      headers: {
        Authorization: props.authtoken,
      }
    }).then((res) => {
      setPatients(res.data);
      setBlankStateMessage(res.data.length > 0 ? false : true);
      setLoading(false);
    });
  };

  const exportTableData = () => {
    const params = {
      "file_name": fileName || "",
      "patient_name": patientName ? patientName.toString() : "",
      "patient_email": patientEmail ? patientEmail.toString() : "",
      "patient_country": patientCountry ? patientCountry.toString() : "",
      "patient_phone": patientPhone ? patientPhone.toString() : "",
      "group_id": patientGroup ? patientGroup.toString() : "",
      "patient_gender": patientGender ? patientGender.toString() : "",
      "patient_postcode": patientPostCode ? patientPostCode.toString() : "",
      "status": patientStatus ? patientStatus.toString() : "",
      "record_limit": patientSizePerPage,
      "offset": patientOffset ? patientOffset.toString() : "0",
      "search_keyword": searchKeyword || "",
      "sortOrder": sortOrder || "",
      "sortColumn": sortColumn || "",
      "is_pagination": "1",
    };

    Axios.post(`${API_ENDPOINT}/user/export-patient-details/`, params, {
        headers: {
          Authorization: props.authtoken,
        },
        responseType: "blob",
      })
      .then((response) => {
        if (response.status === 200) {
          var fileDownload = require("js-file-download");
          fileDownload(
            response.data,
            `${fileName}`,
            response["headers"]["x-suggested-filename"]
          );
          toastr.success("Success", "Export successful");
        }
      })
      .catch((error) => {
        toastr.error("Failed", "Something went wrong");
      });
  };

  const changePatientStatus = () => {
    let newStatus = "";
    setLoading(true);
    if (status === 0) {
      newStatus = 1
    } else {
      newStatus = 0
    }

    const updatedStatus = {
      patient_id: patientID,
      status: newStatus,
    };

    Axios.post(
      API_ENDPOINT + "/user/patient-status-update/",
      updatedStatus,
      {
        headers: {
          Authorization: props.authtoken,
        },
      }
    )
      .then((res) => {
        if (res.data.message === "Patient Status Updated") {
          toastr.success('Status Updated', 'Patient status updated successfully');
          fetchPatients();
          setUserConfirmation(false);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const statusFormatter = (cell, row) => {
    let status = "";
    let badgeclass = " ";
    if (cell === 1) {
      status = "Active";
      badgeclass = "badge badge-success statustoggle button-width";
    } else {
      status = "Inactive";
      badgeclass = "badge badge-danger statustoggle button-width";
    }

    return (
      <>
        <span
          className={badgeclass}
          onClick={() => {
            // changePatientStatus(row.id, cell);
            setPatientID(row.id);
            setStatus(cell);
            setModalState(true);
          }}
        >
          {status}
        </span>
      </>
    );
  };

  const actionFormatter = (cell, row) => {

    let status = "";
    let badgeclass = " ";
    if (cell === 1) {
      status = "2FA Enabled";
      badgeclass = "badge badge-success statustoggle fa-btn";
    } else {
      status = "2FA Disabled";
      badgeclass = "badge badge-danger statustoggle fa-btn";
    }


    return (
      <>

        <span
          className={badgeclass}
          onClick={() => {
            setPatientID(row.id);
            setTwoFA(cell);
            setTwoFAModalState(true);
          }}
        >
          <div className="FA-label">
          {status}
          </div>
        </span>

        <span
          className="change-group-button"
          onClick={() => {
            setRowData(row)
            setStatusChangeModal(true)
          }}
        >
          Change Group
      </span>

      </>
    )

  }

  const dateFormatter = (cell, row) => {
    return `${cell ? moment(cell).utc(cell).local().format(MomentConfig.MOMENT_FORMAT_DATEANDTIME) : "N/A"}`
  }

  const dobFormatter = (cell) => {
    return cell ? moment(cell).format(MomentConfig.MOMENT_FORMAT_DATE) : "N/A"
  }

  const postCodeFormatter = (cell) => {
    return cell ? `${cell}` : "N/A"
  }

  const basicValidation = (cell) => {
    return cell ? cell : "N/A"
  }

  const statusFormatterCSV = (cell) => {
    return cell ? "Active" : "Inactive"
  }

  const insuranceViewFormatter = (cell, row) => {
    return (
      <>
        <div >
          <FontAwesomeIcon
            icon={faEye}
            size={"1x"}
            fixedWidth
            className="align-middle"
            title={row.policyNumber ? "View Insurance" : "No Insurance"}
            style={
              row.insuranceGroup && row.policyNumber
                ? { visibility: "visible", cursor: "pointer" , color:"#2785ca"}
                : {
                    
                    visibility: "visible", cursor: "not-allowed" , color:"#626365",
                    title: "No Insurance",
                    
                  }
            }
            onClick={() => {
              if (row.insuranceGroup && row.policyNumber) {
                setPatientInsuranceViewModal(!patientInsuranceViewModal);
                setRowData(row);  
                // console.log(row)
              }
            }}
          />
        </div>
      </>
    );
  };

  const tableColumns = [
    {
      dataField: "patient_name",
      text: "Name",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
      headerStyle: { width: '100px' },
      style:{ width: '100px'},
    },
    {
      dataField: "gender",
      text: "Gender",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
      headerStyle: { width: '100px' },
      style:{ width: '100px'},
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
      headerStyle: { width: '100px' },
      style:{ width: '100px'},
    },
    {
      dataField: "dob",
      text: "DOB",
      sort: true,
      formatter: dobFormatter,
      csvFormatter: dobFormatter,
      headerStyle: { width: '100px' },
      style:{ width: '100px'},
    },
    {
      dataField: "country",
      text: "Country",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
      headerStyle: { width: '100px' },
      style:{ width: '100px'},
    },
    {
      dataField: "mobile_number",
      text: "Phone",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
      headerStyle: { width: '100px' },
      style:{ width: '100px'},
    },
    {
      dataField: "address1",
      text: "Address",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
      headerStyle: { width: '100px' },
      style:{ width: '100px'},
    },
    {
      dataField: "state",
      text: "State/ Province/ County ",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
      headerStyle: { width: '100px' },
      style:{ width: '100px'},
    },
    {
      dataField: "city",
      text: "City/ Town",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
      headerStyle: { width: '100px' },
      style:{ width: '100px'},
    },
    {
      dataField: "zipcode",
      text: "Post Code",
      sort: true,
      formatter: basicValidation,
      csvFormatter: postCodeFormatter,
      headerStyle: { width: '100px' },
      style:{ width: '100px'},
    },
    {
      dataField: "GroupName",
      text: "Healthcare Partners",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
      headerStyle: { width: '100px' },
      style:{ width: '100px'},
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      formatter: statusFormatter,
      csvFormatter: statusFormatterCSV,
      headerStyle: { width: '100px' },
      style:{ width: '100px'},
    },
    {
      dataField: "is2FAEnabled",
      text: "Actions",
      formatter: actionFormatter,
      csvExport: false,
      headerStyle: { width: '100px' },
      style:{ width: '100px'},
    },
    
    
    {
      dataField: "lastLoginTime",
      text: "Last Login",
      sort: true,
      formatter: dateFormatter,
      csvFormatter: dateFormatter,
      headerStyle: { width: '100px' },
      style:{ width: '100px'},
    },
    {
      dataField: "latest_admissionDate",
      text: "Latest Appt",
      sort: true,
      formatter: dateFormatter,
      csvFormatter: dateFormatter,
      headerStyle: { width: '100px' },
      style:{ width: '100px'},
    },
    {
      text: "Insurance",
      // sort: true,
      formatter: insuranceViewFormatter,
      csvExport: false,
      // csvFormatter: dateFormatter,
       headerStyle: { width: '100px' },
      style:{ width: '100px'},
    },
  ];

  let loader = null;

  if (loading) {
    loader = <Spinner />;
  }

  const handleTableChangeForFacility = (type, newState) => {
    setPatientPage(newState.page);
    setPatientSizePerPage(newState.sizePerPage);
    setPatientOffset((newState.page - 1) * newState.sizePerPage);

    if (newState.sortField === null && newState.sortOrder === undefined) {
      setSortColumn("");
      setSortOrder("");
    }
    else {
      setSortColumn(newState.sortField);
      // Toggle the sortOrder between "desc" and "asc"
      const newSortOrder = sortOrder === "desc" ? "asc" : "desc";
      setSortOrder(newSortOrder);
      setPatientListReload(patientListReload + 1);
    }
  };

  const clearValues = () => {
    setPatientName("");
    setPatientEmail("");
    setPatientCountry("");
    setPatientPhone("");
    setPatientGroup("");
    setPatientGender("");
    setPatientPostCode("");
    setPatientStatus("");
    setSearchKeyword("");
    setPatientListReload(patientListReload + 1);
  };
  // DEFAULT API CALL
  useEffect(() => { fetchPatients() }, [patientName, patientEmail, patientCountry, patientPhone, patientGroup, patientGender, patientPostCode, patientStatus, patientListReload]); // eslint-disable-line
  // EFFECT TO CLEAR PREVIOUS FILTER VARIABLES

  // EFFECT TO HANDLE PATIENT STATUS CHANGE 
  useEffect(() => { if (userConfirmation) {changePatientStatus()} }, [userConfirmation]) // eslint-disable-line

  useEffect(() => {
    if (isGlobalSearch) {
      setPatientOffset(0);
      // setFacilityTotalSize(0);
      fetchPatients();
      setIsGlobalSearch(false);
    }
  }, [isGlobalSearch]); // eslint-disable-line
  useEffect(() => {
    if (searchKeyword.length >= 4) {
      setIsGlobalSearch(true);
    }
  }, [searchKeyword]);

  useEffect(() => {
    if (searchKeyword.length === 0) {
      setPatientListReload(patientListReload + 1);
    }
  }, [searchKeyword]); // eslint-disable-line

  return (
    <Hux>
      {loader}
      <Container fluid>
        <Header>
          <HeaderTitle>Patients</HeaderTitle>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/dashboard">Dashboard</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Patients</BreadcrumbItem>
          </Breadcrumb>
        </Header>
        <ChangeGroupModal
          rowData={rowData}
          statusChangeModal={statusChangeModal}
          setStatusChangeModal={setStatusChangeModal}
          token={props.authtoken}
        />

        <StatusChangeModal
          modalState={modalState}
          setModalState={setModalState}
          setUserConfirmation={setUserConfirmation}
        />

        <TwoFAStatusChangeModal
          patientID={patientID}
          twoFAmodalState={twoFAmodalState}
          setTwoFAModalState={setTwoFAModalState}
          token={props.authtoken}
          twoFA={twoFA}
          patientListReload={patientListReload}
          setPatientListReload={setPatientListReload}
        />

        <PatientInsuranceViewModal 
          patientInsuranceViewModal={patientInsuranceViewModal}
          setPatientInsuranceViewModal={setPatientInsuranceViewModal}
          rowData={rowData}
        
        />

        <ExportCSVTable
           data={patientList} 
           columns={tableColumns}
           handleTableChangeForFacility={(type, newState) => {
            handleTableChangeForFacility(type, newState);
          }}
           exportTableData={exportTableData}
           searchKeyword={searchKeyword}
           setSearchKeyword={setSearchKeyword}
           clearSearchValues={clearValues}
            />
      </Container>
    </Hux>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
    authtoken: state.auth.token,
  };
};

export default connect(mapStateToProps, null)(Tables);
