import React, { useState, useEffect, useContext } from 'react'
import { AvForm, AvGroup } from "availity-reactstrap-validation"
import { Col, Label, Row, Button } from "reactstrap"
import moment from 'moment'
import './DateRangeSelector.css'
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { GlobalState } from '../../contextAPI/Context';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { useLocation } from "react-router-dom";

const DateRangeSelector = () => {
    const {
        setAppointmentStatus,
        appointmentDropDown,
        setDoctorName,
        doctorDropDown,
        groupDropDown,
        couponDropDown,
        spinner,
        setAppoFromDate,
        setAppoToDate,
        setAppStatusID,
        setCouponCode,
        setGroupId,
        providerID,
        setProviderID,
        couponNameSearch,
        appoSearch,
        setAppoSearch,
        today,
        chartFromDate,
        chartToDate,
        setToday,
        appStatusID,
        token,
        setDocterSpeciality,
    } = useContext(GlobalState)

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

    const [search, setSearch] = useState(false)
    const [fromDate, setFromDate] = useState("")
    const [toDate, setToDate] = useState("")
    const [calendarFromDate, setCalendarFromDate] = useState("")
    const [calendarToDate, setCalendarToDate] = useState("")
    const [apiFromDate, setApiFromDate] = useState(moment().subtract(30, 'days').startOf('day').format("YYYY-MM-DD H:mm:ss"))
    const [apiToDate, setApiToDate] = useState(moment().format("YYYY-MM-DD H:mm:ss"))
    const [status, setStatus] = useState("")
    const [graphStatus, setGraphStatus] = useState(appStatusID) // eslint-disable-line
    const [coupon, setCoupon] = useState("")
    const [group, setGroup] = useState("")
    const [doctor, setDoctor] = useState("")
    const [appointment, setAppointment] = useState("")
    const [apptChartStartDate, setApptChartStartDate] = useState("")
    const [apptChartEndDate, setApptChartEndDate] = useState("")
    
    // SPECIALITY
    const [speciality, setSpeciality] = useState("")
    const [specialityList, setspecialityList] = useState("")
    const [specialityDropDown, setSpecialityDropDown] = useState("")

    const animatedComponents = makeAnimated()
    let history = useHistory();
    const location = useLocation();
    const getValueFromArray = array => array.map(item => { return item.value })
    
    const runSearch = () => {
        setSearch(true)
        if (doctor) {history.push(`/appointments/?doc=${doctor[0].id}`)}
        setAppoSearch(appoSearch ? appoSearch + 1 : "")
        setAppoFromDate(apiFromDate ? apiFromDate : moment().subtract(30, 'days').format("YYYY-MM-DD H:mm:ss"))
        setAppoToDate(apiToDate ? apiToDate : moment().format("YYYY-MM-DD H:mm:ss"))
        setAppStatusID(status ? getValueFromArray(status) : "")
        setCouponCode(coupon ? getValueFromArray(coupon) : "")
        setGroupId(group ? getValueFromArray(group) : "")
        if (!providerID) setDoctorName(doctor ? getValueFromArray(doctor) : "")
        setAppointmentStatus(appointment ? getValueFromArray(appointment) : "")
        setDocterSpeciality(speciality ? speciality.value : "")
        setSearch(false)
    }

    const resetFields = () => {
        setFromDate("")
        setToDate("")
        setCalendarFromDate("")
        setCalendarToDate("")
        setAppoFromDate("")
        setAppoToDate("")
        setStatus("")
        setCoupon("")
        setGroup("")
        setDoctor("")
        setAppStatusID("")
        setSpeciality("")
        setDocterSpeciality("")
        setCouponCode("")
        setGroupId("")
        setDoctorName("")
        setAppointment("")
        setAppointmentStatus("")
        setProviderID("")
        setToday("")
        history.push("/appointments")
        setCalendarFromDate(moment().subtract(30, 'days').toDate())
        setCalendarToDate(moment().toDate())
        setAppoSearch(appoSearch ? appoSearch + 1 : "")
    }

    const convertDateToLocal = (value, source) => {
        setToday(today ? "clear" : "")
        history.push("/appointments")
        let date = moment(value).format("DD-MM-YYYY")
        let localDateAndTime = null
        let currentTimeZone = null
        let apiFormat = null
        switch (source) {
            case "fromDate":
                setCalendarFromDate(value)
                localDateAndTime = `${date} 00:00:00`
                currentTimeZone = moment(localDateAndTime, "DD-MM-YYYY H:mm:ss").format("DD-MM-YYYY H:mm:ss")
                setFromDate(currentTimeZone)
                apiFormat = moment(localDateAndTime, 'DD-MM-YYYY H:mm:ss').format("YYYY-MM-DD H:mm:ss")
                setApiFromDate(apiFormat)
                break;

            case "toDate":
                setCalendarToDate(value)
                localDateAndTime = `${date} 23:59:00`
                currentTimeZone = moment(localDateAndTime, "DD-MM-YYYY H:mm:ss").format("DD-MM-YYYY H:mm:ss")
                setToDate(currentTimeZone)
                apiFormat = moment(localDateAndTime, 'DD-MM-YYYY H:mm:ss').format("YYYY-MM-DD H:mm:ss")
                setApiToDate(apiFormat)
                break;

            default:
                // NO DEFAULT EXPECTED 
                break;
        }
    }

    const graphStatusSet = () => {
        if (graphStatus.length > 0) {
            setStatus({
                key: 3,
                label: "Completed",
                value: 3,
            })
        }
    }

    const calendarDate = () => {
        if (!fromDate && !today) setCalendarFromDate(moment(fromDate).format("dd MMM yyyy"))
        if (!toDate && !today) setCalendarToDate(moment(toDate).format("dd MMM yyyy"))
        // if (!fromDate && !chartFromDate) setCalendarFromDate(moment(fromDate).format("dd MMM yyyy"))
        // if (!toDate && !chartToDate) setCalendarToDate(moment(toDate).format("dd MMM yyyy"))
        if (search) setAppoFromDate(moment(fromDate).format("YYYY-MM-DD H:mm:ss"))
        if (search) setAppoToDate(moment(toDate).format("YYYY-MM-DD H:mm:ss"))
    }

    /* const getUserTypeFromURL = () => {
        let userTypeInURL = query.pathname
        userTypeInURL = userTypeInURL.toLocaleLowerCase();
        // SETS USER TYPE BASE ON URL
        if (userTypeInURL === "/doctors") setUserType(3);
        if (userTypeInURL === "/nurses") setUserType(6);
        if (userTypeInURL === "/therapists") setUserType(7);
        if (userTypeInURL === "/carers") setUserType(8);
      } */

    const getspecialityList = () => {
        axios.get(`${API_ENDPOINT}/external/speciality-list/0`,
            {
                headers: {
                    Authorization: token,
                },
            })
            .then((res) => {
                setspecialityList(res.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const createSpecialityOption = () => {
        var array = specialityList
        const options = array.map((item) => {
            return { value: item.id, label: item.name };
        });
        setSpecialityDropDown(options)
    }


    const autoSelectDoctor = () => { doctorDropDown.filter((item) => { if (item.id === parseInt(providerID) && location.pathname !== '/appointments') setDoctor([item]) }) } // eslint-disable-line
    const autoSelectCoupon = () => { couponDropDown.filter((item) => { if (item.value === couponNameSearch) setCoupon([item]) }) } // eslint-disable-line

    useEffect(() => {
        graphStatusSet();
    }, [graphStatus]); // eslint-disable-line

    useEffect(() => { calendarDate() }, [fromDate, toDate]) // eslint-disable-line
    useEffect(() => { if (providerID && doctorDropDown.length > 0) autoSelectDoctor() }, [providerID, doctorDropDown]) // eslint-disable-line
    useEffect(() => { if (couponNameSearch && couponDropDown.length > 0) autoSelectCoupon() }, [couponNameSearch, couponDropDown]) // eslint-disable-line

    useEffect(() => {
        setApptChartStartDate(chartFromDate && chartFromDate !== "Invalid date" ? moment(chartFromDate).toDate() : "")
        setApptChartEndDate(chartToDate && chartToDate !== "Invalid date" ? moment(chartToDate).toDate() : "")
    }, [chartFromDate, chartToDate])

    // EFFECTS FOR CORPORATE / GROUP
    /*  useEffect(() => { getUserTypeFromURL() }, []) // eslint-disable-line */
    useEffect(() => { if (token && !specialityList) getspecialityList(); }, [token]) // eslint-disable-line
    useEffect(() => { if (specialityList.length > 0) createSpecialityOption() }, [specialityList]) // eslint-disable-line
    useEffect(() => {
        if (doctor !== null) {
            if (doctor[0]) {

                console.log('doctor ID..!', doctor[0].id);
            } else {
                console.log('doctor array is empty');
            }
        } else {
            console.log('doctor is null');
        }
    }, [doctor]);

    // useEffect(() => {
    //     debugger
    //     if (location.pathname === '/appointments') { setDoctor(""); }
    // }, [location.pathname]);//eslint-disable-line

    return (
        <AvForm>
            <AvGroup>
                <Row sm="12">
                    <Col sm="2">
                        <Label>From</Label>
                        <div className="date-btn-container">
                            <div className="datepicker-wrapper">
                                {!couponNameSearch && (!today || today === "clear") && chartFromDate === "Invalid date" ?
                                    <DatePicker
                                        dateFormat="dd MMM YYY"
                                        selected={calendarFromDate && calendarFromDate !== "Invalid date" ? calendarFromDate : moment().subtract(30, 'days').toDate()}
                                        onChange={(date) => { convertDateToLocal(date, "fromDate") }}
                                        todayButton="Today"
                                    />
                                    : ""}
                                {couponNameSearch ?
                                    <DatePicker
                                        dateFormat="dd MMM YYY"
                                        selected={calendarFromDate && calendarFromDate !== "Invalid date" ? calendarFromDate : ""}
                                        onChange={(date) => { convertDateToLocal(date, "fromDate") }}
                                        todayButton="Today"
                                    />
                                    : ""}
                                {today && today !== "clear" ?
                                    <DatePicker
                                        dateFormat="dd MMM YYY"
                                        selected={calendarFromDate && calendarFromDate !== "Invalid date" ? calendarFromDate : moment().startOf('day').toDate()}
                                        onChange={(date) => { convertDateToLocal(date, "fromDate") }}
                                        todayButton="Today"
                                    />
                                    : ""}

                                {chartFromDate && chartFromDate !== "Invalid date"
                                    ? <DatePicker
                                        dateFormat="dd MMM YYY"
                                        selected={apptChartStartDate}
                                        onChange={(date) => { convertDateToLocal(date, "fromDate") }}
                                        todayButton="Today"
                                    />
                                    : ""}
                                {/* <FontAwesomeIcon icon={faCalendar} className="datepicker-icon" /> */}
                            </div>
                        </div>
                    </Col>
                    <Col sm="2">
                        <Label>To</Label>
                        <div className="date-btn-container">
                            <div className="datepicker-wrapper">
                                {couponNameSearch && !apptChartEndDate ?
                                    <DatePicker
                                        dateFormat="dd MMM YYY"
                                        selected={calendarToDate && calendarToDate !== "Invalid date" ? calendarToDate : ""}
                                        placeholder="date placeholder"
                                        onChange={(date) => { convertDateToLocal(date, "toDate") }}
                                        todayButton="Today"
                                    />
                                    : ""}
                                {!couponNameSearch && !apptChartEndDate ?
                                    <DatePicker
                                        dateFormat="dd MMM YYY"
                                        selected={calendarToDate && calendarToDate !== "Invalid date" ? calendarToDate : moment().toDate()}
                                        placeholder="date placeholder"
                                        onChange={(date) => { convertDateToLocal(date, "toDate") }}
                                        todayButton="Today"
                                    />
                                    : ""}

                                {!couponNameSearch && apptChartEndDate ?
                                    <DatePicker
                                        dateFormat="dd MMM YYY"
                                        selected={apptChartEndDate}
                                        onChange={(date) => { convertDateToLocal(date, "fromDate") }}
                                        todayButton="Today"
                                    />
                                    : ""} 

                                {/* <FontAwesomeIcon icon={faCalendar} className="datepicker-icon" /> */}
                            </div>
                        </div>
                    </Col>
                    <Col sm="3">
                        <Label>Care Provider</Label>
                        <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            onChange={(event) => { setDoctor(event) }}
                            isSearchable
                            placeholder="All"
                            value={doctor}
                            options={doctorDropDown}
                            components={animatedComponents}
                            isMulti
                        />
                    </Col>
                    <Col sm="3">
                        <Label>Appt Status</Label>
                        <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            onChange={(event) => { setStatus(event) }}
                            isSearchable
                            placeholder="All"
                            value={status}
                            options={appointmentDropDown}
                            components={animatedComponents}
                            isMulti
                        />
                    </Col>
                </Row>
                <Row>
                    <Col sm="3" className="second-row-filters" >
                        <Label>Coupon Code</Label>
                        <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            onChange={(event) => { setCoupon(event) }}
                            isSearchable
                            placeholder="All"
                            value={coupon}
                            options={couponDropDown}
                            components={animatedComponents}
                            isMulti
                        />
                    </Col>
                    <Col sm="3" className="second-row-filters" >
                        <Label>Group</Label>
                        <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            onChange={(event) => { setGroup(event) }}
                            isSearchable
                            placeholder="All"
                            value={group}
                            options={groupDropDown}
                            components={animatedComponents}
                            isMulti
                        />
                    </Col>
                    <Col sm="3" className="second-row-filters" >
                        <Label>Speciality</Label>
                        <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            onChange={(event) => { setSpeciality(event) }}
                            isSearchable
                            placeholder="All"
                            value={speciality}
                            options={specialityDropDown}
                            components={animatedComponents}
                        />
                    </Col>
                    <Col>
                        <Button
                            type="submit"
                            className="go-buttons"
                            disabled={spinner ? false : false}
                            onClick={() => { runSearch() }}
                        >Go</Button>
                        <Button
                            type="reset"
                            className="reset-buttons"
                            disabled={spinner ? true : false}
                            onClick={() => { resetFields() }}
                        >Reset</Button>
                    </Col>
                </Row>
            </AvGroup>
        </AvForm>
    )
}

export default DateRangeSelector
