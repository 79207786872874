/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useEffect, useContext, useState } from 'react';
import {
  Button, Modal, ModalHeader,
  ModalBody, ModalFooter,
  Card, CardText, CardBody, CardTitle,
} from 'reactstrap';
import axios from 'axios';
import BootstrapTable from 'react-bootstrap-table-next';
import { GlobalState } from "../../contextAPI/Context"
import getSymbolFromCurrency from 'currency-symbol-map';
import moment from 'moment';
import MomentConfig from '../../common/MomentConfig';


const CancelAppointmentRulesModal = (props) => {
  // DEPENDENCY VARIABLES 
  const { cancelApptRuleModal, setCancelApptRuleModal, appointmentID, paymentmethod} = props;
 
  // LOCAL VARIABLES 
  // eslint-disable-line
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN = process.env.REACT_APP_API_TOKEN;
  const [apiCallStatus, setApiCallStatus] = useState("ideal");
  const [apiErrorMessage, setApiErrorMessage] = useState("");

  const {
    refundRules, setRefundRules
  } = useContext(GlobalState);


  const fetchRefundRules = () => {
    setApiCallStatus("loading")

    const param = appointmentID ? { 'apt_id': appointmentID } : 0;

    axios.post(API_ENDPOINT + "/user/apt-refund-details/", param, {
      headers: {
        Authorization: API_TOKEN,
      },
    })
      .then((response) => {
        if (response.data.length > 0) {
          setRefundRules(response.data)
          response.data[0].ruleId ? setApiCallStatus("loaded") : setApiCallStatus("no-config-found");
         /*  if(response.data[0].ruleId === null) setApiCallStatus("no-rule-applicable"); */
        }
      })
      .catch((error) => {
        setApiCallStatus("error");
      });
  };

  const amountFormatter = (cell, row) => {
    return `${getSymbolFromCurrency(row.currency)}${cell} (${row.cancelChargePercent}%)`
  }


  const timeFormatter = (utcTimeStamp) => {
    return moment.utc(utcTimeStamp).local().format(MomentConfig.MOMENT_FORMAT_DATEANDTIME);
  }

  const stringOperationForBetween = (cell, row) => {
    let newString = cell.replace("%", timeFormatter(row.maxTime));
    newString = newString.replace("%", timeFormatter(row.minTime));
    return newString;
  }

  const defaultStringOperation = (cell, row) => {
    if(cell) {
      return cell.replace("%", timeFormatter(row.minTime));
    }
  }

  const cancellationTimeFormatter = (cell, row) => {
    if (row.operator === "between") {
      return stringOperationForBetween(cell, row);
    } else {
      return defaultStringOperation(cell, row);
    }
  }

  const rowStyle = (row) => {
    const style = {};
    if (row.isTrue === 1 ) {
      style.backgroundColor = '#F7D159';
    } else {
      style.backgroundColor = '#FFFFFF';
    }

    return style;
  };
    
    const columns = appointmentID
      ? [
        {
          dataField: 'cancellationSlotText',
          text: 'Cancellation Period',
          formatter: cancellationTimeFormatter,
        }, {
          dataField: 'cancelCharge',
          text: 'Cancellation Charge',
          formatter: amountFormatter,
          headerStyle: { "width": '170px',"textAlign": 'center' },
          style: { "textAlign": 'center' },
        }]
      : [
        {
          dataField: 'cancellationSlotText',
          text: 'Cancellation Time'
        }, {
          dataField: 'cancelChargePercent',
          text: 'Cancellation Charge (%)',
          headerStyle: { "width": '200px' }
      }];


  const toggle = () => {
    setCancelApptRuleModal(!cancelApptRuleModal);
    /* setRefundRules(""); */
    setApiErrorMessage("");
    setApiCallStatus("ideal");
  }

  

  useEffect(() => { if (cancelApptRuleModal) fetchRefundRules() }, [cancelApptRuleModal]) // eslint-disable-line

  return (
    <div>
    <Modal isOpen={cancelApptRuleModal} toggle={toggle} >
      <ModalHeader tag="h4" cssModule={{'modal-title': 'w-100 text-center'}}  toggle={toggle}><h3>{appointmentID ? 'Appointment Cancellation and Refund' : 'Appointment Cancellation Policy'} </h3></ModalHeader>

      
        <ModalBody>

          {appointmentID && paymentmethod !== "coupon" && apiCallStatus === "loaded"
            ? <h6>If you cancel the appointment, Cancellation charge will be calculated as below.</h6>
            : ""}

          {cancelApptRuleModal && refundRules.length > 0 && paymentmethod !== "coupon" && apiCallStatus === "loaded"
            ? <>
              <BootstrapTable
                keyField='id'
                rowStyle={rowStyle}
                data={refundRules}
                columns={columns}
              />
              <div className="float-left">
                <div className="rule-legend"> </div>
                <span className="rule-legend-text"> Active Cancellation Rule </span>
              </div>
            </>
            : ""}

           { paymentmethod === "coupon" ? <p>This appointment has been booked using a 100% Coupon. If you cancel the appointment, the coupon is also no longer valid.</p> : ""}
           
           {apiCallStatus === 'error'
              ? <Card>
                <CardBody>
                  <CardTitle tag="h5">That's an error</CardTitle>
                  <CardText>{apiErrorMessage}</CardText>
                </CardBody>
              </Card>
              : ""
            }

            { apiCallStatus === "no-config-found" ? 
              <Card>
              <CardBody>
                <CardTitle tag="h5">No Refund Configuration Found</CardTitle>
               {/*  <CardText>{apiErrorMessage}</CardText>
                <CardText tag="h5">No refund configuration found</CardText> */}
              </CardBody>
            </Card>
            : ""
            }
            
            { apiCallStatus === "no-rule-applicable" ? 
              <Card>
              <CardBody>
                <CardTitle tag="h5">No rule applicable for this appointment </CardTitle>
               {/*  <CardText>{apiErrorMessage}</CardText>
                <CardText tag="h5">No refund configuration found</CardText> */}
              </CardBody>
            </Card>
            : ""
            }

        </ModalBody>
      
      <ModalFooter>
      <Button outline className="close-btn-alone button-width" onClick={toggle}>Close</Button>
      </ModalFooter>
    </Modal>
  </div>
  );
}

export default CancelAppointmentRulesModal;