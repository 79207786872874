/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from 'reactstrap';
import Select from 'react-select';
import BootstrapTable from 'react-bootstrap-table-next';
import axios from 'axios';
import "./DateRangeSelector.css";
import { toastr } from 'react-redux-toastr';

const AdvancedPharmacySearchModal = (props) => {
  // DEPENDENCY VARIABLES
  const { advancedSearchModal, setAdvancedSearchModal, rowData, setPrescriptionReload, prescriptionReload, setEditPrescriptionModal } = props
  // LOCAL VARIABLES
  let token = localStorage.getItem("token");
  const userId = localStorage.getItem('userId');
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const [spinner, setSpinner] = useState(false)
  const [apiLoaded, setApiLoaded] = useState(false)
  const [search, setSearch] = useState(false)
  const [pharmacyList, setPharmacyList] = useState("")
  const [pharmacyInfo, setPharmacyInfo] = useState("")
  const [name, setName] = useState("")
  const [country, setCountry] = useState("")
  const [address, setAddress] = useState("")
  const [city, setCity] = useState("")
  const [state, setState] = useState("")
  const [zip, setZip] = useState("")
  const [Countries, setCountries] = useState("");
  const [patientInfo, setPatientInfo] = useState("");
  const [pharmacyId, setPharmacyId] = useState("");
  const [isOpen, setIsOpen] = useState(true);

  const clearValues = () => {
    setPharmacyList("")
    setApiLoaded(false)
    setPharmacyInfo("")
    setPatientInfo("")
    setName("")
    setCountry("")
    setAddress("")
    setCity("")
    setState("")
    setZip("")
    setIsOpen(true)
  }

  const toggle = () => {
    clearValues();
    setAdvancedSearchModal(!advancedSearchModal);
  }

  const basicValidation = (cell) => {
    return cell ? cell : "N/A"
  }

  const radioValidation = (cell, row) => {
    let data = row
    data.value = row.id
    data.label = row.name
    return (
      <div className="advanced-search-radio-button">
        <Input type="radio" name="choose" onClick={() => { setPharmacyInfo(data.value) }} />
      </div>
    )

  }

  const columns = [{
    dataField: 'id',
    text: 'Select',
    formatter: radioValidation
  }, {
    dataField: 'name',
    text: 'Name',
    sort: true,
    formatter: basicValidation
  }, {
    dataField: 'country_name',
    text: 'Country',
    sort: true,
    formatter: basicValidation
  }, {
    dataField: 'address',
    text: 'Address',
    sort: true,
    formatter: basicValidation
  }, {
    dataField: 'city',
    text: 'City/Town',
    sort: true,
    formatter: basicValidation
  }, {
    dataField: 'state',
    text: 'State/Province/County',
    sort: true,
    formatter: basicValidation
  }, {
    dataField: 'zipcode',
    text: 'Postal Code',
    sort: true,
    formatter: basicValidation
  }];

  const searchPharmacy = () => {
    setSpinner(true);
    setApiLoaded(false);
    setPharmacyInfo("");

    const params =
    {
      "pharmacy_name": name ? name : "",
      "pharmacy_code": "",
      "pharmacy_email": "",
      "pharmacy_efax": "",
      "pharmacy_address": address ? address : "",
      "pharmacy_status": "",
      "pharmacy_country": country ? Number(country.value) : 0,
      "is_pagination": 0,
      "pharmacy_city": city ? city : "",
      "pharmacy_state": state ? state : "",
      "pharmacy_zipcode": zip ? zip : "",
      "offset": "force"
    }

    axios.post(`${API_ENDPOINT}/user/get-pharmacy-data/`, params, {
      headers: {
        Authorization: token,
      },
    })
      .then(function (response) {
        if (response.data.length > 0) {
          setPharmacyList(response.data);
          setApiLoaded(true);
          setSpinner(false);
          setIsOpen(false)
        } else {
          setPharmacyList("");
          setApiLoaded(true);
          setSpinner(false);
        }
      })
      .catch(function (error) {
        setPharmacyList("");
        setApiLoaded(true);
        setSpinner(false);
      });
  };



  const getCountries = () => {

    axios.get(API_ENDPOINT + "/external/get-country-list/",
      {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        setCountries(res.data);
      })
      .catch((error) => {
        console.log(error);
      });

  };

  const CountryList = Countries && Countries.map((key) => {
    return { value: key.id, label: key.country_name };
  });

  const CountryHandler = (event) => {
    setCountry(event);
  };

  const enableOrDisableSearch = () => {
    if (name || country || address || city || state || zip) {
      setSearch(true)
    } else {
      setSearch(false)
    }
  }

  const autoSelectPatientCountry = () => {
    let patientCountry = patientInfo.pharmacy_country
    Countries.map(
      // eslint-disable-next-line
      (country) => {
        if (country.country_name === patientCountry) {
          setCountry({
            value: country.id,
            label: country.country_name
          });
        }
      }
    )
  }

  const conformation = () => {
    if (pharmacyInfo) {
      setPharmacyId(pharmacyInfo);
      updatePharmacyApiCall();
    }
  }

  const updatePharmacyApiCall = () => {
    axios.post(`${API_ENDPOINT}/user/update-prescription-pharmacy/`, {
      "appointment_id": rowData.appointment_id,
      "pharmacy_id": pharmacyId,
      "uploader_id": Number(userId)
    }, {
      headers: {
        Authorization: token,
      }
    })
      .then(function (response) {
        // console.log(response);
        setPrescriptionReload(prescriptionReload + 1)
        toggle();
        setEditPrescriptionModal(false);
        toastr.success("Pharmacy updated successfully")
      })
      .catch(function (error) {
        // console.log(error);
        toastr.error("Update Failed", "Please try again")
      });
  }


  const assignValueToPatientInfo = () => {
    setPatientInfo(rowData)
  }

  // eslint-disable-next-line
  useEffect(() => {
    if (pharmacyInfo) {
      setPharmacyId(pharmacyInfo);
    }
  }, [pharmacyInfo])
  // eslint-disable-next-line
  useEffect(() => { getCountries(); }, [])
  // eslint-disable-next-line
  useEffect(() => { if (rowData && !patientInfo) assignValueToPatientInfo(); }, [advancedSearchModal])
  // eslint-disable-next-line
  useEffect(() => { if (Countries && patientInfo) autoSelectPatientCountry(); }, [Countries, patientInfo])
  // eslint-disable-next-line
  useEffect(() => { enableOrDisableSearch(); }, [name, country, address, city, state, zip])

  return (
    <div>
      <Modal isOpen={advancedSearchModal} toggle={toggle} size="lg" >
        <ModalHeader tag="h4" cssModule={{'modal-title': 'w-100 text-center'}}  toggle={toggle}>Find A Pharmacy</ModalHeader>
        <ModalBody className="adv-search-modal-body">
          <Card className="adv-search-background-card">
            <Row>
              <Col>
                <Input
                  value={name}
                  placeholder="Name"
                  onChange={(e) => { setName(e.target.value) }}
                />
              </Col>
              <Col>
                <Select
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={CountryList}
                  onChange={(event) => CountryHandler(event)}
                  value={country}
                  placeholder="Country"
                  isSearchable
                  isClearable
                />
              </Col>
              <Col>
                <Input
                  value={address}
                  placeholder="Address"
                  onChange={(e) => { setAddress(e.target.value) }}
                />
              </Col>
            </Row>
            <Row className="margin-top">
              <Col>
                <Input
                  value={city}
                  placeholder="City/Town"
                  onChange={(e) => { setCity(e.target.value) }}
                />
              </Col>
              <Col>
                <Input
                  value={state}
                  placeholder="State/Province/County"
                  onChange={(e) => { setState(e.target.value) }}
                />
              </Col>
              <Col>
                <Input
                  value={zip}
                  placeholder="Postal Code"
                  onChange={(e) => { setZip(e.target.value) }}
                />
              </Col>
            </Row>
          </Card>
          <Row>
            <Col className="advanced-search-button">
              <Button
                className="yes-button button-width btn-margin"
                onClick={() => { searchPharmacy() }}
                disabled={spinner || !search ? true : false}
                title={spinner || !search ? "Nothing To Search" : ""}
              >Search</Button> {" "}
              <Button
                outline
                className="no-button button-width"
                onClick={() => { clearValues() }}
                disabled={spinner || !search ? true : false}
                title={spinner || !search ? "Nothing To Clear" : ""}
              >Clear</Button>
            </Col>
          </Row>
          <div className="advanced-search-button">
            {spinner
              ? <Spinner />
              : ""}
          </div>
          {pharmacyList && apiLoaded && !isOpen
            ? <>
              <br />
              <Row className="advanced-search-table-height disable-scrollbars">
                <Col>
                <div className="advanced-search-table">
                  {pharmacyList && apiLoaded ? <BootstrapTable keyField='id' data={pharmacyList} columns={columns} /> : ""}
                </div>
                  {!pharmacyList && apiLoaded ? <Card> <h4 className="no-data-found-card">No Data Found</h4> </Card> : ""}
                </Col>
              </Row>
            </>
            : ""
          }
          {!pharmacyList && apiLoaded && !spinner ? 
            <>
            <br />
            <Row className="advanced-search-table-height">
              <Col>
                {!pharmacyList && apiLoaded && !spinner ? <Card> <h4 className="no-data-found-card">No Data Found</h4> </Card> : ""}
              </Col>
            </Row>
          </>
          : ""
          }
        </ModalBody>
        <ModalFooter>
          {pharmacyInfo
            ? <Button
              className="yes-button button-width"
              onClick={() => { conformation(); }}
              disabled={!spinner && pharmacyInfo ? false : true}
              title={!spinner && pharmacyInfo ? "" : "Pharmacy Not Selected"}
            >Select</Button>

            : ""}
          <Button outline className="no-button button-width" onClick={toggle}>Close</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default AdvancedPharmacySearchModal;