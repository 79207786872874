import React, { useState, useEffect, useContext } from "react";
import { connect } from "react-redux";
import axios from 'axios'
import {
    Button,
    CardHeader,
    CardTitle,
    Card,
    Breadcrumb,
    BreadcrumbItem,
    Col,
    Row,
    Spinner
} from "reactstrap";
import Select from 'react-select';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import '../Schedule/Schedule.css'
import ScheduleCalendar from "../../Calendar/ScheduleCalendar"

import Header from "../../../components/Header";
import HeaderTitle from "../../../components/HeaderTitle";
import Navigation from "../SubNav";
import { GlobalState } from "../../../contextAPI/Context";
import { useLocation } from "react-router-dom";
import moment from 'moment';
import momentTimeZone from 'moment-timezone';
import CancelSlotModal from './CancelSlotModal';

let token = localStorage.getItem('token');
let timeZone = ""; // eslint-disable-line
let utc_offset = "";

// OBTAINING BASE URL BASED ON ENVIRONMENT
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const AvailableHoursInfo = () => {
    // GLOBAL CONTEXT
    const { spinner,
        setSpinner,
        physicianName,
        setPhysicianId,
        setPhysicianName,
        week,
        physicianId
    } = useContext(GlobalState);

    // LOCAL VARIABLES 
    const [pageLoading, setPageLoading] = useState(true);
    const [userId, setUserId] = useState("");
    const [timeZoneCard, setTimeZoneCard] = useState("");
    const [startDateTime, setStartDateTime] = useState("")
    const [endDateTime, setEndDateTime] = useState("")
    const [enableSlot, setEnableSlot] = useState("")
    const [slotStartDateTime, setSlotStartDateTime] = useState("")
    const [slotEndDateTime, setSlotEndDateTime] = useState("")
    const [physicianSpecialitysFromAPI, setPhysicianSpecialitysFromAPI] = useState("")
    const [PhysicianSpecialityDropDownList, setPhysicianSpecialityDropDownList] = useState("");
    const [PhysicianSpeciality, setPhysicianSpeciality] = useState("");

    // MODAL VARIABLES
    const [cancelSlotModal, setCancelSlotModal] = useState(false)

    // GET URL PARAMETERS 
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();

    // EFFECT TO GET THE URL PARAMETERS 
    useEffect(() => {
        if (query) {
            setUserId(Number(atob(query.get("pid"))));
            setPhysicianId(Number(atob(query.get("pid"))));
            setPhysicianName(atob(query.get("pname")));
        }
    }, [query]) // eslint-disable-line

    useEffect(() => {
        if (pageLoading) {
            setPageLoading(false);
        }
        // if(startDateTime && endDateTime) getAllAvailableSlot();
    }, [pageLoading, userId])

    const [calendarSlot, setcalendarSlot] = useState({});

    const updateCalendarSlotDetails = (datesValue) => {
        setcalendarSlot({})
        setcalendarSlot(datesValue)
    }
    
    const updateAvailableSlotsToLocalTimeForCalendar = (workingTime) => {

        let startTime = calculateLocalOffset(workingTime.startAt)
        let endTime = calculateLocalOffset(workingTime.endAt)

        var startTimeHr = getHourFromTime(startTime);
        startTimeHr = startTimeHr < 10 ? "0" + startTimeHr : "" + startTimeHr;
        var startTimeMin = getMinuteFromTime(startTime);
        startTimeMin = startTimeMin < 10 ? "0" + startTimeMin : "" + startTimeMin;

        var endTimeHr = getHourFromTime(endTime);
        endTimeHr = endTimeHr < 10 ? "0" + endTimeHr : "" + endTimeHr;
        var endTimeMin = getMinuteFromTime(endTime);
        endTimeMin = endTimeMin < 10 ? "0" + endTimeMin : "" + endTimeMin;

        let startDate = moment(getDateFromTime(startTime)).format("YYYY-MM-DD")
        let endDate = moment(getDateFromTime(endTime)).format("YYYY-MM-DD")
        return { date: { start: startDate, end: endDate }, updatedSlots: { startTime: startTimeHr + ":" + startTimeMin + ':00', endTime: endTimeHr + ":" + endTimeMin + ':00', specialityText: workingTime.specialityText } }

    }

    const processStartDate = () => {
        let dateTime = moment().add(week, 'week').startOf('week').format("YYYY-MM-DD HH:mm:ss");
        setStartDateTime(dateTime)
    }

    const processEndDate = () => {
        let dateTime = moment().add(week, 'week').endOf('week').format("YYYY-MM-DD HH:mm:ss");
        setEndDateTime(dateTime)
    }

    const getUserDetails = () => {
        setSpinner(true);

        const params = {
            user_id: physicianId
        }

        axios
            .post(`${API_ENDPOINT}/user/get-physician-profile-data/`, params,
                {
                    headers: {
                        Authorization: token,
                    },
                })
            .then(function (response) {
                setSpinner(false);
                setPhysicianSpecialitysFromAPI(response.data.speciality_details)
            })
            .catch(function (error) {
                setSpinner(false);
            });
    };

    const makeSpecialityAvailable = () => {

        let specialityDropDownData = [{
            value: "", label: "All Speciality"
        }]

        physicianSpecialitysFromAPI && physicianSpecialitysFromAPI.map((item) => { //eslint-disable-line
            if (item.amount > 0) {
                specialityDropDownData.push({ value: item.specialityID, label: item.speciality })
            }
        })
        if (specialityDropDownData.length > 0) { setPhysicianSpecialityDropDownList(specialityDropDownData) }
    }

    const getAllAvailableSlot = () => {
        setSpinner(true)
        const param = {
            "user_id": userId,
            "startAt": startDateTime,
            "endAt": endDateTime,
            "specialityID": 0
        }
        const paramWithSpeciality = {
            "user_id": userId,
            "startAt": startDateTime,
            "endAt": endDateTime,
            "specialityID": PhysicianSpeciality.value
        }
        axios.post(`${API_ENDPOINT}/user/get-physician-schedule-data/`,PhysicianSpeciality.value ? paramWithSpeciality : param , {
            headers: {
                'Authorization': token
            }
        }).then(function (response) {
            setSpinner(false)
            timeZone = response.data.tz;
            utc_offset = response.data.utc_offset;
            setTimeZoneCard(response.data.tz);

            const processSlotColor = (status) => {
                // console.log('status', status)
                switch (status) {
                    case "Available":
                        return { backgroundColor: "#1b3b44", borderColor: "white" }
                        break; // eslint-disable-line
                    case "Unavailable":
                        return { backgroundColor: "#7f8389", borderColor: "white", className: "unavailable-slots" }
                        break; // eslint-disable-line
                    case "Scheduled":
                        return { backgroundColor: "#38C485", borderColor: "white" }
                        break; // eslint-disable-line
                    case "In Progress":
                        return { backgroundColor: "#EFB918", borderColor: "white" }
                        break; // eslint-disable-line
                    case "Completed":
                        return { backgroundColor: "#6499D2", borderColor: "white" }
                        break; // eslint-disable-line
                    case "Cancelled":
                        return { backgroundColor: "#FF5A5A", borderColor: "white" }
                        break; // eslint-disable-line
                    default:
                        return { backgroundColor: "#00a6ae", borderColor: "white" }
                }
            }

            if (response.data && response.data.data) {
                let color = "";
                updateCalendarSlotDetails(response.data.data.map(slots => {
                    let { date, updatedSlots } = updateAvailableSlotsToLocalTimeForCalendar(slots);
                    let startTime = date.start + ' ' + updatedSlots.startTime;
                    let endTime = date.end + ' ' + updatedSlots.endTime;
                    color = slots.app_status ? processSlotColor(slots.app_status) : processSlotColor(slots.status);
                    return { start: startTime, end: endTime, extendedProps: {specialityText: updatedSlots.specialityText} ,backgroundColor: color.backgroundColor, borderColor: color.borderColor, className: color.className };
                }));
            }
        })
            .catch(function (error) {
                setSpinner(false)
                // console.log(error);
            });
    }

    // EFFECT FOR CHANGING THE DATE RANGES 
    useEffect(() => {
        processStartDate();
        processEndDate();
    }, [week]) // eslint-disable-line

    // EFFECT FOR CALLING API WHEN DATE CHANGES  
    useEffect(() => { if (physicianId) getUserDetails(); },[physicianId]) // eslint-disable-line
    

    useEffect(() => { if (physicianSpecialitysFromAPI) makeSpecialityAvailable() },[physicianSpecialitysFromAPI]) // eslint-disable-line
    
    // EFFECT FOR CALLING API WHEN DATE CHANGES  
    useEffect(() => { if (startDateTime && endDateTime) getAllAvailableSlot() }, [startDateTime, endDateTime, PhysicianSpeciality]) // eslint-disable-line

    // EFFECT FOR RE-ENABLING SLOTS
    useEffect(() => { if (enableSlot && slotStartDateTime && slotEndDateTime) setCancelSlotModal(true) }, [enableSlot, slotStartDateTime, slotEndDateTime])

    return (
        <Card className="availability-card disable-scrollbars">
            <Spinner style={spinner ? { "display": "block" } : { "display": "none" }} className="physician-spinner" />
            <CardHeader className="schedule-card-adjustment">
                <CardTitle tag="h5" className="mb-0">
                    {physicianName}'s Schedule
                    <div className="schedule-legend">
                        <span className="legend-available"></span>Available
                        <span className="legend-unavailable"></span>Unavailable
                        <span className="legend-scheduled"></span>Scheduled
                        <span className="legend-in-progress"></span>In Progress
                        <span className="legend-completed"></span>Completed
                    </div>
                </CardTitle>
            </CardHeader>
            {timeZoneCard
                ?
                <div>
                    <div className="time-zone-card">
                        <span>
                            <span className="time-zone-exclamation">
                                <FontAwesomeIcon
                                    icon={faExclamationCircle}
                                    className="align-middle"
                                />
                            </span>
                            {`Schedule is displayed based on the doctor's timezone "${timeZoneCard}" (${momentTimeZone.tz(timeZoneCard).format('z')})`}
                        </span>
                    </div>
                </div>
                : ""}
                
            {calendarSlot ?
                <Row /* className="container" */>
                   
                   
                    <div className="schedule-calender disable-scrollbars">

                    <div className="speciality-dropdown">
                                <Select
                                    value={PhysicianSpeciality ? PhysicianSpeciality : PhysicianSpecialityDropDownList[0]}
                                    options={PhysicianSpecialityDropDownList}
                                    onChange={(event) => setPhysicianSpeciality(event)}
                                    placeholder="Select"
                                />
                            </div>

                        <div className="cancel-slot-button-container">
                            
                            <Button
                                className="cancel-slot-button"
                                color="primary"
                                onClick={() => { setEnableSlot(false); setCancelSlotModal(true) }}
                            >Cancel Slot
                        </Button>
                        </div>
                        <ScheduleCalendar
                            timeSlotDetailsCalendar={calendarSlot}
                            setEnableSlot={setEnableSlot}
                            setSlotStartDateTime={setSlotStartDateTime}
                            setSlotEndDateTime={setSlotEndDateTime}
                            utc_offset={utc_offset}
                        />
                    </div>

                </Row>
                : null
            }
            <CancelSlotModal
                cancelSlotModal={cancelSlotModal}
                setCancelSlotModal={setCancelSlotModal}
                utc_offset={utc_offset}
                userId={userId}
                getAllAvailableSlot={getAllAvailableSlot}
                slotStartDateTime={slotStartDateTime}
                slotEndDateTime={slotEndDateTime}
                setSlotStartDateTime={setSlotStartDateTime}
                setSlotEndDateTime={setSlotEndDateTime}
                enableSlot={enableSlot}
                PhysicianSpecialityDropDownList={PhysicianSpecialityDropDownList}
            />
        </Card>
    )
}

const AvailableHours = () => {
    // CONTEXT VARIABLES
    const { physicianName } = useContext(GlobalState);
    const [pathName, setPathName] = useState("");
    let location = useLocation();

    const getUserTypeFromURL = () => {
        let pathName = location.pathname
        let userTypeInURL = pathName.split("/")
        let filtered = userTypeInURL.filter(function (item) { return item !== "" })
        userTypeInURL = filtered[0]
        userTypeInURL = userTypeInURL.toLocaleLowerCase()
        userTypeInURL = userTypeInURL.charAt(0).toUpperCase() + userTypeInURL.slice(1)
        setPathName(userTypeInURL)
    }

    // EFFECT TO CHANGE THE BREADCRUMB BASE ON URL
    useEffect(() => { getUserTypeFromURL() }, []) // eslint-disable-line

    return (
        <>
            <Header>
                <HeaderTitle>Settings</HeaderTitle>
                <Breadcrumb>
                    <BreadcrumbItem>
                    <a href={`/${pathName.toLowerCase()}`}>{pathName}</a>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>Schedule</BreadcrumbItem>
                    <BreadcrumbItem active>{physicianName}</BreadcrumbItem>
                </Breadcrumb>
            </Header>
            <Row>
                <Col md="3" xl="2">
                    <Navigation />
                </Col>
                <Col md="9" xl="10">
                    <AvailableHoursInfo />
                </Col>
            </Row>
        </>
    )
}


const getHourFromTime = (param) => {
    return parseInt(param.split("T")[1].split(":")[0])
}

const getMinuteFromTime = (param) => {
    return parseInt(param.split("T")[1].split(":")[1])
}

const getDateFromTime = (param) => {
    return param.split("T")[0]
}

function calculateLocalOffset(dateTime) {
    // UTC OFFSET ADJUSTMENT 
    let utcEquivalentOfPhysicianTime = moment(dateTime, "YYYY-MM-DD HH:mm:ss").add(utc_offset, 'm').format()
    return utcEquivalentOfPhysicianTime
}

export default connect(store => ({
    sidebar: store.sidebar
}))(AvailableHours);
