import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const ConfirmationModal = (props) => {
    const { confirmationModal, setConfirmationModal, setStatusChangeConfirmation } = props;

    const toggle = () => setConfirmationModal(!confirmationModal);
    const confirmation = () => { setStatusChangeConfirmation(true); setConfirmationModal(!confirmationModal) };

    return (
        <div>
            <Modal isOpen={confirmationModal} toggle={toggle}>
                <ModalHeader tag="h4" cssModule={{'modal-title': 'w-100 text-center'}}  toggle={toggle}>Change Prescription Status</ModalHeader>
                <ModalBody>
                    Are you sure you want to change prescription status to "Pharmacy Approved"?
                </ModalBody>
                <ModalFooter>
                    <Button className="yes-button button-width" onClick={confirmation}>Yes</Button>{' '}
                    <Button outline className="no-button button-width" onClick={toggle}>No</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default ConfirmationModal;