import React from "react";
import { Container, Row, Col } from "reactstrap";
import ApptTrendsGraph from "./ApptTrendsGraph";
import TodayAppointmentsTable from "./TodayAppointmentsTable";

import Header from "../../../components/Header";
import HeaderTitle from "../../../components/HeaderTitle";
import { Hux } from "./../../../hoc/Hux"
import "../Default/Index.css"

const Default = () => (
  <Hux>
    <Container fluid>
      <Header>
        <HeaderTitle>Welcome to Equipo Health Admin Dashboard</HeaderTitle>
      </Header>
      <Row>
        <Col lg="7">
          <TodayAppointmentsTable />
        </Col>
        <Col lg="5" className="todays-appt-card" >
          <ApptTrendsGraph />
        </Col>
      </Row>
      <Row>
      </Row>
    </Container>
  </Hux>
);

export default Default;
