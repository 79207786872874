import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, FormGroup, Label, Spinner } from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import AppointmentCard from './AppointmentCard'

const CancelSlotModal = (props) => {
  // DEPENDENCY VARIABLES  
  const {
    cancelSlotModal,
    setCancelSlotModal,
    utc_offset,
    userId,
    getAllAvailableSlot,
    slotStartDateTime,
    slotEndDateTime,
    setSlotStartDateTime,
    setSlotEndDateTime,
    enableSlot,
    PhysicianSpecialityDropDownList
  } = props;

  // LOCAL VARIABLES
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  let token = localStorage.getItem('token')
  let userID = localStorage.getItem("userId")
  const [spinner, setSpinner] = useState(false)
  const [startDate, setStartDate] = useState(moment().toDate())
  const [endDate, setEndDate] = useState(moment().toDate())
  const [startTime, setStartTime] = useState("")
  const [endTime, setEndTime] = useState("")
  const [startDateTime, setStartDateTime] = useState("")
  const [endDateTime, setEndDateTime] = useState("")
  const [startTimeCalculated, setStartTimeCalculated] = useState(false)
  const [endTimeCalculated, setEndTimeCalculated] = useState(false)
  const [appointments, setAppointments] = useState("")
  const [appointment, setAppointment] = useState("")
  const [startTimePrePopulated, setStartTimePrePopulated] = useState(false)
  const [endTimePrePopulated, setEndTimePrePopulated] = useState(false)
  const [slotsValidated, setSlotsValidated] = useState(false)
  const [PhysicianSpeciality, setPhysicianSpeciality] = useState("");

  // MODAL VARIABLES
  const [userConfirmation, setUserConfirmation] = useState(false)

  const toggle = () => {
    setNewerSlots([{ startTime: 'Select', endTime: 'Select' }])
    setStartDate(moment().toDate())
    setEndDate(moment().toDate())
    setAppointments("")
    setAppointment("")
    setSlotStartDateTime("")
    setSlotEndDateTime("")
    setStartTimePrePopulated(false);
    setEndTimePrePopulated(false);
    setCancelSlotModal(false);
    setSlotsValidated(false);
  };

  const confirmation = () => {
    setUserConfirmation(true);
  };

  const [newerSlots, setNewerSlots] = useState([{ startTime: 'Select', endTime: 'Select' }]);
  const handleAddSlot = (index, slot) => {
    const values = [...newerSlots];
    values.push(slot);
    setNewerSlots(values);
  }

  const changeSlot = (index, slot) => {
    const values = [...newerSlots];
    values[index] = slot;
    setNewerSlots(values);
  }

  const handleRemoveSlot = (index, slot) => {
    const values = [...newerSlots];
    values.splice(index, 1);
    setNewerSlots(values);

  }

  const TimeSlot = ({ newSlot, index, slotAdd, slotRemove, changeSlot, lastItem }) => {

    const allAvailableStartTimeSlots = ["Select", "00:00 AM", "00:15 AM", "00:30 AM", "00:45 AM", "01:00 AM", "01:15 AM", "01:30 AM", "01:45 AM", "02:00 AM", "02:15 AM", "02:30 AM", "02:45 AM", "03:00 AM", "03:15 AM", "03:30 AM", 
    "03:45 AM", "04:00 AM", "04:15 AM", "04:30 AM", "04:45 AM", "05:00 AM", "05:15 AM", "05:30 AM", "05:45 AM", "06:00 AM", "06:15 AM", "06:30 AM", "06:45 AM", "07:00 AM", "07:15 AM", "07:30 AM", "07:45 AM", "08:00 AM", "08:15 AM", "08:30 AM", "08:45 AM", "09:00 AM", "09:15 AM", "09:30 AM", "09:45 AM", "10:00 AM", "10:15 AM", "10:30 AM", "10:45 AM", "11:00 AM", "11:15 AM", "11:30 AM", "11:45 AM",
      "12:00 PM", "12:15 PM", "12:30 PM", "12:45 PM", "01:00 PM", "01:15 PM", "01:30 PM", "01:45 PM", "02:00 PM", "02:15 PM", "02:30 PM", "02:45 PM", "03:00 PM", "03:15 PM", "03:30 PM", "03:45 PM", "04:00 PM", "04:15 PM", "04:30 PM",
      "04:45 PM", "05:00 PM", "05:15 PM", "05:30 PM", "05:45 PM", "06:00 PM", "06:15 PM", "06:30 PM", "06:45 PM", "07:00 PM", "07:15 PM", "07:30 PM", "07:45 PM", "08:00 PM", "08:15 PM", "08:30 PM", "08:45 PM", "09:00 PM", "09:15 PM", "09:30 PM", "09:45 PM", "10:00 PM", "10:15 PM", "10:30 PM", "10:45 PM"];

    const allAvailableEndTimeSlots = ["Select", "00:15 AM", "00:30 AM", "00:45 AM", "01:00 AM", "01:15 AM", "01:30 AM", "01:45 AM", "02:00 AM", "02:15 AM", "02:30 AM", "02:45 AM", "03:00 AM", "03:15 AM", "03:30 AM", 
    "03:45 AM", "04:00 AM", "04:15 AM", "04:30 AM", "04:45 AM", "05:00 AM", "05:15 AM", "05:30 AM", "05:45 AM", "06:00 AM", "06:15 AM", "06:30 AM", "06:45 AM", "07:00 AM", "07:15 AM", "07:30 AM", "07:45 AM", "08:00 AM", "08:15 AM", "08:30 AM", "08:45 AM", "09:00 AM", "09:15 AM", "09:30 AM", "09:45 AM", "10:00 AM", "10:15 AM", "10:30 AM", "10:45 AM", "11:00 AM", "11:15 AM", "11:30 AM", "11:45 AM",
      "12:00 PM", "12:15 PM", "12:30 PM", "12:45 PM", "01:00 PM", "01:15 PM", "01:30 PM", "01:45 PM", "02:00 PM", "02:15 PM", "02:30 PM", "02:45 PM", "03:00 PM", "03:15 PM", "03:30 PM", "03:45 PM", "04:00 PM", "04:15 PM", "04:30 PM",
      "04:45 PM", "05:00 PM", "05:15 PM", "05:30 PM", "05:45 PM", "06:00 PM", "06:15 PM", "06:30 PM", "06:45 PM", "07:00 PM", "07:15 PM", "07:30 PM", "07:45 PM", "08:00 PM", "08:15 PM", "08:30 PM", "08:45 PM", "09:00 PM", "09:15 PM", "09:30 PM", "09:45 PM", "10:00 PM", "10:15 PM", "10:30 PM", "10:45 PM", "11:00 PM"];

    // const [availableStartTimeSlots] = useState(allAvailableStartTimeSlots);
    const [availableEndTime, setAvailableEndTime] = useState(allAvailableEndTimeSlots); // eslint-disable-line
    const [dummySlot, setDummySlot] = useState(newSlot);
    const [newStartTimeOptions, setNewStartTimeOptions] = useState("");
    const [newEndTimeOptions, setNewEndTimeOptions] = useState("");

    // let availableStartTimeSlotsOptions = availableStartTimeSlots.map(function (time) {
    //   return { value: time, label: time };
    // })

    // useEffect(() => {
    //   if (availableEndTime.indexOf(dummySlot.endTime) < 0) {
    //     setDummySlot({ startTime: dummySlot.startTime, endTime: availableEndTime[0] })
    //     changeSlot(index, { startTime: dummySlot.startTime, endTime: availableEndTime[0] });
    //   }
    // }, [availableEndTime, dummySlot.endTime]);

    useEffect(() => {
      setAvailableEndTime(allAvailableEndTimeSlots.slice(allAvailableStartTimeSlots.indexOf(dummySlot.startTime)));
    }, [dummySlot.startTime]); // eslint-disable-line

    useEffect(() => {
      setDummySlot(newSlot);
    }, [newSlot])

    useEffect(() => {
      setStartTime(dummySlot.startTime)
      setEndTime(dummySlot.endTime)
    }, [dummySlot])

    const handleChangeStartTime = (event) => {
      setDummySlot({ startTime: event.value, endTime: dummySlot.endTime })
      changeSlot(index, { startTime: event.value, endTime: dummySlot.endTime });
    }

    const handleChangeEndTime = (event) => {
      setDummySlot({ startTime: dummySlot.startTime, endTime: event.value })
      changeSlot(index, { startTime: dummySlot.startTime, endTime: event.value });
    }

    // let availableEndTimeSlotsOptions = availableEndTime.map(function (time) {
    //   return { value: time, label: time };
    // });

    const startTimeDropDownOptions = () => {
      let slotDate = moment(startDate).format('YYYY-MM-DD')
      let currentTime = moment().format('hh:mm A')
      let localStartDate = moment().format('YYYY-MM-DD')
      let localDateTime = `${localStartDate} ${currentTime}`
      let localToUtcTime = moment(localDateTime).utc().format('YYYY-MM-DD hh:mm A')
      let physicianLocalTime = moment(localToUtcTime, 'YYYY-MM-DD hh:mm A').add(utc_offset, 'm')

      let array = [{ value: "Select", label: "Select" }]
      allAvailableStartTimeSlots.map(item => {
        let slotDateTime = item !== "Select" ? moment(`${slotDate} ${item}`, 'YYYY-MM-DD hh:mm A') : ""
        let diff = slotDateTime - physicianLocalTime
        if (diff > 0) array.push({ value: item, label: item })
        return array
      })
      setNewStartTimeOptions(array);
    }

    const EndTimeDropDownOptions = () => {
      let slotDate = moment(endDate).format('YYYY-MM-DD')
      let currentTime = moment().format('hh:mm A')
      let localEndDate = moment().format('YYYY-MM-DD')
      let localDateTime = `${localEndDate} ${currentTime}`
      let localToUtcTime = moment(localDateTime).utc().format('YYYY-MM-DD hh:mm A')
      let physicianLocalTime = moment(localToUtcTime, 'YYYY-MM-DD hh:mm A').add(utc_offset, 'm')

      let array = [{ value: "Select", label: "Select" }]
      allAvailableEndTimeSlots.map(item => {
        let slotDateTime = item !== "Select" ? moment(`${slotDate} ${item}`, 'YYYY-MM-DD hh:mm A').subtract(15, 'm') : ""
        let diff = slotDateTime - physicianLocalTime
        if (diff > 0) array.push({ value: item, label: item })
        return array
      })
      setNewEndTimeOptions(array);
    }

    const formatSlotTime = dateTime => moment(dateTime).format('hh:mm A')

    useEffect(() => { startTimeDropDownOptions(); EndTimeDropDownOptions(); }, [startDate, endDate]) // eslint-disable-line

    useEffect(() => {
      let slotStartTime = ""
      if (!startTimePrePopulated && slotStartDateTime && newStartTimeOptions) {
        slotStartTime = { value: formatSlotTime(slotStartDateTime), label: formatSlotTime(slotStartDateTime) }
        handleChangeStartTime(slotStartTime)
        setStartTimePrePopulated(true)
      }
    }, [slotStartDateTime, newStartTimeOptions, enableSlot]) // eslint-disable-line

    useEffect(() => {
      let slotEndTime = ""
      if (startTimePrePopulated && !endTimePrePopulated && slotEndDateTime && newEndTimeOptions) {
        slotEndTime = { value: formatSlotTime(slotEndDateTime), label: formatSlotTime(slotEndDateTime) }
        handleChangeEndTime(slotEndTime)
        setEndTimePrePopulated(true)
      }
    }, [slotEndDateTime, newEndTimeOptions, enableSlot]) // eslint-disable-line

    return (
      <Row className="time-duration-row">
        <Col md="4">
          <FormGroup>
            <Label>Start Time</Label>
            <Select
              className="react-select-container startTime"
              classNamePrefix="react-select"
              options={newStartTimeOptions}
              placeholder="Select"
              value={newStartTimeOptions ? newStartTimeOptions.filter(option => option.value === dummySlot.startTime) : ""}
              onChange={e => handleChangeStartTime(e)}
              isSearchable
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup className="test-class">
            <Label>End Time</Label>
            <Select
              className="react-select-container endTime"
              classNamePrefix="react-select"
              options={newEndTimeOptions}
              value={newEndTimeOptions ? newEndTimeOptions.filter(option => option.value === dummySlot.endTime) : ""}
              placeholder="Select"
              onChange={e => handleChangeEndTime(e)}
              isSearchable
            />
          </FormGroup>
        </Col>
      </Row>
    )
  }

  const processAppointment = () => {
    console.log("hello "+ appointments)
    let data = appointments.map(item => ({
      "appTime": item.schStartTime,
      "patientName": item.patient_full_name
    }))
    setAppointment(data)
  }

  // CREATES APPOINTMENT CARDS
  const appointmentCards = appointment ? appointment.map(item => <AppointmentCard appointment={item} utc_offset={utc_offset} />) : ""

  const calculateUtcOffset = (dateTime) => {
    // UTC OFFSET ADJUSTMENT 
    let utcEquivalentOfPhysicianTime = moment(dateTime, "YYYY-MM-DD HH:mm A").subtract(utc_offset, 'm').format('YYYY-MM-DD HH:mm:ss')
    return utcEquivalentOfPhysicianTime
  }

  const callValidationAPI = () => {
    setSpinner(true)
    const params = {
      "user_id": userId,
      "startAt": startDateTime,
      "endAt": endDateTime
    }

    axios.post(`${API_ENDPOINT}/user/get-physician-appointment-list/`, params, {
      headers: {
        'Authorization': token
      }
    }).then(function (response) {
      if (response.status === 200) {
        setAppointments(response.data)
        setSlotsValidated(true)
      } else {
        setAppointments("")
        setAppointment("")
        setSlotsValidated(true)
      }
      setSpinner(false)
    })
      .catch(function (error) {
        // console.log(error);
        setSpinner(false)
      });
    setStartTimeCalculated(false)
    setEndTimeCalculated(false)
  }

  const callCancellationAPI = () => {
    setSpinner(true)
    setUserConfirmation(false);
    const params = {
      "userID": Number(userID),
      "user_id": userId,
      "startAt": startDateTime,
      "endAt": endDateTime,
      "isActivate": enableSlot ? 1 : 0,
      "specialityId": PhysicianSpeciality ? PhysicianSpeciality.value.toString() : ""
    }

    axios.post(`${API_ENDPOINT}/user/set-physician-cancel-slots/`, params, {
      headers: {
        'Authorization': token
      }
    }).then(function (response) {
      debugger
      setSpinner(false)
      getAllAvailableSlot();
      toggle();
      /* toastr.success('Selected Slot(s) Are Cancelled Successfully') */
      toastr.success('Success', response.data.message)
    })
      .catch(function (error) {
        // console.log(error);
        setSpinner(false)
        setNewerSlots([{ startTime: 'Select', endTime: 'Select' }])
        toastr.error(error.response.data.message, '')
      });
  }

  // EFFECTs TO CREATE PARAMETER FOR API CALLS
  useEffect(() => {
    // BALANCE DATE RANGE  
    if (startDate - endDate > 0) setEndDate(startDate)
    // SET START TIME
    if (startTime !== "Select") {
      let tempStartDateTime = `${moment(startDate).format('YYYY-MM-DD')} ${startTime}`
      tempStartDateTime = calculateUtcOffset(tempStartDateTime)
      setStartDateTime(tempStartDateTime)
      setStartTimeCalculated(true)
      setEndTimeCalculated(true)
    }
  }, [startDate, startTime]) // eslint-disable-line

  useEffect(() => {
    // BALANCE DATE RANGE  
    if (endDate - startDate < 0) setStartDate(endDate)
    // SET END TIME
    if (endTime !== "Select") {
      let tempEndDateTime = `${moment(endDate).format('YYYY-MM-DD')} ${endTime}`
      tempEndDateTime = calculateUtcOffset(tempEndDateTime)
      setEndDateTime(tempEndDateTime)
      setStartTimeCalculated(true)
      setEndTimeCalculated(true)
    }
  }, [endDate, endTime]) // eslint-disable-line

  useEffect(() => {
    // CALL VALIDATION API IF ANY 4 INPUT CHANGES
    if (!spinner
      && !enableSlot
      && startTimeCalculated
      && endTimeCalculated
      && startTime !== "Select"
      && endTime !== "Select"
    ) {
      callValidationAPI()
    }
  }, [startDateTime, endDateTime, slotStartDateTime, slotEndDateTime, enableSlot]) // eslint-disable-line

  useEffect(() => { if (userConfirmation) callCancellationAPI() }, [userConfirmation]) // eslint-disable-line

  useEffect(() => { if (appointments) processAppointment() }, [appointments]) // eslint-disable-line

  useEffect(() => { if (cancelSlotModal) setSlotsValidated(false) }, [cancelSlotModal])

  useEffect(() => {
    if (slotStartDateTime && slotEndDateTime) {
      setStartDate(moment(slotStartDateTime).toDate())
      setEndDate(moment(slotEndDateTime).toDate())
    }
  }, [slotStartDateTime, slotEndDateTime, enableSlot])

  return (
    <Modal isOpen={cancelSlotModal} toggle={toggle}>
        <ModalHeader tag="h4" cssModule={{'modal-title': 'w-100 text-center'}}  toggle={toggle}>Update Availability</ModalHeader>
        <ModalBody>
          {/* SPINNER */}
          {spinner ? <Spinner /> : ""}
                    
            <Select
                value={PhysicianSpeciality ? PhysicianSpeciality : PhysicianSpecialityDropDownList[0]}
                options={PhysicianSpecialityDropDownList}
                onChange={(event) => setPhysicianSpeciality(event)}
                placeholder="Select"
              />
          <br/>
          <Row>
            <Col>
              {enableSlot ? <p><strong>Are you sure you want to mark the below slot(s) available?</strong></p> : ""}
            </Col>
          </Row>
          <Row>
            <Col className="date-label-spacer">From Date</Col>
            <Col className="date-label-spacer">To Date</Col>
          </Row>
          <Row>
            <Col >
              <DatePicker
                selected={startDate}
                minDate={moment().toDate()}
                dateFormat="dd MMM yyyy"
                onChange={date => setStartDate(date)}
              />
            </Col>
            <Col>
              <FontAwesomeIcon
                icon={faCalendarAlt}
                fixedWidth
                className="align-middle schedule-calendar-logo"
              />{" "}
            </Col>
            <Col >
              <DatePicker
                selected={endDate}
                minDate={moment().toDate()}
                dateFormat="dd MMM yyyy"
                onChange={date => setEndDate(date)}
              />
            </Col>
            <Col>
              <FontAwesomeIcon
                icon={faCalendarAlt}
                fixedWidth
                className="align-middle schedule-calendar-logo"
              />{" "}
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              {newerSlots.length > 0 ?
                newerSlots.map((newSlot, idx) => {
                  return (
                    <TimeSlot newSlot={newSlot} index={idx} changeSlot={changeSlot} slotAdd={handleAddSlot} slotRemove={handleRemoveSlot} lastItem={newerSlots.length - 1 === idx} />
                  );
                }) : <Button className="mr-1 mb-1 add-row-btn btn-primary" onClick={() => setNewerSlots([{ startTime: '06:00 AM', endTime: '06:15 AM' }])}>
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
              }
            </Col>
        </Row>
        {appointments
          ? <Row>
            <Col>
              <div className="availability-disclaimer">
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                />
                <Label className="availability-warning-text">
                  Please note that the slot(s) listed below are already booked by patients, those won't be cancelled.
                  Please click on 'Mark As Unavailable' to cancel the remaining slots
                </Label>
              </div>
            </Col>
          </Row>
          : ""}

          <div className="appointment-found-modal-height disable-scrollbars">
          <Row>
            <Col>
            <div className="appointment-card-container">
              {/* APPOINTMENT CARD */}
              {!enableSlot ? appointmentCards : ""}
            </div>
            </Col>
          </Row>
        </div>
        </ModalBody>
        <ModalFooter>
          <div className="cancel-slot-footer">
          {enableSlot
            ? <Button
              className="cancel-button button-width"
              onClick={confirmation}
            >
              Mark As Available
            </Button>
            : <Button
              className="cancel-button button-width"
              onClick={confirmation}
              disabled={slotsValidated ? false : true}
              title={slotsValidated ? "" : "Select Start Time"}
            >
              Mark As Unavailable
          </Button>}
          {" "}{" "}
          <Button outline className="no-button button-width" onClick={toggle}>
            Close
            </Button>
          </div>
        </ModalFooter>
      </Modal>
  );
};

export default CancelSlotModal;
