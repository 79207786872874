import React, { useContext } from 'react'
import { GlobalState } from '../../contextAPI/Context'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import axios from "axios";
import { toastr } from 'react-redux-toastr';

const EmailPharmacyModal = (props) => {
    // DEPENDENCY VARIABLES 
    const { rowData, emailPharmacyModal, setEmailPharmacyModal } = props;
    // CONTEXT VARIABLES 
    const { spinner, setSpinner, prescriptionReload, setPrescriptionReload } = useContext(GlobalState);
    let token = localStorage.getItem('token');
    let userId = localStorage.getItem('userId');
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

    const sendEmailToPharmacy = () => {
        setSpinner(true);

        const params =
        {
            "to_email": rowData.pharmacy_email,
            "appointment_id": rowData.appointment_id,
            "note_type": rowData.note_type,
            "uploader_id": Number(userId)
        }

        axios.post(API_ENDPOINT + "/external/pharmacy-mail/", params, {
            headers: {
                Authorization: token,
            }
        }).then((res) => {
            setSpinner(false);
            setPrescriptionReload(prescriptionReload + 1)
            toggle();
            toastr.success("Success", "Email sent to pharmacy")
        }).catch(function (error) {
            console.log(error);
            setSpinner(false);
            toastr.error("Email Not Sent", "Please try again")
        });
    };

    const toggle = () => setEmailPharmacyModal(!emailPharmacyModal)
    const confirmation = () => sendEmailToPharmacy()

    return (
        <div>
            <Modal isOpen={emailPharmacyModal} toggle={toggle}>
                <ModalHeader tag="h4" cssModule={{'modal-title': 'w-100 text-center'}}  toggle={toggle}>Send Prescription - {rowData ? rowData.patient_name : ""}</ModalHeader>
                <ModalBody>
                    Are you sure you want to send the prescription to "{rowData ? rowData.pharmacy_name : ""}" ({rowData ? rowData.pharmacy_email : ""})?
                </ModalBody>
                <ModalFooter>
                    <Button className="yes-button button-width" onClick={confirmation}>Yes</Button>{' '}
                    <Button outline className="no-button button-width" onClick={toggle}>No</Button>
                </ModalFooter>
                {spinner ? <Spinner /> : ""}
            </Modal>
        </div>
    );
}

export default EmailPharmacyModal;