import React, {   useContext } from 'react';
import { GlobalState } from '../../contextAPI/Context';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import axios from 'axios';
import { toastr } from 'react-redux-toastr';

const StatusChangeModal = (props) => {
  const {
    modalState,
    setModalState,
    
    CommisionFeePercent,
    ProcessingFeePercent,
    IsVat,
    Status,
    VatPercentage,
    RuleId,
  } = props;

  const {
    ruleConfigListReload, 
    setRuleConfigListReload,

  } = useContext(GlobalState);

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  let userID = localStorage.getItem("userId");

  /* const [newStatus, setNewStatus] = useState("initial"); */

  let newStatus = ""

  const toggle = () => {
    
    setModalState(!modalState);
  };

  const StatusUpdate = () => {
    if(Status === 1) {
      newStatus = 0 
    }
    else {
      newStatus = 1
    }
    updateRuleConfigurationApiCall(); 
  };

  const updateRuleConfigurationApiCall = () => {
    axios.post(`${API_ENDPOINT}/external/update-payment-rule/`, {
      
        "rule_id": RuleId,
        "commision_fee_percent": CommisionFeePercent ? Number(CommisionFeePercent) : 0,
        "processing_fee_percent": ProcessingFeePercent ? Number(ProcessingFeePercent)  : 0,
        "is_vat": IsVat ? 1 : 0,
        "vat_percentage": VatPercentage ? Number(VatPercentage) : 0,
        "status": newStatus,
        "updated_by": userID
    }, {
        headers: {
            Authorization: props.token,
        }
    })
        .then(function (response) {
            setModalState(!modalState);
            setRuleConfigListReload(ruleConfigListReload + 1)
            toastr.success("Successfully Deleted", "Payment configuration deleted successfully")
        })
        .catch(function (error) {
            // console.log(error);
            toastr.error("Failed", "please try again")
        });
    }
 

  return (
    <div>
      <Modal isOpen={modalState} toggle={toggle}>
        <ModalHeader tag="h4" cssModule={{'modal-title': 'w-100 text-center'}}  toggle={toggle}>Delete Payment Configuration</ModalHeader>
        <ModalBody>
            <div>
              Are you sure you want to delete payment configuration ?
            </div>
        </ModalBody>
        <ModalFooter>
          <Button className="yes-button button-width" onClick={StatusUpdate}>
            Yes
          </Button>{" "}
          <Button outline className="no-button button-width" onClick={toggle}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default StatusChangeModal;
