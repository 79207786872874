import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Row,
  Col,
} from "reactstrap";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import { connect } from "react-redux";
import MomentConfig from "../../../common/MomentConfig";
import BootstrapTable from "react-bootstrap-table-next";
import axios from "axios";
import { RefreshCw } from "react-feather";
import ReactTooltip from 'react-tooltip';

const TodayAppointmentsTable = (props) => {
  const [TodayAppointments, setTodayAppointments] = useState([]);
  const [NodataFound, setNodataFound] = useState(false);
  const [Loading, setLoading] = useState(true);

  const DateFormatter = (cell) => {
    return moment(cell).utc(cell).local().format(MomentConfig.MOMENT_FORMAT_DATE);
  };

  const SlotFormatter = (cell) => {
    return moment(cell).utc(cell).local().format("h:mm A");
  };

  const StatusFormatter = (cell) => {
    if (cell === "Completed") {
      return <span className="bg-completed table-status">{cell}</span>;
    } else if (cell === "In Progress") {
      return <span className="bg-inprogress table-status">{cell}</span>;
    } else if (cell === "Scheduled") {
      return <span className="bg-scheduled table-status">{cell}</span>;
    } else if (cell === "Cancelled") {
      return <span className="bg-cancelled table-status">{cell}</span>;
    }else if(cell === "No Show"){
      return <span className="bg-no-show table-status">{cell}</span>;
    }else{
      return <span className="nostatus table-status">{cell}</span>;
    }
  };

  const LongNameFormatter = (cell) => {

    return (
      <div className="care-provider-name">
      <span className="sidebar-item" data-tip={cell} data-for="name">
      {cell}
      </span>
        <ReactTooltip place="top" type="dark" effect="float" className="card-tooltip"  id="name" />
      </div>
    )
  }

  const tableColumns = [
    {
      dataField: "patient_name",
      text: "Patient",
    },
    {
      dataField: "onsetDate",
      text: "Appt Date",
      formatter: DateFormatter,
    },
    {
      dataField: "schStartTime",
      text: "Start Slot",
      formatter: SlotFormatter,
    },
    {
      dataField: "appStatus",
      text: "Status",
      formatter: StatusFormatter,
    },
    {
      dataField: "physician_name",
      text: "Care Provider",
      formatter: LongNameFormatter,
    },
  ];

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

  const SkeletonLoader = (
    <Row>
      <Col className="m-3">
        <Skeleton height={25} />
        <Skeleton height={25} />
        <Skeleton height={25} />
        <Skeleton height={25} />
        <Skeleton height={25} />
        <Skeleton height={25} />
        <Skeleton height={25} />
        <Skeleton height={25} />
        <Skeleton height={25} />
        <Skeleton height={25} />
        <Skeleton height={25} />
        <Skeleton height={25} />
      </Col>
    </Row>
  );

  const formData = {
    appStatusID: "",
    appointmentStatusID: "",
    coupon_code: "",
    from_date: moment().startOf("day").utc().format("YYYY-MM-DD H:mm:ss"),
    to_date: moment().endOf("day").utc().format("YYYY-MM-DD H:mm:ss"),
    group_id: "",
    physician_name: "",
    provider_id: "",
  };

  const fetchAppointments = () => {
    axios.post(API_ENDPOINT + "/user/appointment-details/", formData, {
      headers: {
        Authorization: props.authtoken,
      },
    })
      .then((res) => {
        if (res.status === 201) {
          if (res.data.length === 0) {
            setNodataFound(true);
            setLoading(false);
          } else {
            // setTodayAppointments(res.data.slice(0, 7));
            setTodayAppointments(res.data);
            setLoading(false);
            
          }
        } else {
          setNodataFound(true);
        }
      })
      .catch(function (error) {
        setLoading(false);
        setNodataFound(true);
      });
  };

  const refresh = () => {
    fetchAppointments();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  };
  

  const blankState = (
    <Card className="blank-state">
      <CardBody>
        <h3>No Appointments Found</h3>
      </CardBody>
    </Card>
  );

  useEffect(() => {
    fetchAppointments();
  }, []); // eslint-disable-line


  return (
    <Card className="flex-fill w-100 todays-appt-card">
      <CardHeader>
        <div className="card-actions float-right">
          <a className="view-more" href={`/appointments/?appdate=today`}>
            View More
          </a>
          <span className="cursor-pointer mr-1" onClick={() => refresh()}>
            <RefreshCw />
          </span>{" "}
        </div>
        <CardTitle tag="h5" className="todays-appt-card-header">
          Todays Appointments
        </CardTitle>
      </CardHeader>
      {Loading ? (
        SkeletonLoader
      ) : NodataFound ? (
        blankState
      ) : (
        <div className="todays-appt-table">
          <BootstrapTable
            keyField="id"
            data={TodayAppointments}
            columns={tableColumns}
            bordered={false}
            bootstrap4
          />
        </div>
      )}
    </Card>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
    authtoken: state.auth.token,
  };
};

export default connect(mapStateToProps, null)(TodayAppointmentsTable);
